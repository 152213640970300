import { CircledImageCtr, GenericImageWrapper } from "components/Logo";
import React from "react";
import styled from "styled-components";
import { TEXT } from "theme/texts";
import { useNavigate } from "react-router-dom";
import ResponsiveListTile, { ListTileWrapper } from "components/ListTile";
import Column from "components/Column";
import assets from "assets";

type searchItem = {
  contractAddress: string;
  name: string;
  iconLink: string;
  items: number;
  onClick?: () => void;
};

const PopularNFTCollections = ({ list }: { list: searchItem[] }) => {
  return (
    <ListTileWrapper>
      {list?.map((item) => (
        <RenderResultItem key={item?.contractAddress} item={item} />
      ))}
    </ListTileWrapper>
  );
};

const RenderResultItem = ({ item }: { item: searchItem }) => {
  const navigate = useNavigate();
  return (
    <HoverWrapper>
    <ResponsiveListTile
      onClick={() => {
        item.onClick && item.onClick();
        navigate("/collections/" + item?.contractAddress);
      }}
      prefixIcon={item?.iconLink || assets?.icons?.defaultCollectionIcon}
    >
      <Column>
        <TEXT.Body>{item?.name}</TEXT.Body>
        <TEXT.SubBody>{item?.contractAddress?.slice(0,5)+"...."+item?.contractAddress?.slice(-4)}</TEXT.SubBody>
      </Column>
    </ResponsiveListTile>
    </HoverWrapper>
  );
};

const HoverWrapper = styled.div`
&:hover {
  background: ${({ theme }) => theme.activeRadialGradient};
  border-radius: 0px;
}
`;

export default PopularNFTCollections;
