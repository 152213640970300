import dayjs from "dayjs";
import { TimeWindow } from "types";
import { getOnlyDate } from "./date";

export default function getTimewindowFilteredData(
    chartData:
        | {
            dateTime: string;
        }[]
        | undefined,
    timeWindow: TimeWindow,
    customTime?: { year: number; month: number } | undefined
) {
    if (chartData) {
        const data: Record<string, any>[] = [];
        switch (timeWindow) {
            case TimeWindow.threeMonth: {
                chartData.forEach((value) => {
                    if (
                        dayjs(getOnlyDate(value.dateTime)).isValid() &&
                        dayjs()
                            .subtract(3, "month")
                            .isBefore(dayjs(getOnlyDate(value.dateTime)), "month")
                    ) {
                        data.push(value);
                    }
                });
                break;
            }
            case TimeWindow.sixMonth: {
                chartData.forEach((value) => {
                    if (
                        dayjs(getOnlyDate(value.dateTime)).isValid() &&
                        dayjs()
                            .subtract(6, "month")
                            .isBefore(dayjs(getOnlyDate(value.dateTime)), "month")
                    ) {
                        data.push(value);
                    }
                });
                break;
            }
            case TimeWindow.oneYear: {
                chartData.forEach((value) => {
                    if (
                        dayjs(getOnlyDate(value.dateTime)).isValid() &&
                        dayjs()
                            .subtract(12, "month")
                            .isBefore(dayjs(getOnlyDate(value.dateTime)), "month")
                    ) {
                        data.push(value);
                    }
                });
                break;
            }
            case TimeWindow.twoYear: {
                chartData.forEach((value) => {
                    if (
                        dayjs(getOnlyDate(value.dateTime)).isValid() &&
                        dayjs()
                            .subtract(2, "year")
                            .isBefore(dayjs(getOnlyDate(value.dateTime)), "month")
                    ) {
                        data.push(value);
                    }
                });
                break;
            }
            case TimeWindow.all: {
                chartData.forEach((value) => {
                    if (dayjs(getOnlyDate(value.dateTime)).isValid()) {
                        data.push(value);
                    }
                });
                break;
            }
            case TimeWindow.custom: {
                if (customTime) {
                    const { year, month } = customTime;

                    console.log("customTime", customTime);
                    chartData.forEach((value) => {
                        //  The `month` is coming between 0 to 11, so make it incremented by one to get friendly month number
                        if (
                            dayjs(getOnlyDate(value.dateTime)).isValid() &&
                            dayjs(`${month + 1}-${1}-${year}`, "M-D-YYYY").isBefore(
                                dayjs(getOnlyDate(value.dateTime)),
                                "day"
                            ) &&
                            dayjs(
                                `${month + 1}-${dayjs(
                                    `${month + 1}-${1}-${year}`,
                                    "M-D-YYYY"
                                ).daysInMonth()}-${year}`,
                                "M-D-YYYY"
                            ).isAfter(dayjs(getOnlyDate(value.dateTime)), "day")
                        ) {
                            data.push(value);
                        }
                    });
                    console.log("custom time data", data);
                    break;
                }
                chartData.forEach((value) => {
                    if (dayjs(getOnlyDate(value.dateTime)).isValid()) {
                        data.push(value);
                    }
                });
                break;
            }
            default: {
                chartData.forEach((value) => {
                    if (dayjs(getOnlyDate(value.dateTime)).isValid()) {
                        data.push(value);
                    }
                });
                break;
            }
        }
        return Object.values(data);
    } else {
        return [];
    }
}
