import dayjs from "dayjs";
import html2canvas from "html2canvas";

export default function useTakeScreenshot({
    ref,
    fileName,
    onConfirm,
    elementIdsTohide,
    elementIdsToShow,
    location,
}: {
    ref: React.RefObject<HTMLElement>;
    fileName?: string;
    onConfirm?: (dataURL: string) => void;
    elementIdsTohide?: string[];
    elementIdsToShow?: string[];
    location?: string;
}) {
    const saveAs = (uri: string, filename: string) => {
        const link = document.createElement("a");
            
        if (typeof link.download === "string") {
            link.href = uri;
            link.download = filename;
            //@ts-ignore
            window.gtag("event", "DownloadScreenshot", {
                event_category: `Chart - ${fileName}`,
                event_label: location,
            });
            // Firefox requires the link to be in the body
            document.body.appendChild(link);

            // simulate click
            link.click();
            // remove the link when done
            document.body.removeChild(link);
        } else {
            window.open(uri);
        }
    };

    const takeSnapshot = () => {
        if (ref.current) {
            html2canvas(ref.current, {
                backgroundColor: "#0E0E0E",
                allowTaint: true,
                useCORS: true,
                onclone: function (document) {
                    if (document) {
                        elementIdsTohide?.map((id) => {
                            const elementByID = document.querySelector<HTMLElement>(`#${id}`);
                            if (elementByID) {
                                elementByID.style.display = "none"
                            }
                        })
                        elementIdsToShow?.map((id) => {
                            const elementByID = document.querySelector<HTMLElement>(`#${id}`);
                            if (elementByID) {
                                elementByID.style.display = "block"
                            }
                        })
                    }
                }
            }).then((canvas) => {
                onConfirm && onConfirm(canvas.toDataURL());
                saveAs(canvas.toDataURL(), fileName ?? `snapshot${dayjs().utc(false).toString()}.png`);
            });
        }
    };
    return takeSnapshot;
}
