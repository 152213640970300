import assets from "assets";
import { SmallOptionButton } from "components/Button";
import BarChart from "components/Chart/BarChart";
import LineAreaChart from "components/Chart/LineAreaChart";
import { AutoColumn } from "components/Column";
import { HorizontalDivider } from "components/Divider/inde";
import GraphTableTopHeader from "components/HeaderComponent/GraphTableTopHeader";
import { GenericImageWrapper } from "components/Logo";
import { TabularNumsText } from "components/shared";
import useMatchBreakpoints from "hooks/useMatchBreakpoints";
import useTakeScreenshot from "hooks/useTakeSnapshot";
import useTheme from "hooks/useTheme";
import React, { useEffect, useMemo, useState } from "react";
import { TEXT } from "theme/texts";
import { ChartDayData, GenericChartSeries, TimeWindow } from "types";
import { unixToDate } from "utils/date";
import { formatAmount } from "utils/numbers";
import { getScrollToElementPath } from "utils/path";
import {
  ChartTopRightSectionWrapper,
  ChartWrapper,
  ListButtonWrapper,
} from "./styled";
const twitterShareScrollPath = getScrollToElementPath(null, null);
const snapshotDownloadIconId = "snapshot-owner-count-chart-download-icon";
const snapshotBrandLogoId = "snapshot-owner-count-chart-brand-logo";

const ButtonList = ({
  timeWindow,
  setTimeWindow,
}: {
  timeWindow: TimeWindow;
  setTimeWindow: React.Dispatch<React.SetStateAction<TimeWindow>>;
}) => {
  return (
    <ChartTopRightSectionWrapper>
      <ListButtonWrapper>
        {[TimeWindow.threeMonth, TimeWindow.sixMonth, TimeWindow.oneYear].map(
          (option) => {
            return (
              <SmallOptionButton
                active={timeWindow === option}
                onClick={() => setTimeWindow(option)}
              >
                {option === "custom" ? (
                  <GenericImageWrapper
                    src={assets.icons.calendarIcon}
                    size="18px"
                  />
                ) : (
                  option
                )}
              </SmallOptionButton>
            );
          }
        )}
      </ListButtonWrapper>
    </ChartTopRightSectionWrapper>
  );
};

export type graphProps = {
  chartData: ChartDayData[] | undefined;
  refGraph: React.RefObject<HTMLDivElement>;
  disable: boolean;
};

function OwnersCountGraph({ chartData, refGraph, disable }: graphProps) {
  const theme = useTheme();
  const isBarGraph = false;

  const [volumeHover, setVolumeHover] = useState<number | undefined>();
  const [liquidityHover, setLiquidityHover] = useState<number | undefined>();
  const [leftLabel, setLeftLabel] = useState<string | undefined>();
  const [rightLabel, setRightLabel] = useState<string | undefined>();
  const [timeWindow, setTimeWindow] = useState(TimeWindow.threeMonth);
  const [totalOwners, settotalOwners] = useState<number | undefined>(0);
  const { isMobile } = useMatchBreakpoints();
  const downloadScreenshot = useTakeScreenshot({
    ref: refGraph,
    elementIdsTohide: [snapshotDownloadIconId],
    elementIdsToShow: [snapshotBrandLogoId],
    fileName: "owner-count-chart",
    location: window.location.href,
  });;

  useEffect(() => {
    setLiquidityHover(undefined);
    setVolumeHover(undefined);
  }, []);

  // const formattedChartData = useMemo(() => {
  //   if (totalNFTWallets && totalNFTWallets.records) {
  //     return getTimewindowFilteredData(
  //       reverseTotalNFTWallets,
  //       timeWindow,
  //       selectedCustomTime
  //     ).map((record) => {
  //       return {
  //         time: record.dateTime,
  //         valueWallets: record.nftCumulativeWalletsCount,
  //       };
  //     });
  //   } else {
  //     return [];
  //   }
  // }, [totalNFTWallets, timeWindow, selectedCustomTime]);

  const FilterDate = (data: ChartDayData[], timeWindow: TimeWindow) => {
    let filteredData: ChartDayData[] | any = [];
    switch (timeWindow) {
      case TimeWindow.threeMonth:
        filteredData = data.filter(
          (day) => day.date > new Date().getTime() / 1000 - 7776000
        );
        break;
      case TimeWindow.sixMonth:
        filteredData = data.filter(
          (day) => day.date > new Date().getTime() / 1000 - 15552000
        );
        break;
      case TimeWindow.oneYear:
        filteredData = data.filter(
          (day) => day.date > new Date().getTime() / 1000 - 31104000
        );
        break;
      default:
        filteredData = data;
        break;
    }

    settotalOwners(filteredData[filteredData.length - 1].count);
    console.log("totalOwners", totalOwners);
    return filteredData;
  };

  const formattedChartData = useMemo(() => {
    if (chartData) {
      return FilterDate(chartData, timeWindow).map((day: any) => {
        return {
          time: unixToDate(day.date),
          count: Number(day.count),
        };
      });
    } else {
      return [];
    }
  }, [chartData, timeWindow]);

  /**
   * @notice
   * @dev 'formattedChartSeries'  must change according to requirement
   * Add according requirement (This will plot line graph with provided color and data)
   */
  const formattedChartSeries: GenericChartSeries[] = [
    { dataKey: "count", name: "owners", color: "#1FD57B" },
  ];

  return (
    <>
      <GraphTableTopHeader
        title={"Unique Owners"}
        tooltip="Number of unique wallets that hold atleast 1 NFT from this collection"
        isMobileView={isMobile}
        downloadScreenshot={downloadScreenshot}
        snapshotBrandLogoId={snapshotBrandLogoId}
        snapshotDownloadIconId={snapshotDownloadIconId}
        tweetText={`https://twitter.com/intent/tweet?text=💜 Polygon NFT insights: Unique Owners%0A📆 for: ${timeWindow}%0A🎯 ${formatAmount(
          totalOwners,2
        )} %0A%0A🔗Check out the detailed chart on MNFST Tools by @MNFSTLabs here: ${twitterShareScrollPath} `} />
      <HorizontalDivider />
      {isBarGraph ? (
        <ChartWrapper>
          <BarChart
            data={formattedChartData}
            series={formattedChartSeries}
            setValue={setVolumeHover}
            setLabel={setRightLabel}
            value={volumeHover}
            label={rightLabel}
            activeWindow={timeWindow}
            topRight={
              disable ? null : 
              <ButtonList
                timeWindow={timeWindow}
                setTimeWindow={setTimeWindow}
              />
            }
            topLeft={
              <AutoColumn gap="4px">
                <TEXT.LargeHeader>
                  <TabularNumsText>
                    {formatAmount(totalOwners, 2)}
                  </TabularNumsText>
                </TEXT.LargeHeader>
                <TEXT.SmallHeader fontSize="12px" height="14px">
                  {rightLabel ? (
                    <TabularNumsText>{rightLabel} (UTC)</TabularNumsText>
                  ) : null}
                </TEXT.SmallHeader>
              </AutoColumn>
            }
          />
        </ChartWrapper>
      ) : (
        <ChartWrapper>
          <LineAreaChart
            data={formattedChartData}
            // Change this according to dataset

            series={formattedChartSeries}
            setValue={setVolumeHover}
            setLabel={setRightLabel}
            value={volumeHover}
            label={rightLabel}
            activeWindow={timeWindow}
            topRight={
              disable ? null : 
              <ButtonList
                timeWindow={timeWindow}
                setTimeWindow={setTimeWindow}
              />
            }
            topLeft={
              <AutoColumn gap="4px">
                <TEXT.MediumHeader fontSize="16px">
                  Unique Owners
                </TEXT.MediumHeader>
                <TEXT.LargeHeader fontSize="32px">
                  <TabularNumsText>
                    {formatAmount(totalOwners, 2)}
                  </TabularNumsText>
                </TEXT.LargeHeader>
                <TEXT.SmallHeader fontSize="12px" height="14px">
                  {rightLabel ? (
                    <TabularNumsText>{rightLabel} (UTC)</TabularNumsText>
                  ) : null}
                </TEXT.SmallHeader>
              </AutoColumn>
            }
          />
        </ChartWrapper>
      )}
    </>
  );
}

export default OwnersCountGraph;
