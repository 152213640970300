import assets from "assets";
import axios from "axios";
import React, {useRef} from "react";
import styled from "styled-components";

const CarouselCardWellcome = () => {

 
  return (
    <CarouselCardWrappersrw>
      <div className="cont">
      <div className="left">
      <img src={assets.images.wellL} alt="" />
      </div>
      <div className="right">
      <img src={assets.images.wellR} alt="" />
      </div>
      <div className="content">
      WELCOME TO
      <br/>
      mnfst tools
      </div>
       
      </div>
    </CarouselCardWrappersrw>
  );
};
const CarouselCardWrappersrw = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  font-family: contentFont;

  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  background: url(${assets.images.bgf});
  background-repeat: no-repeat;

  /* border: 1px solid #2d2f31; */
  background-position: center center;
  background-size: cover;
  position: relative;
  img {
    width: 100%;
  }
  .cont {
  }
  .left{
    position: absolute;
    left: 0px;
    top: 0; 
    height: 100%;

    img{
      height: 100%;
    }


  }
  .right{
    position: absolute;
    right: 0px;
    height: 100%;

    top: 0; 
    img{
      height: 100%;
    }


  }
  .content{
    color: #14EEEC;
text-align: center;
font-family: titleFont;
font-size: 46px;
/* font-style: normal; */
/* font-weight: 900; */
line-height: 130%; /* 124.8px */
text-transform: uppercase;
border-bottom:1px solid #14EEEC;
border-top:1px solid #14EEEC;
padding: 10px 0;


  }

  
  @media only screen and (max-width: 700px) {
    flex-direction: column;
    height: 270px;
    background-size: cover;
    background-position: center;
    .cont {
      height: 100%;
  }
    .left{
    position: absolute;
    left: 0px;
    top: auto;
    bottom: 0; 
    height: 140px;
    width: 90px;


    img{
      height: 140px;
    }


  }
  .right{
    position: absolute;
    right: 0px;
    height: 140px;
    width: 140px;

    top: auto;

    bottom: 0; 
    img{
      height: 140px;
     
    }


  }
  .content{
    color: #14EEEC;
text-align: center;
font-family: titleFont;
font-size: 26px;
margin-top: 20px;


padding: 8px 0;


  }
  

   
  }
`;
export default CarouselCardWellcome;
