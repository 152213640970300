import { useState, useMemo, useEffect, useCallback } from "react";
import CollectionCard from "./components/CollectionCard";
import { useTotalNFTVolumeAndSales } from "data/nft/totalNFTVolumeAndSales";
import assets from "assets";

import getTimewindowFilteredData from "utils/chart";
import { Currencies, TypeCurrency } from "constant/currency";
import { useCurrencyUnit } from "store/user/hooks";

import { GenericChartSeries, TimeWindow, TradeActionWindow } from "types";
import dayjs from "dayjs";
import { formatAmount } from "utils/numbers";
import { useNFTMarketPlace } from "data/nft/nftMarketplace";

const TotalNftVolume = ({ dataType }) => {
  const [timeWindow, setTimeWindow] = useState("3M");
  // const totalNFTWallets = useTotalNFTWallets();
  const [options, setOptions] = useState(defaultAvailableOptions);
  const [tradeActionWindow, setTradeActionWindow] = useState(dataType);
  // Sales
  // Volume
  const { data: totalNFTVolumeAndSalesData } = useNFTMarketPlace();
  const reverseTotalNFTVolumeAndSalesData = [
    ...(totalNFTVolumeAndSalesData ?? []),
  ].reverse();
  const { currency } = useCurrencyUnit();

  useEffect(() => {
    console.debug("currency updated in TotalNFTVolumeGraph", currency);
  }, [currency]);

 
  const totalNFTVolumeAndSalesFomattedData = useCallback(() => {
    var data = {};
    reverseTotalNFTVolumeAndSalesData &&
      reverseTotalNFTVolumeAndSalesData.forEach((day) => {
        if (!data[day.project]) {
          data[day.project] = {};
        }
        data[day.project][day.dateTime] = {
          dateTime: day.dateTime,
          salesCount: day.salesCount,
          volumeUSD: day.volumeUSD,
          volumeInMatic: day.volumeInMatic,
          volumeInWETH: day.volumeInWETH,
        };
      });
    return data;
  }, [reverseTotalNFTVolumeAndSalesData]);
  const getCurrencyAccordingData = (currency, projectData, project, time) => {
    switch (currency.id) {
      case Currencies.WETH:
        return projectData[project][time].volumeInWETH;
      case Currencies.MATIC:
        return projectData[project][time].volumeInMatic;
      case Currencies.USD:
        return projectData[project][time].volumeUSD;
      default:
        return projectData[project][time].volumeInWETH;
    }
  };

  const getMarketPlaceFilteredData = (
    day,
    projectData,
    currency,
    selectedDropDownOption,
    options,
    tradeActionWindow
  ) => {
    if (selectedDropDownOption.id === 1) {
      var totalVolume = 0,
        totalSalesCount = 0;
      // total of all marketplaces
      (
        options.filter((option) => option.subOptions.length !== 0) ?? []
      ).forEach((filteredOption) => {
        filteredOption.subOptions.forEach((subOption, index) => {
          if (
            projectData[subOption.value.toLowerCase()][day.dateTime] !==
            undefined
          ) {
            totalVolume =
              totalVolume +
              getCurrencyAccordingData(
                currency,
                projectData,
                subOption.value.toLowerCase(),
                day.dateTime
              );
            totalSalesCount =
              totalSalesCount +
              projectData[subOption.value.toLowerCase()][day.dateTime]
                .salesCount;
          }
        });
      });

      //
      return {
        time: day.dateTime,
        ecosystem: tradeActionWindow === "All" ? totalVolume : totalSalesCount,
      };
    } else {
      let dataObj = { time: day?.dateTime };

      selectedDropDownOption.subOptions.forEach((subOption, index) => {
        try {
          if (subOption?.checked) {
            dataObj[subOption?.value] = projectData[
              subOption?.value?.toLowerCase()
            ][day?.dateTime]
              ? tradeActionWindow === "All"
                ? getCurrencyAccordingData(
                    currency,
                    projectData,
                    subOption.value.toLowerCase(),
                    day.dateTime
                  )
                : projectData[subOption.value.toLowerCase()][day.dateTime]
                    .salesCount
              : 0;
          }
        } catch (e) {
          console.debug("error", e);
        }
      });

      return dataObj;
    }
  };

  const selectedDropDownOption = useMemo(() => {
    return options.find((option) => option.selected) ?? options[0];
  }, [options]);

  const formattedChartData = useMemo(() => {
    const projectData = totalNFTVolumeAndSalesFomattedData();
    if (
      totalNFTVolumeAndSalesData &&
      reverseTotalNFTVolumeAndSalesData &&
      reverseTotalNFTVolumeAndSalesData.length !== 0
    ) {
      const AllData = getTimewindowFilteredData(
        reverseTotalNFTVolumeAndSalesData,
        "All"
      ).map((day) => {
        return getMarketPlaceFilteredData(
          day,
          projectData,
          currency,
          selectedDropDownOption,
          options,
          tradeActionWindow
        );
      });
      const totalData = getTimewindowFilteredData(
        reverseTotalNFTVolumeAndSalesData,
        timeWindow
      ).map((day) => {
        return getMarketPlaceFilteredData(
          day,
          projectData,
          currency,
          selectedDropDownOption,
          options,
          tradeActionWindow
        );
      });
      let updateTimeWindow;
      if (timeWindow === "3M") {
        updateTimeWindow = "6M";
      } else if (timeWindow === "6M") {
        updateTimeWindow = "1Y";
      } else if (timeWindow === "1Y") {
        updateTimeWindow = "2Y";
      }
      const secndtotalData = getTimewindowFilteredData(
        reverseTotalNFTVolumeAndSalesData,
        updateTimeWindow
      ).map((day) => {
        return getMarketPlaceFilteredData(
          day,
          projectData,
          currency,
          selectedDropDownOption,
          options,
          tradeActionWindow
        );
      });

      const distinctData = [];
      const AlldistinctData = [];
      const secondDistinctData = [];

      totalData.forEach((singleData) => {
        if (
          distinctData.find((dData) => dData.time === singleData.time) ===
          undefined
        ) {
          distinctData.push(singleData);
        }
      });

      AllData.forEach((singleData) => {
        if (
          AlldistinctData.find((dData) => dData.time === singleData.time) ===
          undefined
        ) {
          AlldistinctData.push(singleData);
        }
      });
      secndtotalData.forEach((singleData) => {
        if (
          secondDistinctData.find((dData) => dData.time === singleData.time) ===
          undefined
        ) {
          secondDistinctData.push(singleData);
        }
      });
      return { distinctData, AlldistinctData, secondDistinctData };
    } else {
      return [];
    }
  }, [
    totalNFTVolumeAndSalesData,
    timeWindow,
    tradeActionWindow,
    options,
    reverseTotalNFTVolumeAndSalesData,
  ]);

  const filteredTotalCount = useMemo(() => {
    if (
      totalNFTVolumeAndSalesData &&
      reverseTotalNFTVolumeAndSalesData &&
      reverseTotalNFTVolumeAndSalesData.length !== 0
    ) {
      console.debug(
        "getTimewindowFilteredData",
        getTimewindowFilteredData(reverseTotalNFTVolumeAndSalesData, timeWindow)
      );

      console.debug("formattedChartData", formattedChartData);
      let previousToCurrentTimeTotal = 0;
      let AllToCurrentTimeTotal = 0;
      let SecendToCurrentTimeTotal = 0;

      formattedChartData.distinctData.forEach((singleData) => {
        Object.keys(singleData).forEach((valueKey) => {
          if (valueKey !== "time" && !isNaN(singleData[valueKey])) {
            previousToCurrentTimeTotal =
              previousToCurrentTimeTotal + singleData[valueKey] ?? 0;
          }
        });
      });

      formattedChartData.AlldistinctData.forEach((singleData) => {
        Object.keys(singleData).forEach((valueKey) => {
          if (valueKey !== "time" && !isNaN(singleData[valueKey])) {
            AllToCurrentTimeTotal =
              AllToCurrentTimeTotal + singleData[valueKey] ?? 0;
          }
        });
      });
      formattedChartData.secondDistinctData.forEach((singleData) => {
        Object.keys(singleData).forEach((valueKey) => {
          if (valueKey !== "time" && !isNaN(singleData[valueKey])) {
            SecendToCurrentTimeTotal =
              SecendToCurrentTimeTotal + singleData[valueKey] ?? 0;
          }
        });
      });

      const percentValue =
        ((SecendToCurrentTimeTotal - previousToCurrentTimeTotal) /
          SecendToCurrentTimeTotal) *
        100;

      return { totalCount: previousToCurrentTimeTotal, percentValue };
    } else {
      return 0;
    }
  }, [
    totalNFTVolumeAndSalesData,
    timeWindow,
    reverseTotalNFTVolumeAndSalesData,
  ]);

  let volumeData;
  if (dataType === "All") {
    volumeData = {
      title: "Volume",
      endpoint: `${process.env.REACT_APP_API_URL}/topCollectionByCategory`,
      toolTip: "Total NFT Volume on Polygon",
      price_change: filteredTotalCount.percentValue,
      value: formatAmount(filteredTotalCount.totalCount),
      showTimePeriod: true,
      showLogo: true,
    };
  } else {
    volumeData = {
      title: "Total NFT Sales",
      endpoint: `${process.env.REACT_APP_API_URL}/topCollectionByTrend`,
      toolTip: "Total NFT Sales on Polygon",
      price_change: filteredTotalCount.percentValue,
      value: formatAmount(filteredTotalCount.totalCount),
      showTimePeriod: true,
      showLogo: false,
    };
  }

  return (
    <>
      <CollectionCard card={volumeData} time={setTimeWindow} />
    </>
  );
};

export default TotalNftVolume;
const defaultAvailableOptions = [
  {
    id: 1,
    name: "Ecosystem",
    icon: assets.icons.ecosystemIcon,
    selected: false,
    subOptions: [],
  },
  {
    id: 2,
    name: "Marketplaces",
    icon: assets.icons.marketplacesIcon,
    selected: true,
    subOptions: [
      {
        id: 1,
        name: "Opensea",
        value: "opensea",
        checked: true,
      },
      {
        id: 2,
        name: "Magic Eden",
        value: "magiceden",
        checked: true,
      },
      {
        id: 3,
        name: "One Planet",
        value: "oneplanet",
        checked: true,
      },
    ],
  },
];
