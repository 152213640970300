import { useModal } from "connectkit";
import React,{useState} from "react";
import { NavLink } from "react-router-dom";
import { useToGetUserInfo } from "store/user/hooks";
import styled from "styled-components";
import { TEXT } from "theme/texts";
import { appRoutes } from "utils/path";

export const NavConfig = [
  {
    label: "Market Overview",
    url: appRoutes.marketOverview,
    disable: false,
  },
  {
    label: "Collections",
    url: appRoutes.collections,
    disable: false,
  },
  {
    label: "Ranks",
    url: appRoutes.leaderBoard,
    disable: false,
  },

  {
    label: "Drops",
    url: appRoutes.drops,
    disable: false,
  },
  {
    label: "Buy/Sell",
    url: "",
    disable: true,
  },
];

const AppNav = () => {
  const { setOpen } = useModal();
  const { wallet } = useToGetUserInfo();
  let appException = localStorage.getItem("app") === "true" ? true : false;
  const [hover, setHover] = useState(false);
    // Function to handle mouse over
    const handleMouseOver = () => {
      setHover(true);
  };

  // Function to handle mouse out
  const handleMouseOut = () => {
    setHover(false);
  };
  return (
    <AppNavCtr>
      {NavConfig.map((element) => {
        if (element.disable) {
          return (
            <StyledNavLinkDisable
            // to={"/"}
            // active={false}
            // onClick={!wallet && !appException ? () => setOpen(true) : () => {}}
            onMouseOver={handleMouseOver} 
            onMouseOut={handleMouseOut} 
            >
              <TEXT.SmallBody
                paddingX={14}
                lineHeight={4.6}
                fontWeight={500}
                color={"#ffffff"}
              >
                {
                  hover ?
                  "Coming Soon"

                  :
                  element.label

                }

              </TEXT.SmallBody>
              <div className="_bottom" />
            </StyledNavLinkDisable>
          );
        } else {
          return (
            <StyledNavLink
              to={element.url}
              // onClick={!wallet && !appException ? () => setOpen(true) : () => {}}
            >
              <TEXT.SmallBody paddingX={14} lineHeight={4.6} fontWeight={500}>
                {element.label}
              </TEXT.SmallBody>
              <div className="_bottom" />
            </StyledNavLink>
          );
        }
      })}
    </AppNavCtr>
  );
};

const StyledNavLink = styled(NavLink)<{ active?: boolean }>`
  color: ${({ active, theme }) => (active ? theme.body : theme.disabledVar2)};
  text-decoration: none;
  align-items: center;
  white-space: nowrap;
  &.active {
    color: ${({ theme }) => theme.body};
    ._bottom {
      width: auto;
      height: 4px;
      background: ${({ theme }) => theme.btnSecondary};
    }
  }
`;
const StyledNavLinkDisable = styled.div`
  color: #555;
  text-decoration: none;
  align-items: center;
  white-space: nowrap;
`;

const AppNavCtr = styled.div`
  display: flex;
  gap: 0rem;

  /* margin-left: 126px; */
`;

export default AppNav;
