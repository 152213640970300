import { SecondaryButton } from "components/Button";
import React from "react";
import styled from "styled-components";

const BlogCard = ({ card }) => {
  return (
    <BlogCardWrapper>
      <div className="imageSectionn">
        <img src={card?.image} alt="img" srcset="" />
      </div>
      <div className="content">
        <p className="tittle">{card?.title}</p>
        <p className="par">{card?.discription}</p>
      </div>

      <a href={card?.link} target="_blank" className="linkblocka" >
        <SecondaryButton className="linkblock"  borderRadius={'0px'}>
          {card?.buttonTitle} <span>→</span>
        </SecondaryButton>
      </a>
    </BlogCardWrapper>
  );
};
const BlogCardWrapper = styled.div`
  p,
  h2,
  h1,
  h4 {
    margin-block-start: 0;
    margin-block-end: 0;
    margin-block-end: 0;
  }
  ${(props) => props.theme.flexColNoWrap}
  background: rgba(14, 14, 14, 0.7);
  border: 1px solid #2d2f31;
  padding: 66px;
  height: 600px;
  position: relative;

  .imageSectionn {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 304px;
    height: 284px;
    img {
      width: 100%;
      height: 100%;
    }

    /* background: #ffffff; */
    border-radius: 4px;

    overflow: hidden;
  }
  .tittle {
    font-weight: 500;
    font-size: 54px;
    line-height: 130%;
  }
  .par {
    margin-top: 24px;
    font-weight: 400;
    font-size: 24px;
    line-height: 130%;
    /* or 21px */

    display: flex;
    align-items: center;
  }
  .linkblocka{
    text-decoration: none;

  }
  .linkblock {
    margin-top: 150px;
    cursor: pointer;
    padding: 11px 36px;

    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 24px;
    display: flex;
    align-items: center;
   
    width: max-content;

    span {
      margin-left: 8px;
      font-size: 18px;
      font-weight: 700;
    }
   
  }

  @media only screen and (max-width: 700px) {
    padding: 36px;
    height: 340px;
    background: rgba(14, 14, 14, 0.7);

    .tittle {
      font-size: 30px;
    }
    .par {
      margin-top: 14px;

      font-size: 14px;
      align-items: center;
    }
    .content {
      width: 230px;
    }
    .imageSectionn {
      width: 196px;
      height: 186px;
    }

    .linkblock {
      margin-top: 80px;
      font-weight: 500;
      font-size: 10.5px;
      line-height: 16px;
      padding: 6px 18px;
      span {
        margin-left: 5px;
        font-size: 10px;
        font-weight: 600;
      }
    }
  }
`;

export default BlogCard;
