import { CircledImageCtr } from "components/Logo";
import styled from "styled-components";
import { formatAmount } from "utils/numbers";

const TableHeadItem = ({ item }) => {
  return <th className="_heading">{item.heading}</th>;
};

const TableDataItem = ({ item, column }) => {
  return (
    <tr className="_tableData" key={item._id}>
      {column.map((colItem, idx) => {
        if (colItem.value === "name")
          return (
            <td key={idx} className="_dataCell"
            >
              <CircledImageCtr radius="100%" size={"20px"}>
                <img
                  src={
                    "https://i.pinimg.com/564x/61/3e/d0/613ed02b84895f8dfe9b2cd5847f4cfa.jpg"
                  }
                  alt=""
                />
              </CircledImageCtr>
              {item[`${colItem.value}`]}
            </td>
          );
        else
          return (
            <td key={idx} className="_dataCell"
              onClick={() => 
                colItem.value === "wallet" ? window.open(`https://debank.com/profile/${item[`${colItem.value}`]}`, "_blank") : null
              }
            >
              
              {// 1.3776482407407408e+24
              colItem.value === "owned_percent" ? item[`${colItem.value}`] > 100000000000000000000000  ? formatAmount(
                parseFloat(item[`${colItem.value}`]/ 100000000000000000000000)
              ).toUpperCase() : formatAmount(
                parseFloat(item[`${colItem.value}`])
              ).toUpperCase() :
              colItem.value !== "wallet" && colItem.value !== "id" && colItem.value !== "balance"
                ? formatAmount(
                    parseFloat(item[`${colItem.value}`])
                  ).toUpperCase()
                : 
               
                  colItem?.value === "wallet" ? item[`${colItem.value}`].slice(0, 6) + "..." + item[`${colItem.value}`].slice(-4) :
                colItem.value === "balance" ? formatAmount(
                  parseFloat(item[`${colItem.value}`])
                ).toUpperCase() + " Ξ" : item[`${colItem.value}`]
                
                }
            </td>
          );
      })}
    </tr>
  );
};
const OwnersTable = ({ data, column }) => {
  return (
    <OwnersTableCtr>
      <table className="_table">
        <thead className="_tableCategories">
          <tr className="_headings">
            {column.map((item, idx) => {
              return <TableHeadItem item={item} key={idx} />;
            })}
          </tr>
        </thead>
        <tbody className="_tableBody">
          {data.map((item, idx) => {
            return <TableDataItem item={item} column={column} key={idx} />;
          })}
        </tbody>
      </table>
    </OwnersTableCtr>
  );
};
const OwnersTableCtr = styled.div`
  overflow-y: auto;
  overflow-x: auto;
  max-height: 100%;
  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge add Firefox */
  -ms-overflow-style: none;
  scrollbar-width: none; /* Firefox */
  ._table {
    width: 100%;
    font-family: var(--ff-subtitle);
    position: relative;
    border-collapse: collapse;
  }
  ._tableCategories {
    display: block;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 20;
    background: ${(props) => props?.theme?.bgModal};
  }

  ._headings {
    border-top: 1px solid ${(props) => props?.theme?.disabledVar3};
    border-bottom: 1px solid ${(props) => props?.theme?.disabledVar3};
    font-family: var(--ff-title);
    display: flex;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 10;
  }
  ._heading {
    flex: 1;
    min-width: 125px;
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 1.05em;
    &:nth-of-type(2) {
      min-width: 200px;
      justify-content: initial;
    }
    &:nth-of-type(1) {
      min-width: initial;
      flex: 0;
      min-width: 50px;
    }
    //@media quries : mobile screens
    ${({ theme }) => theme.mediaWidth.upToLarge`
        justify-content:center;
        &:nth-of-type(1), &:nth-of-type(2) {
          position:sticky;
          left:0;
          top:0;
          z-index:10;
            background:${(props) => props?.theme?.bgModal};
    }
    &:nth-of-type(2){
     min-width: 200px;
         border-right: 1px solid ${(props) => props?.theme?.disabledVar3};
    }
      `}
  }
  ._tableBody {
    ${(props) => props?.theme?.flexColumnNoWrap};
    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
      display: none !important;
    }
    /* Hide scrollbar for IE, Edge add Firefox */
    -ms-overflow-style: none !important;
    scrollbar-width: none !important; /* Firefox */
  }
  ._tableData {
    display: flex;
    align-items: center;
    cursor: pointer;

    //switch bg color for evey even child
    &:nth-child(odd) {
      background: var(--nav-bg);
    }
    ._dataCell {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 1rem 0.5rem;
      gap: 0.5rem;
      min-width: 125px;
      ._userAvatar {
        width: 30px;
        height: 30px;
        border-radius: 100%;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          object-position: center;
        }
      }
      &:nth-of-type(2) {
        min-width: 200px;
        justify-content: initial;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      &:nth-of-type(1) {
        min-width: initial;
        flex: 0;
        min-width: 50px;
      }
      //@media quries : mobile screens
      ${({ theme }) => theme.mediaWidth.upToLarge`
      justify-content:center;
        &:nth-of-type(1), &:nth-of-type(2) {
          position:sticky;
          left:0;
          top:0;
              z-index:10;
          background:${(props) => props?.theme?.bgModal};

    }
    &:nth-of-type(2){
     min-width: 200px;
              border-right: 1px solid ${(props) => props?.theme?.disabledVar3};
    }
      `}
    }
  }
`;
export default OwnersTable;
