import assets from "assets";
import AppNav from "components/AppNav";
import AppNavModal from "components/AppNav/AppNavModal";
import BrandLogo from "components/Brandlogo";
import { PrimaryButton } from "components/Button";
import CurrencyMenu from "components/Currency";
import {
  HideExtraLarge,
  HideLarge,
  HideLargeNav,
  HideMedium,
  HideLargeN,
  ShowLarge,
  ShowLargeN,
  HideSmall,
} from "components/Hide";
import { RowFixed } from "components/Row";
import Search from "components/Search";
import { ConnectKitButton, useModal } from "connectkit";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useToGetUserInfo } from "store/user/hooks";
import styled from "styled-components";
import { useAccount } from "wagmi";

const HeaderWrapper = styled.div`
  width: 100%;
  height: 68px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px 62px;
  position: relative;
  background: ${(props) => props.theme.black};
  .kkImbB {
    background: #14eeec !important;
  }
  ${({ theme }) => theme.mediaWidth.upToExtraLarge`
     padding: 0px 20px ;

  `};
  ${({ theme }) => theme.mediaWidth.upToMedium`
    padding: 0px 16px;
    height: 64px;
  `};
`;
const LogoWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 2rem;
  ${({ theme }) => theme.mediaWidth.upToExtraLarge`
      gap: 1rem;


  `};
`;

const IconWrapper = styled.img`
  padding: 0px 18px;
`;

function Header() {
  const [hideNav, setHideNav] = useState<boolean>(true);
  const [showYootsHeader, setShowYootsHeader] = useState<boolean>(false);
  const { pathname } = useLocation();
  const { setOpen } = useModal();
  const { wallet } = useToGetUserInfo();
  const { address, isConnecting, isDisconnected, isConnected } = useAccount();

  const onLinkClicked = () => {
    if (!wallet) {
      // setOpen(true);
    }
    setHideNav(true);
  };
  useEffect(() => {
    if (pathname.startsWith("/yoots")) {
      setShowYootsHeader(true);
    } else {
      setShowYootsHeader(false);
    }
  }, [pathname, showYootsHeader]);
  return (
    <HeaderWrapper>
      <LogoWrapper>
        <BrandLogo hideNavOnLoc={() => setHideNav(true)} footer={false} />
        {showYootsHeader ? null : (
          <>
            <HideLargeNav>
              <AppNav />
            </HideLargeNav>
            <NavModalforMobile>
              <AppNavModal isOpen={!hideNav} onClose={onLinkClicked} />
            </NavModalforMobile>
          </>
        )}
      </LogoWrapper>

      {showYootsHeader ? (
        <CurrencyMenu />
      ) : (
        <RowFixed>
          {hideNav && (
            <>
              <Search />
              <HideSmall style={{ paddingLeft: "20px", paddingRight: "20px" }}>
                <CurrencyMenu />
              </HideSmall>
            </>
          )}
          <HideLargeN>
            {!isConnected ? (
              <ConnectKitButton.Custom>
                {({
                  isConnected,
                  isConnecting,
                  show,
                  hide,
                  address,
                  ensName,
                  chain,
                }) => {
                  return (
                    <PrimaryButton
                      onClick={show}
                      style={{
                        fontSize: "16px",
                        fontWeight: "bold",
                        minWidth: "max-content",
                        borderRadius: "4px",
                      }}
                    >
                      {isConnected ? address : "Connect Wallet"}
                    </PrimaryButton>
                  );
                }}
              </ConnectKitButton.Custom>
            ) : (
              <ConnectKitButton />
            )}
          </HideLargeN>
          <ShowLargeN onClick={() => setHideNav((prev) => !prev)}>
            <IconWrapper
              src={hideNav ? assets.icons.navMenuIcon : assets.icons.closeIcon}
            />
          </ShowLargeN>
        </RowFixed>
      )}
    </HeaderWrapper>
  );
}

const NavModalforMobile = styled.div`
  display: none;

  ${({ theme }) => theme.mediaWidth.updoNav`
     display:block;
  `};
`;
export default Header;
