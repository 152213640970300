export const listOfCollection = [
  {
    Name: "Owlpha",
    MagicEden: "https://magiceden.io/collections/polygon/owlpha",
    OpenSea: "https://opensea.io/collection/owlpha",
    contract: "0x12aa01f646fe5c993c66c9c86eddad4e514f6cbc",
  },
  {
    contract: "0xdc901017d1c8c9e5745a0a52e3237804da32790c",

    Name: "RingRunnerz",

    MagicEden: "https://magiceden.io/collections/polygon/ring_runnerz",
    OpenSea: "https://opensea.io/collection/ringrunnerz-nft",
  },
  {
    Name: "Galactic Eagle Gang",
    contract: "0xfcaa34d7ef46d1e4cf5d7933e6be8c57374a6fa7",

    MagicEden: "https://magiceden.io/collections/polygon/galactic_eagle_gang",
    OpenSea: "https://opensea.io/collection/galactic-eagle-gang-1",
  },
  {
    Name: "The Herd",
    contract: "0x99c1b681305d9aa95465547494d321eecb094bdc",

    MagicEden: "https://magiceden.io/collections/polygon/the_herd",
    OpenSea: "https://opensea.io/collection/the-herd-4",
  },
  {
    Name: "Drill Club",
    contract: "0x39cd103414106b922eb09c7d45df89608b59e887",

    MagicEden: "https://magiceden.io/collections/polygon/drill_club",
    OpenSea: "https://opensea.io/collection/drill-club-official",
  },
  {
    contract: "0xc1a5f386e3b2d3cb280191fcd11e76c41117197d",

    Name: "Gambulls",
    MagicEden:
      "https://magiceden.io/collections/polygon/0xc1a5f386e3b2d3cb280191fcd11e76c41117197d",
    OpenSea: null,
  },
  {
    Name: "Polygon Ape: The Evolution",
    contract: "0x419e82d502f598ca63d821d3bbd8dfefaf9bbc8d",

    MagicEden:
      "https://magiceden.io/collections/polygon/polygonapetheevolution",
    OpenSea: "https://opensea.io/collection/polygonapetheevolution",
  },
  {
    Name: "The Leos",
    contract: "0xaa0489f3f92474b993ef11176f7cbe10510d3e70",

    MagicEden: "https://magiceden.io/collections/polygon/the_leos",
    OpenSea: "https://opensea.io/collection/the-leos-5",
  },
  {
    Name: "BoomLand - Hunters",
    contract: "0x20b807b9af56977ef475c089a0e7977540743560",

    MagicEden: "https://magiceden.io/collections/polygon/boomland-hunters",
    OpenSea: "https://opensea.io/collection/boomland-hunters",
  },
  {
    Name: "Normies",
    contract: "0x2ebe61759e916b61523ea1fda8e1c057688bd8f3",

    MagicEden:
      "https://magiceden.io/collections/polygon/0x2ebe61759e916b61523ea1fda8e1c057688bd8f3",
    OpenSea: "https://opensea.io/collection/normies-4",
  },
  {
    Name: "Normies Wristbands",
    contract: "0xdb9be9cf5ba3d0598ce30c5c9bc8ea608c2a95b3",

    MagicEden: "https://magiceden.io/collections/polygon/normies-wristbands",
    OpenSea: "https://opensea.io/collection/normies-wristbands",
  },
  {
    Name: "Evil Skellies",
    contract: "0x051c5fa955cec55032fd5d17b643d43ef946d038",

    MagicEden: "https://magiceden.io/collections/polygon/evil_skellies",
    OpenSea: "https://opensea.io/collection/evil-skellies",
  },
  {
    Name: "Hyper Kongz",
    contract: "0xcf8aa0b9e6bd01571f4e1e2475d77c2c84cece4d",

    MagicEden: "https://magiceden.io/collections/polygon/hyper_kongz",
    OpenSea: "https://opensea.io/collection/hyperkongznft",
  },
  {
    Name: "Rekt Dogs",
    contract: "0xace8187b113a38f83bd9c896c6878b175c234dcc",

    MagicEden: "https://magiceden.io/collections/polygon/rektdogs",
    OpenSea: "https://opensea.io/collection/rektdogs",
  },
  {
    Name: "Genesis Wildpass",
    contract: "0xef41141fbc0a7c870f30fee81c6214582dc2a494",

    MagicEden: "https://magiceden.io/collections/polygon/wildpass",
    OpenSea: "https://opensea.io/collection/wildpass",
  },
  {
    Name: "Brozo",
    contract: "0x220fa5ccc9404802ed6db0935eb4feefc27c937e",

    MagicEden: "https://magiceden.io/collections/polygon/brozo",
    OpenSea: "https://opensea.io/collection/brozo",
  },
  {
    Name: "Owlpha Cores",
    contract: "0xb4178a53763263c3db52965371b3a91da3724e1b",

    MagicEden: "https://magiceden.io/collections/polygon/owlpha-core",
    OpenSea: "https://opensea.io/collection/owlpha-core",
  },
  {
    Name: "Rival Bears",
    contract: "0xa25541164ae9d59322b59fe94a73869b494c3691",

    MagicEden: "https://magiceden.io/collections/polygon/rival_bears",
    OpenSea: "https://opensea.io/collection/rivalbears",
  },
  {
    Name: "Tribuzz",
    contract: "0x7802c717b3fc4e6a32d1ae3a31a6175ebae9b164",

    MagicEden: "https://magiceden.io/collections/polygon/tribuzz",
    OpenSea: "https://opensea.io/collection/tribuzz",
  },
  {
    Name: "Space Skellies",
    contract: "0x0ab302a678c1cf97043c1d932968d09d3176e64c",

    MagicEden: "https://magiceden.io/collections/polygon/space-skellies-1",
    OpenSea: "https://opensea.io/collection/spaceskellies",
  },
  {
    Name: "Nine",
    contract: "0xa9cc7c358bfa3fa6cee4231f975efc4ebd505538",

    MagicEden: "https://magiceden.io/collections/polygon/nine",
    OpenSea: "https://opensea.io/collection/nine-worldz-9",
  },
  {
    Name: "Yieldnodes NFT",
    contract: "0x198d38c5f21eab36731d0576560440f70cbd9418",

    MagicEden: "https://magiceden.io/collections/polygon/yieldnodesnft",
    OpenSea: null,
  },
  {
    Name: "ArcheWorld_Land (POLYGON)",
    contract: "0x56d23f924cd526e5590ed94193a892e913e38079",

    MagicEden:
      "https://magiceden.io/collections/polygon/archeworld-land-polygon",
    OpenSea: "https://opensea.io/collection/archeworld-land-polygon",
  },
  {
    Name: "Danketsu",
    contract: "0xee79a3e8aef1109a6ee82bf399ce9e1bd43cf5c4",

    MagicEden: "https://magiceden.io/collections/polygon/danketsu",
    OpenSea: "https://opensea.io/collection/danketsu-3",
  },
  {
    Name: "Soul Searchers",
    contract: "0xdcb074190b01a8c08c34866ee972d363c4339d53",

    MagicEden:
      "https://magiceden.io/collections/polygon/soul-searchers-polygon",
    OpenSea: "https://opensea.io/collection/soul-searchers-polygon",
  },
  {
    Name: "Iced Out Coin Flip",
    contract: "0x50d2915927255109570d9f72ebeb8cb477df508e",

    MagicEden: "https://magiceden.io/collections/polygon/iced_out_coin_flip",
    OpenSea: "https://opensea.io/collection/iced-out-coin-flip",
  },
  {
    Name: "The Poker Pro",
    contract: "0xa9843edf08588a52ae92e853d0d8b9977a1b0791",

    MagicEden: "https://magiceden.io/collections/polygon/the-poker-pro",
    OpenSea: null,
  },
  {
    Name: "HellCats",
    contract: "0x09421f533497331e1075fdca2a16e9ce3f52312b",

    MagicEden: "https://magiceden.io/collections/polygon/hellcats",
    OpenSea: "https://opensea.io/collection/hellcatsnft1",
  },
  {
    Name: "HellHounds",
    contract: "0x895a23ebb9dae1abd34eaae2e11db3ee6233071a",

    MagicEden: "https://magiceden.io/collections/polygon/hellhounds",
    OpenSea: "https://opensea.io/collection/hellhounds-",
  },
  {
    Name: "PolygonMonkeys",
    contract: "0xe5c93b6692c03d4279d1a3098e4321254b560f47",

    MagicEden:
      "https://magiceden.io/collections/polygon/0xe5c93b6692c03d4279d1a3098e4321254b560f47",
    OpenSea: "https://opensea.io/collection/polygonmonkeys-2",
  },

  {
    Name: "DinoDash",
    contract: "0x248d6e4f9ab178bb8022c18ca25a281a53e9a047",

    MagicEden: "https://magiceden.io/collections/polygon/dinodash",
    OpenSea: "https://opensea.io/collection/dino-dash",
  },
  {
    Name: "Trippy Wolves",
    contract: "0xb5ed47dd1be9dd7bf6304b3e823d8581bdf5fcfd",

    MagicEden:
      "https://magiceden.io/collections/polygon/0xb5ed47dd1be9dd7bf6304b3e823d8581bdf5fcfd",
    OpenSea: "https://opensea.io/collection/trippy-wolves-2",
  },
  {
    Name: "Unstoppable Domains",
    contract: "0xa9a6a3626993d487d2dbda3173cf58ca1a9d9e9f",

    MagicEden:
      "https://magiceden.io/collections/polygon/0xa9a6a3626993d487d2dbda3173cf58ca1a9d9e9f",
    OpenSea: "https://opensea.io/collection/unstoppable-domains-polygon",
  },
];
