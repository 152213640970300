import assets from "assets";
import { dropdownOptions } from "components/DropDown/BasicDropdown";

export enum Currencies {
    USD = "usd",
    MATIC = "matic",
    WETH = "weth",
}

export const CurrencyMap: { [x: string]: TypeCurrency } = {
    [Currencies.WETH]: {
        id: Currencies.WETH,
        name: "eth",
        symbol: "ETH",
        decimal: 18,
        logo: assets.logos.ethLogo,
        logoIcon: assets.logos.eth,
        logoIconActiv: assets.logos.ethBlack,

    },
    [Currencies.MATIC]: {
        id: Currencies.MATIC,
        name: "Matic",
        symbol: "MATIC",
        decimal: 18,
        logo: assets.logos.polygonLogoCircle,
        logoIcon: assets.logos.maticLogo,
        logoIconActiv: assets.logos.maticBlack,

    },
    [Currencies.USD]: {
        id: Currencies.USD,
        name: "usd",
        symbol: "USD",
        decimal: 18,
        logo: assets.logos.usdLogo,
        logoIcon: assets.logos.usd,
        logoIconActiv: assets.logos.usdBlack,

    }
}

export const CurrencyList: TypeCurrency[] = Object.keys(CurrencyMap).map((keyElement) => CurrencyMap[keyElement]);

export type TypeCurrency = {
    id: string;
    name: string;
    symbol: string;
    decimal: number;
    logo: string;
    logoIcon?: string;
    logoIconActiv?: string;
}
