import assets from "assets";
import { SmallOptionButton } from "components/Button";
import BarChart from "components/Chart/BarChart";
import LineAreaChart from "components/Chart/LineAreaChart";
import { AutoColumn } from "components/Column";
import { HorizontalDivider } from "components/Divider/inde";
import GraphTableTopHeader from "components/HeaderComponent/GraphTableTopHeader";
import { GenericImageWrapper } from "components/Logo";
import { TabularNumsText } from "components/shared";
import useMatchBreakpoints from "hooks/useMatchBreakpoints";
import useTakeScreenshot from "hooks/useTakeSnapshot";
import useTheme from "hooks/useTheme";
import React, { useEffect, useMemo, useState } from "react";
import { useCurrencyUnit } from "store/user/hooks";
import { TEXT } from "theme/texts";
import { ChartDayData, GenericChartSeries, TimeWindow } from "types";
import { unixToDate } from "utils/date";
import { formatAmount } from "utils/numbers";
import { getScrollToElementPath } from "utils/path";
import {
  ChartTopRightSectionWrapper,
  ChartWrapper,
  ListButtonWrapper,
} from "./styled";
const twitterShareScrollPath = getScrollToElementPath(null, null);
const snapshotDownloadIconId = "snapshot-volume-chart-download-icon";
const snapshotBrandLogoId = "snapshot-volume-chart-brand-logo";

const ButtonList = ({
  timeWindow,
  setTimeWindow,
}: {
  timeWindow: TimeWindow;
  setTimeWindow: React.Dispatch<React.SetStateAction<TimeWindow>>;
}) => {
  return (
    <ChartTopRightSectionWrapper>
      <ListButtonWrapper>
        {[TimeWindow.threeMonth, TimeWindow.sixMonth, TimeWindow.oneYear].map(
          (option) => {
            return (
              <SmallOptionButton
                active={timeWindow === option}
                onClick={() => setTimeWindow(option)}
              >
                {option === "custom" ? (
                  <GenericImageWrapper
                    src={assets.icons.calendarIcon}
                    size="18px"
                  />
                ) : (
                  option
                )}
              </SmallOptionButton>
            );
          }
        )}
      </ListButtonWrapper>
    </ChartTopRightSectionWrapper>
  );
};

export type graphProps = {
  chartData: ChartDayData[] | undefined;
  refGraph: React.RefObject<HTMLDivElement>;
  disable: boolean;
};

function VolumeGraph({ chartData, refGraph, disable }: graphProps) {
  const theme = useTheme();
  const isBarGraph = false;

  const [volumeHover, setVolumeHover] = useState<number | undefined>();
  const [liquidityHover, setLiquidityHover] = useState<number | undefined>();
  const [leftLabel, setLeftLabel] = useState<string | undefined>();
  const [rightLabel, setRightLabel] = useState<string | undefined>();
  const [timeWindow, setTimeWindow] = useState(TimeWindow.oneYear);
  const [totalVolume, setTotalVolume] = useState<number | undefined>(0);
  const [allData, setAllData] = useState<ChartDayData[] | undefined>([]);
  const { isMobile } = useMatchBreakpoints();
  const { currency } = useCurrencyUnit();

  useEffect(() => {
    let data = chartData?.map((day: any) => {
      return {
        ...day,
        volumeUSD:
          currency?.symbol === "ETH"
            ? day?.volume_in_eth
            : currency?.symbol === "USD"
            ? day?.volumeUSD
            : day?.volume_in_matic,
      };
    });
    setAllData(data);
  }, [chartData, currency?.symbol]);

  const downloadScreenshot = useTakeScreenshot({
    ref: refGraph,
    elementIdsTohide: [snapshotDownloadIconId],
    elementIdsToShow: [snapshotBrandLogoId],
    fileName: "volume-chart",
    location: window.location.href,
  });

  useEffect(() => {
    setLiquidityHover(undefined);
    setVolumeHover(undefined);
  }, []);

  const FilterDate = (data: ChartDayData[], timeWindow: TimeWindow) => {
    let filteredData: ChartDayData[] = [];
    switch (timeWindow) {
      case TimeWindow.threeMonth:
        filteredData = data.filter(
          (day) => day.date > new Date().getTime() / 1000 - 7776000
        );

        break;
      case TimeWindow.sixMonth:
        filteredData = data.filter(
          (day) => day.date > new Date().getTime() / 1000 - 15552000
        );
        break;
      case TimeWindow.oneYear:
        filteredData = data.filter(
          (day) => day.date > new Date().getTime() / 1000 - 31104000
        );
        break;
      default:
        filteredData = data;
        break;
    }

    let totalVolume = filteredData.reduce(
      (a, b: any) => a + Number(b.volumeUSD),
      0
    );
    setTotalVolume(totalVolume);
    return filteredData;
  };

  const formattedChartData = useMemo(() => {
    if (allData) {
      return FilterDate(allData, timeWindow).map((day: any) => {
        return {
          time: disable
            ? new Date(day.date * 1000)?.toLocaleString("en-US", {
                hour12: true,
              })
            : unixToDate(day.date),
          volume: day?.volumeUSD,
        };
      });
    } else {
      return [];
    }
  }, [allData, timeWindow]);

  /**
   * @notice
   * @dev 'formattedChartSeries'  must change according to requirement
   * Add according requirement (This will plot line graph with provided color and data)
   */
  const formattedChartSeries: GenericChartSeries[] = [
    { dataKey: "volume", name: "Volume", color: "#14EEEC" },
  ];

  return (
    <>
      <GraphTableTopHeader
        title={"Volume"}
        tooltip="Total matic transacted via buying and selling of the NFTs in this collection"
        downloadScreenshot={downloadScreenshot}
        isMobileView={isMobile}
        snapshotBrandLogoId={snapshotBrandLogoId}
        snapshotDownloadIconId={snapshotDownloadIconId}
        tweetText={`https://twitter.com/intent/tweet?text=💜 Polygon NFT insights: Volume%0A📆 for: ${timeWindow}%0A🎯 ${formatAmount(
          totalVolume,
          2
        )} ${currency?.name?.toLocaleUpperCase()}%0A%0A🔗Check out the detailed chart on MNFST Tools by @MNFSTLabs here: ${twitterShareScrollPath} `}
      />
      <HorizontalDivider />
      {isBarGraph ? (
        <ChartWrapper>
          <BarChart
            data={formattedChartData}
            series={formattedChartSeries}
            setValue={setVolumeHover}
            setLabel={setRightLabel}
            value={volumeHover}
            label={rightLabel}
            activeWindow={timeWindow}
            topRight={
              disable ? null : (
                <ButtonList
                  timeWindow={timeWindow}
                  setTimeWindow={setTimeWindow}
                />
              )
            }
            topLeft={
              <AutoColumn gap="4px">
                <TEXT.LargeHeader>
                  <TabularNumsText>{formatAmount(12000, 2)}</TabularNumsText>
                </TEXT.LargeHeader>
                <TEXT.SmallHeader fontSize="12px" height="14px">
                  {rightLabel ? (
                    <TabularNumsText>{rightLabel} (UTC)</TabularNumsText>
                  ) : null}
                </TEXT.SmallHeader>
              </AutoColumn>
            }
          />
        </ChartWrapper>
      ) : (
        <ChartWrapper>
          <LineAreaChart
            data={formattedChartData}
            // Change this according to dataset

            series={formattedChartSeries}
            setValue={setVolumeHover}
            setLabel={setRightLabel}
            value={volumeHover}
            label={rightLabel}
            activeWindow={timeWindow}
            topRight={
              disable ? null : (
                <ButtonList
                  timeWindow={timeWindow}
                  setTimeWindow={setTimeWindow}
                />
              )
            }
            topLeft={
              <AutoColumn gap="4px">
                <TEXT.MediumHeader fontSize="16px">
                  Volume ({currency?.symbol})
                </TEXT.MediumHeader>
                <TEXT.LargeHeader fontSize="32px">
                  <GenericImageWrapper src={currency?.logo} size="24px" />
                  <TabularNumsText>
                    {formatAmount(totalVolume, 2)}
                  </TabularNumsText>
                </TEXT.LargeHeader>
                <TEXT.SmallHeader fontSize="12px" height="14px">
                  {rightLabel ? (
                    <TabularNumsText>{rightLabel} (UTC)</TabularNumsText>
                  ) : null}
                </TEXT.SmallHeader>
              </AutoColumn>
            }
          />
        </ChartWrapper>
      )}
    </>
  );
}

export default VolumeGraph;
