import assets from "assets";
import React from "react";
import styled from "styled-components";

const CarouselCardHome5 = () => {
  return (
    <CarouselCardWrapper2>
      <div className="center">
        <div className="texts">
          We're Live with <br></br> <span>Surfacenboard</span>{" "}
        </div>
      </div>
      <div className="rightSided ">
        <div className="imgBoxright">
          <div className="smallText">
            Ultimate NFT analytics tool designed exclusively for the purple
            madness{" "}
          </div>
          <a href="https://twitter.com/intent/tweet?text=gm%20polygon%20fam%21%20%F0%9F%92%9C%0D%0A%0D%0Agrab%20real-time%20analytics%2C%20juicy%20insights%2C%20top%20collections%2C%20and%20upcoming%20airdrops%20on%20https%3A%2F%2Fctt.ec%2FW29rw%2B%20by%20%40LayerEhq%20%F0%9F%9A%80%0D%0A%0D%0Ago%20check%20it%20out%21%20&original_referer=https://clicktotweet.com&related=tweetdripapp" target="_blank" className="lf" rel="noopener noreferrer">
          <div className="btnbox">
            <div className="btnicon">
              <img src={assets.images.twitteri} alt="" />
            </div>
            <div className="btntext">Share with your Frens</div>
          </div>
          </a>
        </div>
      </div>
      <div className="rightSide mobile">
        <div className="imgBoxright">
         
        </div>
      </div>
    </CarouselCardWrapper2>
  );
};
const CarouselCardWrapper2 = styled.div`
  position: relative;
  .fl{
    margin-top: 20px;
text-decoration: none;
    box-sizing: border-box;
    padding: 2px;
    border-radius: 100%;
    background: linear-gradient(209.57deg, rgba(144, 150, 230, 0.69) 18.11%, rgba(16, 22, 101, 0) 47.68%, rgba(126, 132, 211, 0.7) 81.9%); 

  }
  .cc {
    position: absolute;
    right: 0px;
    /* bottom: 0px; */
  }
  .pc {
    display: block;
  }
  .mobile {
    display: none;
  }
  display: flex;
  width: 100%;
  height: 225px;

  /* background: #0e0e0e; */
  /* background: linear-gradient(180deg, #020202 0%, #1c1d2b 100%); */
  /* stroke */
  background-image: url(${assets.images.bgup});
  background-repeat: no-repeat;
  background-size: 100% 100%;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
  /* border-radius: 16px; */
  /* background: linear-gradient(180deg, #f1ede5 0%, #e3d7bf 100%); */

  display: flex;
  justify-content: space-between;
  align-items: center;
  /* border: 1px solid #2d2f31; */

  .leftSide {
    .imgBoxLeft {
      img {
        z-index: 3;
      }
    }
  }
  .imgBoxright {
    max-width: 350px;
    padding-right: 60px;
  }
  .smallText {
    font-weight: 300;
    font-size: 16px;
    line-height: 20px;
  }
  .lf{
    text-decoration: none;
    
  }
  .btnbox {
    margin-top: 20px;
    display: flex;
    background: linear-gradient(
        119.07deg,
        rgba(95, 55, 179, 0.2) 12.52%,
        rgba(104, 65, 186, 0) 67.86%
      ),
      #111032;
    border-radius: 6.74346px;
    padding: 8px 19px;
    justify-content: center;
    align-items: center;
    border: 1px solid;

    border-image-source: linear-gradient(
      105.64deg,
      #bbbdd8 10.14%,
      rgba(20, 26, 119, 0) 50.09%,
      #bec0e0 89.21%
    );

    .btnicon {
      margin-right: 15px;
    }
    .btntext {
      font-weight: 600;
      font-size: 21.0733px;
      line-height: 18px;
      background: linear-gradient(
          196.86deg,
          #b4bcfc 44.54%,
          rgba(45, 54, 196, 0.83) 107.68%
        ),
        #070707;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }
  }
  .center {
    color: #000000;
    /* text-align: center; */
    padding-left: 60px;

    .texts {
      font-weight: 400;
      font-size: 62.2575px;
      line-height: 55px;
      color: #fff !important;
      span {
        background: linear-gradient(
            174.92deg,
            rgba(158, 119, 237, 1) 95.97%,
            rgba(133, 140, 255, 0.95) 51.93%
          ),
          #070707;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
      }
      /* or 38px */

      /* text-align: center; */
    }
    .mediumText {
      font-weight: 300;
      font-size: 26px;
      line-height: 120%;
    }
    .btnBox {
      margin-top: 20px;
      a {
        text-decoration: none;
      }
      .btn {
        /* margin: 0 auto; */
        color: #fff;
        width: max-content;
        background: #1b1b21;
        border: 0.5px solid #363636;
        border-radius: 8px;
        padding: 12px 16px;
      }
    }
    .rightSided {
      /* position: relative; */
      .imgBoxright {
        img {
        }
      }
    }
  }

  @media only screen and (max-width: 700px) {
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;

    background-image: url(${assets.images.bgup});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 70% ;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);

  .btnbox {
    margin-top: 20px;
    display: flex;
    background: linear-gradient(
        119.07deg,
        rgba(95, 55, 179, 0.2) 12.52%,
        rgba(104, 65, 186, 0) 67.86%
      ),
      #111032;
    border-radius: 6.74346px;
    padding: 8px 13px;
    justify-content: center;
    align-items: center;
    border: 1px solid;

    border-image-source: linear-gradient(
      105.64deg,
      #bbbdd8 10.14%,
      rgba(20, 26, 119, 0) 50.09%,
      #bec0e0 89.21%
    );

    .btnicon {
      margin-right: 8px;
    }
    .btntext {
      font-weight: 500;
font-size: 14px;
line-height: 30px;
    }
  }
    .pc {
      display: none;
    }
    .mobile {
      display: block;
    }
    position: relative;
    padding: 20px;
    .imgBoxright {
      max-width: 100%;
      padding-right: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
     
    }
    .center {
      position: relative;
      z-index: 10;
      color: #000000;
      width: 100%;
      text-align: left;
      padding-left: 0px;

      .smallText {
        font-weight: 400;
font-size: 14px;
line-height: 20px;
        width: 100%;
      }
      .texts {
        font-weight: 400;
font-size: 38.4089px;
line-height: 34px;
        text-align: left;
        margin-bottom: 32px;

        /* or 29px */

       
      }
      .mediumText {
        font-weight: 300;
        font-size: 14px;
        line-height: 120%;
      }
      .btnBox {
        margin-top: 20px;
        a {
          text-decoration: none;
        }
        .btn {
          margin: 0 0;
          color: #fff;
          font-size: 12px;
          width: max-content;
          background: #1b1b21;
          border: 0.5px solid #363636;
          border-radius: 8px;
          padding: 12px 16px;
        }
      }
    }

    display: flex;
    justify-content: start;
    align-items: left;
    height: 270px;

    .carouselContent {
      .text {
        font-weight: 600;
        font-size: 32px;
        line-height: 130%;
      }
    }
  }
  .rightSide {
    position: absolute;
    right: 0;
    bottom: 0;

   
  }
`;
export default CarouselCardHome5;
