import axios from "axios";
import Row, {
  AutoRow,
  ResponsiveRow,
  ResponsiveRowWrap,
  ResponsiveRowWrap2,
} from "components/Row";
import React, { createRef, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import ProfileHeader from "./ProfileHeader";
import { OverviewProfileCard } from "components/DataCard";
import { PageWrapper } from "pages/styled";
import styled from "styled-components";
import Column, { AutoColumn } from "components/Column";
import Spacer from "components/Spacer";
import VolumeGraph from "./components/VolumeGraph";
import { useProtocolChartData } from "store/protocol/hooks";
import { TEXT } from "theme/texts";
import QuestionHelper from "components/QuestionHelper";
import FloorAvgPriceGraph from "./components/FloorAvgPriceGraph";
import SalesGraph from "./components/SalesGraph";
import OwnersCountGraph from "./components/OwnersCountGraph";
import TokenSupplyGraph from "./components/TokenSupplyGraph";
import TopOwners from "./components/TopOwners";
import ListingAndSales from "./components/ListingAndSales";

import MutualCollections from "./components/MutualCollections";
import FinancialProfiles from "./components/FinancialProfiles";

import FloorPriceGraph from "./components/FloorPrice";

import { ChevronRight } from "react-feather";
import { useNavigate } from "react-router-dom";

import MockData from "./mockdata.json";
import PageNav from "./components/PageNav";
import { utils, providers } from "ethers";

import { useCurrencyUnit } from "store/user/hooks";
import { formatAmount } from "utils/numbers";
import SKLTTable from "components/Skeletons/common/SKLTTable";
import useTheme from "hooks/useTheme";
import { listOfCollection } from "./data";

const Profile = () => {
  const { profileID } = useParams();
  const [chartData] = useProtocolChartData();
  const [profileData, setProfileData] = useState(null);
  const [owners, setOwners] = useState(null);
  const [sales, setSales] = useState(null);
  const [listing, setListing] = useState(false);
  const [salesVolume, setSalesVolume] = useState(null);
  const [floorPriceData, setFloorPriceData] = useState(null);
  const [ownersCountGraph, setOwnersCountGraph] = useState(null);
  const [tokenSupplyGraph, setTokenSupplyGraph] = useState(null);

  const [topSales, setTopSales] = useState(null);

  const [mutualCollections, setMutualCollections] = useState(null);
  const [financialProfileData, setFinancialProfileData] = useState(null);

  const navigate = useNavigate();

  let yootState = false;
  let newCollections = [
    "0x670fd103b1a08628e9557cd66b87ded841115190",
    "0xc1a5f386e3b2d3cb280191fcd11e76c41117197d",
    "0x5ebf35cae481e6fec6b908d99c74cddfac237856",
    "0x220fa5ccc9404802ed6db0935eb4feefc27c937e",
    "0x2ebe61759e916b61523ea1fda8e1c057688bd8f3",
  ];
  // if(profileID && newCollections.includes(profileID.toLowerCase())){
  //   yootState = false;
  // }
  // console.log(yootState);

  const refSalesGraph = createRef();
  const refFloorsAvgPriceGraph = createRef();
  const refVolumeGraph = createRef();
  const refOwnersGraph = createRef();
  const refTotalSupplyGraph = createRef();
  const refFloorPriceGraph = createRef();

  const { currency } = useCurrencyUnit();

  let FloorPriceFromGraph =
    floorPriceData && floorPriceData[floorPriceData.length - 1];
  let SalesVolumeFromGraph = salesVolume && salesVolume[salesVolume.length - 1];
  let FloorChangePercentage =
    floorPriceData &&
    ((floorPriceData[floorPriceData.length - 1].min -
      floorPriceData[floorPriceData.length - 2].min) /
      floorPriceData[floorPriceData.length - 2].avg) *
      100;
  let SalesChangePercentage =
    salesVolume &&
    ((salesVolume[salesVolume.length - 1].volumeUSD -
      salesVolume[salesVolume.length - 2].volumeUSD) /
      salesVolume[salesVolume.length - 2].volumeUSD) *
      100;
  let AvgPriceChangePercentage =
    floorPriceData &&
    ((floorPriceData[floorPriceData.length - 1].avg -
      floorPriceData[floorPriceData.length - 2].avg) /
      floorPriceData[floorPriceData.length - 2].avg) *
      100;
  let OwnersChange =
    ownersCountGraph &&
    ((ownersCountGraph[ownersCountGraph.length - 1].count -
      ownersCountGraph[ownersCountGraph.length - 2].count) /
      ownersCountGraph[ownersCountGraph.length - 2].count) *
      100;

  let TopSale =
    sales && sales?.sort((a, b) => b.price_in_eth - a.price_in_eth)[0];

  SalesChangePercentage =
    SalesChangePercentage === Infinity ? 0 : SalesChangePercentage;
  FloorChangePercentage =
    FloorChangePercentage === Infinity ? 0 : FloorChangePercentage;
  AvgPriceChangePercentage =
    AvgPriceChangePercentage === Infinity ? 0 : AvgPriceChangePercentage;
  OwnersChange = OwnersChange === Infinity ? 0 : OwnersChange;

  const FetchEthBalance = async (address) => {
    try {
      const provider = new providers.JsonRpcProvider(
        "https://rpc.ankr.com/eth"
      );
      const balance = await provider.getBalance(address);
      return utils.formatEther(balance);
    } catch (error) {
      console.log(error);
      return 0;
    }
  };

  const FetchENSName = async (address) => {
    try {
      const provider = new providers.JsonRpcProvider(
        "https://rpc.ankr.com/eth"
      );
      const ensName = await provider.lookupAddress(address);
      return ensName;
    } catch (error) {
      console.log(error);
      return address?.slice(0, 6) + "..." + address?.slice(-4);
    }
  };

  const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));
  const fetchProfileData = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/collectionDetails/${profileID}`
      );

      const dataRes = res?.data?.data

      listOfCollection.map(item => {
       const mache =  item.contract === dataRes.contractAddress
       if(mache) {
        dataRes.marketplace.magiceden = item?.MagicEden
        dataRes.marketplace.opensea = item?.OpenSea

      setProfileData(res?.data?.data);
      return true;

       }
      setProfileData(res?.data?.data);

      })
     
    } catch (error) {
      console.log(error);
    }
  };


  
  const FetchSales = async () => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/collection/sales/${profileID}`
      );
      setTopSales(
        res?.data?.data?.TopSales24?.length > 0
          ? res?.data?.data?.TopSales24
          : null
      );
      setSales(res?.data?.data?.TopSales);
      setListing(res?.data?.data?.TopListing);
    } catch (error) {
      console.log(error);
    }
  };
  const fetchSalesVolume = async () => {
    try {
      const res = await axios.get(
        `${
          process.env.REACT_APP_API_URL
        }/profileData/sales/${profileID}?duration=${yootState ? 3 : 0}`,
        {
          timeout: 10000,
        }
      );
      let data = res?.data?.data?.map((item) => {
        return {
          date: new Date(item?.timestamp).getTime() / 1000,
          qty: item?.quantity,
          volume_in_matic: item?.volume,
          volume_in_eth: item?.volume_in_eth,
          volumeUSD: item?.volume_in_usd,
        };
      });
      setSalesVolume(data);
    } catch (error) {
      console.log(error);
    }
  };
  const fetchFloorPriceData = async () => {
    try {
      const res = await axios.get(
        `${
          process.env.REACT_APP_API_URL
        }/profileData/tokenPrice/${profileID}?duration=${yootState ? 3 : 0}`,
        {
          timeout: 10000,
        }
      );
      let data = res?.data?.data?.map((item) => {
        return {
          date: new Date(item?.timestamp).getTime() / 1000,
          min: Number(item?.min),
          max: Number(item?.max),
          avg: Number(item?.avg),
          min_in_eth: item?.min_in_eth,
          max_in_eth: item?.max_in_eth,
          avg_in_eth: item?.avg_in_eth,
          min_in_usd: item?.min_in_usd,
          max_in_usd: item?.max_in_usd,
          avg_in_usd: item?.avg_in_usd,
        };
      });
      setFloorPriceData(data);
    } catch (error) {
      console.log(error);
    }
  };
  const fetchOwnersCountGraph = async () => {
    try {
      const res = await axios.get(
        `${
          process.env.REACT_APP_API_URL
        }/profileData/owners/${profileID}?duration=${yootState ? 1 : 0}`,
        {
          timeout: 10000,
        }
      );
      let data = res?.data?.data?.map((item) => {
        return {
          date: new Date(item?.timestamp).getTime() / 1000,
          count: item?.count,
        };
      });
      setOwnersCountGraph(data);
    } catch (error) {
      console.log(error);
    }
  };
  const fetchTokenSupplyGraph = async () => {
    try {
      const res = await axios.get(
        `${
          process.env.REACT_APP_API_URL
        }/profileData/supply/${profileID}?duration=${yootState ? 3 : 0}`,
        {
          timeout: 10000,
        }
      );
      let data = res?.data?.data?.map((item) => {
        return {
          date: new Date(item?.timestamp).getTime() / 1000,
          minted: item?.minted,
          burned: item?.burned,
          totalMinted: item?.totalMinted,
          totalBurned: item?.totalBurned,
        };
      });
      setTokenSupplyGraph(data);
    } catch (error) {
      console.log(error);
    }
  };
  const FetchOwners = async () => {
    try {
      const res = await axios.get(
        `${
          process.env.REACT_APP_API_URL
        }/collection/owners/${profileID}?duration=${yootState ? 1 : 0}`
      );
      let data = res?.data?.data?.TopOwners?.map(async (item) => {
        let ensName = await FetchENSName(item?.wallet);
        return {
          ...item,
          ens: ensName
            ? ensName
            : item?.wallet?.slice(0, 6) + "..." + item?.wallet?.slice(-4),
        };
      });
      data = await Promise.all(data);
      setOwners(data);
      if (res?.data?.data?.TopOwners?.length > 0) {
        FetchFinancialProfiles(
          res?.data?.data?.TopOwners?.slice(0, 20).map((item) => item?.wallet)
        );
        await delay(1000);
        FetchMutualCollections(
          res?.data?.data?.TopOwners?.slice(0, 20).map((item) => item?.wallet)
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  const FetchMutualCollections = async (wallets) => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/mutualCollections/${profileID}?wallets=${wallets}`
      );
      let data = res?.data?.data;

      data = data.filter((item, index) => {
        return (
          data.findIndex(
            (obj) => obj?.contractAddress === item?.contractAddress
          ) === index
        );
      });

      setMutualCollections(data);
    } catch (error) {
      console.log(error);
    }
  };

  const FetchFinancialProfiles = async (wallets) => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/financialProfileData/?walletAddress=${wallets}`
      );
      let data = res?.data?.data?.profiles?.map(async (item) => {
        let balance = await FetchEthBalance(item?.walletAddress);
        return {
          ...item,
          balance,
        };
      });
      data = await Promise.all(data);

      setFinancialProfileData(data);
    } catch (error) {
      console.log(error);
    }
  };

  // const FetchVolume = async () => {
  //   try {
  //     const res = await axios.get(
  //       `${process.env.REACT_APP_API_URL}/collection/volume/${profileID}`
  //     );
  //     console.log(res?.data?.data);
  //     setVolume(res?.data?.data);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };
  const [itemIdx, setItemIdx] = useState(1);
  useEffect(() => {
    if (profileID) {
      let idxValue =
        itemIdx === 1
          ? "Overall Stats"
          : itemIdx === 2
          ? "Trading Data"
          : "Wallet Data";
      window.gtag("event", "MicroPage_Stats", {
        event_category: `Collection-${profileID} - ${idxValue}`,
        event_label: window.location.pathname,
      });
      window.gtag("event", "MicroPage", {
        event_category: `${idxValue}`,
        event_label: window.location.pathname,
      });
    }
  }, [itemIdx, profileID]);
  useEffect(() => {
    fetchProfileData();
    FetchSales();
    fetchSalesVolume();
    async function fetchData1() {
      await delay(1000);
      fetchFloorPriceData();
      await delay(1000);
      fetchOwnersCountGraph();
      await delay(1000);
      fetchTokenSupplyGraph();
    }
    async function fetchData() {
      await delay(2000);
      FetchOwners();
    }
    fetchData1();
    fetchData();
    window.gtag("event", "MicroPage", {
      event_category: `Collection-${profileID}`,
      event_label: window.location.pathname,
    });
  }, [profileID]);

  return (
    <ProfileCtr>
  
      <Row style={{ gap: ".5rem" }}>
        <TEXT.Disabled
          color={"grey"}
          style={{ cursor: "pointer", minWidth: "fit-content" }}
          onClick={() => {
            navigate("/collections");
          }}
        >
          Collections
        </TEXT.Disabled>
        <ChevronRight size={"1.25rem"} />
        <TEXT.Primary className="_profileNameLabel" color={useTheme().body}>
          {profileData?.name}
        </TEXT.Primary>
      </Row>
      
      <Spacer />
      <Column style={{ gap: "2rem" }}>
        <ProfileHeader profileData={profileData} />
        <PageNav setItemIdx={setItemIdx} currentItemIdx={itemIdx} />
        {profileData && itemIdx === 1 && (
          <Column style={{ gap: "1rem" }}>
            <ResponsiveRowWrap minWidth={300}>
              {[
                {
                  title: "Floor price",
                  priceCount:
                    currency?.symbol === "ETH"
                      ? profileData?.floorPrice_in_eth ||
                        FloorPriceFromGraph?.min_in_eth
                      : currency?.symbol === "USD"
                      ? profileData?.floorPrice_in_usd ||
                        FloorPriceFromGraph?.min_in_usd
                      : profileData?.floorPrice_in_matic ||
                        FloorPriceFromGraph?.min,
                  priceChange:
                    profileData?.floorPriceChange || FloorChangePercentage,
                  tooltip: `The minimum ${currency?.name?.toLocaleUpperCase()} required to purchase a NFT from this collection`,
                },
                {
                  title: "Volume(24h)",
                  priceCount:
                    currency?.symbol === "ETH"
                      ? profileData?.volume_in_weth ||
                        SalesVolumeFromGraph?.volume_in_eth
                      : currency?.symbol === "USD"
                      ? profileData?.volume_in_usd ||
                        SalesVolumeFromGraph?.volumeUSD
                      : profileData?.volume_in_matic ||
                        SalesVolumeFromGraph?.volume_in_matic,
                  priceChange:
                    currency?.symbol === "ETH"
                      ? profileData?.volume_in_weth_change ||
                        SalesChangePercentage
                      : currency?.symbol === "USD"
                      ? profileData?.volume_change_in_usd ||
                        SalesChangePercentage
                      : profileData?.volume_in_matic_change ||
                        SalesChangePercentage,
                  tooltip: `Total ${currency?.name?.toLocaleUpperCase()} transacted via buying and selling of the NFTs in this collection`,
                },
                {
                  title: "Average Price(24h)",
                  priceCount:
                    currency?.symbol === "ETH"
                      ? profileData?.floorPrice_in_eth ||
                        FloorPriceFromGraph?.avg_in_eth
                      : currency?.symbol === "USD"
                      ? profileData?.floorPrice_in_usd ||
                        FloorPriceFromGraph?.avg_in_usd
                      : profileData?.floorPrice_in_matic ||
                        FloorPriceFromGraph?.avg,
                  priceChange:
                    currency?.symbol === "ETH"
                      ? profileData?.floorPrice_change_in_eth ||
                        AvgPriceChangePercentage
                      : currency?.symbol === "USD"
                      ? profileData?.floorPrice_change_in_usd ||
                        AvgPriceChangePercentage
                      : profileData?.floorPrice_change_in_matic ||
                        AvgPriceChangePercentage,
                  tooltip: `Average amount of ${currency?.name?.toLocaleUpperCase()} needed to buy a NFT from this collection`,
                },
                {
                  title: "Owners",
                  count:
                    profileData?.owners ||
                    (ownersCountGraph &&
                      ownersCountGraph[ownersCountGraph.length - 1]?.count),
                  priceChange: profileData?.owners_change || OwnersChange,
                  tooltip:
                    "Number of unique wallets that hold atleast 1 NFT from this collection",
                },
                {
                  title: "Total Supply",
                  count:
                    profileData?.supply ||
                    (tokenSupplyGraph &&
                      tokenSupplyGraph[tokenSupplyGraph?.length - 1]
                        ?.totalMinted -
                        tokenSupplyGraph[tokenSupplyGraph?.length - 1]
                          ?.totalBurned),
                  tooltip: "Number of NFTs present in this collection",
                },
                profileData?.washtrades_in_weth ||
                profileData?.washtrades_in_usd ||
                profileData?.washtrades_in_matic
                  ? {
                      title: "Wash trades",
                      count:
                        currency?.symbol === "ETH"
                          ? profileData?.washtrades_in_weth
                          : currency?.symbol === "USD"
                          ? profileData?.washtrades_in_usd
                          : profileData?.washtrades_in_matic,
                      tooltip: `Total amount of ${currency?.name?.toLocaleUpperCase()} transacted via washtrades in this collection`,
                    }
                  : null,
                topSales
                  ? {
                      title: "Top Sales (24h)",
                      priceCount: topSales
                        ? currency?.symbol === "ETH"
                          ? topSales[0]?.price_in_eth
                          : currency?.symbol === "USD"
                          ? topSales[0]?.price_in_usd
                          : topSales[0]?.price_in_matic
                        : 0,
                      priceChange: profileData?.sales_change,
                      tooltip:
                        "Top sales in this collection in the last 24 hours",
                    }
                  : null,

                owners?.length > 0
                  ? {
                      title: `Top Owner`,
                      redirect: `https://debank.com/profile/${owners[0]?.wallet}`,
                      text:
                        owners[0]?.wallet?.slice(0, 6) +
                        "..." +
                        owners[0]?.wallet?.slice(-4) +
                        " (" +
                        formatAmount(owners[0]?.amount, 0).toUpperCase() +
                        " - NFTs)",
                      tooltip: `Number of NFTs owned by the top owner in this collection`,
                    }
                  : null,
                mutualCollections?.length > 0
                  ? {
                      title: `Top Mutual Collection`,
                      redirect: `${window.location.origin}/#/collections/${mutualCollections[0]?.contractAddress}`,
                      text: mutualCollections[0]?.name,
                      tooltip: `Top mutual collection held by top owners`,
                    }
                  : null,
              ].map((value) => {
                if (value)
                  return (
                    <OverviewProfileCard
                      redirect={value?.redirect}
                      title={value?.title}
                      tooltip={value?.tooltip}
                      count={value?.count}
                      percentCount={value?.percentCount}
                      priceCount={value?.priceCount}
                      priceChange={value?.priceChange}
                      text={value?.text}
                    />
                  );
                else return null;
              })}
            </ResponsiveRowWrap>

            <Column
              style={{ width: "100%" }}
              className="_graph"
              ref={refFloorPriceGraph}
            >
              {floorPriceData && (
                <FloorPriceGraph
                  chartData={floorPriceData}
                  refGraph={refFloorPriceGraph}
                  disable={yootState}
                />
              )}
            </Column>
          </Column>
        )}
        {itemIdx === 2 && (
          <>
            <ResponsiveRow gap="1rem" className="_volAndFloorGraphsCtr">
              {salesVolume?.length > 0 ? (
                <Column
                  style={{ width: "100%" }}
                  className="_graph"
                  ref={refVolumeGraph}
                >
                  <VolumeGraph
                    chartData={salesVolume}
                    refGraph={refVolumeGraph}
                    disable={yootState}
                  />
                </Column>
              ) : null}
              <Column
                style={{ width: "100%" }}
                className="_graph"
                ref={refFloorsAvgPriceGraph}
              >
                <FloorAvgPriceGraph
                  chartData={floorPriceData}
                  refGraph={refFloorsAvgPriceGraph}
                  disable={yootState}
                />
              </Column>
            </ResponsiveRow>
            <ResponsiveRow gap="1rem" className="_volAndFloorGraphsCtr">
              <Column
                style={{ width: "100%" }}
                className="_graph"
                ref={refOwnersGraph}
              >
                <OwnersCountGraph
                  chartData={ownersCountGraph}
                  refGraph={refOwnersGraph}
                  disable={yootState}
                />
              </Column>
              <Column
                style={{ width: "100%" }}
                className="_graph"
                ref={refTotalSupplyGraph}
              >
                <TokenSupplyGraph
                  chartData={tokenSupplyGraph}
                  refGraph={refTotalSupplyGraph}
                  disable={yootState}
                />
              </Column>
            </ResponsiveRow>
          </>
        )}
        {itemIdx === 2 && (
          <Column
            style={{ width: "100%" }}
            className="_graph"
            ref={refSalesGraph}
          >
            <SalesGraph
              chartData={salesVolume}
              refGraph={refSalesGraph}
              disable={yootState}
            />
          </Column>
        )}
        {itemIdx === 3 && (
          <Column style={{ maxWidth: "100%", gap: "1.5rem" }}>
            <ResponsiveRow
              gap="1.5rem"
              className="_salesAndOwnersGraphsCtr"
              style={{ alignItems: "flex-start" }}
            >
              {!listing?.length && !sales?.length ? null : (
                <ListingAndSales
                  salesData={sales?.length > 0 ? sales : []}
                  ListingData={listing?.length > 0 ? listing : []}
                />
              )}
              <TopOwners data={owners} />
            </ResponsiveRow>
            {mutualCollections?.length > 0 && (
              <MutualCollections mutualData={mutualCollections?.slice(0, 5)} />
            )}
            <ResponsiveRow gap="1rem" className="_salesAndOwnersGraphsCtr">
              {financialProfileData?.length > 0 && (
                <FinancialProfiles data={financialProfileData} />
              )}
            </ResponsiveRow>
          </Column>
        )}
      </Column>
    </ProfileCtr>
  );
};

const ProfileCtr = styled(PageWrapper)`
// background-color:red;
  padding-top: 1rem;
  ._graph {
    border: 1px solid ${(props) => props.theme.disabled};
  }
  ._profileNameLabel {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 300px;
  }
  ._volAndFloorGraphsCtr,
  ._salesAndOwnersGraphsCtr {
    overflow: hidden;
    //@media quries : mobile screens
    ${({ theme }) => theme.mediaWidth.upToLarge`
      flex-direction:column;
    `}
  }
`;
export default Profile;
