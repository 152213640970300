import assets from "assets";
import axios from "axios";
import React, {useRef, useState} from "react";
import styled from "styled-components";

const CarouselCardNews = () => {

  const [email, setEmail] = useState("");
  const formRef = useRef(null);

  const subscribeToNewsletter = async (e) => {
    e.preventDefault();
    let bodyFormData = new FormData();
    bodyFormData.append("Email", email?.toLowerCase());
    try {
      
      const res = await axios({
        method: "post",
        url: "https://script.google.com/macros/s/AKfycbwsHkZdRtJKMGuRgZGr136s4XFTheeGzvADlgqF39KjGrU7uFJ3FloQVuK_IhFazRWB/exec",
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      });
      if (res.status === 200) {
        // toast.success("Subscribed Successfully!");
      } else {
        // toast.error("Error subscribing to newsletter!");
      }
    } catch {
      // toast.error("Error subscribing to newsletter!");
    } finally {
      formRef?.current?.reset();
    }
  };
  return (
    <CarouselCardWrapperb>
      <div className="content">
        <div className="subheader">SUBSCRIBE TO THE</div>
        <div className="head">MNFST Newsletter</div>
        <div className="subheader">FOR UPDATES ON ALL THINGS POLYGON</div>
        <div className="inp">
          <div className="lefts pc">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="180"
              height="28"
              viewBox="0 0 180 28"
              fill="none"
            >
              <path
                d="M179.5 13V1H20.5L1 20.5V27H164.5L179.5 13Z"
                stroke="white"
              />
            </svg>
          </div>
          <div className="inbox">
          <form onSubmit={subscribeToNewsletter} ref={formRef}>

            <input type="email" placeholder="Add email adress here"  
            required

            onChange={(e) => setEmail(e?.target?.value)}

            
            />
            
            <button>Join</button>
            </form>

          </div>
          <div className="rights pc">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="180"
              height="28"
              viewBox="0 0 180 28"
              fill="none"
            >
              <path d="M1 13V1H160L179.5 20.5V27H16L1 13Z" stroke="white" />
            </svg>
          </div>
        </div>

        <div className="bo">
        <div className="line pc"></div>
        <div className="linec">
        <svg xmlns="http://www.w3.org/2000/svg" width="188" height="18" viewBox="0 0 188 18" fill="none">
        <g clip-path="url(#clip0_3936_4619)">
          <path d="M-10.0469 25.3441L30.1275 -18.4824" stroke="white" stroke-width="3"/>
          <path d="M1.9043 25.3441L42.0786 -18.4824" stroke="white" stroke-width="3"/>
          <path d="M13.8594 26.6723L54.0337 -17.1543" stroke="white" stroke-width="3"/>
          <path d="M26.4746 26.6723L66.649 -17.1543" stroke="white" stroke-width="3"/>
          <path d="M37.7637 28.0004L77.938 -15.8262" stroke="white" stroke-width="3"/>
          <path d="M50.3809 28.0004L90.5552 -15.8262" stroke="white" stroke-width="3"/>
          <path d="M62.9961 28.0004L103.17 -15.8262" stroke="white" stroke-width="3"/>
          <path d="M76.2773 28.0004L116.452 -15.8262" stroke="white" stroke-width="3"/>
          <path d="M89.5586 28.0004L129.733 -15.8262" stroke="white" stroke-width="3"/>
          <path d="M100.848 28.0004L141.022 -15.8262" stroke="white" stroke-width="3"/>
          <path d="M114.791 28.0004L154.965 -15.8262" stroke="white" stroke-width="3"/>
          <path d="M126.08 28.0004L166.254 -15.8262" stroke="white" stroke-width="3"/>
          <path d="M139.361 28.0004L179.536 -15.8262" stroke="white" stroke-width="3"/>
          <path d="M152.643 28.0004L192.817 -15.8262" stroke="white" stroke-width="3"/>
          <path d="M165.26 28.0004L205.434 -15.8262" stroke="white" stroke-width="3"/>
          <path d="M177.875 28.0004L218.049 -15.8262" stroke="white" stroke-width="3"/>
          <path d="M-22 25.3441L18.1743 -18.4824" stroke="white" stroke-width="3"/>
        </g>
        <defs>
          <clipPath id="clip0_3936_4619">
            <rect width="188" height="18" fill="white"/>
          </clipPath>
        </defs>
      </svg>
        </div>

        <div className="line pc">
       
        </div>

        </div>
      </div>
    </CarouselCardWrapperb>
  );
};
const CarouselCardWrapperb = styled.div`
  fill: linear-gradient(
      136deg,
      rgba(4, 176, 176, 0.2) 0%,
      rgba(0, 0, 0, 0) 42.19%,
      rgba(8, 202, 202, 0.2) 100%
    ),
    #141415;
  width: 100%;
  height: 100%;
background:url(${assets.images.bgf});
  background-repeat: no-repeat;


background-position:center center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  content {
  }
  .subheader {
    text-align: center;


    font-size: 28px;
    font-style: normal;
    font-weight: 400;
    line-height: 130%;
  }
  .head {
    color: #14eeec;
    text-align: center;
    font-family: titleFont;

    font-size: 48px;
    font-style: normal;
    font-weight: 700;
    line-height: 130%; /* 72.8px */
    text-transform: uppercase;
  }
  .inp {
    margin-top: 26px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 2rem;

    svg {
      width: 150px;
    }
  }
  .bo{
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.7em;
    margin: 25px;

  }
  .line{
    background-color: #14EEEC;
    height: 1px;
    width: 180px;

  }
  .linec{
    display: flex;
    align-items: center;
    justify-content: center;

  }
  .inbox {
    display: flex;

    input {
      border-radius: 4px 0px 0px 4px;
      background: #d9d9d9;
      padding: 5px 10px;
    }
    button {
      border-radius: 0px 4px 4px 0px;
      border-left: 1px solid #000;
      background: #14eeec;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 130%;
      color: #121212;
      text-align: center;
      height: 100%;
      height: 36px;
      padding: 0 20px;
    }
  }
  img {
    width: 100%;
  }
.pc{
  display: block;

}
  @media only screen and (max-width: 700px) {
    .pc{
  display: none;

}
.subheader {
    text-align: center;

    font-size: 16px;

  }
  .head {


    font-size: 24px;

    font-weight: 900;

  }
    flex-direction: column;
    height: 270px;
    background-size: cover;
    background-position: center;

    .carouselContent {
      .text {
        font-weight: 600;
        font-size: 24px;
        line-height: 130%;
      }
    }
  }
`;
export default CarouselCardNews;
