import axios from "axios";
import ContentCardCtr, { ContentCardCtrB } from "components/ContentCard";
import DataTable from "../DataTable";
import { DropDownCard } from "components/DropDown";
import QuestionHelper from "components/QuestionHelper";
import Row from "components/Row";
import React, { useCallback, useEffect, useState } from "react";
import { AlertCircle, ArrowLeft, ArrowRight, Search } from "react-feather";
import styled from "styled-components";
import { TEXT } from "theme/texts";
import { useCurrencyUnit } from "store/user/hooks";
import SKLTCollectionsTable from "components/Skeletons/CollectionsPage/SKLTCollectionsTable";
import { collectionURl } from "pages/LandingPage/data";
const CollectionsTableNav = ({ results, page, setPage }) => {
  const firstPage = () => {
    if (page > 1) setPage(1);
  };
  const prevPage = () => {
    if (page > 1) setPage((prev) => (prev -= 1));
  };

  const nextPage = () => {
    if (page < results.totalPages) setPage((prev) => (prev += 1));
  };
  const lastPage = () => {
    if (page < results.totalPages) setPage(results.totalPages);
  };

  const pagesArray = Array(results.totalPages)
    .fill()
    .map((_, index) => index + 1);
  return results?.totalPages > 0 ? (
    <div className="_navs">
      <button className="_staticNavs" onClick={firstPage}>
        First
      </button>
      <nav className="_tableNav">
        <button onClick={prevPage} disabled={page === 0} className="_navBtn">
          <ArrowLeft size={"1rem"} />
        </button>
        {/* Removed isPreviousData from PageButton to keep button focus color instead */}

        <button className="_navPageBtn">
          Page {page} of {results.totalPages}
        </button>

        <button
          onClick={() => nextPage(results)}
          disabled={page === results.totalPages}
          className="_navBtn"
        >
          <ArrowRight size={"1rem"} />
        </button>
      </nav>
      <button className="_staticNavs" onClick={lastPage}>
        Last
      </button>
    </div>
  ) : null;
};

const CollectionsTable = () => {
  //constants
  const SORT_DIR = { ASEC: "asec", DESC: "desc" };
  const SORT_BY = { FLOOR: "floor_in_usd", VOL: "volume_in_usd", SALES: "sales", SUPPLY: "supply" };
  const { currency } = useCurrencyUnit();
  //states
  const [tableData, setTableData] = useState(null);
  const [page, setPage] = useState(1);
  const [sortBy, setSortBy] = useState(null);
  const [sortDir, setSortDir] = useState(null);
  const [query, setQuery] = useState(""); //page query endpoint
  const [currentTime, setCurrentTime] = useState(0);
  const [supplyLimit, setSupplyLimit] = useState(0);
  const [category, setCategory] = useState({
    id: 0,
    name: "All",
  });
  const [reddit, setReddit] = useState(false);

  const [AvailableCategories, setAvailableCategories] = useState([]);

  //callbacks
  const fetchData = useCallback(async () => {
    let res = await axios.get(
       process.env.REACT_APP_API_URL +`/topCollection?duration=${currentTime}&page=${page}&search=${query}&sortBy=${sortBy}&sortDirection=${sortDir}&category=${category?.name}&supply=${supplyLimit}`
    );
    
    // const filteredData = dataf.filter(item => {
    //   // Check if the address matches any in the checkingData
    //   const matchingData = collectionURl.find(d => d.address === item.contractAddress);

    //   // If there's a match and the imgUrl is false or not equal to the item's imgUrl
    //   // then replace the item's imgUrl
    //   if (matchingData && (matchingData.imgUrl !== false )) {
    //     item.logo = matchingData.imgUrl;
    //     return true;  // keep this item in the filtered array
    //   }
    //   return !!matchingData;  // keep this item in the filtered array if there's a match
    // });

    
    setTableData(res.data);
    let AvailableCategoriesFilter = res.data?.category?.filter((item) => item !== null);
    setAvailableCategories(
    AvailableCategoriesFilter.map((item, index) => {
        return {
          id: index,
          name: item,
        };
      })
    );
    if (category?.id > 0 && !res.data?.category?.includes(category?.name)) {
      setCategory({
        id: 0,
        name: "All",
      });
    }
  }, [currentTime, category, page, query, sortBy, sortDir,supplyLimit,reddit]);
  const sortItem = ({ sort_by }) => {
    setSortBy(sort_by);
    setSortDir((prev) =>
      prev === SORT_DIR.ASEC ? SORT_DIR.DESC : SORT_DIR.ASEC
    );
  };
  const columns = currentTime < 4  ? [
    {
      id: 1,
      heading: "",
      value: "no",
    },

    {
      id: 2,
      heading: "Name",
      value: "name",
      link: "contractAddress",
      logo: "logo",
    },
    {
      id: 3,
      heading: "Volume",
      value:
        currency?.symbol === "USD"
          ? "volume_in_usd"
          : currency?.symbol === "ETH"
          ? "volume_in_eth"
          : "volume_in_matic",
      action: () => {
        sortItem({ sort_by: SORT_BY.VOL });
      },
      sort_dir: sortDir,
      sort_item: SORT_BY.VOL,
    },
    {
      id: 4,
      heading: "Sales",
      value: "sales",
      action: () => {
        sortItem({ sort_by: SORT_BY.SALES });
      },
      sort_dir: sortDir,
      sort_item: SORT_BY.SALES,
    },
    {
      id: 5,
      heading: "Floor",
      value:
        currency?.symbol === "USD"
          ? "floor_in_usd"
          : currency?.symbol === "ETH"
          ? "floor_in_eth"
          : "floor_in_matic",
      action: () => {
        sortItem({ sort_by: SORT_BY.FLOOR });
      },
      sort_dir: sortDir,
      sort_item: SORT_BY.FLOOR,
    },
    {
      id: 6,
      heading: "Floor Change",
      value: "floor_change",
    },
    {
      id: 7,
      heading: "Supply",
      value: "supply",
      action: () => {
        sortItem({ sort_by: SORT_BY.SUPPLY });
      },
    },
    {
      id: 8,
      heading: "Avg Price",
      value:
        currency?.symbol === "USD"
          ? "avg_price_in_usd"
          : currency?.symbol === "ETH"
          ? "avg_price_in_eth"
          : "avg_price_in_matic",
    },
    // {
    //   id: 9,
    //   heading: "Volume Change",
    //   value: "volume_change",
    // },
    // {
    //   id: 10,
    //   heading: "Sales Change",
    //   value: "sales_change",
    // },
    // {
    //   id: 11,
    //   heading: "Wash Trades",
    //   value:
    //     currency?.symbol === "USD"
    //       ? "wash_trades_in_usd"
    //       : currency?.symbol === "ETH"
    //       ? "wash_trades_in_eth"
    //       : "wash_trades_in_matic",
    // },
  ] :
   [
    {
      id: 1,
      heading: "",
      value: "no",
    },

    {
      id: 2,
      heading: "Name",
      value: "name",
      link: "contractAddress",
      logo: "logo",
    },
    {
      id: 3,
      heading: "Volume",
      value:
        currency?.symbol === "USD"
          ? "volume_in_usd"
          : currency?.symbol === "ETH"
          ? "volume_in_eth"
          : "volume_in_matic",
      action: () => {
        sortItem({ sort_by: SORT_BY.VOL });
      },
      sort_dir: sortDir,
      sort_item: SORT_BY.VOL,
    },
    {
      id: 4,
      heading: "Sales",
      value: "sales",
      action: () => {
        sortItem({ sort_by: SORT_BY.SALES });
      },
      sort_dir: sortDir,
      sort_item: SORT_BY.SALES,
    },
    {
      id: 5,
      heading: "Floor",
      value:
        currency?.symbol === "USD"
          ? "floor_in_usd"
          : currency?.symbol === "ETH"
          ? "floor_in_eth"
          : "floor_in_matic",
      action: () => {
        sortItem({ sort_by: SORT_BY.FLOOR });
      },
      sort_dir: sortDir,
      sort_item: SORT_BY.FLOOR,
    },
    {
      id: 6,
      heading: "Supply",
      value: "supply",
      action: () => {
        sortItem({ sort_by: SORT_BY.SUPPLY });
      },
    },
    {
      id: 7,
      heading: "Avg Price",
      value:
        currency?.symbol === "USD"
          ? "avg_price_in_usd"
          : currency?.symbol === "ETH"
          ? "avg_price_in_eth"
          : "avg_price_in_matic",
    },
    // {
    //   id: 11,
    //   heading: "Wash Trades",
    //   value:
    //     currency?.symbol === "USD"
    //       ? "wash_trades_in_usd"
    //       : currency?.symbol === "ETH"
    //       ? "wash_trades_in_eth"
    //       : "wash_trades_in_matic",
    // },
  ];
  const [rerentur, setrerentur] = useState(0)

  useEffect(() => {
    if(rerentur <= 2){
      setrerentur(rerentur+1)

    }
    fetchData(currentTime);
  },[fetchData, setrerentur,rerentur]);

  return (
    <CollectionsTableCtr>
      <ContentCardCtrB hideHover={false} style={{border: "1px solid #2D2F31"}}>
        <div className="_tableHeader">
          <TEXT.MediumHeader>
            <span>Top Collections</span>
            <QuestionHelper text="Top NFT collections in Polygon ecosystem" />
          </TEXT.MediumHeader>
          <div className="_rightHeaderCtr">
            <div className="_searchResultsBar">
              <input
                type="search"
                name="search_item"
                id=""
                onChange={(e) => {
                  setQuery(e.target.value);
                  setPage(1);
                }}
                placeholder="Search by collection"
              />
              <Search color="grey" />
            </div>
            {/* <div className="_timelines">
             <input type="checkbox" id="reddit" name="reddit" value={reddit} onChange={(e) => {
                setReddit(e.target.checked);
              }}/>
              <label for="reddit">  Reddit Avatars</label>

              </div> */}
              
            <div className="_timelines">
            <DropDownCard
                data={[
                  {
                    val: 0,
                    label: "Supply Range - All",
                    action: () => {
                      setSupplyLimit(0);
                      setPage(1);
                    },
                  },
                  { val: 10000, label: "< 10K", action: () =>{ setSupplyLimit(1);
                  setPage(1);
                  } },
                  { val: 20000, label: "< 20k", action: () => {setSupplyLimit(2);
                  setPage(1);
                    } },
                  { val: 50000, label: "< 50k", action: () => {setSupplyLimit(3);
                  setPage(1);
                  } },
                  { val: 100000, label: "< 100k", action: () => {setSupplyLimit(4);
                  setPage(1);
                  } },
                ]}
                activeTab={supplyLimit}
              />
            </div>
            <div className="_timelines">
              {AvailableCategories?.length > 0 ? (
                <DropDownCard
                  data={AvailableCategories?.map((item, index) => {
                    return {
                      val: index,
                      label: item.name,
                      action: () => {
                        setCategory({
                          id: index,
                          name: item.name,
                        });
                        setPage(1);
                      },
                    };
                  })}
                  activeTab={category?.id}
                />
              ) : null}
            </div>
           
            <div className="_timelines">
              <DropDownCard
                
                data={[
                
                  {
                    val: 0,
                    label: "1 hour",
                    action: () => {
                      setCurrentTime(0);
                      setPage(1);
                    },
                  },
                  { val: 1, label: "6 hour", action: () => {setCurrentTime(1)
                  setPage(1);
                  }},
                  { val: 2, label: "12 hour", action: () => {setCurrentTime(2);
                  setPage(1);
                  } },
                  { val: 3, label: "1 day", action: () => {setCurrentTime(3);
                  setPage(1);
                  } },
                  { val: 4, label: "1 week", action: () => {setCurrentTime(4);
                  setPage(1);
                  } },
                  { val: 5, label: "1 month", action: () => {setCurrentTime(5);
                  setPage(1);
                  } },
                ]}
                activeTab={currentTime}
              />
            </div> 
          </div>
        </div>
        {tableData?.data?.length > 0 ? (
          <DataTable
            column={columns}
            data={tableData?.data}
            sortItemLabel={sortBy}
          />
        ) : (
          <SKLTCollectionsTable />
        )}
      </ContentCardCtrB>
      {tableData?.data?.length > 0 ? (
        <CollectionsTableNav
          results={tableData}
          page={page}
          setPage={setPage}
        />
      ) : null}
    </CollectionsTableCtr>
  );
};

const CollectionsTableCtr = styled.div`

  gap: 0;
  ._navs {
    ${(props) => props?.theme?.flexRowNoWrap}
    gap:.25rem;
    width: fit-content;
    margin: 1rem;
    margin-left: auto;
    button {
      background: none;
      outline: none;
      border: none;
      color: ${(props) => props?.theme?.body};
      font-family: var(--ff-subtitle);
      display: grid;
      place-items: center;
      padding: 0.5rem;
      font-size: var(--fs-s) !important;
    }

    ._staticNavs {
      width: 70px;
      border: 1px solid ${(props) => props?.theme?.btnSecondary};
      border-radius: 2rem;
    }

    ._tableNav {
      ${(props) => props?.theme?.flexRowNoWrap}
      gap:.25rem;
      align-items: center;
      justify-content: center;
      width: fit-content;
      border: 1px solid ${(props) => props?.theme?.btnSecondary};
      border-radius: 2rem;
      ._navPageBtn {
        min-width: 100px;
      }
      ._navBtn {
        &:nth-of-type(1) {
          border-right: 1px solid ${(props) => props?.theme?.btnSecondary};
        }
        &:nth-of-type(3) {
          border-left: 1px solid ${(props) => props?.theme?.btnSecondary};
        }
      }
    }

    //@media quries : mobile screens
    ${({ theme }) => theme.mediaWidth.upToLarge`
      margin:1rem auto;
      `}
  }

  ._tableHeader {
  
    padding: 1.25rem 1rem;
    ${(props) => props?.theme?.flexRowNoWrap}
    justify-content: space-between;
    align-items: center;
    ._rightHeaderCtr {
      display: flex;
      align-items: center;
      gap: 1rem;
    }
    ._tableTitle {
      
      font-family: var(--ff-title);
      font-size: var(--fs-l);
      display: flex;
      gap: 0.5rem;
    }
    ._searchResultsBar {
      ${(props) => props?.theme?.flexRowNoWrap}
      align-items: center;
      gap: 0.5rem;
      border: 1px solid ${(props) => props?.theme?.primary};
      width: 200px;
      padding: 0 0.5rem;
      border-radius: 0.25rem;

      input {
        background: none;
        color: ${(props) => props?.theme?.body};
        border: none;
        outline: none;
        padding: 0.5rem;
        font-family: var(--ff-subtitle);
      }
    }

    //@media quries : mobile screens
    ${({ theme }) => theme.mediaWidth.upToExtraSmall`
        flex-direction:column;
        gap:1rem;
        align-items:flex-start;
        padding:.75rem 1rem;
        ._rightHeaderCtr{
            flex-direction:column;
        width:fit-content;
        align-items:flex-start;
        margin-right:auto;
        }
      `}
  }
`;

export default CollectionsTable;
