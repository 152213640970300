import { SecondaryButton } from "components/Button";
import React from "react";
import styled from "styled-components";

const DropPageNav = ({ setItemIdx, currentItemIdx }) => {
  
  return (
    <PageNavCtr>
    <div className="left">

      <button className="button" onClick={() => setItemIdx(1)}>
        <span>Ongoing Mints</span>
        <p className={currentItemIdx === 1 ? "_activeTab _tab" : "_tab"}></p>
      </button>
      <button className="button" onClick={() => setItemIdx(2)}>
        <span>Upcoming Mints</span>
        <p className={currentItemIdx === 2 ? "_activeTab _tab" : "_tab"}></p>
      </button>
      </div>
      <a className="right"  href="https://docs.google.com/forms/d/e/1FAIpQLSf0vbhgn_0BFMTK1Vpr5sR-OHzhUbQztfIKyx95lOAJFshuxA/viewform"
      target="_blank">
      <SecondaryButton borderRadius={'0px'}>
      Submit your Project
      </SecondaryButton>
      
      </a>
    </PageNavCtr>
  );
};

const PageNavCtr = styled.div`
  border-bottom: 1px solid ${(props) => props?.theme?.disabled};
  margin-bottom: 50px;

  display: flex;
  justify-content: space-between;
  gap: 1rem;
  .left{
    display: flex;
  gap: 1rem;

  }
  ._tab {
    width: 100%;
    padding: 0.1rem;
    position: absolute;
    bottom:-15px;
    left: 0;
 
  }
  ._activeTab {
    background:#14EEEC;
  }
  .button {
    background: none;
    border: none;
    outline: none;
    display: block;
    padding: .75rem;
    padding-bottom: 1.5rem;
    color: ${(props) => props.theme.body};
    font-family: var(--ff-title);
    position: relative;
  }
  .right {
      text-decoration: none;
    }

  @media only screen and (max-width: 700px) {
  margin-bottom: 20px;

    .right {
      display: none;
    }
  }
`;
export default DropPageNav;
