import BrandLogo from "components/Brandlogo";
import CurrencyMenu from "components/Currency";
import { ShowLarge, ShowMedium } from "components/Hide";
import { ConnectKitButton } from "connectkit";
import React, { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import styled from "styled-components";
import { TEXT } from "theme/texts";
import { NavConfig } from "./index";

const AppNavModal = ({
  isOpen,
  onClose,
}: {
  isOpen: boolean;
  onClose: () => void;
}) => {
  //prevent page scroll
  useEffect((): any => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
      return () => (document.body.style.overflow = "scroll");
    }
  }, [isOpen]);

  const [hover, setHover] = useState(false);
  // Function to handle mouse over
  const handleMouseOver = () => {
    setHover(true);
};

// Function to handle mouse out
const handleMouseOut = () => {
  setHover(false);
};

  return (
    <AppNavModalCtr isOpen={isOpen} onClick={onClose}>
      <LinksCtr onClick={(e) => e.stopPropagation()}>
        {NavConfig.map((element) => {
          return (
            <MenuItemWrapper>
            {
              element.disable?
              <StyledNavLinkDisable
              onMouseOver={handleMouseOver} 
              onMouseOut={handleMouseOut} 
            >
              <TEXT.SmallHeader
             
              >
                 {
                  hover ?
                  "Coming Soon"

                  :
                  element.label

                }

              </TEXT.SmallHeader>
              <div className="_bottom" />
            </StyledNavLinkDisable>


              :
              <StyledNavLink to={element.url} onClick={onClose}>
                <TEXT.SmallHeader>{element.label}</TEXT.SmallHeader>
                <div className="_bottom" />
              </StyledNavLink>

            }
              
            </MenuItemWrapper>
          );
        })}
        <ShowLarge>
          <MenuItemWrapper
            style={{
              borderBottom: "0px",
              display: "flex",
              alignItems: "center",
              gap: "1rem",
              flexWrap: "wrap",
            }}
          >
            <CurrencyMenu /> <ConnectKitButton />
          </MenuItemWrapper>
        </ShowLarge>
      </LinksCtr>
    </AppNavModalCtr>
  );
};

const LinksCtr = styled.div`
  border-radius: 0 0 1rem 1rem;
  background: ${(props) => props.theme.black};
  
  /* background: red; */
  padding-bottom: 2rem;
  padding-top: 1rem;
  height: 100%;
  transition: height 5s;
`;
const StyledNavLinkDisable = styled.div`

  text-decoration: none;
  align-items: center;
  white-space: nowrap;
`;

const StyledNavLink = styled(NavLink)<{ active?: boolean }>`
  color: ${({ active, theme }) => (active ? theme.body : theme.disabledVar2)};
  text-decoration: none;
  width: fit-content;
  display: block;
  &.active {
    color: ${({ theme }) => theme.body};
    width: fit-content;
    ._bottom {
      width: auto;
      height: 4px;
      background: #14EEEC
    }
  }
  transition: 3s;
`;

const MenuItemWrapper = styled.div`
  width: 100%;
  height: fit-content;
  border-bottom: 1px solid ${({ theme }) => theme.border};
  padding: 2.6rem 0.8rem 2.6rem 1.4rem;


  transition: 3s;
`;

const AppNavModalCtr = styled.div<{ isOpen: boolean }>`
  position: absolute;
  top: 50px;
  left: 0;
  width: 100%;
  z-index: 1000;
  height: ${({ isOpen }) => (isOpen ? "100vh" : "0px")};
  overflow: hidden;
  
  /* From https://css.glass */
  background: rgba(80, 80, 80, 0.2);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);

  transition: all 0.5s;
`;
export default AppNavModal;
