import axios from "axios";
import ContentCardCtr from "components/ContentCard";
import DataTable from "../MarketDataTable";
import { DropDownCard } from "components/DropDown";
import QuestionHelper from "components/QuestionHelper";
import Row from "components/Row";
import React, { useCallback, useEffect, useState, useRef } from "react";
import { AlertCircle, ArrowLeft, ArrowRight, Search } from "react-feather";
import styled from "styled-components";
import { TEXT } from "theme/texts";
import { useCurrencyUnit } from "store/user/hooks";
import SKLTCollectionsTable from "components/Skeletons/CollectionsPage/SKLTCollectionsTable";
import MarketDataTable from "../MarketDataTable";
import {
  GenericImageWrapper,
  SnapshotBrandLogoImageWrapper,
} from "components/Logo";
import { isMobile } from "react-device-detect";

import useTakeScreenshot from "hooks/useTakeSnapshot";
import assets from "assets";
import MarketDataTable2 from "../MarketDataTablesmall";
import { utils, providers } from "ethers";
import { getCoingeckoConvertedPrices } from "utils/api";

const TopWallets = () => {
  //constants
  const SORT_DIR = { ASEC: "asc", DESC: "desc" };
  const SORT_BY = { VOL: "volume", SALES: "sales", WALLET: "wallet" };
  const { currency } = useCurrencyUnit();
  //states
  const [tableData, setTableData] = useState(null);
  const [chain, setChain] = useState("crosschain");
  const [sortBy, setSortBy] = useState(null);
  const [sortDir, setSortDir] = useState(null);
  const [query, setQuery] = useState(""); //page query endpoint
  const [currentTime, setCurrentTime] = useState({ active: 0, duration: "1d" });
  const [supplyLimit, setSupplyLimit] = useState(0);
  const [category, setCategory] = useState({
    id: 0,
    name: "All",
  });

  const refCard = useRef(null);
  const snapshotDownloadIconId = `snapshot-${3}-download-icon`;
  const snapshotBrandLogoId = `snapshot-${3}-brand-logo`;

  const downloadScreenshot = useTakeScreenshot({
    ref: refCard,
    elementIdsTohide: [snapshotDownloadIconId],
    elementIdsToShow: [snapshotBrandLogoId],
  });

  const FetchENSName = async (address) => {
    try {
      const provider = new providers.JsonRpcProvider(
        "https://rpc.ankr.com/eth"
        // "https://eth-mainnet.g.alchemy.com/v2/XIafqTx9bRWUIJcE1MKCeS6Oa6vYf27R"
      );
      const ensName = await provider.lookupAddress(address);
      return ensName;
    } catch (error) {
      console.log(error);
      return address?.slice(0, 6) + "..." + address?.slice(-4);
    }
  };

  //callbacks
  const fetchData = useCallback(async () => {
    let res = await axios.get(
      `${process.env.REACT_APP_API_URL}/topWalletCrosschain?chain=${chain}&duration=${currentTime.duration}&type=buy`
    );
    let resData = await res?.data?.data;

    let newData = resData?.map(async (item) => {
      let ensName = await FetchENSName(item?.account_address);

      return {
        ...item,
        account_address: ensName
          ? ensName
          : item?.account_address?.slice(0, 6) +
            "..." +
            item?.account_address?.slice(-6),
      };
    });
    const newDatas = await Promise.all(newData);

    setTableData(newDatas);
  }, [currentTime, category, chain, query, sortBy, sortDir, supplyLimit]);
  const sortItem = ({ sort_by }) => {
    setSortBy(sort_by);
    setSortDir((prev) =>
      prev === SORT_DIR.ASEC ? SORT_DIR.DESC : SORT_DIR.ASEC
    );
  };

  const columns = [
    {
      id: 1,
      heading: "",
      value: "no",
    },

    {
      id: 2,
      heading: "Wallet Address",
      value: "account_address",
      link: "contractAddress",
      logo: "logo_url",
      chain: "chain",
      chainUrl: "chainUrl",
    },
    // {
    //   id: 3,
    //   heading: "Holding Value",
    //   value:
    //     currency?.symbol === "USD"
    //       ? "holding_volume"
    //       : currency?.symbol === "ETH"
    //       ? "holding_volume"
    //       : "holding_volume",

    // },

    // {
    //   id: 4,
    //   heading: "Transfers",
    //   value: "sell_volume",

    // },

    {
      id: 5,
      heading: "Volume",
      value: "volume",
    },
    {
      id: 5,
      heading: "Trades",
      value: "trades_total",
    },

    // {
    //   id: 5,
    //   heading: "Realized gains",
    //   value: "realized_gains_volume",

    // },
    // {
    //   id: 7,
    //   heading: "Trades",
    //   value: "trade_count",
    //   action: () => {
    //     sortItem({ sort_by: SORT_BY.WALLET });
    //   },
    //   sort_dir: sortDir,
    //   sort_item: SORT_BY.WALLET,
    // },
  ];
  const [first, setfirst] = useState(1)

  useEffect(() => {
    if(first < 3) {
      setfirst(first+1);
    }
    fetchData();
  }, [fetchData, first,setfirst,setTableData]);

  return (
    <MarketCollectionsTableCtr ref={refCard}>
      <ContentCardCtr hideHover={false}>
        <div className="_tableHeader">
          <TEXT.MediumHeader>
            <span>Top Buyer</span>
            <QuestionHelper text="Top wallets across ecosystem" />
          </TEXT.MediumHeader>
          <div className="_rightHeaderCtr">
            <div className="_timelines">
              <DropDownCard
                data={[
                  {
                    val: 1,
                    label: "Crosschain",
                    action: () => {
                      setSupplyLimit(0);
                      setChain("crosschain");
                    },
                  },
                  {
                    val: 1,
                    label: "Ethereum",
                    action: () => {
                      setSupplyLimit(1);
                      setChain("rest");
                    },
                  },
                  {
                    val: 0,
                    label: "Polygon",
                    action: () => {
                      setSupplyLimit(2);
                      setChain("polygon");
                    },
                  },

                  {
                    val: 2,
                    label: "BNB Chain",
                    action: () => {
                      setSupplyLimit(3);
                      setChain("bnb");
                    },
                  },
                  {
                    val: 3,
                    label: "Arbitrum One",
                    action: () => {
                      setSupplyLimit(4);
                      setChain("arbitrum");
                    },
                  },
                  {
                    val: 4,
                    label: "Optimism",
                    action: () => {
                      setSupplyLimit(5);
                      setChain("optimism");
                    },
                  },
                  {
                    val: 5,
                    label: "Avalanche",
                    action: () => {
                      setSupplyLimit(6);
                      setChain("avax");
                    },
                  },
                  // {
                  //   val: 6,
                  //   label: "Cronos",
                  //   action: () => {
                  //     setSupplyLimit(7);
                  //     setChain("cronos");
                  //   },
                  // },
                  // {
                  //   val: 7,
                  //   label: "PlatON",
                  //   action: () => {
                  //     setSupplyLimit(8);
                  //     setChain("platon");
                  //   },
                  // },
                  // {
                  //   val: 8,
                  //   label: "Moonbeam",
                  //   action: () => {
                  //     setSupplyLimit(9);
                  //     setChain("moonbeam");
                  //   },
                  // },
                  {
                    val: 9,
                    label: "Fantom",
                    action: () => {
                      setSupplyLimit(7);
                      setChain("fantom");
                    },
                  },
                  // {
                  //   val: 100000,
                  //   label: "Gnosis",
                  //   action: () => {
                  //     setSupplyLimit(11);
                  //     setChain("gnosis");
                  //   },
                  // },
                ]}
                activeTab={supplyLimit}
              />
            </div>

            <GenericImageWrapper
              id={snapshotDownloadIconId}
              className="_cardDownloadIcon"
              onClick={() => downloadScreenshot()}
              src={assets.icons.roundedDownloadIcon}
              size="2rem"
            />
            <SnapshotBrandLogoImageWrapper
              id={snapshotBrandLogoId}
              src={assets.logos.surfaceboardXYZLogo}
              size="6rem"
              style={{ marginRight: isMobile ? "8px" : "10px" }}
            />
          </div>
        </div>
        {tableData?.length > 0 ? (
          <MarketDataTable2
            column={columns}
            data={tableData}
            sortItemLabel={sortBy}
            chain={chain}
          />
        ) : (
          <SKLTCollectionsTable />
        )}
      </ContentCardCtr>
    </MarketCollectionsTableCtr>
  );
};

const MarketCollectionsTableCtr = styled.div`
  gap: 0;
  ._navs {
    ${(props) => props?.theme?.flexRowNoWrap}
    gap:.25rem;
    width: fit-content;
    margin: 1rem;
    margin-left: auto;
    button {
      background: none;
      outline: none;
      border: none;
      color: ${(props) => props?.theme?.body};
      font-family: var(--ff-subtitle);
      display: grid;
      place-items: center;
      padding: 0.5rem;
      font-size: var(--fs-s) !important;
    }

    ._staticNavs {
      width: 70px;
      border: 1px solid ${(props) => props?.theme?.disabledVar3};
      border-radius: 2rem;
    }

    ._tableNav {
      ${(props) => props?.theme?.flexRowNoWrap}
      gap:.25rem;
      align-items: center;
      justify-content: center;
      width: fit-content;
      border: 1px solid ${(props) => props?.theme?.disabledVar3};
      border-radius: 2rem;
      ._navPageBtn {
        min-width: 100px;
      }
      ._navBtn {
        &:nth-of-type(1) {
          border-right: 1px solid ${(props) => props?.theme?.disabled};
        }
        &:nth-of-type(3) {
          border-left: 1px solid ${(props) => props?.theme?.disabled};
        }
      }
    }

    //@media quries : mobile screens
    ${({ theme }) => theme.mediaWidth.upToLarge`
      margin:1rem auto;
      `}
  }

  ._tableHeader {
    padding: 1.25rem 1rem;
    ${(props) => props?.theme?.flexRowNoWrap}
    justify-content: space-between;
    align-items: center;
    ._rightHeaderCtr {
      display: flex;
      align-items: center;
      gap: 1rem;
    }
    ._tableTitle {
      font-family: var(--ff-title);
      font-size: var(--fs-l);
      display: flex;
      gap: 0.5rem;
    }
    ._searchResultsBar {
      ${(props) => props?.theme?.flexRowNoWrap}
      align-items: center;
      gap: 0.5rem;
      border: 1px solid ${(props) => props?.theme?.disabled};
      width: 200px;
      padding: 0 0.5rem;
      border-radius: 0.25rem;

      input {
        background: none;
        color: ${(props) => props?.theme?.body};
        border: none;
        outline: none;
        padding: 0.5rem;
        font-family: var(--ff-subtitle);
      }
    }

    //@media quries : mobile screens
    ${({ theme }) => theme.mediaWidth.upToExtraSmall`
        flex-direction:column;
        gap:1rem;
        align-items:flex-start;
        padding:.75rem 1rem;
        ._rightHeaderCtr{
            // flex-direction:column;
        width:fit-content;
        align-items:flex-start;
        margin-right:auto;
        }
      `}
  }
`;

export default TopWallets;
