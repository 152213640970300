import assets from "assets";
import React from "react";
import styled from "styled-components";

const CarouselCardHome2 = () => {
  return (
    <CarouselCardWrapper2>
      <div className="leftSide">
        <div className="imgBoxLeft pc">
          <img src={assets.images.yoots3} alt="" />
        </div>
      </div>
      <div className="center">
        <div className="smallText">Welcoming y00ts on Polygon! </div>
        <div className="texts">Bridge to grab your Genesis Pass</div>
        <div className="mediumText">Gateway to Surfaceboard </div>
        <div className="btnBox">
          <a href="" target={"_blank"}>
            <div className="btn">View More</div>
          </a>
        </div>
      </div>
      <div className="rightSided pc">
        <div className="imgBoxright">
          <img className="pc" src={assets.images.yootsCard} alt="" />
          <img className="mobile" src={assets.images.yootsmobile} alt="" />
        </div>
      </div>
      <div className="rightSide mobile">
        <div className="imgBoxright">
          <img className="pc" src={assets.images.yootsCard} alt="" />
          <img className="mobile" src={assets.images.yootsmobile} alt="" />
        </div>
      </div>
    </CarouselCardWrapper2>
  );
};
const CarouselCardWrapper2 = styled.div`
overflow: hidden;

  .pc {
    display: block;
  }
  .mobile {
    display: none;
  }
  display: flex;
  width: 100%;
  height: 225px;

  /* background: #0e0e0e; */
  background: linear-gradient(180deg, #020202 0%, #1c1d2b 100%);
  /* stroke */

  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
  /* border-radius: 16px; */
  background: linear-gradient(180deg, #f1ede5 0%, #e3d7bf 100%);

  display: flex;
  justify-content: space-between;
  align-items: center;
  /* border: 1px solid #2d2f31; */

  .leftSide {
    .imgBoxLeft {
      img {
      }
    }
  }
  .center {
    color: #000000;
    text-align: center;
    .smallText {
      font-weight: 400;
      font-size: 15px;
      line-height: 140%;
      margin-bottom: 12px;
    }
    .texts {
      font-weight: 700;
      font-size: 28px;
      line-height: 120%;
      /* or 38px */

      text-align: center;

      background: linear-gradient(
          174.92deg,
          #000000 1.97%,
          rgba(82, 87, 163, 0.83) 51.93%
        ),
        #070707;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }
    .mediumText {
      font-weight: 300;
      font-size: 26px;
      line-height: 120%;
    }
    .btnBox {
      margin-top: 20px;
      a {
        text-decoration: none;
      }
      .btn {
        margin: 0 auto;
        color: #fff;
        width: max-content;
        background: #1b1b21;
        border: 0.5px solid #363636;
        border-radius: 8px;
        padding: 12px 16px;
      }
    }
    .rightSided {
      .imgBoxright {
        img {
        }
      }
    }
  }

  @media only screen and (max-width: 700px) {
    .pc {
      display: none;
    }
    .mobile {
      display: block;
    }
    position: relative;
    padding: 20px;

    .center {
      position: relative;
      z-index: 10;
      color: #000000;
      width: 70%;
      text-align: left;
      .smallText {
        font-weight: 400;
        font-size: 10px;
        line-height: 140%;
        margin-bottom: 12px;
      }
      .texts {
        font-weight: 700;
        font-size: 22px;
        line-height: 120%;
        text-align: left;

        /* or 29px */

        background: linear-gradient(
            177.85deg,
            #000000 3.74%,
            rgba(82, 87, 163, 0.83) 94.86%
          ),
          #070707;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
      }
      .mediumText {
        font-weight: 300;
        font-size: 14px;
        line-height: 120%;
      }
      .btnBox {
        margin-top: 20px;
        a {
          text-decoration: none;
        }
        .btn {
          margin: 0 0;
          color: #fff;
          font-size: 12px;
          width: max-content;
          background: #1b1b21;
          border: 0.5px solid #363636;
          border-radius: 8px;
          padding: 12px 16px;
        }
      }
    }

    display: flex;
    justify-content: start;
    align-items: left;
    height: 270px;

    .carouselContent {
      .text {
        font-weight: 600;
        font-size: 32px;
        line-height: 130%;
      }
    }
  }
  .rightSide {
    position: absolute;
    right: 0;

    .imgBoxright {
      img {
        width: 180px;
      }
    }
  }
`;
export default CarouselCardHome2;
