import assets from "assets";
import axios from "axios";
import React, { useState, useRef } from "react";
import { toast } from "react-hot-toast";
import { useToGetUserInfo } from "store/user/hooks";
import styled from "styled-components";
import { useModal } from "connectkit";

const CarouselCardHome6 = () => {
  const { wallet } = useToGetUserInfo();
  const { setOpen } = useModal();

  const formRef = useRef(null);

  const [email, setEmail] = useState(null);

  const onFormSubmit = (e) => {
    e.preventDefault();
    PostData(email, wallet);
  };

  const PostData = async (email, wallet) => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/earlyAccess`,
        {
          email: email,
          wallet: wallet,
        }
      );
      console.log(res);
      formRef?.current?.reset();
      toast.success("Email submitted successfully!");
    } catch (error) {
      console.log(error);
      toast.error("Email submission failed!");
    }
  };
  return (
    <CarouselCardWrapper6>
      <div className="center">
        <div className="texts pc">
          Subscribe to <span>NFTonPolygon</span> for <br></br> weekly NFT Wrap
          in your mailbox.{" "}
        </div>
        <div className="texts mobile">
          Subscribe to <span>NFTonPolygon</span> <br /> for weekly NFT Wrap
          <br />
          in your mailbox.{" "}
        </div>
      </div>
      <div className="rightSided ">
        <div className="imgBoxright">
          <div className="formbox">
            <form onSubmit={onFormSubmit} ref={formRef}>
              <div className="formdata">
                <input
                  type="email"
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  placeholder="Add email address here"
                />
                {
                  wallet?
                <button>Submit</button>


                  :
                <div className="button" onClick={()=> setOpen(true)}>Connect</div>

                  

                }
              </div>
            </form>
          </div>
          <a
            href="https://twitter.com/LayerEhq"
            target="_blank"
            className="smallText pc"
          >
            Drop a Follow on
            <svg
              width="25"
              height="24"
              viewBox="0 0 25 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M21.1748 6.40106C20.5366 6.68372 19.8503 6.87232 19.1262 6.96156C19.8623 6.52455 20.4286 5.82754 20.6944 5.00417C20.0056 5.4079 19.2425 5.70454 18.4307 5.86083C17.7824 5.17347 16.8577 4.74707 15.8332 4.74707C13.8652 4.74707 12.2701 6.32823 12.2701 8.27791C12.2701 8.55381 12.3024 8.82248 12.3632 9.08344C9.40246 8.93487 6.77652 7.52736 5.01979 5.3915C4.71108 5.9134 4.53743 6.52455 4.53743 7.16945C4.53743 8.39512 5.16497 9.47414 6.12197 10.1099C5.53784 10.092 4.98795 9.92949 4.50656 9.66902C4.50656 9.67963 4.50656 9.69555 4.50656 9.7105C4.50656 11.4233 5.73512 12.8497 7.36355 13.1743C7.06593 13.2558 6.75144 13.3011 6.4273 13.3011C6.19721 13.3011 5.97292 13.2751 5.75586 13.236C6.20927 14.6358 7.52465 15.6589 9.08314 15.6893C7.86375 16.6347 6.32793 17.201 4.65802 17.201C4.36957 17.201 4.08739 17.1846 3.80859 17.1508C5.38638 18.1498 7.25984 18.7354 9.27078 18.7354C15.8236 18.7354 19.4084 13.3542 19.4084 8.68598C19.4084 8.53307 19.4036 8.38065 19.3964 8.23015C20.0958 7.73622 20.6987 7.11302 21.1748 6.40106Z"
                fill="#03A9F4"
              />
            </svg>
            LayerEhq
          </a>
        </div>
      </div>
      <div className="rightSide mobile">
        <div className="imgBoxright"></div>
      </div>
    </CarouselCardWrapper6>
  );
};
const CarouselCardWrapper6 = styled.div`
  .formbox {
    .formdata {
      display: flex;
      gap: 15px;

      input {
        padding: 7px;
        width: 276.6px;
        height: 35.4px;

        background: #ffffff;
        border: 0.737594px solid rgba(214, 214, 214, 0.6);
        border-radius: 7.37594px;
        outline: none;

        :focus {
          outline: none;
        }
      }
      .button {
        cursor: pointer;
        background: linear-gradient(
          62.79deg,
          #a726c1 5.57%,
          #803bdf 80.18%,
          #7b3fe4 90.36%
        );
        box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 7.37594px;
        border: none;
        padding: 6px 24px;
        font-weight: 600;
        font-size: 16px;
        line-height: 130%;
        color: #fff;
      }
    }
  }
  position: relative;
  .fl {
    margin-top: 20px;
    text-decoration: none;
    box-sizing: border-box;
    padding: 2px;
    border-radius: 100%;
    background: linear-gradient(
      209.57deg,
      rgba(144, 150, 230, 0.69) 18.11%,
      rgba(16, 22, 101, 0) 47.68%,
      rgba(126, 132, 211, 0.7) 81.9%
    );
  }
  .cc {
    position: absolute;
    right: 0px;
    /* bottom: 0px; */
  }
  .pc {
    display: block;
  }
  .mobile {
    display: none;
  }
  display: flex;
  width: 100%;
  height: 225px;

  /* background: #0e0e0e; */
  /* background: linear-gradient(180deg, #020202 0%, #1c1d2b 100%); */
  /* stroke */
  background-image: url(${assets.images.bgsing});
  background-repeat: no-repeat;
  background-size: 100% 100%;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
  /* border-radius: 16px; */
  /* background: linear-gradient(180deg, #f1ede5 0%, #e3d7bf 100%); */

  display: flex;
  justify-content: space-between;
  align-items: center;
  /* border: 1px solid #2d2f31; */

  .leftSide {
    .imgBoxLeft {
      img {
        z-index: 3;
      }
    }
  }
  .imgBoxright {
    /* max-width: 350px; */
    padding-right: 30px;
  }
  .smallText {
    text-decoration: none;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: start;
    margin-top: 10px;
    font-weight: 200;
    font-size: 15.5222px;
    line-height: 120%;
  }
  .lf {
    text-decoration: none;
  }
  .btnbox {
    margin-top: 20px;
    display: flex;
    background: linear-gradient(
        119.07deg,
        rgba(95, 55, 179, 0.2) 12.52%,
        rgba(104, 65, 186, 0) 67.86%
      ),
      #111032;
    border-radius: 6.74346px;
    padding: 8px 19px;
    justify-content: center;
    align-items: center;
    border: 1px solid;

    border-image-source: linear-gradient(
      105.64deg,
      #bbbdd8 10.14%,
      rgba(20, 26, 119, 0) 50.09%,
      #bec0e0 89.21%
    );

    .btnicon {
      margin-right: 15px;
    }
    .btntext {
      font-weight: 600;
      font-size: 21.0733px;
      line-height: 18px;
      background: linear-gradient(
          196.86deg,
          #b4bcfc 44.54%,
          rgba(45, 54, 196, 0.83) 107.68%
        ),
        #070707;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }
  }
  .center {
    color: #000000;
    /* text-align: center; */
    padding-left: 30px;

    .texts {
      font-weight: 400;
      font-size: 37.0896px;
      line-height: 46px;
      color: #fff !important;
      span {
        font-weight: 600;

        background: linear-gradient(
            174.92deg,
            rgba(158, 119, 237, 1) 95.97%,
            rgba(133, 140, 255, 0.95) 51.93%
          ),
          #070707;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
      }
      /* or 38px */

      /* text-align: center; */
    }
    .mediumText {
      font-weight: 300;
      font-size: 26px;
      line-height: 120%;
    }
    .btnBox {
      margin-top: 20px;
      a {
        text-decoration: none;
      }
      .btn {
        /* margin: 0 auto; */
        color: #fff;
        width: max-content;
        background: #1b1b21;
        border: 0.5px solid #363636;
        border-radius: 8px;
        padding: 12px 16px;
      }
    }
    .rightSided {
      /* position: relative; */
      .imgBoxright {
        img {
        }
      }
    }
  }

  @media only screen and (max-width: 700px) {
    .rightSided {
      width: 100%;
    }
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: column;

    background-image: url(${assets.images.bgsingm});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 70%;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);

    .btnbox {
      margin-top: 20px;
      display: flex;
      background: linear-gradient(
          119.07deg,
          rgba(95, 55, 179, 0.2) 12.52%,
          rgba(104, 65, 186, 0) 67.86%
        ),
        #111032;
      border-radius: 6.74346px;
      padding: 8px 13px;
      justify-content: center;
      align-items: center;
      border: 1px solid;

      border-image-source: linear-gradient(
        105.64deg,
        #bbbdd8 10.14%,
        rgba(20, 26, 119, 0) 50.09%,
        #bec0e0 89.21%
      );

      .btnicon {
        margin-right: 8px;
      }
      .btntext {
        font-weight: 500;
        font-size: 14px;
        line-height: 30px;
      }
    }
    .pc {
      display: none;
    }
    .mobile {
      display: block;
    }
    position: relative;
    padding: 20px;
    .imgBoxright {
      padding-right: 0px;

      max-width: 100%;
      padding-right: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    .center {
      position: relative;
      z-index: 10;
      color: #000000;
      width: 100%;
      text-align: left;
      padding-left: 0px;

      .smallText {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        width: 100%;
      }
      .texts {
        font-weight: 400;
        font-size: 22.9448px;
        line-height: 34px;
        text-align: center;

        span {
          font-weight: 600;
          font-size: 32.9448px;
        }

        /* or 29px */
      }
      .mediumText {
        font-weight: 300;
        font-size: 14px;
        line-height: 120%;
      }
      .btnBox {
        margin-top: 20px;
        a {
          text-decoration: none;
        }
        .btn {
          margin: 0 0;
          color: #fff;
          font-size: 12px;
          width: max-content;
          background: #1b1b21;
          border: 0.5px solid #363636;
          border-radius: 8px;
          padding: 12px 16px;
        }
      }
    }

    display: flex;
    /* justify-content: start; */
    align-items: left;
    height: 270px;

    .carouselContent {
      .text {
        font-weight: 600;
        font-size: 32px;
        line-height: 130%;
      }
    }
    .rightSide {
      position: absolute;
      right: 0;
      bottom: 0;
    }

    .formbox {
      .formdata {
        display: flex;
        gap: 10px;
        width: 100%;

        input {
          padding: 7px;
          width: 100%;
          height: 24.4px;

          background: #ffffff;
          border: 0.737594px solid rgba(214, 214, 214, 0.6);
          border-radius: 7.37594px;
          outline: none;

          :focus {
            outline: none;
          }
        }
        .button {
          border-radius: 5.37594px;
          border: none;
          padding: 4px 17px;
          font-weight: 600;
          font-size: 11px;
          line-height: 130%;
          color: #fff;
        }
      }
    }
  }
`;
export default CarouselCardHome6;
