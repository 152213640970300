import { PrimaryButton, SecondaryButton, SquareIconButton } from "components/Button";
import Column, { AutoColumn } from "components/Column";
import Row, { AutoRow, ResponsiveRowWrap } from "components/Row";
import React, { FormEvent, useRef, useState } from "react";
import styled from "styled-components";
import { ExternalLink } from "theme/components";
import { StyledInternalLink } from "theme/components";
import { TEXT } from "theme/texts";
import { CircledImageCtr, GenericImageWrapper } from "components/Logo";
import useTheme from "hooks/useTheme";
import assets from "assets";
import useMatchBreakpoints from "hooks/useMatchBreakpoints";
import BrandLogo from "components/Brandlogo";
import { HorizontalDivider } from "components/Divider/inde";
import TextField from "components/Input/TextField";
import { appRoutes } from "utils/path";
import { Copy } from "react-feather";
import { toast } from "react-hot-toast";
import axios from "axios";
import { copyTextToClipboard } from "utils/clipboardCopy";

const FooterWrapper = styled.div`
  width: 100%;
  background: ${({ theme }) => theme.bgFooter};
  padding: 4rem 0% 2rem 0%;
  position: relative;
`;
const ResponsiveContainer = styled(Row)`
  width: 100%;
  align-items: start;
  padding: 0% 10% 1% 10%;
  gap: 10%;
  position: relative;
  ${({ theme }) => theme.mediaWidth.upToMedium`
flex-direction:column;
padding: 1% 6% 10% 6%;
`}
`;

const DetailSection = styled.div`
  width: 54%;
  align-items: start;
  ${({ theme }) => theme.mediaWidth.upToMedium`
  padding-bottom: 20px;
  width: 100%;
`}
`;

// const RightSection = styled(ResponsiveRowWrap)`
//    {
//     grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
//   }
// `;

const RightSection = styled.div`
  width: 100%;
  display: flex;
  justify-content: right;
  gap: 5rem;
  flex-wrap: wrap;
  gap: 3rem;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    display: grid;
 grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
    margin-top: 1rem;

`}
`;

const StyledExternalLink = styled(ExternalLink)`
  font-size: clamp(0.9rem, 2vw, 1rem);
  display: flex;
  align-items: center;
  gap: 0.35rem;
  color: #E4E7EC;
`;

const AutoColumnNew = styled.div<{
  gap?: 'sm' | 'md' | 'lg' | string
  justify?: 'stretch' | 'center' | 'start' | 'end' | 'flex-start' | 'flex-end' | 'space-between'
}>`
  display: flex;
  flex-direction: column;
  gap: 15px;
  /* grid-auto-rows: auto;
  grid-row-gap: ${({ gap }) => (gap === 'sm' && '8px') || (gap === 'md' && '12px') || (gap === 'lg' && '24px') || gap};
  justify-items: ${({ justify }) => justify && justify}; */
`

function Footer() {
  const theme = useTheme();
  const { isMobile } = useMatchBreakpoints();
  const [email, setEmail] = useState<string>("");
  const formRef = useRef<HTMLFormElement>(null);

  const subscribeToNewsletter = async (e: FormEvent) => {
    e.preventDefault();
    let bodyFormData = new FormData();
    bodyFormData.append("Email", email?.toLowerCase());
    try {
      const res = await axios({
        method: "post",
        url: "https://script.google.com/macros/s/AKfycbwsHkZdRtJKMGuRgZGr136s4XFTheeGzvADlgqF39KjGrU7uFJ3FloQVuK_IhFazRWB/exec",
        data: bodyFormData,
        headers: { "Content-Type": "multipart/form-data" },
      });
      if (res.status === 200) {
        toast.success("Subscribed Successfully!");
      } else {
        toast.error("Error subscribing to newsletter!");
      }
    } catch {
      toast.error("Error subscribing to newsletter!");
    } finally {
      formRef?.current?.reset();
    }
  };
  const openLink = (link: string) => {
    window.open(link, "_blank");
  };
  return (
    <FooterWrapper>
      <ResponsiveContainer gap={isMobile ? "68px" : "22px"}>
        <DetailSection>
          <AutoColumn gap={"1.5rem"}>
            <BrandLogo hideNavOnLoc={undefined} footer ={true} />
            <TEXT.Body>
            MNFST Tool is your one-stop analytical tool for all things Polygon NFTs.
            </TEXT.Body>
            <Row gap="16px" marginBottom={isMobile ? "18px" : "10px"}>
              <SquareIconButton
                backgroundColor={"transparent"}
                onClick={() => openLink("https://twitter.com/MNFSTLabs")}
              >
                <GenericImageWrapper src={assets.logos.twitterLogo} />
              </SquareIconButton>
              <SquareIconButton
                backgroundColor={"transparent"}
                onClick={() => openLink("https://discord.gg/mnfstlabs")}
              >
                <GenericImageWrapper src={assets.logos.discordLogo} />
              </SquareIconButton>
              {/* <SquareIconButton
                backgroundColor={"transparent"}
                onClick={() => openLink("https://instagram.com/LayerEhq")}
              >
                <GenericImageWrapper src={assets.logos.instagramLogo} />
              </SquareIconButton>
              <SquareIconButton
                backgroundColor={"transparent"}
                onClick={() =>
                  openLink("https://www.linkedin.com/company/layerehq/")
                }
              >
                <GenericImageWrapper src={assets.logos.linkedinLogo} />
              </SquareIconButton> */}
            </Row>
            <AutoColumn gap="1rem">
              <TEXT.Body>
              Subscribe to MNFST Newsletter for latest updates
              </TEXT.Body>
              <form onSubmit={subscribeToNewsletter} ref={formRef}>
                <Row gap="8px">
                  <TextField
                    type="email"
                    style={{
                      fontSize: "1rem",
                      border: `.85px solid ${theme.subBody}`,
                      borderRadius: ".35rem",
                backgroundColor:"#0A0D13"

                      
                    }}
                    onChange={(e: any) => setEmail(e?.target?.value)}
                    placeholder="Enter Your Email"
                    required
                  />
                  <PrimaryButton
                    width="fit-content"
                    style={{
                      padding: ".5rem 1.15rem",
                      height: "100%",
                      minWidth: "80px",
                      fontSize: "1rem",
                      fontWeight: "bold",
                      // border: `.85px solid ${theme.body}`,
                    }}
                    borderRadius="0 !important"
                  >
                    Subscribe
                  </PrimaryButton>
                </Row>
              </form>
            </AutoColumn>
          </AutoColumn>
        </DetailSection>
        <RightSection style={{ position: "relative", border: "" }}>
          {linkListSamePage.map((data) => {
            return (
              <AutoColumnNew >
                <TEXT.SmallHeader fontSize={"clamp(0.9rem, 2vw, 1rem)"} style={{color: "#C5F9FF"}}>
                  {data.title}
                </TEXT.SmallHeader>
                {data.elements.map((element) => (
                  <StyledExternalLink
                    href={element.url}
                    target={ "_self"}
                  >
                    {element.label}
                  </StyledExternalLink>
                ))}
              </AutoColumnNew>
            );
          })}

          {linkList.map((data) => {
            return (
              <AutoColumnNew gap=".75rem">
                <TEXT.SmallHeader fontSize={"clamp(0.9rem, 2vw, 1rem)"}  style={{color: "#C5F9FF"}}>
                  {data.title}
                </TEXT.SmallHeader>

                {data.elements.map((element) => (
                  // <a target={'_self'}
                  <StyledExternalLink
                    href={element.email ? `mailto:${element.url}` : element.url}
                    target={
                      element.url?.includes("https") ? "_blank" : element.url
                    }
                  >
                    {element.label}
                  </StyledExternalLink>
                ))}
              </AutoColumnNew>
            );
          })}
        </RightSection>
      </ResponsiveContainer>
      <HorizontalDivider />
      <Column
        style={{
          gap: ".5rem",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <TEXT.SubBody textAlign="center" paddingTop={3}>
          © 2023 MNFST. All Rights reserved.
        </TEXT.SubBody>
      </Column>
    </FooterWrapper>
  );
}

const ZettablockLogo = styled.div`
  width: 150px;
  opacity: 0.6;
  cursor: pointer;
  img {
    max-width: 100%;
  }
`;
export default Footer;
const linkListSamePage = [
  {
    title: "Analytics & Resources",
    elements: [
      {
        label: "Market Overview",
        url: `#${appRoutes.marketOverview}`,
      },
      {
        label: "Collection Rankings",
        url: `#${appRoutes.collections}`,
      },
      {
        label: "Upcoming Drops",
        url: `#${appRoutes.drops}`,
      },
      {
        label: "Crosschain Rankings",
        url: `#${appRoutes.leaderBoard}`,
      },
    ],
  },
];

const linkList = [
  {
    title: "Contribute",
    elements: [
      {
        label: "Partner with us",
        url: "https://forms.gle/pJ1uTkpqtrcjSHUU8",
      },
      {
        label: "Submit upcoming project",
        url: "https://forms.gle/BtxDsKTdfRUDtUM69",
      },
      {
        label: "Submit ideas",
        url: "https://forms.gle/563yUohK7iqvdBAa6",
      },
      {
        label: "Give Feedback",
        url: "https://forms.gle/aHfsJhFtGqMemeSk6",
      },
    ],
  },
  {
    title: "Company",
    elements: [
      {
        label: "MNFST Labs",
        url: "https://mnfst.io/",
      },
      {
        label: "Blog",
        url: "https://medium.com/@mnfsttools",
      },
      {
        label: "Contact Us",
        url: "",
        email: "hello@mnfst.io",
      },
      // {
      //   label: "Terms of Service",
      //   url: "https://drive.google.com/file/d/1gl2f-jPpqnw3wipYBOn0HG0qEBVc03uT/view?usp=sharing",
      // },
      {
        label: "Privacy Policy",
        url: "https://drive.google.com/file/d/19nnBG93k22hfPbxfXOQjHA2ufRLJZa3-/view?usp=sharing",
      },
    ],
  },
];
