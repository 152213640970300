import { linearGradient } from "polished";
import { Colors } from "./styled";

const white = "#FFFFFF";
const black = "#000000";

export function colors(darkMode?: boolean): Colors {
  return {
    // base
    white,
    black,

    // text
    header: darkMode ? white : black,
    subHeader: darkMode ? "#E9E9E9" : black,
    body: darkMode ? white : black,
    subBody: darkMode ? "#919191" : black,
    label: darkMode ? "rgba(255, 255, 255, 0.7)" : black,

    // backgrounds
    bgBase: darkMode ? black : white,
    bgPrimeBase:"rgb(10,30,30)" ,

    bgFooter: "rgb(10,30,30)",
    bgMiddle: darkMode ? "rgba(14, 14, 14, 0.7)" : white,
    bgTop: darkMode ? "#000000" : white,
    bgModal: darkMode
      ? "linear-gradient(0deg, rgba(66, 66, 66, 0.2), rgba(66, 66, 66, 0.2)), black"
      : white,
    bgTransparent01: "rgba(255, 255, 255, 0.1)",
    bgTransparent025: "rgba(0, 0, 0, 0.25)",

    //primary colors
    primary: darkMode ? "#AAC0DC" : "rgba(20, 238, 236, 1)",
    primaryLight: darkMode ? "#6FA2D6" : "rgba(20, 238, 236, 1)",
    primaryDark: darkMode ? "#513d79" : "rgba(20, 238, 236, 1)",

    // Secondary colors
    secondary: darkMode ? "#616161" : black,

    // different colors
    grey: "grey",
    blue: "blue",

    // Graphs
    graphAxisElements: "rgba(255, 255, 255, 0.7)",
    graphLines: "#AAC0DC",
    graphArea: "rgba(170, 192, 220, 0.5)", //#aac0dc80

    //  Buttons
    btnPrimary: darkMode ? "#8247e5" : "rgba(20, 238, 236, 1)",
    btnSecondary: darkMode ? "rgba(20, 238, 236, 1)" : black,
    btnSecondary2: darkMode ? "#054649" : black,


    // Status
    disabled: "#2D2F31",
    disabledVar2: "rgba(14, 14, 14, 0.7);",
    disabledVar3: "#474747",
    pending: "",
    success: "#12B76A",
    warning: "#FF6F65",

    //Links
    aTag: "blue",

    //shadows
    shadow: darkMode ? "#000" : "#2F80ED",

    // Borders
    border: "#2D2F31",
    border2: "#47484D",
    borderSuccess: "#007C7C",
    borderToggleBtn: "#5F6061",
    borderActiveToggleBtn: "#474747",

    // Gradients
    radialGradient:
      "radial-gradient(102.54% 102.54% at 41.56% 100%, rgba(82, 66, 123, 0.4) 0%, rgba(0, 0, 0, 0) 100%), rgba(66, 66, 66, 0.2)",
    activeRadialGradient:
      "linear-gradient(180deg, #49808044 0%, rgba(20, 23, 27, 0) 80%);",
    linearGradient:
      "linear-gradient(0deg, rgba(66, 66, 66, 0.2), rgba(66, 66, 66, 0.2)), radial-gradient(102.54% 102.54% at 50.05% 100%, rgba(82, 66, 123, 0.4) 0%, rgba(0, 0, 0, 0) 100%)",
    activeLinearGradient:
      "linear-gradient(360deg, rgba(95, 61, 153, 0.5) -47.88%, rgba(51, 52, 74, 0) 100%), linear-gradient(0deg, rgba(66, 66, 66, 0.2), rgba(66, 66, 66, 0.2)), radial-gradient(102.54% 102.54% at 50.05% 100%, rgba(82, 66, 123, 0.4) 0%, rgba(0, 0, 0, 0) 100%)",
    linearGradient2: "linear-gradient(180deg, #FFFFFF 0%, #A094CA 81.4%)",
   
  };
}
