import React, { Component, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Header from "components/Header";
import Footer from "components/Footer";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import { fontFamily } from "html2canvas/dist/types/css/property-descriptors/font-family";

export function GoToTop() {
  const routePath = useLocation();
  const onTop = () => {
    window.scrollTo(0, 0);
  };
  useEffect(() => {
    onTop();
  }, [routePath]);

  return null;
}
const LayoutFrame = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  flex-direction: column;
  width: 100%;
  top: 0;
  position: relative;

  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  background: #0B1518 ;

  ${({ theme }) => theme.mediaWidth.upToExtraSmall`
   `}
`;

function Layout({ children }: { children: React.ReactNode }) {
  const [hideHeaderFooter, setHideHeaderFooter] = useState(false);
  const { pathname } = useLocation();
  useEffect(() => {
    if (pathname === "/yoots/login") {
      setHideHeaderFooter(true);
    } else {
      setHideHeaderFooter(false);
    }
  }, [pathname]);
  return (
    <LayoutFrame>
      <Toaster position="top-right" reverseOrder={false} />
      {!hideHeaderFooter ? <Header /> : null}
      {children}
      {!hideHeaderFooter ? <Footer /> : null}
      <GoToTop />
    </LayoutFrame>
  );
}

export default Layout;
