import assets from "assets";
import Column from "components/Column";
import ContentCardCtr from "components/ContentCard";
import { CircledImageCtr, GenericImageWrapper } from "components/Logo";
import QuestionHelper from "components/QuestionHelper";
import Row, { AutoRow } from "components/Row";
import React, { useState } from "react";
import styled from "styled-components";
import { colors } from "theme/colors";
import { TEXT } from "theme/texts";
import { formatAmount } from "utils/numbers";
import { useNavigate } from "react-router-dom";

const Entity = ({ data }) => {
    const navigate = useNavigate();
  return (
    <Row
      style={{
        cursor: "pointer",
        gap: "2rem",
        padding: ".5rem .75rem",
        borderRadius: ".25rem",
      }}
    >
      <Row gap=".5rem" onClick={() => window.open(`${window.location.origin}/#/collections/${data?.contractAddress}`)}>
        <CircledImageCtr radius="100%" size={"30px"}>
          <img src={data?.icon} alt="" />
        </CircledImageCtr>
        <Column>
          <TEXT.Body>{data?.collection}</TEXT.Body>
          <TEXT.SmallBody>
            <TEXT.Disabled>{data?.contractAddress?.slice(0,6)+"...."+data?.contractAddress?.slice(-4)}</TEXT.Disabled>
          </TEXT.SmallBody>
        </Column>
      </Row>
      <Column style={{ alignItems: "flex-end", gap: ".15rem" }}>
        <TEXT.Body style={{ display: "flex", gap: ".25rem" }}>
          {data?.count} mutual owners
        </TEXT.Body>
       
      </Column>
    </Row>
  );
};

const ListingsTable = ({ listings }) => {
  return (
    <Column style={{ width: "100%", gap: "1rem" }} className="_listingsGraph">
      <Column style={{ gap: "1rem" }} className="_listingCol">
        {listings.map((entity, idx) => (
          <Entity data={entity} key={idx} />
        ))}
      </Column>
    </Column>
  );
};
const SalesTable = ({ sales }) => {
  return (
    <Column style={{ width: "100%", gap: "1rem" }} className="_salesGraph">
      <Column style={{ gap: "1rem" }} className="_salesCol">
        {sales.map((entity, idx) => (
          <Entity data={entity} key={idx} />
        ))}
      </Column>
    </Column>
  );
};

const MutualCollections = ({ mutualData }) => {
  const [showListingData, setShowListingData] = useState(true);
  const showListingsTable = () => setShowListingData(true);
  const hideListingsTable = () => setShowListingData(false);
  const mutualList = mutualData?.map((item, index) => {
    return {
      id: index + 1,
      icon: item?.icon || assets?.icons?.defaultCollectionIcon,
      collection: item?.name || item?.contractAddress?.slice(0, 5) + "..." + item?.contractAddress?.slice(-5),
      contractAddress: item?.contractAddress,
      count: item?.noOfOwners,
      floor_percent: undefined,
     time: undefined,
    };
  });
 

  return (
    <ListingAndSalesCtr>
      <ContentCardCtr
        style={{
          height: "100%",
          padding: "0rem",
          gap: 0,
        }}
      >
        <Row
          alignItems={"center"}
          alignContent={"center"}
          justifyContent={"center"}
          className="_cardTitle"
        >
          <TEXT.MediumHeader>Top Mutual Collections</TEXT.MediumHeader>
          <QuestionHelper
            text={"Top Mutual Collections of Top 20 owners"}
            iconSize="24px"
          />
        </Row>
        
        
        <Row gap="1rem" className="_listingAndSalesGraphCtr">
          <ListingsTable listings={mutualList} />
        </Row>
        <Row gap="1rem" className="_mobGraphsCtr">
          
            <ListingsTable listings={mutualList} />
          
        </Row>
      </ContentCardCtr>
    </ListingAndSalesCtr>
  );
};
const ListingAndSalesCtr = styled(Column)`
  width: 100%;
  max-height: 550px;
  ._tableHeadings {
    display: flex;
    border-bottom: 1px solid ${(props) => props.theme.disabled};
    justify-content: space-around;
    ._heading {
      flex: 1;
      padding: 1rem;
      &:nth-of-type(1) {
        border-right: 1px solid ${(props) => props.theme.disabled};
      }
    }
  }
  ._cardTitle {
    border-bottom: 1px solid ${(props) => props.theme.disabled};
    padding: 1rem;
  }
  ._listingAndSalesGraphCtr,
  ._mobGraphsCtr {
    padding: 1rem;
    overflow-x: auto;
    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
      display: none;
    }
    /* Hide scrollbar for IE, Edge add Firefox */
    -ms-overflow-style: none;
    scrollbar-width: none; /* Firefox */
  }
  ._mobGraphsCtr {
    display: none;
  }
  ._listingCol,
  ._salesCol {
    height: 100%;
    max-height: 100%;
    overflow: hidden;
    overflow-y: auto;
    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
      display: none !important;
    }
    /* Hide scrollbar for IE, Edge add Firefox */
    -ms-overflow-style: none !important;
    scrollbar-width: none !important; /* Firefox */
  }
  ._graphBtns {
    border-bottom: 1px solid ${(props) => props?.theme.disabled};
    width: 100%;
    gap: 1rem;
    display: none;
    padding: 0.5rem;
    button {
      border: none;
      outline: none;
      background: none;
      border-radius: 0.25rem;
      color: ${(props) => props?.theme.body};
      min-width: 100px;
      padding: 0.5rem 1rem;
      font-family: var(--ff-subtitle);
    }
  }
  //@media quries : mobile screens
  ${({ theme }) => theme.mediaWidth.upToSmall`
   ._tableHeadings{
    display:none;
   }
      ._listingAndSalesGraphCtr {
        display:none;

      }
      ._mobGraphsCtr{
        display:block;
      }
       ._graphBtns {
        display:block;
       }
    `}
`;

export default MutualCollections;
