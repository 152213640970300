import { SmallOptionButton } from "components/Button";
import { GenericImageWrapper } from "components/Logo";
import Row from "components/Row";
import { CurrencyList, TypeCurrency } from "constant/currency";
import useMatchBreakpoints from "hooks/useMatchBreakpoints";
import { ListButtonWrapper } from "pages/MarketOverview/styled";
import React from "react";
import { useCurrencyUnit } from "store/user/hooks";
import styled from "styled-components";
import { TEXT } from "theme/texts";

function CurrencyMenu() {
  const { currency, setCurrencyUnit } = useCurrencyUnit();
  const { isMobile } = useMatchBreakpoints();

  const handleChange = (option: TypeCurrency) => {
    setCurrencyUnit(option.id);
  };

  return (
    <div>
      <ListButtonWrappernaV withoutDivider={true} style={{ overflow: "hidden" }}>
        {CurrencyList.map((option, index) => {
          return (
            <SmallOptionButton
              key={index}
              active={option.id === currency.id}
              onClick={() => handleChange(option)}
              style={{
                height: "auto",
                borderRadius: ".15rem",
                padding: ".5rem .75rem",
              }}
            >
              <Row gap="5px">
                <GenericImageWrapper src={option.id === currency.id ? option.logoIconActiv  :option.logoIcon} size={".7rem"} />
                <TEXT.Body fontWeight={600} fontSize={".7rem"} className={option.id === currency.id ? ' activetest': 'test'}>
                  {option.symbol}
                </TEXT.Body>
              </Row>
            </SmallOptionButton>
          );
        })}
      </ListButtonWrappernaV>
    </div>
  );
}


export const ListButtonWrappernaV = styled(Row) <{
  withoutDivider?: boolean;
  active?: boolean;
}>`
.test{
  color: #919191;
 
}
.activetest{
    color: black;
  }
  width: fit-content;
  z-index: 10 !important;
  background-color: ${({ theme }) => theme.bgTop};
  border: 1px solid ${({ theme }) => theme.btnSecondary};
  border-radius: ${({ theme }) => theme.borderRadius.button};
  & button:first-child {
    border-radius: ${({ theme, withoutDivider }) =>
    withoutDivider
      ? theme.borderRadius.button
      : `${theme.borderRadius.button} 0px 0px ${theme.borderRadius.button}`};
  }
  & button {
    border: 1px solid
      ${({ theme, withoutDivider }) =>
    withoutDivider ? "transparent" : theme.borderToggleBtn};
    border-radius: ${({ theme, withoutDivider }) =>
    withoutDivider ? theme.borderRadius.button : "0px"};
 

    
  }
  & button:last-child {
    /* border-right: 0px solid ${({ theme }) => theme.border}; */
    border-radius: ${({ theme, withoutDivider }) =>
    withoutDivider
      ? theme.borderRadius.button
      : ` 0px  ${theme.borderRadius.button} ${theme.borderRadius.button} 0px`};
  }
`;

export default CurrencyMenu;
