import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useAccount } from "wagmi";
import { AppDispatch } from "../index";
import {
  updateUserDarkMode,
  updateUserWallet,
  updateUserToken,
  updateUserHoldNFTStatus,
} from "./actions";

export class ThemeType {
  static DARK: string = "dark";
  static LIGHT: string = "light";
}

export default function Updater(): null {
  const dispatch = useDispatch<AppDispatch>();
  const match = window?.matchMedia("(prefers-color-scheme: dark)");
  const theme = match.matches ? ThemeType.DARK : ThemeType.LIGHT;
  const { isConnected, address } = useAccount();
  useEffect(() => {
    if (isConnected && address) {
      dispatch(updateUserWallet({ wallet: address }));
    } else {
      dispatch(updateUserWallet({ wallet: null }));
      dispatch(updateUserHoldNFTStatus({ holdsNFT: false }));
    }
  }, [dispatch, isConnected, address]);
  useEffect(() => {
    dispatch(updateUserDarkMode({ userDarkMode: true }));
  }, [dispatch]);

  return null;
}
