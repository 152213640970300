import { PageWrapper } from "pages/styled";
import { useEffect, useState } from "react";
import styled, { useTheme } from "styled-components";
import Row from "components/Row";
import { TEXT } from "theme/texts";
import { ChevronRight } from "react-feather";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import assets from "assets";
import { SecondaryButton } from "components/Button";
import { dropsList } from "pages/DropsPage/data";

import Airtable from "airtable";
import axios from "axios";

var base = new Airtable({ apiKey: process.env.REACT_APP_AIRTABLE_API }).base(
  "appwOj9xvqGwXPYzV"
);

const DropsDetailsPage = () => {
  const navigate = useNavigate();
  const [tabIndex, setTabIndex] = useState(1);
  const [dropItem, setDropItem] = useState({});
  const { search } = useLocation();
  const { id } = useParams();
  const [dropsList, setdropsList] = useState([]);


  const AirtableDropsFetch = async () => {
    base("Projects")
      .select({
        // Selecting the first 3 records in Grid view:
        maxRecords: 100,
        view: "Grid view",
      })
      .eachPage(
        function page(records, fetchNextPage) {
          // This function (`page`) will get called for each page of records.
          setdropsList([]);

          records.forEach(function (record) {
            const dropobj = {
              tittle: record.get("Collection Name"),
              description: record.get("Description"),
              name: record.get("Collection Name"),
              mintDate: record.get("Minting Date"),
              siteHref: record.get("Minting Link"),
              twitterHref: record.get("Twitter_URL"),
              discordHref: record.get("Discord_URL"),
              imageSrc: record.get("Logo Image URL"),
              mintPrice: record.get("Mint Price (in Matic)")
            };
            setdropsList((prevState) => [...prevState, dropobj]);
          });

          // fetchNextPage();
         
        },
        function done(err) {
          if (err) {
            console.error(err);
            return;
          }
        }
      );
  };

  const magicedenlrops = async () => {
    try{
      setdropsList([]);
    
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/drop/magiceden`

          );
    
          const json  = await  res?.data?.filter((f)=> f?.evm?.chainId === 137 ).map(item =>{
    
            return {
              id: item?._id,
              tittle: item?.symbol,
                  description: item?.description,
                  name: item?.name,
                  mintDate: item?.launchDate,
                  siteHref: item?.websiteLink,
                  twitterHref: item?.twitterLink,
                  discordHref: item?.discordLink,
                  imageSrc: item?.image,
                  contractAddress: item?.evm?.contractAddress
            }
    
          })
    
          // setdropsList((prevState) => [...prevState, ...json]);
          setdropsList(await json);
    
      
    
    
    }catch(err){
      console.log(err);
    
    }
    
      }

  useEffect(() => {
    Object.values(dropsList)
      .filter((obj) => obj.name === id)
      .map((obj, index) => setDropItem(obj));
    // AirtableDropsFetch()
    magicedenlrops()

  }, [dropsList]);

  useEffect(() => {
    if (search) {
      const queryObj = new URLSearchParams(search);
      const mintStatus = queryObj.get("ongoing-mint");
      setTabIndex(mintStatus === "true" ? 1 : 2);
    }
  }, [search]);
  return (
    <DropPageWrapper>
      <DropPageCtr>
        <Row style={{ gap: ".5rem" }}>
          <TEXT.Disabled
            color={"grey"}
            style={{ cursor: "pointer", minWidth: "fit-content" }}
            onClick={() => {
              navigate("/drops", { state: { tabIndex: tabIndex } });
            }}
          >
            Drops
          </TEXT.Disabled>
          <ChevronRight size={"1.25rem"} />
          <TEXT.Primary className="_profileNameLabel" color={useTheme().body}>
            {dropItem?.name}
          </TEXT.Primary>
        </Row>

        <div className="DetailsBox">
          <div className="left">
            <img src={dropItem?.imageSrc} />
          </div>
          <div className="right">
            <div className="header">{dropItem?.name}</div>
            <div className="datebox">
              Mint Date: <span>{dropItem?.mintDate}</span>
            </div>
            <div className="discription">{dropItem?.description}</div>
            <div className="socialbox">
              {dropItem?.twitterHref ? (
                <a
                  href={dropItem?.twitterHref}
                  target="_blank"
                  className="socialBedage"
                >
                  <img src={assets.logos.twitterWhiteLogo} />
                  {dropItem?.twitterCount ? (
                    <div>{dropItem?.twitterCount} Members</div>
                  ) : null}
                </a>
              ) : null}
              {dropItem?.discordHref ? (
                <a
                  href={dropItem?.discordHref}
                  target={"_blank"}
                  className="socialBedage"
                >
                  <img src={assets.logos.discordWhiteLogo} />
                  {dropItem?.discordCount ? (
                    <div>{dropItem?.discordCount} Members</div>
                  ) : null}
                </a>
              ) : null}
             
            </div>
            <div className="cardBox">
              <div className="mintCard">
                <div className="p">Public Mint Price</div>
                {dropItem?.mintPrice ? (
                  <div className="count">
                    <img src={assets.logos.polygonLogoCircle} />
                    {dropItem.mintPrice}
                  </div>
                ) : (
                  <div className="count">N/A</div>
                )}
              </div>
          {/*    <div className="mintCard">
                <div className="p">Public Mint Price</div>
                {dropItem?.presalePrice ? (
                  <div className="count">
                    <img src={assets.logos.polygonLogoCircle} />
                    {dropItem?.presalePrice}
                  </div>
                ) : (
                  <div className="count">N/A</div>
                )}
              </div>
              */}
            </div>

            <div className="btnSection">
              {dropItem?.siteHref ? (
                <a href={dropItem?.siteHref} target="_blank">
                  <SecondaryButton borderRadius={"0px"}>
                    <svg
                      width="29"
                      height="28"
                      viewBox="0 0 29 28"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M6.23877 14C6.23877 9.584 9.81477 6 14.2308 6C18.6548 6 22.2388 9.584 22.2388 14C22.2388 18.416 18.6548 22 14.2308 22C9.81477 22 6.23877 18.416 6.23877 14ZM17.4148 10.8H19.7748C18.9997 9.46538 17.7701 8.4544 16.3108 7.952C16.7908 8.84 17.1588 9.8 17.4148 10.8ZM14.2388 7.632C14.9108 8.60152 15.4264 9.67054 15.7668 10.8H12.7108C13.0512 9.67054 13.5668 8.60152 14.2388 7.632ZM7.83877 14C7.83877 14.552 7.91877 15.088 8.04677 15.6H10.7508C10.6811 15.0694 10.6437 14.5351 10.6388 14C10.6388 13.456 10.6868 12.928 10.7508 12.4H8.04677C7.9122 12.9228 7.84235 13.4602 7.83877 14ZM8.70277 17.2H11.0628C11.3188 18.2 11.6868 19.16 12.1668 20.048C10.706 19.5483 9.47546 18.5366 8.70277 17.2ZM8.70277 10.8H11.0628C11.3188 9.8 11.6868 8.84 12.1668 7.952C10.706 8.45169 9.47546 9.46337 8.70277 10.8ZM14.2388 20.368C13.5668 19.3985 13.0512 18.3295 12.7108 17.2H15.7668C15.4264 18.3295 14.9108 19.3985 14.2388 20.368ZM12.2388 14C12.2388 14.544 12.2948 15.072 12.3668 15.6H16.1108C16.1898 15.0702 16.2326 14.5356 16.2388 14C16.2388 13.456 16.1828 12.92 16.1108 12.4H12.3668C12.2948 12.92 12.2388 13.456 12.2388 14ZM16.3108 20.048C16.7936 19.1473 17.1644 18.1908 17.4148 17.2H19.7748C18.9997 18.5346 17.7701 19.5456 16.3108 20.048ZM17.8388 14C17.8388 14.544 17.7908 15.072 17.7268 15.6H20.4308C20.5653 15.0772 20.6352 14.5398 20.6388 14C20.6388 13.448 20.5588 12.912 20.4308 12.4H17.7268C17.7908 12.928 17.8388 13.456 17.8388 14Z"
                        fill="white"
                      />
                      <rect
                        x="0.73877"
                        y="0.5"
                        width="27"
                        height="27"
                        rx="13.5"
                        stroke="#363746"
                      />
                    </svg>
                    Visit mint Website
                  </SecondaryButton>
                </a>
              ) : null}
            </div>
          </div>
        </div>
      </DropPageCtr>
    </DropPageWrapper>
  );
};
const DropPageCtr = styled(PageWrapper)`
  ${(props) => props?.theme?.flexColumnNoWrap}
`;
const DropPageWrapper = styled.div`
  width: 100%;
  .DetailsBox {
    margin-top: 80px;
    display: flex;
    gap: 48px;
    .left {
      flex: 1;

      img {
        width: 100%;
      }
    }

    .right {
      flex: 2;
      .header {
        font-weight: 700;
        font-size: 36px;
        line-height: 40px;
      }
      .discription {
        font-weight: 400;
        font-size: 14px;
        line-height: 15px;
        /* max-width: 350px; */
      }
      .datebox {
        font-weight: 400;
        font-size: 14px;
        line-height: 15px;
        margin: 24px 0;
        background: rgba(0, 124, 124, 0.50);
        padding: 10px;
        width: max-content;

        span {
          font-weight: 700;
        }
      }
      .socialbox {
        display: flex;
        margin: 24px 0;
        gap: 10px;

        .socialBedage {
          border: 1px solid #363746;
          border-radius: 8px;
          padding: 10px;
          gap: 10px;
          display: flex;
          width: max-content;
          font-weight: 500;
          font-size: 12px;
          line-height: 120%;
          justify-content: center;
          align-items: center;
          text-decoration: none;
          color: #fff;
        }
      }

      .cardBox {
        display: flex;
        gap: 24px;
        margin-bottom: 24px;

        .mintCard {
          
background: #0A1619;
          border: 1px solid #2d2f31;
          padding: 24px;
          width: max-content;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          .p {
            font-weight: 500;
            font-size: 12px;
            line-height: 14px;
            margin-bottom: 12px;
          }
          .count {
            font-weight: 600;
            font-size: 20px;
            line-height: 24px;
            display: flex;
            gap: 8px;
          }
        }
      }

      .btnSection {
        width: max-content;
        a {
          text-decoration: none;
        }
      }
    }
  }

  @media only screen and (max-width: 700px) {
    .DetailsBox {
      flex-direction: column;
      margin-top: 20px;
    }
    .header {
      font-size: 32px;
      line-height: 38px;
    }
    .discription {
      max-width: auto;
    }

    .btnSection {
      width: max-content;
      button {
        padding: 12px 15px;
      }
    }
  }
`;
export default DropsDetailsPage;
