import assets from "assets";

export const cardsData = [
  {
    title: "Market Cap",
    endpoint: `${process.env.REACT_APP_API_URL}/topCollectionByVolume`,
    toolTip: "Top 5 collections by the volume of their transactions in Matic",
    price_change: -2.4,
    value: "908.56M",
    showTimePeriod: true,
    showLogo: true,
  },
  {
    title: "Total NFT Sales",
    endpoint: `${process.env.REACT_APP_API_URL}/topCollectionByTrend`,
    toolTip: "Top 5 collections that are trending across the landscape",
    price_change: 2.4,
    value: "908.56M ",
    showTimePeriod: true,
    showLogo: true,
  },
  {
    title: "Matic Price",
    endpoint: `${process.env.REACT_APP_API_URL}/topCollectionBySales`,
    toolTip: "Top 5 categories of Polygon NFTs that are trending on Polygon",
    price_change: -2.4,
    value: 1.09,
    showTimePeriod: false,
    showLogo: false,
    dollarSign: true,
  },
  {
    title: "Total NFT Wallets",
    endpoint: `${process.env.REACT_APP_API_URL}/topCollectionByCategory`,
    toolTip: "Top NFT collections in Polygon ecosystem",
    price_change: 2.4,
    value: "56.23M ",
    showTimePeriod: true,
    showLogo: false,
  },
  {
    title: "Total NFT Collections",
    endpoint: `${process.env.REACT_APP_API_URL}/topCollectionByCategory`,
    toolTip: "Top NFT collections in Polygon ecosystem",
    price_change: 2.4,
    value: "56.23M ",
    showTimePeriod: true,
    showLogo: false,
  },
  {
    title: "Volume",
    endpoint: `${process.env.REACT_APP_API_URL}/topCollectionByCategory`,
    toolTip: "Top NFT collections in Polygon ecosystem",
    price_change: 2.4,
    value: "356k",
    showTimePeriod: true,
    showLogo: false,
  },
];

export const blogData = [
  {
    title: "Your go-to for Polygon NFTs",
    discription:
      "Keep up with the latest news and for Polygon NFT Projects",
    link: "https://medium.com/@mnfsttools",
    buttonTitle: "Read more",
    image: assets.images.blog2,
  },
  {
    title:"Who is MNFST Labs?",
    discription: "Take a Deeper Dive into All Things MNFST",
    link: "https://medium.com/@mnfstlabs",
    buttonTitle: "Read more",
    image: assets.images.blog2,
  },
];

export const StaticContent = {
  heroSection: {
    title: "The only dashboard you’ll need for all things Polygon NFT",
    discription:
      "From trending NFTs to all time ecosystem analytics, Surfaceboard provides the comprehensive data for NFTs on Polygon",
    subHeading: "What’s Hot?",
    nfts: [
      {
        title: "y00ts",
        discription:
          "The much awaited announcement is finally here! Learn more about the y00ts bridging to Polygon and learn how you can get yours over too.",
        image: assets.images.nft1,
        link: "https://www.y00ts.com/",
      },
      {
        title: "Rekt Dogs",
        discription:
          "Who let the dawgs out again? Well Rekt dogs just started minting, so you better catch them up before they run out. Headover to grab yours",
        image: assets.images.nft2,
        link: "https://polygon.magiceden.io/launchpad/rekt_dogs",
      },
    ],
  },
};

export const trending = [
  {
    title: "y00ts",
    image: assets.images.top1,
    link: "/collections/0x670fd103b1a08628e9557cd66b87ded841115190",
  },
  {
    title: "RektDog",
    image: assets.images.top2,
    link: "/collections/0xace8187b113a38f83bd9c896c6878b175c234dcc",
  },
  {
    title: "Trump Digital Trading Cards",
    image: assets.images.top3,
    link: "/collections/0x24a11e702cd90f034ea44faf1e180c0c654ac5d9",
  },
  {
    title: "Owlpha",
    image: assets.images.top4,
    link: "/collections/0x12aa01f646fe5c993c66c9c86eddad4e514f6cbc",
  },
  {
    title: "Brozo ",
    image: assets.images.top5,
    link: "/collections/0x220fa5ccc9404802ed6db0935eb4feefc27c937e",
  },
];

export const collectionAddresses = [
  "0x12aa01f646fe5c993c66c9c86eddad4e514f6cbc",//Owlpha
  "0xdc901017d1c8c9e5745a0a52e3237804da32790c",//RingRunnerz
  "0xfcaa34d7ef46d1e4cf5d7933e6be8c57374a6fa7",//Galactic Eagle Gang
  "0x99c1b681305d9aa95465547494d321eecb094bdc",//The Herd
  "0x39cd103414106b922eb09c7d45df89608b59e887",//Dril club
  "0xc1a5f386e3b2d3cb280191fcd11e76c41117197d",//Gambulls
  "0x419e82d502f598ca63d821d3bbd8dfefaf9bbc8d",// Polygon Ape: The Evolution
  "0xaa0489f3f92474b993ef11176f7cbe10510d3e70",//The Leos
  "0x20b807b9af56977ef475c089a0e7977540743560",//BoomLand - Hunters
  "0x2ebe61759e916b61523ea1fda8e1c057688bd8f3",//Normies
  "0xdb9be9cf5ba3d0598ce30c5c9bc8ea608c2a95b3",//Normies Wristbands
  "0x051c5fa955cec55032fd5d17b643d43ef946d038",//Evil Skellies
  "0xcf8aa0b9e6bd01571f4e1e2475d77c2c84cece4d",// Hyper Kongz
  "0xace8187b113a38f83bd9c896c6878b175c234dcc",//Rekt dog
  "0xef41141fbc0a7c870f30fee81c6214582dc2a494",//Genesis Wildpass
  "0x220fa5ccc9404802ed6db0935eb4feefc27c937e",//Brozo
  "0xa9a6a3626993d487d2dbda3173cf58ca1a9d9e9f",//Unstoppable Domains
  "0xb4178a53763263c3db52965371b3a91da3724e1b",//Owlpha Cores
  "0xa25541164ae9d59322b59fe94a73869b494c3691",//Rival Bears
  "0x7802c717b3fc4e6a32d1ae3a31a6175ebae9b164",//Tribuzz
  "0x0ab302a678c1cf97043c1d932968d09d3176e64c",//Space Skellies
  "0xa9cc7c358bfa3fa6cee4231f975efc4ebd505538",//Nine
  "0x198d38c5f21eab36731d0576560440f70cbd9418",//Yieldnodes NFT
  "0x56d23f924cd526e5590ed94193a892e913e38079",//ArcheWorld_Land (POLYGON)
  "0xee79a3e8aef1109a6ee82bf399ce9e1bd43cf5c4",//Danketsu
  "0xdcb074190b01a8c08c34866ee972d363c4339d53",//Soul Searchers
  "0x50d2915927255109570d9f72ebeb8cb477df508e",//Iced Out Coin Flip
  "0xa9843edf08588a52ae92e853d0d8b9977a1b0791",//The Poker Pro
  "0x09421f533497331e1075fdca2a16e9ce3f52312b",//HellCats
  "0xe5c93b6692c03d4279d1a3098e4321254b560f47",//PolygonMonkeys
  "0x248d6e4f9ab178bb8022c18ca25a281a53e9a047",// DinoDash
  "0xb5ed47dd1be9dd7bf6304b3e823d8581bdf5fcfd",//Trippy Wolves
  "0x895a23ebb9dae1abd34eaae2e11db3ee6233071a", //HellHound

]
export const collectionURl = [
  
    {
      name: "Owlpha",
      imgUrl: false,
      address: "0x12aa01f646fe5c993c66c9c86eddad4e514f6cbc"
    },
    {
      name: "RingRunnerz",
      imgUrl: false,
      address: "0xdc901017d1c8c9e5745a0a52e3237804da32790c"
    },
    {
      name: "Galactic Eagle Gang",
      imgUrl: "https://img-cdn.magiceden.dev/rs:fill:400:400:0:0/plain/https://bafybeie4o6bvy4rty2y7h5wi5oc4gnsrarhwfyqi5o4mhbbhbww2xx6kpy.ipfs.nftstorage.link/",
      address: "0xfcaa34d7ef46d1e4cf5d7933e6be8c57374a6fa7"
    },
    {
      name: "The Herd",
      imgUrl: "https://img-cdn.magiceden.dev/rs:fill:400:400:0:0/plain/https://bafkreiavefuvm4jonrs2ya6rjpyef7edgv2njogwa2uksrtcwagdvwad7u.ipfs.nftstorage.link/",
      address: "0x99c1b681305d9aa95465547494d321eecb094bdc"
    },
    {
      name: "Dril club",
      imgUrl: false,
      address: "0x39cd103414106b922eb09c7d45df89608b59e887"
    },
    {
      name: "Gambulls",
      imgUrl: false,
      address: "0xc1a5f386e3b2d3cb280191fcd11e76c41117197d"
    },
    {
      name: "P ape",
      imgUrl: false,
      address: "0x419e82d502f598ca63d821d3bbd8dfefaf9bbc8d"
    },
    {
      name: "The Leos",
      imgUrl: "https://img-cdn.magiceden.dev/rs:fill:400:400:0:0/plain/https://bafkreihgzz7uabpdcu7gygnbmhnq7cugtbyjmpudvrnupz7i3yc57pgz4e.ipfs.nftstorage.link/",
      address: "0xaa0489f3f92474b993ef11176f7cbe10510d3e70"
    },
    {
      name: "BoomLand - Hunters",
      imgUrl: false,
      address: "0x20b807b9af56977ef475c089a0e7977540743560"
    },
    {
      name: "Normies",
      imgUrl: false,
      address: "0x2ebe61759e916b61523ea1fda8e1c057688bd8f3"
    },
    {
      name: "Normies Wristbands",
      imgUrl: "https://img-cdn.magiceden.dev/rs:fill:400:400:0:0/plain/https://bafybeiejj6st6v6janad2hdc5kumlispo6brpcuyt7bf6atqcvdi7qikl4.ipfs.nftstorage.link/31.PNG",
      address: "0xdb9be9cf5ba3d0598ce30c5c9bc8ea608c2a95b3"
    },
    {
      name: "Evil Skellies",
      imgUrl: "https://img-cdn.magiceden.dev/rs:fill:400:400:0:0/plain/https://bafkreiedtzq6hqvg7tfyw5kymyblmawi2spo7jxcckhpi3gchjly24r6mi.ipfs.nftstorage.link/",
      address: "0x051c5fa955cec55032fd5d17b643d43ef946d038"
    },
    {
      name: "Hyper Kongz",
      imgUrl: "https://img-cdn.magiceden.dev/rs:fill:400:400:0:0/plain/https://bafkreif3jxuwa2jfg6eyvcwj5s6h6i4zqyvprk54k7fahnw3o5h75jd5nm.ipfs.nftstorage.link/",
      address: "0xcf8aa0b9e6bd01571f4e1e2475d77c2c84cece4d"
    },
    {
      name: "Rekt dog",
      imgUrl: false,
      address: "0xace8187b113a38f83bd9c896c6878b175c234dcc"
    },
    {
      name: "Genesis Wildpass",
      imgUrl: false,
      address: "0xef41141fbc0a7c870f30fee81c6214582dc2a494"
    },
    {
      name: "Brozo",
      imgUrl: false,
      address: "0x220fa5ccc9404802ed6db0935eb4feefc27c937e"
    },
    {
      name: "Unstoppable Domains",
      imgUrl: false,
      address: "0xa9a6a3626993d487d2dbda3173cf58ca1a9d9e9f"
    },
    {
      name: "Owlpha Cores",
      imgUrl: false,
      address: "0xb4178a53763263c3db52965371b3a91da3724e1b"
    },
    {
      name: "Rival Bears",
      imgUrl: false,
      address: "0xa25541164ae9d59322b59fe94a73869b494c3691"
    },
    {
      name: "Tribuzz",
      imgUrl: false,
      address: "0x7802c717b3fc4e6a32d1ae3a31a6175ebae9b164"
    },
    {
      name: "Space Skellies",
      imgUrl: false,
      address: "0x0ab302a678c1cf97043c1d932968d09d3176e64c"
    },
    {
      name: "Nine",
      imgUrl: "https://img-cdn.magiceden.dev/rs:fill:400:400:0:0/plain/https://bafkreiguwgknk75n6t4ygr43xlhoxarg36ip6epzwmo3nqh2ood6erj2ou.ipfs.nftstorage.link/",
      address: "0xa9cc7c358bfa3fa6cee4231f975efc4ebd505538"
    },
    {
      name: "Yieldnodes NFT",
      imgUrl: false,
      address: "0x198d38c5f21eab36731d0576560440f70cbd9418"
    },
    {
      name: "ArcheWorld_Land (POLYGON)",
      imgUrl: false,
      address: "0x56d23f924cd526e5590ed94193a892e913e38079"
    },
    {
      name: "Danketsu",
      imgUrl: "https://img-cdn.magiceden.dev/rs:fill:400:400:0:0/plain/https://bafybeifhzmg6qdccyk5sqe23tppsno2ww4srusdchviqxnu5toy6hjpa4u.ipfs.nftstorage.link/",
      address: "0xee79a3e8aef1109a6ee82bf399ce9e1bd43cf5c4"
    },
    {
      name: "Soul Searchers",
      imgUrl: "https://img-cdn.magiceden.dev/rs:fill:400:400:0:0/plain/https%3A%2F%2Fbafybeibuealn4lvkosl445ojc5bela2ri57reng4ubeqyvpzcogzaifore.ipfs.nftstorage.link%2F0.gif%3Fext%3Dgif",
      address: "0xdcb074190b01a8c08c34866ee972d363c4339d53"
    },
    {
      name: "Iced Out Coin Flip",
      imgUrl: false,
      address: "0x50d2915927255109570d9f72ebeb8cb477df508e"
    },
    {
      name: "The Poker Pro",
      imgUrl: false,
      address: "0xa9843edf08588a52ae92e853d0d8b9977a1b0791"
    },
    {
      name: "HellCats",
      imgUrl: false,
      address: "0x09421f533497331e1075fdca2a16e9ce3f52312b"
    },
    {
      name: "PolygonMonkeys",
      imgUrl: false,
      address: "0xe5c93b6692c03d4279d1a3098e4321254b560f47"
    },
  
    {
      name: "DinoDash",
      imgUrl: false,
      address: "0x248d6e4f9ab178bb8022c18ca25a281a53e9a047"
    },
    {
      name: "Trippy Wolves",
      imgUrl: "https://img-cdn.magiceden.dev/rs:fill:400:400:0:0/plain/https://bafybeieob2fdtz6fd2vbctahb2fkonqr6uu7goasoyhlf6net6vozlytve.ipfs.nftstorage.link/530.png",
      address: "0xb5ed47dd1be9dd7bf6304b3e823d8581bdf5fcfd"
    }
]

export const collectionURLnew = [
  {
    name: "Owlpha",
    imgUrl: false,
    address: "0x12aa01f646fe5c993c66c9c86eddad4e514f6cbc"
  },
  {
    name: "RingRunnerz",
    imgUrl: false,
    address: "0xdc901017d1c8c9e5745a0a52e3237804da32790c"
  },
  {
    name: "Galactic Eagle Gang",
    imgUrl: false,
    address: "0xfcaa34d7ef46d1e4cf5d7933e6be8c57374a6fa7"
  },
  {
    name: "The Herd",
    imgUrl: "",
    address: "0x99c1b681305d9aa95465547494d321eecb094bdc"
  },
  {
    name: "Dril club",
    imgUrl: "",
    address: "0x39cd103414106b922eb09c7d45df89608b59e887"
  },
  {
    name: "Gambulls",
    imgUrl: "",
    address: "0xc1a5f386e3b2d3cb280191fcd11e76c41117197d"
  },
  {
    name: "P ape",
    imgUrl: "",
    address: "0x419e82d502f598ca63d821d3bbd8dfefaf9bbc8d"
  },
  {
    name: "The Leos",
    imgUrl: "",
    address: "0xaa0489f3f92474b993ef11176f7cbe10510d3e70"
  },
  {
    name: "BoomLand - Hunters",
    imgUrl: "",
    address: "0x20b807b9af56977ef475c089a0e7977540743560"
  },
  {
    name: "Normies",
    imgUrl: "",
    address: "0x2ebe61759e916b61523ea1fda8e1c057688bd8f3"
  },
  {
    name: "Normies Wristbands",
    imgUrl: "",
    address: "0xdb9be9cf5ba3d0598ce30c5c9bc8ea608c2a95b3"
  },
  {
    name: "Evil Skellies",
    imgUrl: "",
    address: "0x051c5fa955cec55032fd5d17b643d43ef946d038"
  },
  {
    name: "Hyper Kongz",
    imgUrl: "",
    address: "0xcf8aa0b9e6bd01571f4e1e2475d77c2c84cece4d"
  },
  {
    name: "Rekt dog",
    imgUrl: "",
    address: "0xace8187b113a38f83bd9c896c6878b175c234dcc"
  },
  {
    name: "Genesis Wildpass",
    imgUrl: "",
    address: "0xef41141fbc0a7c870f30fee81c6214582dc2a494"
  },
  {
    name: "Brozo",
    imgUrl: "",
    address: "0x220fa5ccc9404802ed6db0935eb4feefc27c937e"
  },
  {
    name: "Unstoppable Domains",
    imgUrl: "",
    address: "0xa9a6a3626993d487d2dbda3173cf58ca1a9d9e9f"
  },
  {
    name: "Owlpha Cores",
    imgUrl: "",
    address: "0xb4178a53763263c3db52965371b3a91da3724e1b"
  },
  {
    name: "Rival Bears",
    imgUrl: "",
    address: "0xa25541164ae9d59322b59fe94a73869b494c3691"
  },
  {
    name: "Tribuzz",
    imgUrl: "",
    address: "0x7802c717b3fc4e6a32d1ae3a31a6175ebae9b164"
  },
  {
    name: "Space Skellies",
    imgUrl: "",
    address: "0x0ab302a678c1cf97043c1d932968d09d3176e64c"
  },
  {
    name: "Nine",
    imgUrl: "",
    address: "0xa9cc7c358bfa3fa6cee4231f975efc4ebd505538"
  },
  {
    name: "Yieldnodes NFT",
    imgUrl: "",
    address: "0x198d38c5f21eab36731d0576560440f70cbd9418"
  },
  {
    name: "ArcheWorld_Land (POLYGON)",
    imgUrl: "",
    address: "0x56d23f924cd526e5590ed94193a892e913e38079"
  },
  {
    name: "Danketsu",
    imgUrl: "",
    address: "0xee79a3e8aef1109a6ee82bf399ce9e1bd43cf5c4"
  },
  {
    name: "Soul Searchers",
    imgUrl: "",
    address: "0xdcb074190b01a8c08c34866ee972d363c4339d53"
  },
  {
    name: "Iced Out Coin Flip",
    imgUrl: "",
    address: "0x50d2915927255109570d9f72ebeb8cb477df508e"
  },
  {
    name: "The Poker Pro",
    imgUrl: "",
    address: "0xa9843edf08588a52ae92e853d0d8b9977a1b0791"
  },
  {
    name: "HellCats",
    imgUrl: "",
    address: "0x09421f533497331e1075fdca2a16e9ce3f52312b"
  },
  {
    name: "PolygonMonkeys",
    imgUrl: "",
    address: "0xe5c93b6692c03d4279d1a3098e4321254b560f47"
  },

  {
    name: "DinoDash",
    imgUrl: "",
    address: "0x248d6e4f9ab178bb8022c18ca25a281a53e9a047"
  },
  {
    name: "Trippy Wolves",
    imgUrl: "",
    address: "0xb5ed47dd1be9dd7bf6304b3e823d8581bdf5fcfd"
  }
];
