import { SecondaryButton, SmallOptionButton } from "components/Button";
import LineAreaChart from "components/Chart/LineAreaChart";
import { AutoColumn } from "components/Column";
import { GenericImageWrapper } from "components/Logo";
import Percent from "components/Percent";
import { useTotalNFTCollections } from "data/nft/totalNFTCollections";
import useTheme from "hooks/useTheme";
import React, { useEffect, useMemo, useState } from "react";
import { ExternalLink } from "react-feather";
import { GenericChartSeries, TimeWindow } from "types";
import { formatAmount } from "utils/numbers";
import { ChartWrapper, ListButtonWrapper } from "./styled";

import assets from "assets";
import { HorizontalDivider } from "components/Divider/inde";
import GraphTableTopHeader from "components/HeaderComponent/GraphTableTopHeader";
import { ShowMedium } from "components/Hide";
import Row, { RowBetween } from "components/Row";
import dayjs from "dayjs";
import useMatchBreakpoints from "hooks/useMatchBreakpoints";
import useTakeScreenshot from "hooks/useTakeSnapshot";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { TEXT } from "theme/texts";
import getTimewindowFilteredData from "utils/chart";
import {
  appRoutes,
  getScrollToElementPath,
  marketOverviewPageElementIDs,
} from "utils/path";
import { useNFTCollections } from "data/nft/totalNFTCollection";

const ResponsiveColumn = styled.div<{}>`
  display: flex;
  flex-direction: column;
  padding-top: 6px;
  gap: 6px;
  ${({ theme }) => theme.mediaWidth.upToMedium`
  `}
`;

const twitterShareScrollPath = getScrollToElementPath(
  appRoutes.marketOverview,
  marketOverviewPageElementIDs.nftCollections
);
const snapshotDownloadIconId = "snapshot-nft-collections-download-icon";
const snapshotBrandLogoId = "snapshot-nft-collections-brand-logo";

const TopLeftComponent = ({
  timeWindow,
  setTimeWindow,
  filteredCollectionValues,
  isMobileView,
}: {
  timeWindow: TimeWindow;
  setTimeWindow: React.Dispatch<React.SetStateAction<TimeWindow>>;
  filteredCollectionValues: { countValue: number; percentValue: number };
  isMobileView: boolean;
}) => {
  const navigate = useNavigate();
  return (
    <Row width={"100%"} justify={"space-between"} align="start">
      <AutoColumn>
        <ResponsiveColumn>
          <TEXT.LargeHeader fontSize={"clamp(1.75rem,3vw,2.5rem)"}>
            {formatAmount(
              filteredCollectionValues.countValue
            ).toLocaleUpperCase()}
          </TEXT.LargeHeader>
          {timeWindow !== TimeWindow.all ? (
            <Percent
              value={filteredCollectionValues.percentValue}
              fontSize={isMobileView ? "12px" : "24px"}
              transparentBorder={true}
            />
          ) : (
            <TEXT.Body fontSize={isMobileView ? 12 : 24}>&nbsp;</TEXT.Body>
          )}
        </ResponsiveColumn>
        <RowBetween gap="20px">
          <ShowMedium>
            <SecondaryButton
              width={"fit-content"}
              marginY={"1.4rem"}
              onClick={() => navigate("/collections")}
              active={false}
            >
              <TEXT.Body fontSize={isMobileView ? 14 : 16}>
                See all collections
              </TEXT.Body>{" "}
              &nbsp;{" "}
              <ExternalLink
                width={isMobileView ? 12 : 18}
                height={isMobileView ? 12 : 18}
              />
            </SecondaryButton>
          </ShowMedium>
        </RowBetween>
      </AutoColumn>
      <ListButtonWrapper marginTop="20px">
        {[
          TimeWindow.threeMonth,
          TimeWindow.sixMonth,
          TimeWindow.oneYear,
          TimeWindow.all,
        ].map((option, index) => {
          return (
            <SmallOptionButton
              key={index}
              active={timeWindow === option}
              onClick={() => setTimeWindow(option)}
            >
              {option === "custom" ? (
                <GenericImageWrapper
                  src={assets.icons.calendarIcon}
                  size="18px"
                />
              ) : (
                option
              )}
            </SmallOptionButton>
          );
        })}
      </ListButtonWrapper>
    </Row>
  );
};

function TotalNFTCollectionsGraph({
  refNFTCollectionsGraph,
}: {
  refNFTCollectionsGraph: React.RefObject<HTMLElement>;
}) {
  const theme = useTheme();
  const [volumeHover, setVolumeHover] = useState<number | undefined>();
  const [liquidityHover, setLiquidityHover] = useState<number | undefined>();
  const [leftLabel, setLeftLabel] = useState<string | undefined>();
  const [rightLabel, setRightLabel] = useState<string | undefined>();
  const [timeWindow, setTimeWindow] = useState(TimeWindow.all);

  const { data: newNFTCollections } = useNFTCollections();

  const reverseNFTCollections = [...(newNFTCollections ?? [])].reverse();
  const { isMobile } = useMatchBreakpoints();

  const downloadScreenshot = useTakeScreenshot({
    ref: refNFTCollectionsGraph,
    elementIdsTohide: [snapshotDownloadIconId],
    elementIdsToShow: [snapshotBrandLogoId],
  });

  useEffect(() => {
    setLiquidityHover(undefined);
    setVolumeHover(undefined);
  }, []);

  /**
   * @notice
   * @dev 'formattedVolumeData' function format data according to graph plot format
   * @dev 'getTransformedVolumeData' function filter chart data according to selected timewindow
   * @dev 'formattedChartSeries' change according to requirement
   * @param timewindow is selected option out of provided time options to user for filtering chart data
   * @param time  for X-axis
   * @param value... for Y-axis it must be same as @param datakey of config variable 'formattedChartSeries'
   */
  const formattedChartData = useMemo(() => {
    if (newNFTCollections && newNFTCollections.length > 0) {
      return getTimewindowFilteredData(newNFTCollections, timeWindow).map(
        (record) => {
          return {
            time: record.dateTime,
            valueNFTCollection: record.cumulative_collections,
          };
        }
      );
    } else {
      return [];
    }
  }, [newNFTCollections, timeWindow]);
  /**
   * @notice
   * @dev 'formattedChartSeries'  must change according to requirement
   * Add according requirement (This will plot line graph with provided color and data)
   */
  const formattedChartSeries: GenericChartSeries[] = [
    {
      dataKey: "valueNFTCollection",
      name: "NFT Collections",
      color: theme.btnSecondary,
    },
  ];

  const filteredTotalCount = useMemo(() => {
    let countValue = 0;
    let percentValue = 0;
    if (reverseNFTCollections.length === 0) {
      return { countValue, percentValue };
    }
    switch (timeWindow) {
      case TimeWindow.threeMonth: {
        const beforeThreeMonthData = reverseNFTCollections.find((element) =>
          dayjs(element.dateTime).isBefore(
            dayjs().subtract(3, "month"),
            "month"
          )
        );
        const beforeSixMonthData = reverseNFTCollections.find((element) =>
          dayjs(element.dateTime).isBefore(
            dayjs().subtract(6, "month"),
            "month"
          )
        );
        const lastThreeMonthCount =
          reverseNFTCollections[1].cumulative_collections -
          (beforeThreeMonthData?.cumulative_collections ?? 0);
        const lastSecondThreeMonthCount =
          (beforeThreeMonthData?.cumulative_collections ?? 0) -
          (beforeSixMonthData?.cumulative_collections ?? 0);
        countValue =
          reverseNFTCollections[0].cumulative_collections -
          (beforeThreeMonthData?.cumulative_collections ?? 0);
        percentValue = reverseNFTCollections[0].pct_change_90_days;
        break;
      }
      case TimeWindow.sixMonth: {
        const beforeSixMonthData = reverseNFTCollections.find((element) =>
          dayjs(element.dateTime).isBefore(
            dayjs().subtract(6, "month"),
            "month"
          )
        );
        const beforeTwelveMonthData = reverseNFTCollections.find((element) =>
          dayjs(element.dateTime).isBefore(
            dayjs().subtract(12, "month"),
            "month"
          )
        );
        const lastSixMonthCount =
          reverseNFTCollections[1].cumulative_collections -
          (beforeSixMonthData?.cumulative_collections ?? 0);
        const lastSecondSixMonthCount =
          (beforeSixMonthData?.cumulative_collections ?? 0) -
          (beforeTwelveMonthData?.cumulative_collections ?? 0);
        countValue =
          reverseNFTCollections[0]?.cumulative_collections_last_180_days ?? 0;
        percentValue =
          ((lastSixMonthCount - lastSecondSixMonthCount) * 100) /
          (lastSecondSixMonthCount ?? 1);

        // percentValue =
        //   ((reverseNFTCollections[1].count_collections -
        //     (beforeSixMonthData?.count_collections ?? 0)) *
        //     100) /
        //   (beforeSixMonthData?.count_collections ?? 1);
        break;
      }
      case TimeWindow.oneYear: {
        const beforeOneYearData = reverseNFTCollections.find((element) =>
          dayjs(element.dateTime).isBefore(dayjs().subtract(1, "year"), "month")
        );
        const beforeTwoYearData = reverseNFTCollections.find((element) =>
          dayjs(element.dateTime).isBefore(dayjs().subtract(2, "year"), "month")
        );
        const lastOneYearCount =
          reverseNFTCollections[1].cumulative_collections -
          (beforeOneYearData?.cumulative_collections ?? 0);
        const lastSecondYearCount =
          (beforeOneYearData?.cumulative_collections ?? 0) -
          (beforeTwoYearData?.cumulative_collections ?? 0);
        countValue =
          reverseNFTCollections[0].cumulative_collections -
          (beforeOneYearData?.cumulative_collections ?? 0);
        percentValue =
          ((lastOneYearCount - lastSecondYearCount) * 100) /
          (lastSecondYearCount ?? 1);
        break;
      }
      case TimeWindow.all:
        countValue = reverseNFTCollections[0].cumulative_collections;
        percentValue =
          ((reverseNFTCollections[1].cumulative_collections -
            reverseNFTCollections[2].cumulative_collections) *
            100) /
          reverseNFTCollections[2].cumulative_collections;
        break;
      default:
        countValue = reverseNFTCollections[0].cumulative_collections;
        percentValue =
          ((reverseNFTCollections[1].cumulative_collections -
            reverseNFTCollections[2].cumulative_collections) *
            100) /
          reverseNFTCollections[2].cumulative_collections;
        break;
    }
    return { countValue, percentValue };
  }, [reverseNFTCollections, timeWindow]);

  return (
    <>
      <GraphTableTopHeader
        title={"Total NFT Collections"}
        tooltip="Number of NFT collections minted "
        downloadScreenshot={downloadScreenshot}
        isMobileView={isMobile}
        snapshotBrandLogoId={snapshotBrandLogoId}
        snapshotDownloadIconId={snapshotDownloadIconId}
        tweetText={`https://twitter.com/intent/tweet?text=💜 Polygon NFT insights: Total NFT Collection%0A📆 for: ${timeWindow}%0A🎯 ${formatAmount(
          filteredTotalCount?.countValue
        )}
         %0A%0A🔗Check out the detailed chart on MNFST Tools by @MNFSTLabs here: ${twitterShareScrollPath} `}
      />
      <HorizontalDivider />
      <ChartWrapper>
        <LineAreaChart
          data={formattedChartData}
          series={formattedChartSeries}
          setValue={setVolumeHover}
          setLabel={setRightLabel}
          value={volumeHover}
          label={rightLabel}
          activeWindow={timeWindow}
          topLeft={
            <TopLeftComponent
              timeWindow={timeWindow}
              setTimeWindow={setTimeWindow}
              filteredCollectionValues={filteredTotalCount}
              isMobileView={isMobile}
            />
          }
          showXAxis={false}
          showYAxis={false}
          showCartesian={false}
          isTinyChart={true}
        />
      </ChartWrapper>
    </>
  );
}

export default TotalNFTCollectionsGraph;
