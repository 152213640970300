import axios from "axios";
import ContentCardCtr from "components/ContentCard";
import DataTable from "../MarketDataTable";
import { DropDownCard } from "components/DropDown";
import QuestionHelper from "components/QuestionHelper";
import Row from "components/Row";
import React, { useCallback, useEffect, useState, useRef } from "react";
import { AlertCircle, ArrowLeft, ArrowRight, Search } from "react-feather";
import styled from "styled-components";
import { TEXT } from "theme/texts";
import { useCurrencyUnit } from "store/user/hooks";
import SKLTCollectionsTable from "../SKLTCollectionsTable";
import MarketDataTable from "../MarketDataTable";
import { GenericImageWrapper, SnapshotBrandLogoImageWrapper } from "components/Logo";
import { isMobile } from "react-device-detect";
import useTakeScreenshot from "hooks/useTakeSnapshot";
import assets from "assets";

const TopChain = () => {
  //constants
  const SORT_DIR = { ASEC: "asc", DESC: "desc" };
  const SORT_BY = {
    VOL: "salesVolumeUSD",
    BUYER: "buyerCount",
    TRANSACTION: "transactionCount",
  };
  const { currency } = useCurrencyUnit();
  //states
  const [tableData, setTableData] = useState(null);
  const [chain, setChain] = useState("polygon");
  const [sortBy, setSortBy] = useState(null);
  const [sortDir, setSortDir] = useState(null);
  const [query, setQuery] = useState(""); //page query endpoint
  const [currentTime, setCurrentTime] = useState({
    active: 0,
    duration: "day",
  });
  const [supplyLimit, setSupplyLimit] = useState(0);
  const [category, setCategory] = useState({
    id: 0,
    name: "All",
  });

  const refCard = useRef(null);
  const snapshotDownloadIconId = `snapshot-${1}-download-icon`;
  const snapshotBrandLogoId = `snapshot-${1}-brand-logo`;

  const downloadScreenshot = useTakeScreenshot({
    ref: refCard,
    elementIdsTohide: [snapshotDownloadIconId],
    elementIdsToShow: [snapshotBrandLogoId],
  });

  //callbacks
  const fetchData = useCallback(async () => {

    let res = await axios.get(
      `${process.env.REACT_APP_API_URL}/topChain?chain=${chain}&duration=${currentTime.duration}`
    );
    const apiData = res.data.data;
    let sortedData;

    if (sortBy === "salesVolumeUSD" && sortDir === "asc") {
      sortedData = apiData.sort((a, b) => a.salesVolumeUSD + b.salesVolumeUSD);
    }
    if (sortBy === "salesVolumeUSD" && sortDir === "desc") {
      sortedData = apiData.sort((a, b) => a.salesVolumeUSD - b.salesVolumeUSD);
    }
    if (sortBy === "buyerCount" && sortDir === "asc") {
      sortedData = apiData.sort((a, b) => a.buyerCount + b.salesVolumeUSD);
    }
    if (sortBy === "buyerCount" && sortDir === "desc") {
      sortedData = apiData.sort((a, b) => a.buyerCount - b.salesVolumeUSD);
    }
    if (sortBy === "transactionCount" && sortDir === "asc") {
      sortedData = apiData.sort(
        (a, b) => a.transactionCount + b.salesVolumeUSD
      );
    }
    if (sortBy === "transactionCount" && sortDir === "desc") {
      sortedData = apiData.sort(
        (a, b) => a.transactionCount - b.salesVolumeUSD
      );
    } else {
      sortedData = apiData;
    }
   

    setTableData(sortedData);
  }, [currentTime, category, chain, query, sortBy, sortDir, supplyLimit]);
  const sortItem = ({ sort_by }) => {
    setSortBy(sort_by);
    setSortDir((prev) =>
      prev === SORT_DIR.ASEC ? SORT_DIR.DESC : SORT_DIR.ASEC
    );
  };
  const columns = [
    {
      id: 1,
      heading: "",
      value: "no",
    },

    {
      id: 2,
      heading: "Platforms",
      value: "blockchainId",
      link: "contractAddress",
      logo: "logoUrl",
    },
    {
      id: 3,
      heading: "Volume",
      value:
        currency?.symbol === "USD"
          ? "salesVolumeUSD"
          : currency?.symbol === "ETH"
          ? "salesVolumeUSD"
          : "salesVolumeUSD",
      action: () => {
        sortItem({ sort_by: SORT_BY.VOL });
      },
      sort_dir: sortDir,
      sort_item: SORT_BY.VOL,
    },

    {
      id: 4,
      heading: "VolumeChange",
      value: "salesVolumeChangePercentage",
    },

    {
      id: 5,
      heading: "Buyer",
      value: "buyerCount",
      action: () => {
        sortItem({ sort_by: SORT_BY.BUYER });
      },
      sort_dir: sortDir,
      sort_item: SORT_BY.BUYER,
    },
    {
      id: 7,
      heading: "Transaction",
      value: "transactionCount",
      action: () => {
        sortItem({ sort_by: SORT_BY.TRANSACTION });
      },
      sort_dir: sortDir,
      sort_item: SORT_BY.TRANSACTION,
    },
  ];


  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <MarketCollectionsTableCtr ref={refCard}>
      <ContentCardCtr hideHover={false}>
        <div className="_tableHeader">
          <TEXT.MediumHeader>
            <span>Top Chains </span>
            <QuestionHelper text="Top NFT Chains by Volume across ecosystem" />
          </TEXT.MediumHeader>
          <div className="_rightHeaderCtr">
            <div className="_timelines">
              <DropDownCard
                data={[
                  {
                    val: 0,
                    label: "1 day",
                    action: () => {
                      setCurrentTime({ active: 0, duration: "day" });
                      setChain(1);
                    },
                  },
                  {
                    val: 1,
                    label: "7 day",
                    action: () => {
                      setCurrentTime({ active: 1, duration: "week" });
                    },
                  },
                  {
                    val: 2,
                    label: "30 Day",
                    action: () => {
                      setCurrentTime({ active: 2, duration: "month" });
                    },
                  },
                ]}
                activeTab={currentTime.active}
              />
            </div>
            <GenericImageWrapper
              id={snapshotDownloadIconId}
              className="_cardDownloadIcon"
              onClick={() => downloadScreenshot()}
              src={assets.icons.roundedDownloadIcon}
              size="2rem"
            />
            <SnapshotBrandLogoImageWrapper
            id={snapshotBrandLogoId}
            src={assets.logos.surfaceboardXYZLogo}
            size="6rem"
            style={{ marginRight: isMobile ? "8px" : "10px" }}
          />
          </div>
        </div>
        {tableData?.length > 0 ? (
          <MarketDataTable
            column={columns}
            data={tableData}
            sortItemLabel={sortBy}
          />
        ) : (
          <SKLTCollectionsTable />
        )}
      </ContentCardCtr>
    </MarketCollectionsTableCtr>
  );
};

const MarketCollectionsTableCtr = styled.div`
  gap: 0;
  ._navs {
    ${(props) => props?.theme?.flexRowNoWrap}
    gap:.25rem;
    width: fit-content;
    margin: 1rem;
    margin-left: auto;
    button {
      background: none;
      outline: none;
      border: none;
      color: ${(props) => props?.theme?.body};
      font-family: var(--ff-subtitle);
      display: grid;
      place-items: center;
      padding: 0.5rem;
      font-size: var(--fs-s) !important;
    }

    ._staticNavs {
      width: 70px;
      border: 1px solid ${(props) => props?.theme?.disabledVar3};
      border-radius: 2rem;
    }

    ._tableNav {
      ${(props) => props?.theme?.flexRowNoWrap}
      gap:.25rem;
      align-items: center;
      justify-content: center;
      width: fit-content;
      border: 1px solid ${(props) => props?.theme?.disabledVar3};
      border-radius: 2rem;
      ._navPageBtn {
        min-width: 100px;
      }
      ._navBtn {
        &:nth-of-type(1) {
          border-right: 1px solid ${(props) => props?.theme?.disabled};
        }
        &:nth-of-type(3) {
          border-left: 1px solid ${(props) => props?.theme?.disabled};
        }
      }
    }

    //@media quries : mobile screens
    ${({ theme }) => theme.mediaWidth.upToLarge`
      margin:1rem auto;
      `}
  }

  ._tableHeader {
    padding: 1.25rem 1rem;
    ${(props) => props?.theme?.flexRowNoWrap}
    justify-content: space-between;
    align-items: center;
    ._rightHeaderCtr {
      display: flex;
      align-items: center;
      gap: 1rem;
    }
    ._tableTitle {
      font-family: var(--ff-title);
      font-size: var(--fs-l);
      display: flex;
      gap: 0.5rem;
    }
    ._searchResultsBar {
      ${(props) => props?.theme?.flexRowNoWrap}
      align-items: center;
      gap: 0.5rem;
      border: 1px solid ${(props) => props?.theme?.disabled};
      width: 200px;
      padding: 0 0.5rem;
      border-radius: 0.25rem;

      input {
        background: none;
        color: ${(props) => props?.theme?.body};
        border: none;
        outline: none;
        padding: 0.5rem;
        font-family: var(--ff-subtitle);
      }
    }

    //@media quries : mobile screens
    ${({ theme }) => theme.mediaWidth.upToExtraSmall`
        flex-direction:column;
        gap:1rem;
        align-items:flex-start;
        padding:.75rem 1rem;
        ._rightHeaderCtr{
            // flex-direction:column;
        width:fit-content;
        align-items:flex-start;
        margin-right:auto;
        }
      `}
  }
`;

export default TopChain;
