import assets from "assets";
import { SquareIconButton } from "components/Button";
import { AutoColumn } from "components/Column";
import { OutlinedLabel } from "components/DataCard";
import { CircledImageCtr, GenericImageWrapper } from "components/Logo";
import Row from "components/Row";
import { Copy, Globe } from "react-feather";
import styled from "styled-components";
import { colors } from "theme/colors";
import { TEXT } from "theme/texts";
import { copyTextToClipboard } from "utils/clipboardCopy";
import SocialLinks from "./components/SocialLinks";
import { useParams } from "react-router-dom";
import { useEffect } from "react";

//to generate ellipses in middle of address text
function trimAddress(str) {
  if (str.length > 12) {
    return str.substr(0, 5) + "..." + str.substr(str.length - 4, str.length);
  }
  return str;
}

const ProfileHeader = ({ profileData }) => {
  const { profileID } = useParams();

  useEffect(() => {
    console.log(profileData, profileID, "ssssssssssssss");
    if (
      profileID === "0x419e82d502f598ca63d821d3bbd8dfefaf9bbc8d" &&
      profileData
    ) {
      profileData.name = "Polygon Ape: The Evolution";
      profileData.twitter = "https://twitter.com/0xPolygonApe";
      profileData.website = "https://klekshun.com/";
      profileData.marketplace.opensea =
        "https://opensea.io/collection/polygonapetheevolution";
      profileData.marketplace.magiceden =
        "https://magiceden.io/collections/polygon/polygonapetheevolution";
      profileData.marketplace.rarible =
        "https://rarible.com/polygonapetheevolution";
    }
  });

  return (
    <ProfileHeaderCtr>
      <Row gap="1rem" margin={"1rem 0"} className="_leftCred">
        <CircledImageCtr radius="100%" size={"80px"}>
          <img
            src={profileData?.image || assets.icons.defaultCollectionIcon}
            alt=""
          />
        </CircledImageCtr>
        <AutoColumn>
          <Row>
            <p className="_cred">
              <TEXT.LargeHeader className="_profileName ">
                {profileData?.name}
              </TEXT.LargeHeader>
              <img src={assets.icons.verifyIcon} alt="" />
            </p>
          </Row>
          <Row gap=".5rem">
            {profileData?.contractAddress ? (
              <div
                onClick={() => {
                  copyTextToClipboard(profileData?.contractAddress);
                }}
              >
                <OutlinedLabel>
                  <TEXT.SmallBody style={{ cursor: "pointer" }}>
                    {profileData?.contractAddress?.length > 0 &&
                      trimAddress(profileData?.contractAddress)}
                  </TEXT.SmallBody>
                  <TEXT.Disabled style={{ cursor: "pointer" }}>
                    <Copy size="1rem" />
                  </TEXT.Disabled>
                </OutlinedLabel>
              </div>
            ) : null}
            {profileData?.erc_type && (
              <OutlinedLabel>
                <TEXT.SmallBody>
                  {profileData?.erc_type?.toUpperCase()}
                </TEXT.SmallBody>
              </OutlinedLabel>
            )}
            <OutlinedLabel>
              <CircledImageCtr size="18px">
                <img
                  onClick={() =>
                    window.open(
                      `https://polygonscan.com/address/${profileData?.contractAddress}`,
                      "_blank"
                    )
                  }
                  src={assets.logos.ethscanLogo}
                  alt=""
                />
              </CircledImageCtr>
            </OutlinedLabel>
            {/* <OutlinedLabel isSuccess={true}>
              <TEXT.SmallBody color={colors().success}>
                Profile Picture
              </TEXT.SmallBody>
            </OutlinedLabel> */}
          </Row>
          {profileData?.marketplace ? (
            <SocialLinks links={profileData?.marketplace} />
          ) : null}
        </AutoColumn>
      </Row>
      <Row
        gap="16px"
        margin={"2rem 0"}
        justify="flex-end"
        className="_socialRedirects"
      >
        {profileData?.discord ? (
          <SquareIconButton
            backgroundColor="#26282B"
            showBorder={false}
            onClick={() => window.open(`${profileData?.discord}`, "_blank")}
          >
            <GenericImageWrapper src={assets.logos.discordWhiteLogo} />
          </SquareIconButton>
        ) : null}
        {profileData?.twitter ? (
          <SquareIconButton
            backgroundColor="#26282B"
            showBorder={false}
            onClick={() =>
              profileData?.twitter?.includes("https://twitter.com/")
                ? window.open(`${profileData?.twitter}`, "_blank")
                : window.open(
                    `https://twitter.com/${profileData?.twitter}`,
                    "_blank"
                  )
            }
          >
            <GenericImageWrapper src={assets.logos.twitterWhiteLogo} />
          </SquareIconButton>
        ) : null}
        {profileData?.website ? (
          <SquareIconButton
            backgroundColor="#26282B"
            showBorder={false}
            onClick={() => window.open(`${profileData?.website}`, "_blank")}
          >
            <Globe size="1.25rem" color="#fff" />
          </SquareIconButton>
        ) : null}
      </Row>
    </ProfileHeaderCtr>
  );
};

const ProfileHeaderCtr = styled.div`
  display: flex;
  justify-content: space-between;
  position: relative;
  ._cred {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    position: relative;
  }
  ._leftCred {
    ._profileName {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 500px;
    }
    ._profileIcon {
      width: 100px;
      height: 100px;
      border-radius: 100%;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
      }
    }
    ${({ theme }) => theme.mediaWidth.upToMedium`
      flex-direction:column;
      justify-content:flex-start;
      align-items:flex-start;
      ._profileName{
           max-width: 300px;
      }
      `}
  }
  ._socialRedirects {
    position: absolute;
    top: 0;
    right: 0;
    width: fit-content;
  }
`;
export default ProfileHeader;
