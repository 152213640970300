import assets from "assets";
import { SmallOptionButton } from "components/Button";
import BarChart from "components/Chart/BarChart";
import LineChart from "components/Chart/LineChart";
import { AutoColumn } from "components/Column";
import { HorizontalDivider } from "components/Divider/inde";
import GraphTableTopHeader from "components/HeaderComponent/GraphTableTopHeader";
import { GenericImageWrapper } from "components/Logo";
import { TabularNumsText } from "components/shared";
import { useTotalNFTMint } from "data/nft/totalNFTMint";
import dayjs from "dayjs";
import useMatchBreakpoints from "hooks/useMatchBreakpoints";
import useTakeScreenshot from "hooks/useTakeSnapshot";
import useTheme from "hooks/useTheme";
import React, { useEffect, useMemo, useState } from "react";
import { TEXT } from "theme/texts";
import { GenericChartSeries, TimeWindow } from "types";
import getTimewindowFilteredData from "utils/chart";
import { formatAmount } from "utils/numbers";
import {
  appRoutes,
  getScrollToElementPath,
  marketOverviewPageElementIDs,
} from "utils/path";
import {
  ChartTopRightSectionWrapper,
  ChartWrapper,
  ListButtonWrapper,
} from "./styled";

import { useNFTMints } from "data/nft/nftmints";

const twitterShareScrollPath = getScrollToElementPath(
  appRoutes.marketOverview,
  marketOverviewPageElementIDs.nftMints
);
const snapshotDownloadIconId = "snapshot-nft-mints-download-icon";
const snapshotBrandLogoId = "snapshot-nft-mints-brand-logo";

const TopRightComponent = ({
  timeWindow,
  setTimeWindow,
  isMobileView,
}: {
  timeWindow: TimeWindow;
  setTimeWindow: React.Dispatch<React.SetStateAction<TimeWindow>>;
  isMobileView: boolean;
}) => {
  return (
    <ChartTopRightSectionWrapper>
      <ListButtonWrapper>
        {[
          TimeWindow.threeMonth,
          TimeWindow.sixMonth,
          TimeWindow.oneYear,
          TimeWindow.all,
        ].map((option, index) => {
          return (
            <SmallOptionButton
              key={index}
              active={timeWindow === option}
              onClick={() => setTimeWindow(option)}
            >
              {option === "custom" ? (
                <GenericImageWrapper
                  src={assets.icons.calendarIcon}
                  size={isMobileView ? "14px" : "16px"}
                />
              ) : (
                option
              )}
            </SmallOptionButton>
          );
        })}
      </ListButtonWrapper>
    </ChartTopRightSectionWrapper>
  );
};

const TopLeftComponent = ({ count }: { count: number }) => {
  return (
    <AutoColumn gap="4px">
      <TEXT.LargeHeader fontSize={"clamp(1.75rem,3vw,2.5rem)"}>
        <TabularNumsText>
          {`${formatAmount(count).toLocaleUpperCase()} Mints`}
        </TabularNumsText>
      </TEXT.LargeHeader>
    </AutoColumn>
  );
};

function TotalNFTMintsGraph({
  refNFTMintsGraph,
}: {
  refNFTMintsGraph: React.RefObject<HTMLElement>;
}) {
  const theme = useTheme();
  const [volumeHover, setVolumeHover] = useState<number | undefined>();
  const [liquidityHover, setLiquidityHover] = useState<number | undefined>();
  const [leftLabel, setLeftLabel] = useState<string | undefined>();
  const [rightLabel, setRightLabel] = useState<string | undefined>();
  const [timeWindow, setTimeWindow] = useState(TimeWindow.oneYear);
  // const totalNFTMints = useTotalNFTMint();
  // const reverseTotalNFTMints = [...(totalNFTMints.records ?? [])].reverse();
  const { isMobile } = useMatchBreakpoints();
  const [showBarGraph, setShowBarGraph] = useState(true);
  const { data: nftMints } = useNFTMints();
  let newNFTMints =
    nftMints?.records?.length > 0
      ? nftMints?.records.map((item: any) => {
          return {
            ...item,
            dateTime: item.date,
          };
        })
      : [];

  const reverseNFTMints = [...(nftMints?.records ?? [])].reverse();
  const downloadScreenshot = useTakeScreenshot({
    ref: refNFTMintsGraph,
    elementIdsTohide: [snapshotDownloadIconId],
    elementIdsToShow: [snapshotBrandLogoId],
  });

  useEffect(() => {
    setLiquidityHover(undefined);
    setVolumeHover(undefined);
  }, []);

  /**
   * @notice
   * @dev 'formattedVolumeData' function format data according to graph plot format
   * @dev 'getTransformedVolumeData' function filter chart data according to selected timewindow
   * @dev 'formattedChartSeries' change according to requirement
   * @param timewindow is selected option out of provided time options to user for filtering chart data
   * @param time will be for x-axis
   * @param value... must be same as @param datakey of config variable 'formattedChartSeries'
   */
  const formattedChartData = useMemo(() => {
    if (nftMints && nftMints.records) {
      return getTimewindowFilteredData(newNFTMints, timeWindow).map(
        (record) => {
          return {
            time: new Date(record.date).getTime(),
            valueNFTMint: record.daily_mints,
          };
        }
      );
    } else {
      return [];
    }
  }, [nftMints, timeWindow]);

  /**
   * @notice
   * @dev 'formattedChartSeries'  must change according to requirement
   * Add according requirement (This will plot line graph with provided color and data)
   */
  const formattedChartSeries: GenericChartSeries[] = [
    { dataKey: "valueNFTMint", name: "NFT Mints", color: theme.btnSecondary },
  ];

  const filteredTotalCount: number = useMemo(() => {
    if (reverseNFTMints.length === 0) {
      return 0;
    }
    switch (timeWindow) {
      case TimeWindow.threeMonth: {
        const beforeThreeMonthData = reverseNFTMints.find((element) =>
          dayjs(element.date).isBefore(dayjs().subtract(3, "month"), "month")
        );
        return (
          reverseNFTMints[0].cumulative_mints -
          (beforeThreeMonthData?.cumulative_mints ?? 0)
        );
      }
      case TimeWindow.sixMonth: {
        const beforeSixMonthData = reverseNFTMints.find((element) =>
          dayjs(new Date(element.date).getTime()).isBefore(
            dayjs().subtract(6, "month"),
            "month"
          )
        );
        return (
          reverseNFTMints[0].cumulative_mints -
          (beforeSixMonthData?.cumulative_mints ?? 0)
        );
      }
      case TimeWindow.oneYear: {
        const beforeOneYearData = reverseNFTMints.find((element) =>
          dayjs(new Date(element.date).getTime()).isBefore(
            dayjs().subtract(1, "year"),
            "month"
          )
        );
        return (
          reverseNFTMints[0].cumulative_mints -
          (beforeOneYearData?.cumulative_mints ?? 0)
        );
      }
      case TimeWindow.all:
        return reverseNFTMints[0].cumulative_mints;
      default:
        return reverseNFTMints[0].cumulative_mints;
    }
  }, [nftMints, timeWindow]);

  return (
    <>
      <GraphTableTopHeader
        title={"Daily NFT Mints"}
        tooltip="Number of Polygon NFTs minted"
        showBarGraph={showBarGraph}
        setShowBarGraph={setShowBarGraph}
        downloadScreenshot={downloadScreenshot}
        isMobileView={isMobile}
        snapshotBrandLogoId={snapshotBrandLogoId}
        snapshotDownloadIconId={snapshotDownloadIconId}
        tweetText={`https://twitter.com/intent/tweet?text=💜 Polygon NFT insights: Daily NFT Mints%0A📆 for: ${timeWindow}%0A🎯 ${formatAmount(
          filteredTotalCount
        )} %0A%0A🔗Check out the detailed chart on MNFST Tools by @MNFSTLabs here: ${twitterShareScrollPath} `}
      />
      <HorizontalDivider />
      {showBarGraph ? (
        <ChartWrapper>
          <BarChart
            data={formattedChartData}
            series={formattedChartSeries}
            yAxisLabel="NFT Mints"
            setValue={setVolumeHover}
            setLabel={setRightLabel}
            value={volumeHover}
            label={rightLabel}
            activeWindow={timeWindow}
            topRight={
              <TopRightComponent
                timeWindow={timeWindow}
                setTimeWindow={setTimeWindow}
                isMobileView={isMobile}
              />
            }
            topLeft={<TopLeftComponent count={filteredTotalCount} />}
          />
        </ChartWrapper>
      ) : (
        <ChartWrapper>
          <LineChart
            data={formattedChartData}
            series={formattedChartSeries}
            yAxisLabel="NFT Mints"
            setValue={setVolumeHover}
            setLabel={setRightLabel}
            value={volumeHover}
            label={rightLabel}
            activeWindow={timeWindow}
            topRight={
              <TopRightComponent
                timeWindow={timeWindow}
                setTimeWindow={setTimeWindow}
                isMobileView={isMobile}
              />
            }
            topLeft={<TopLeftComponent count={filteredTotalCount} />}
          />
        </ChartWrapper>
      )}
    </>
  );
}

export default TotalNFTMintsGraph;
