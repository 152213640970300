import axios from "axios";
import { useMemo, useState } from "react";

const getTotalMints = async () => {
    try {
        const { data } = await axios.get(
            process.env.REACT_APP_API_URL + "/marketOverview/nftMints?type=full"
        )
        return data;
    }
    catch (error) {
        return error;
    }
}

export function useNFTMints() {
    const [api, setAPIData] = useState<any>({
        loading: true,
        error: false,
        data: undefined
    });



    useMemo(async () => {
        const data = await getTotalMints();
        setAPIData({
            loading: false,
            error: false,
            data: data
        })
    }, [])
    return api;
}
