import assets from "assets";
import Column from "components/Column";
import ContentCardCtr from "components/ContentCard";
import { CircledImageCtr, GenericImageWrapper } from "components/Logo";
import QuestionHelper from "components/QuestionHelper";
import Row, { AutoRow } from "components/Row";
import React, { useState } from "react";
import styled from "styled-components";
import { colors } from "theme/colors";
import { TEXT } from "theme/texts";
import { formatAmount } from "utils/numbers";
import { useCurrencyUnit } from "store/user/hooks";
import SKLTTable from "components/Skeletons/common/SKLTTable";

const Entity = ({ data }) => {
  return (
    <Row
      style={{
        gap: "2rem",
        padding: ".5rem .75rem",
        borderRadius: ".25rem",
      }}
    >
      <Row gap=".5rem">
        <CircledImageCtr radius="100%" size={"30px"}>
          <img src={data?.icon} alt="" />
        </CircledImageCtr>
        <Column
          style={{ cursor: "pointer" }}
          onClick={() => window.open(data?.link, "_blank")}
        >
          <TEXT.Body>@{data?.user}</TEXT.Body>
          <TEXT.SmallBody>
            <TEXT.Disabled>{data?.time}</TEXT.Disabled>
          </TEXT.SmallBody>
        </Column>
      </Row>
      <Column style={{ alignItems: "flex-end", gap: ".15rem" }}>
        <TEXT.Body style={{ display: "flex", gap: ".25rem" }}>
          <CircledImageCtr size="15px">
            <img src={data?.logo} alt="" style={{ objectFit: "contain" }} />
          </CircledImageCtr>

          {data?.floor}
        </TEXT.Body>
        <TEXT.SmallBody>
          <TEXT.Disabled>{data?.floor_percent}% floor</TEXT.Disabled>
        </TEXT.SmallBody>
      </Column>
    </Row>
  );
};

const ListingsTable = ({ listings }) => {
  return (
    <Column style={{ width: "100%", gap: "1rem" }} className="_listingsGraph">
      <Column style={{ gap: "1rem" }} className="_listingCol">
        {listings.map((entity, idx) => (
          <Entity data={entity} key={idx} />
        ))}
      </Column>
    </Column>
  );
};
const SalesTable = ({ sales }) => {
  return (
    <Column style={{ width: "100%", gap: "1rem" }} className="_salesGraph">
      <Column style={{ gap: "1rem" }} className="_salesCol">
        {sales.map((entity, idx) => (
          <Entity data={entity} key={idx} />
        ))}
      </Column>
    </Column>
  );
};

const ListingAndSales = ({ salesData, ListingData }) => {
  const [showListingData, setShowListingData] = useState(
    ListingData?.length > 0 ? true : false
  );
  const showListingsTable = () => setShowListingData(true);
  const hideListingsTable = () => setShowListingData(false);
  const { currency } = useCurrencyUnit();
  const sales = salesData?.map((item, index) => {
    return {
      id: index + 1,
      icon: assets?.icons?.defaultCollectionIcon,
      user: item?.wallet?.slice(0, 5) + "..." + item?.wallet?.slice(-5),
      floor:
        currency?.symbol === "ETH"
          ? item?.price_in_eth?.toFixed(2)
          : currency?.symbol === "MATIC"
          ? item?.price_in_matic?.toFixed(2)
          : item?.price_in_usd?.toFixed(2),
      floor_percent:
        currency?.symbol === "ETH"
          ? item?.percentage_in_eth?.toFixed(2)
          : currency?.symbol === "MATIC"
          ? item?.percentage_in_matic?.toFixed(2)
          : item?.percentage_in_usd?.toFixed(2),
      chain: "polygon",
      logo: currency?.logo,
      time: new Date(item?.date)?.toLocaleDateString(),
      link: item?.link,
    };
  });
  const listings = ListingData?.map((item, index) => {
    return {
      id: index + 1,
      icon: assets?.icons?.defaultCollectionIcon,
      user: item?.wallet?.slice(0, 5) + "..." + item?.wallet?.slice(-5),
      floor:
        currency?.symbol === "ETH"
          ? item?.price_in_eth?.toFixed(2)
          : currency?.symbol === "MATIC"
          ? item?.price_in_matic?.toFixed(2)
          : item?.price_in_usd?.toFixed(2),
      floor_percent:
        currency?.symbol === "ETH"
          ? item?.percentage_in_eth?.toFixed(2)
          : currency?.symbol === "MATIC"
          ? item?.percentage_in_matic?.toFixed(2)
          : item?.percentage_in_usd?.toFixed(2),
      chain: "polygon",
      logo: currency?.logo,
      time: new Date(item?.date)?.toLocaleDateString(),
      link: item?.link,
    };
  });

  return (
    <ListingAndSalesCtr>
      <ContentCardCtr
        style={{
          height: "100%",
          padding: "0rem",
          gap: 0,
        }}
      >
        <Row
          alignItems={"center"}
          alignContent={"center"}
          justifyContent={"center"}
          className="_cardTitle"
        >
          <TEXT.MediumHeader>
            {(ListingData?.length > 0 && salesData?.length) > 0
              ? "Listing & Sales"
              : ListingData?.length > 0
              ? "Listing"
              : "Sales"}
          </TEXT.MediumHeader>
          <QuestionHelper
            text={"Most recent NFTs from the collection on sale and sold"}
            iconSize="24px"
          />
        </Row>
        {ListingData?.length === 0 || salesData?.length === 0 ? null : (
          <div className="_graphBtns">
            <button
              onClick={showListingsTable}
              disabled={ListingData?.length === 0}
              style={showListingData ? { background: colors().primary } : {}}
            >
              Listings
            </button>

            <button
              onClick={hideListingsTable}
              disabled={salesData?.length === 0}
              style={!showListingData ? { background: colors().primary } : {}}
            >
              Sales
            </button>
          </div>
        )}
        <div className="_tableHeadings">
          {ListingData?.length === 0 || salesData?.length === 0 ? null : (
            <>
              <div className="_heading">
                <TEXT.SmallHeader>Listings</TEXT.SmallHeader>
              </div>

              <div className="_heading">
                <TEXT.SmallHeader>Sales</TEXT.SmallHeader>
              </div>
            </>
          )}
        </div>
        <Row className="_listingAndSalesGraphCtr">
          {listings?.length > 0 ? (
            <ListingsTable listings={listings} />
          ) : listings?.length !== 0 ? (
            <SKLTTable rowCount={8} />
          ) : null}
          {sales?.length > 0 ? (
            <SalesTable sales={sales} />
          ) : sales?.length !== 0 ? (
            <SKLTTable rowCount={8} />
          ) : null}
        </Row>
        <Row gap="1rem" className="_mobGraphsCtr">
          {showListingData ? (
            <ListingsTable listings={listings} />
          ) : (
            <SalesTable sales={sales} />
          )}
        </Row>
      </ContentCardCtr>
    </ListingAndSalesCtr>
  );
};
const ListingAndSalesCtr = styled(Column)`
  max-height: 550px;
  ._tableHeadings {
    display: flex;
    border-bottom: 1px solid ${(props) => props.theme.disabled};
    justify-content: space-around;
    ._heading {
      flex: 1;
      padding: 1rem;
      &:nth-of-type(1) {
        border-right: 1px solid ${(props) => props.theme.disabled};
      }
    }
  }
  ._cardTitle {
    border-bottom: 1px solid ${(props) => props.theme.disabled};
    padding: 1rem;
  }
  ._listingAndSalesGraphCtr,
  ._mobGraphsCtr {
    padding: 1rem;
    overflow-y: auto;
    align-items: flex-start;
    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
      display: none;
    }
    /* Hide scrollbar for IE, Edge add Firefox */
    -ms-overflow-style: none;
    scrollbar-width: none; /* Firefox */
  }
  ._mobGraphsCtr {
    display: none;
  }
  ._listingCol,
  ._salesCol {
    height: 100%;
  }

  ._graphBtns {
    border-bottom: 1px solid ${(props) => props?.theme.disabled};
    width: 100%;
    gap: 1rem;
    display: none;
    padding: 0.5rem;
    button {
      border: none;
      outline: none;
      background: none;
      border-radius: 0.25rem;
      color: ${(props) => props?.theme.body};
      min-width: 100px;
      padding: 0.5rem 1rem;
      font-family: var(--ff-subtitle);
    }
  }
  //@media quries : mobile screens
  ${({ theme }) => theme.mediaWidth.upToSmall`
   ._tableHeadings{
    display:none;
   }
      ._listingAndSalesGraphCtr {
        display:none;

      }
      ._mobGraphsCtr{
        display:block;
      }
       ._graphBtns {
        display:block;
       }
    `}
`;

export default ListingAndSales;
