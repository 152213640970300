export interface Block {
  number: number
  timestamp: string
}

export enum TimeWindow {
  oneDay = "1D",
  threeMonth = "3M",
  sixMonth = "6M",
  oneYear = "1Y",
  twoYear = "2Y",
  all = "All",
  custom = "custom"
}

export enum TradeActionWindow {
  sale = "Sales",
  listing = "Listings",
  volume = 'Volume',
}

export enum ChainType {
  polygon = "Polygon",
  sol = "Sol",
}

export interface ChartDayData {
  qty?: number,
  date: number,
  volumeUSD: number,
  tvlUSD: number
}

export interface GenericChartEntry {
  time: string
  value: number
}

export interface GenericChartSeries {
  dataKey: string
  name: string
  color: string
}

export enum TransactionType {
  SWAP,
  MINT,
  BURN,
}

export type Transaction = {
  type: TransactionType
  hash: string
  timestamp: string
  sender: string
  token0Symbol: string
  token1Symbol: string
  token0Address: string
  token1Address: string
  amountUSD: number
  amountToken0: number
  amountToken1: number
}

/**
 * Formatted type for Candlestick charts
 */
export type PriceChartEntry = {
  time: number // unix timestamp
  open: number
  close: number
  high: number
  low: number
}
