import assets from "assets";
import axios from "axios";
import { PrimaryButton } from "components/Button";
import Column from "components/Column";
import { HideSmall } from "components/Hide";
import { StyledInput } from "components/Input/TextField";
import { CircledImageCtr } from "components/Logo";
import Row from "components/Row";
import React, { FormEvent, useRef, useState } from "react";
import { Twitter } from "react-feather";
import { toast } from "react-hot-toast";
import styled, { useTheme } from "styled-components";
import { TEXT } from "theme/texts";
import { useModal } from "connectkit";
import { useToGetUserInfo } from "store/user/hooks";

const SignupBanner = ({
  setPopup,
  showPopup,
}: {
  setPopup: () => React.Dispatch<React.SetStateAction<boolean>>;
  showPopup: boolean;
}) => {
  const { setOpen } = useModal();
  const { wallet } = useToGetUserInfo();
  let appException = localStorage.getItem("app") === "true" ? true : false;

  console.log(showPopup);
  const [email, setEmail] = useState<string>("");
  const formRef = useRef<HTMLFormElement>(null);
  const postData = async (email: any) => {
    try {
      const res = await axios.post(`${process.env.REACT_APP_API_URL}/early`, {
        email: email,
      });

      formRef?.current?.reset();
      toast.success("Email added to waitlist!");
    } catch (error) {
      console.log(error);
      toast.error("Email submission failed!");
    }
  };
  const onEmailSubmit = (e: FormEvent) => {
    e.preventDefault();
    postData(email);
  };
  return (
    <SignupBannerWrapper>
      <Row gap="1rem">
        <CircledImageCtr
          size="clamp(90px,15vw,180px)"
          style={{ transform: "rotate(-20deg)" }}
        >
          <img src={assets.images.genesis_pass} alt="" />
        </CircledImageCtr>
        <Column style={{ gap: ".5rem" }}>
          <TEXT.MediumHeader fontSize={"clamp(1rem, 3vw, 1.75rem)"}>
            Sign up to grab your
          </TEXT.MediumHeader>
          <TEXT.LargeHeader
            fontSize={"clamp(1.25rem, 3vw, 2rem)"}
            className="_gradientText"
          >
            Season 0 Genesis Pass
          </TEXT.LargeHeader>
        </Column>
      </Row>
      <Column
        style={{
          gap: "1rem",
          alignItems: "flex-end",
          justifyContent: "center",
        }}
      >
        {/* <form
          style={{ width: "100%", maxWidth: "400px" }}
          ref={formRef}
          onSubmit={onEmailSubmit}
        >
          <Row gap=".5rem">
            <StyledInput
              type="email"
              placeholder="Add email address here"
              style={{
                background: `${useTheme().bgFooter}`,
                borderRadius: ".5rem",
                padding: ".5rem 1rem",
                border: `1px solid ${useTheme().disabledVar3}`,
                fontSize: "clamp(.7rem,2vw,1rem)",
              }}
              required
              onChange={(e) => setEmail(e.target.value)}
            /> */}
        <PrimaryButton
          // onClick={() => {
          //   !wallet && !appException ? setOpen(true) : setPopup();
          // }}
          style={{
            background:
              "linear-gradient(63.38deg, #8E7DD2 5.47%, #2C3284 77.8%, #181B4B 124.16%) !important;",
            width: "250px",
            borderRadius: ".5rem",
            padding: ".5rem",
          }}
        >
          <TEXT.SmallHeader fontSize={"clamp(.7rem,2vw,1rem)"}>
            Sign up for Waitlist
          </TEXT.SmallHeader>
        </PrimaryButton>
        {/* </Row>
        </form> */}
        <HideSmall>
          <Row gap=".5rem" width={"fit-content"}>
            <TEXT.Body>Drop a Follow on</TEXT.Body>
            <a
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                color: "#fff",
                gap: ".25rem",
              }}
              href="https://twitter.com/LayerEhq"
              target="_blank"
            >
              <Twitter />
              <TEXT.SmallHeader>LayerEhq</TEXT.SmallHeader>
            </a>
          </Row>
        </HideSmall>
      </Column>
    </SignupBannerWrapper>
  );
};

const SignupBannerWrapper = styled(Row)`
  height: 140px;
  padding: 1rem;
  gap: 1rem;
  overflow: hidden;
  justify-content: space-between;
  background: url(${assets.images.bgSignupBanner}) no-repeat,
    linear-gradient(180deg, #020202 0%, #1c1d2b 100%);
  box-shadow: 0px 3.5px 8.75px rgba(0, 0, 0, 0.3);
  background-size: cover;
  ._gradientText {
    background: linear-gradient(
      62.79deg,
      #a726c1 5.57%,
      #803bdf 80.18%,
      #7b3fe4 90.36%
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-fill-color: transparent;
  }

  ${({ theme }) => theme.mediaWidth.upToSmall`
    flex-direction:column;
    height:initial;
  `}
`;
export default SignupBanner;
