import Row, { RowBetween } from "components/Row";
import styled from "styled-components";

export const ChartWrapper = styled.div`
  width: 100%;
  overflow: visible;
`;


export const ListButtonWrapper = styled(Row) <{
  withoutDivider?: boolean;
  active?: boolean;
}>`
  width: fit-content;
  z-index: 10 !important;
  background-color: ${({ theme }) => theme.bgTop};
  border: 0px solid ${({ theme }) => theme.borderToggleBtn};
  border-radius: ${({ theme }) => theme.borderRadius.button};
  & button:first-child {
    border-radius: ${({ theme, withoutDivider }) =>
    withoutDivider
      ? theme.borderRadius.button
      : `${theme.borderRadius.button} 0px 0px ${theme.borderRadius.button}`};
  }
  & button {
    border: 1px solid
      ${({ theme, withoutDivider }) =>
    withoutDivider ? "transparent" : theme.borderToggleBtn};
    border-radius: ${({ theme, withoutDivider }) =>
    withoutDivider ? theme.borderRadius.button : "0px"};
 

    
  }
  & button:last-child {
    /* border-right: 0px solid ${({ theme }) => theme.border}; */
    border-radius: ${({ theme, withoutDivider }) =>
    withoutDivider
      ? theme.borderRadius.button
      : ` 0px  ${theme.borderRadius.button} ${theme.borderRadius.button} 0px`};
  }
`;

export const ChartTopRightSectionWrapper = styled(Row)`
  width: fit-content;
  display: flex;
  flex-wrap: nowrap;
  gap: 12px;
  ${({ theme }) => theme.mediaWidth.upToSmall`
      justify-content:start;
      flex-wrap: wrap;
      flex-direction:row-reverse;
  `}
`;

export const ResponsiveRowReverseByDesktop = styled(RowBetween)`
  flex-direction: row;
  ${({ theme }) => theme.mediaWidth.upToLarge`
    flex-direction: column-reverse;
    row-gap: 1rem;
  `};
`;
