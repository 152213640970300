import assets from "assets";
import { SecondaryButton } from "components/Button";

import styled from "styled-components";

const CarouselCard = ({ card }) => {
  console.log(card);
  return (
    <CarouselCardWrapper>
    <div className="wrapper">
      <div className="leftSide">
        <div className="cardimg3 ">
          <img src={card?.img}></img>
        </div>
      </div>

      <div className="rightSide">
        <div className="carouselContent">
          <div className="header">
            <img src={card?.logo}></img>
            {card?.name}
          </div>
          <div className="dis">{card?.description}</div>
          <a className="btn" href={card.buttonlink}>
            <SecondaryButton borderRadius={"0px"}>
              {card?.buttontext}
              <svg
                width="13"
                height="13"
                viewBox="0 0 13 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.625 5.1875V2.375H7.8125"
                  // stroke="white"
                  stroke-width="1.6875"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M7.25 5.75L10.625 2.375"
                  // stroke="white"
                  stroke-width="1.6875"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M9.125 7.25V10.25C9.125 10.3495 9.08549 10.4448 9.01517 10.5152C8.94484 10.5855 8.84946 10.625 8.75 10.625H2.75C2.65054 10.625 2.55516 10.5855 2.48484 10.5152C2.41451 10.4448 2.375 10.3495 2.375 10.25V4.25C2.375 4.15054 2.41451 4.05516 2.48484 3.98484C2.55516 3.91451 2.65054 3.875 2.75 3.875H5.75"
                  // stroke="white"
                  stroke-width="1.6875"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </SecondaryButton>
          </a>
        </div>
      </div>
      </div>
    </CarouselCardWrapper>
  );
};
const CarouselCardWrapper = styled.div`
background: #000;
.wrapper{
  display: flex;
  width: 100%;
  height: 300px;
  background-image: url(${assets.images.bgline2});
  // background: linear-gradient(6.78deg, rgba(82, 87, 163, 0.1) -43.06%, rgba(130, 71, 229, 0.063) 38.36%, rgba(0, 0, 0, 0) 130.63%), #0E0E0E;
text-align:center;
  overflow: hidden;

}
 

  .leftSide {
    height: 100%;
    position: relative;

    flex: 2;
    .cardimg3 {
      /* padding: 25px 32px; */
       position: absolute;
       bottom: 0;
       left: 0;
      max-width: 100%;
      height: 100%;
      img{
      border-radius: 32px;
      width: 100%;

      }
    }
    .c1 {
      left: -40px;
      bottom: 0;
      rotate: -30deg;
    }
    .c2 {
      left: 150px;
      bottom: 0;
      rotate: 40deg;
    }
    .c3 {
      left: 300px;
      bottom: 0;
      rotate: -40deg;
    }

    img {
      height: 100%;
      width: 100%;
    }
  }
  .rightSide {
    display: flex;
    justify-content: center;
    align-items: center;
    flex: 3;
  }
  .carouselContent {
    max-width: 420px;
    z-index: 10;

    .header {
      font-size: 48px;
      font-weight: 700;
      line-height: 63px;
      gap: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: 30px;
      img {
        width: 64px;
        height: 64px;
        border-radius: 50%;
      }
    }
    .dis {
      font-size: 16px;
      font-weight: 400;
      line-height: 19px;
      margin-bottom: 38px;
    }
    .btn {
      margin: 0 auto;
      text-decoration: none;
      button {
        width: max-content;
        margin: 0 auto;
        // background: rgba(101, 106, 144, 0.1);
// border: 1px solid #363636;
// border-radius: 12px;
      }
    }
  }

  @media only screen and (max-width: 700px) {
    .wrapper{
      flex-direction: column;
    width: 100%;
    height: auto;
    background: transparent;
    border: none;
    position: relative;
    height: 330px;  


    }

    
    .leftSide {
      position: static;
      flex: 2;
      .cardimg3 {
        position: absolute;
        width: 70px;
        height: 70px;
        display: none;
        // margin: 0 auto;
      }
      .c1 {
        left: -10px;
        top: 0;
        rotate: -30deg;
      }
      .c2 {
        left: -10px;
        bottom: 0;
        rotate: 40deg;
      }
      .c3 {
        left: auto;
        right: -10px;
        bottom: 0;
        rotate: -40deg;
      }
    }
  
   
    .carouselContent {
      text-align: center;
      margin-top: 60px;
      margin-bottom: 40px;
      .header {
        font-size: 24px;
        line-height: 31px;
        gap: 20px;

        margin-bottom: 30px;
        img {
          width: 44px;
          height: 44px;
          border-radius: 50%;
        }
      }
      .dis {
        font-size: 12px;
        font-weight: 400;
        line-height: 14px;
        margin-bottom: 38px;
        max-width: 85%;
        margin: 0 auto;
      }
      .btn {
        margin: 0 auto;

        max-width: max-content;
        button {
          margin-top: 30px;
        }
      }
    }
  }
`;
export default CarouselCard;
