import axios from "axios";
import { useMemo, useState } from "react";

const getVolumeAndSales = async () => {
    try {
        const { data } = await axios.get(
            'https://api.dune.com/api/v1/query/2693130/results?api_key=drSMqiiI6vuKDYF9dKbzGq2HAc7xkh4a'
        )
        return data;
    }
    catch (error) {
        return error;
    }
}

export function useNFTMarketPlace() {
    const [api, setAPIData] = useState<any>({
        loading: true,
        error: false,
        data: undefined
    });


    useMemo(async () => {
        const data = await getVolumeAndSales();
        setAPIData({
            loading: false,
            error: false,
            data: data?.result?.rows?.length > 0 ? data.result.rows?.map((item: any) => {
                return {
                    dateTime: new Date(item?.sale_date).getTime(),
                    project: item?.order_source,
                    salesCount: item?.total_txs,
                    volumeUSD: item?.total_amount_usd,
                    volumeInMatic: item?.total_amount_matic,
                    volumeInWETH: item?.total_amount_weth,
                }
            }) : []
        })
    }, [])
    return api;
}
