import React from "react";
import { Navigate, Outlet,useLocation } from "react-router-dom";
import { useToGetUserInfo } from "store/user/hooks";


function YootsProtectedRoutes() {
  const { JWT } = useToGetUserInfo();
  return JWT ? <Outlet /> : <Navigate to="/yoots/login" />;
}

function AppProtectedRoutes() {
  const { wallet, holdsNFT } = useToGetUserInfo();
  // store the pathname from the page is redirected from 
  const { pathname,search } = useLocation();
  let appException = localStorage.getItem('app') === 'true' ? true : false;
  console.log('appException',appException);
  if(search.includes('app=true')){
    localStorage.setItem('app', 'true');
  }
  // return (wallet && holdsNFT) || appException ? <Outlet /> : <Navigate to={`/?redirect=${pathname}`}  />;
  return  <Outlet />;

}
const ProtectedRoutes = {
  AppProtectedRoutes,
  YootsProtectedRoutes,
};
export default ProtectedRoutes;
