import { Suspense, useEffect, useState, useLayoutEffect } from "react";
import React, { Route, Routes, useLocation } from "react-router-dom";
import styled from "styled-components";
import { TEXT } from "theme/texts";
import { ENDPOINT_CLIENT } from "../apollo/endpoints";
import { DarkGreyCard } from "../components/Card";
import { AnimatedLoader } from "../components/Loader";
import { ExternalLink } from "../theme/components";
import CollectionsPage from "./CollectionsPage";
import MarketOverview from "./MarketOverview";
import Layout from "./Layout";
import Profile from "./Profile";
import LandingPage from "./LandingPage";
import DropsPage from "./DropsPage";
import DropsDetailsPage from "./DropsDetailsPage";
import NewLoader from "components/Loader/NewLoader";
import { SkeletonTheme } from "react-loading-skeleton";
import YootsDashboard from "./YootsDashboard";
import ProtectedRoutes from "components/ProtectedRoutes";
import YootsLogin from "./YootsDashboard/Login";
import RedditCollectionsPage from "./CollectionsPage/redditPage";
import RedditGen3CollectionsPage from "./CollectionsPage/redditGenPage";
import LeaderBoard from "./LeaderBoard";

const AppWrapper = styled.div`
  display: flex;
  flex-flow: column;
  align-items: center;
  overflow-x: hidden;
  min-height: 100vh;
  width: 100%;
`;

const HeaderWrapper = styled.div`
  ${({ theme }) => theme.flexColumnNoWrap}
  width: 100%;
  position: fixed;
  justify-content: space-between;
  z-index: 2;
`;

const BodyWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  flex: 1;
  overflow-y: auto;
  overflow-x: hidden;
  z-index: 1;
  // > * {
  //   max-width: 1200px;
  // }
  @media (max-width: 1080px) {
    /*padding-top: 2rem;
     margin-top: 140px;  why to have this margin top /Padding ??? @shaswa7  */
  }
`;

const Marginer = styled.div`
  margin-top: 5rem;
`;

const WarningWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;

const WarningBanner = styled.div`
  background-color: ${({ theme }) => theme.bgBase};
  padding: 1rem;
  color: ${({ theme }) => theme.white};
  font-size: 14px;
  width: 100%;
  text-align: center;
  font-weight: 500;
`;

// const BLOCK_DIFFERENCE_THRESHOLD = 30;

export default function App() {
  // load buffer
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => setLoading(false), 1300);
  }, []);
  const showNotSyncedWarning = true;
  // subgraphStatus.headBlock && subgraphStatus.syncedBlock && activeNetwork === OptimismNetworkInfo
  //   ? subgraphStatus.headBlock - subgraphStatus.syncedBlock > BLOCK_DIFFERENCE_THRESHOLD
  //   : false
  return (
    <SkeletonTheme baseColor="#202020" highlightColor="#444">
      <Suspense fallback={null}>
        {loading ? (
          <NewLoader />
        ) : (
          <Layout>
            <AppWrapper>
              {/* <URLWarning /> */}
              {/* <HeaderWrapper>
            {showNotSyncedWarning && (
              <WarningWrapper>
                <WarningBanner>
                    {`Warning:  */}
              {/* Data has only synced to  block ${subgraphStatus.syncedBlock} (out of ${subgraphStatus.headBlock}). Please check back soon.`}
                </WarningBanner>
              </WarningWrapper>
            )}
            </HeaderWrapper> */}
              {false ? ( //"subgraphStatus.available" === "false"
                <AppWrapper>
                  <BodyWrapper>
                    <DarkGreyCard style={{ maxWidth: "340px" }}>
                      <TEXT.MediumHeader>
                        The Graph hosted network which provides data for this
                        site is temporarily experiencing issues. Check current
                        status{" "}
                        <ExternalLink href={ENDPOINT_CLIENT}>
                          here.
                        </ExternalLink>
                      </TEXT.MediumHeader>
                    </DarkGreyCard>
                  </BodyWrapper>
                </AppWrapper>
              ) : (
                <BodyWrapper>
                  {/* <Popups /> */}
                  <Routes>
                    {/* <Route exact strict path="/:networkID?/pools/:address" component={PoolPage} />
                <Route exact strict path="/:networkID?/pools" component={PoolsOverview} />
                <Route exact strict path="/:networkID?/tokens/:address" component={RedirectInvalidToken} />
                <Route exact strict path="/:networkID?/tokens" component={TokensOverview} /> */}
                    <Route caseSensitive path="/" element={<LandingPage />} />

                    <Route element={<ProtectedRoutes.AppProtectedRoutes />}>
                      <Route
                        caseSensitive
                        path="/collections"
                        element={<CollectionsPage />}
                      />
                      <Route
                       caseSensitive
                       path="/reddit/collections"
                       element={
                        <RedditCollectionsPage/>
                       }
                       />
                       <Route
                       caseSensitive
                       path="/reddit/gen3/collections"
                       element={
                        <RedditGen3CollectionsPage/>
                       }
                       />
                      <Route
                        caseSensitive
                        path="/collections/:profileID"
                        element={<Profile />}
                      />
                      <Route
                        caseSensitive
                        path="/drops"
                        element={<DropsPage />}
                      />
                        <Route
                        caseSensitive
                        path="/rankings"
                        element={<LeaderBoard />}
                      />
                      <Route
                        caseSensitive
                        path="/drops/:id"
                        element={<DropsDetailsPage />}
                      />

                      <Route
                        path="/market-overview"
                        element={<MarketOverview />}
                      />
                    </Route>
                    {/* <Route
                      path="yoots/*"
                      element={
                        <Routes>
                          <Route path="/login" element={<YootsLogin />} />
                          <Route
                            element={<ProtectedRoutes.YootsProtectedRoutes />}
                          >
                            <Route path="/" element={<YootsDashboard />} />
                          </Route>
                        </Routes>
                      }
                    /> */}
                  </Routes>
                </BodyWrapper>
              )}
            </AppWrapper>
          </Layout>
        )}
      </Suspense>
    </SkeletonTheme>
  );
}
