import Column from "components/Column";
import ContentCardCtr from "components/ContentCard";
import QuestionHelper from "components/QuestionHelper";
import Row from "components/Row";
import SKLTTable from "components/Skeletons/common/SKLTTable";
import styled from "styled-components";
import { TEXT } from "theme/texts";
import OwnersTable from "./OwnersTable";

const TopOwners = ({ data }) => {
  const owners = data?.map((owner, index) => {
    return {
      id: index + 1,
      name: owner?.ens,
      wallet: owner?.wallet,
      owned: owner?.amount,
      owned_percent: owner?.percentage?.toFixed(2),
    };
  });

  const columns = [
    {
      heading: "",
      value: "id",
    },
    {
      heading: "Name",
      value: "name",
    },
    {
      heading: "Wallet",
      value: "wallet",
    },
    {
      heading: "Owned",
      value: "owned",
    },
    {
      heading: "% Owned",
      value: "owned_percent",
    },
  ];

  return (
    <TopOwnersCtr>
      <ContentCardCtr
        className="_ownersList"
        style={{
          height: "100%",
          padding: "0rem",
          borderRadius: 0,
          gap: 0,
        }}
      >
        <Row
          alignItems={"center"}
          alignContent={"center"}
          justifyContent={"center"}
          className="_cardTitle"
        >
          <TEXT.MediumHeader>Top owners</TEXT.MediumHeader>
          <QuestionHelper
            text={"Wallets holding NFTs from the collection"}
            iconSize="24px"
          />
        </Row>
        {owners?.length > 0 ? (
          <OwnersTable column={columns} data={owners} />
        ) : (
          <SKLTTable rowCount={8} />
        )}
      </ContentCardCtr>
    </TopOwnersCtr>
  );
};

const TopOwnersCtr = styled(Column)`
  width: 100%;
  max-height: 550px;
  ._cardTitle {
    padding: 1rem;
  }
  ._ownersList {
    border-radius: 0.5rem;
  }
`;
export default TopOwners;
