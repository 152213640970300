import { CircledImageCtr, GenericImageWrapper } from "components/Logo";
import React from "react";
import styled from "styled-components";
import { TEXT } from "theme/texts";
import { useNavigate } from "react-router-dom";
import ResponsiveListTile, { ListTileWrapper } from "components/ListTile";
import Column from "components/Column";
import assets from "assets";

export type searchItem = {
  contractAddress: string;
  name: string;
  iconLink: string;
  items: number;
  onClick?: () => void;
};

const SearchHistoryUI = ({
  list,
  onRemoveClick,
}: {
  list: searchItem[];
  onRemoveClick: (id: string) => void;
}) => {
  return (
    <HoverWrapper>
    <ListTileWrapper
    >
      {list?.map((item) => (
        <RenderResultItem
          key={item?.contractAddress}
          item={item}
          onRemoveClick={onRemoveClick}
        />
      ))}
    </ListTileWrapper>
    </HoverWrapper>
  );
};

const RenderResultItem = ({
  item,
  onRemoveClick,
}: {
  item: searchItem;
  onRemoveClick: (id: string) => void;
}) => {
  const navigate = useNavigate();
  return (
    <ResponsiveListTile
      onClick={() => {
        item.onClick && item.onClick();
        navigate("/collections/" + item?.contractAddress);
      }}
      prefixIcon={item?.iconLink}
      suffixChild={
        <GenericImageWrapper
          style={{ zIndex: "120px" }}
          src={assets.icons.closeIcon}
          size="12px"
          onClick={(event) => {
            event.stopPropagation();
            onRemoveClick(item.contractAddress);
          }}
        />
      }
    >
      <Column>
        <TEXT.Body>{item?.name}</TEXT.Body>
        <TEXT.SubBody>{item?.contractAddress?.slice(0,6) + "..." + item?.contractAddress?.slice(-4)}</TEXT.SubBody>
      </Column>
    </ResponsiveListTile>
  );
};

const HoverWrapper = styled.div`
&:hover {
  background: ${({ theme }) => theme.activeRadialGradient};
  border-radius: 0px;
}
`;

export default SearchHistoryUI;
