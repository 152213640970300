import { LoadingRows } from "components/Loader";
import { RowBetween, AutoRow } from "components/Row";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import useTheme from "hooks/useTheme";
import {
  Bar,
  BarChart,
  CartesianGrid,
  Label,
  Legend,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { ChartGlobalProps } from "types/chart";
import { getRelativeTimeWindowName, getTickFormattedData } from "utils/date";
import { formatAmount } from "utils/numbers";
import {
  ChartCustomTooltip,
  ChartRenderLegend,
  CustomCursor,
  StyledChartFillGradient,
} from "../components";
import { CHART_DEFAULT_HEIGHT, ChartSubWrapper } from "../styled";
import { HideSmall } from "components/Hide";
import useMatchBreakpoints from "hooks/useMatchBreakpoints";
dayjs.extend(utc);

const CustomBar = ({
  x,
  y,
  width,
  height,
  stroke,
  fill,
}: {
  x: number;
  y: number;
  width: number;
  height: number;
  stroke: string;
  fill: string;
}) => {
  return (
    <g>
      <rect
        x={x}
        y={y}
        fill={fill}
        width={width}
        height={height}
        stroke={stroke}
        type="monotone"
        strokeWidth={0.1}
      />
    </g>
  );
};

const Chart = ({
  data,
  series,
  yAxisLabel,
  value,
  label,
  setValue,
  setLabel,
  activeWindow,
  topLeft,
  topRight,
  bottomLeft,
  bottomRight,
  showXAxis = true,
  showYAxis = true,
  showLegend = false,
  showCartesian = true,
  height = CHART_DEFAULT_HEIGHT,
  minHeight = CHART_DEFAULT_HEIGHT,
  isTinyChart = false,
  ...rest
}: ChartGlobalProps) => {
  const theme = useTheme();
  const parsedValue = value;
  const { isMobile } = useMatchBreakpoints();
  const now = dayjs();

  return (
    <ChartSubWrapper minHeight={minHeight} height={height} {...rest}>
      <AutoRow
        alignItems={"flex-start"}
        justify={"space-between"}
        gap="6px"
        padding={isMobile ? "2.2% 2.6% 8% 3.6%" : "24px"}
      >
        {topLeft ?? null}
        {topRight ?? null}
      </AutoRow>
      {data?.length === 0 ? (
        <LoadingRows>
          <div />
          <div />
          <div />
        </LoadingRows>
      ) : (
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            data={data}
            margin={{
              top: 6,
              right: isTinyChart ? 0 : isMobile ? 12 : 34,
              left: isTinyChart ? 0 : isMobile ? 12 : 48,
              bottom: isTinyChart ? 0 : isMobile ? 24 : showLegend ? 40 : 64,
            }}
            onMouseLeave={() => {
              setLabel && setLabel(undefined);
              setValue && setValue(undefined);
            }}
          >
            <XAxis
              tick={{ fill: theme.graphAxisElements, strokeWidth: 0 }}
              hide={!showXAxis}
              dataKey="time"
              axisLine={true && !isMobile}
              tickLine={false}
              tickFormatter={(time) =>
                typeof time === "string" ? time :
                dayjs(time).format(getTickFormattedData(activeWindow))
              }
              minTickGap={isMobile ? 10 : 48}
              scale="auto"
              tickMargin={8}
              fontSize={12}
              padding={{ left: 2, right: isTinyChart ? 0 : 20 }}
            >
              {isMobile ? (
                <></>
              ) : (
                <Label
                  fontSize={12}
                  fontWeight={400}
                  fill={theme.body}
                  value={`${getRelativeTimeWindowName(activeWindow)}`}
                  offset={20}
                  position="bottom"
                />
              )}
            </XAxis>

            <YAxis
              tick={{ fill: theme.graphAxisElements, strokeWidth: 0 }}
              fontSize={12}
              width={isMobile ? 60 : 68}
              type="number"
              // dataKey="value"
              // dataKey={(v) => {
              // }}
              hide={!showYAxis}
              axisLine={true && !isMobile}
              tickLine={false}
              tickFormatter={(value) =>
                `${formatAmount(value, 2).toUpperCase()}`
              }
              minTickGap={10}
              tickCount={8}
              tickMargin={8}
            >
              {isMobile ? (
                <></>
              ) : (
                <Label
                  fontSize={12}
                  fontWeight={400}
                  fill={theme.body}
                  value={yAxisLabel}
                  angle={-90}
                  offset={16}
                  position={{ x: -16, y: 112 }}
                  textAnchor="middle"
                />
              )}
            </YAxis>

            <Tooltip
              cursor={<CustomCursor />}
              wrapperStyle={undefined}
              contentStyle={undefined}
              itemStyle={undefined}
              labelStyle={undefined}
              content={({ active, payload, label }) => {
                return (
                  <ChartCustomTooltip
                    active={active}
                    payload={payload}
                    label={label}
                  />
                );
              }}
            />

            {/* Note: set reverse data list to show first data on top of the Stack graph. */}
            {series.reverse().map((s, index) => (
              <Bar
                key={index}
                dataKey={s.dataKey}
                stroke={s.color}
                name={s.name}
                fill={s.color}
                stackId="a"
                shape={(props) => {
                  return (
                    <CustomBar
                      key={index}
                      height={props.height}
                      width={props.width}
                      x={props.x}
                      y={props.y}
                      stroke={s.color}
                      fill={`url(#barGD${s.dataKey})`}
                    />
                  );
                }}
              />
            ))}
            {/* Note: set reverse data list to show first data on top of the Stack graph. */}
            {[...series].reverse().map((s, index) => {
              return (
                <defs key={index}>
                  <StyledChartFillGradient
                    id={`barGD${s.dataKey}`}
                    color={s.color}
                  />
                </defs>
              );
            })}

            {showLegend ? (
              <Legend
                content={({ payload }) => (
                  <ChartRenderLegend payload={payload} isMobile={isMobile} />
                )}
              />
            ) : (
              <></>
            )}
            {/* <CartesianAxis hide={!showCartesian} x={10} y={10} height={10} width={10} stroke=''
              axisLine={true} tickLine={true} minTickGap={10} /> */}

            {showCartesian ? (
              <CartesianGrid
                vertOriginX={10}
                horizontal={true}
                vertical={false}
                pointsAtX={"1"}
                strokeDasharray="8"
                stroke={theme.graphAxisElements}
              />
            ) : (
              <></>
            )}
          </BarChart>
        </ResponsiveContainer>
      )}
      <RowBetween>
        {bottomLeft ?? null}
        {bottomRight ?? null}
      </RowBetween>
    </ChartSubWrapper>
  );
};

export default Chart;
