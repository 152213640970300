import Marquee from "components/Marquee";
import { PageWrapper } from "pages/styled";
import { useEffect, useState } from "react";
import styled from "styled-components";
import CarouselCard from "./components/CarouselCard.jsx";
import Carousel from "better-react-carousel";
import DropNav from "./components/DropNav";
import DropsCard from "./components/DropsCard";
import { useNFTSold } from "data/nft/nftSold";
import dayjs from "dayjs";

import { useCollectionMintedToday } from "data/nft/collectionMintedToday";
import { useTopMarketPlace } from "data/nft/topMarketplace";
import { SecondaryButton } from "components/Button";
// import { dropsList } from "./data/index.js";
import axios from "axios";
import Loader, { AnimatedLoader } from "components/Loader";
import DropPageNav from "./components/PageNav.jsx";
import NewLoader from "components/Loader/NewLoader.jsx";
import assets from "assets/index";
import CarouselCard2 from "./components/CarouselCard.jsx/CarouselCard2.jsx";
import CarouselCard3 from "./components/CarouselCard.jsx/CarouselCard3.jsx";
import { useLocation, useNavigate } from "react-router-dom";
import CarouselCard4 from "./components/CarouselCard.jsx/CarouselCard4.jsx";
import Airtable from "airtable";
const { Client } = require("@notionhq/client");

var base = new Airtable({ apiKey: process.env.REACT_APP_AIRTABLE_API }).base(
  "appwOj9xvqGwXPYzV"
);

const DropsPage = () => {
  const [itemIdx, setItemIdx] = useState(1);
  const [notiondata, setNotiondata] = useState([]);
  const { state } = useLocation();
  const navigate = useNavigate();
  const [dropsList, setdropsList] = useState([]);

  const bannerData = [
    {
      name: "OWLPHA",
      img: assets.images.go,
      logo: "https://i.seadn.io/gcs/files/48d0c6fe91b0432cb80d03f32673c842.jpg?auto=format&dpr=1&w=256",
      buttontext: "Explore Collection",
      buttonlink: "https://polygon.magiceden.io/collections/polygon/owlpha",
      description:
        "Owlpha is a first NFT collection that is designed to provide its holders with high level analytical and social long- term value.",
    },
    {
      name: "Upcoming Drops",
      img: "https://miro.medium.com/max/500/1*DTr2PViqGHMCMyRCqACAwA.png",
      description: "Discover new upcoming collections on Polygon",
      cards: [
        {
          Image: assets.images.unft1,
          imgM: assets.images.unftm1,

          description:
            "5555 Actor Pandas that rigged, animated and ready to use in any platform as the actors and bring revenue to those who hold and mint them.",
          title: "PolygonBoyz",
          buttonlink: "https://magiceden.io/drops/polygonboyz",
          buttontext: "More Details",
        },
        {
          Image: assets.images.unft2,
          imgM: assets.images.unftm2,

          description:
            "5555 Actor Pandas that rigged, animated and ready to use in any platform as the actors and bring revenue to those who hold and mint them.",
          title: "Meta Toy City",
          buttonlink: "https://magiceden.io/drops/meta_toy_city_genesis_heroz",
          buttontext: "More Details",
        },
        {
          Image: assets.images.unft3,
          imgM: assets.images.unftm3,
          description:
            "5555 Actor Pandas that rigged, animated and ready to use in any platform as the actors and bring revenue to those who hold and mint them.",
          title: "Normies",
          buttonlink: "https://magiceden.io/drops/normies",
          buttontext: "More Details",
        },
      ],
    },
    {
      name: "Get Listed ",
      img: assets.images.tool,

      buttontext: "Submit your Project",
      buttonlink:
        "https://docs.google.com/forms/d/e/1FAIpQLSf0vbhgn_0BFMTK1Vpr5sR-OHzhUbQztfIKyx95lOAJFshuxA/viewform",
      description:
        "Feature your collection across the dashboard and explore co-marketing initiatives.",
    },
  ];

  const AirtableDropsFetch = async () => {
    base("Projects")
      .select({
        // Selecting the first 3 records in Grid view:
        maxRecords: 100,
        view: "Grid view",
      })
      .eachPage(
        function page(records, fetchNextPage) {
          // This function (`page`) will get called for each page of records.
          setdropsList([]);

          records.forEach(function (record) {
            const dropobj = {
              tittle: record.get("Collection Name"),
              description: record.get("Description"),
              name: record.get("Collection Name"),
              mintDate: record.get("Minting Date"),
              siteHref: record.get("Minting Link"),
              twitterHref: record.get("Twitter_URL"),
              discordHref: record.get("Discord_URL"),
              imageSrc: record.get("Logo Image URL"),
            };
            setdropsList((prevState) => [...prevState, dropobj]);
          });

          // fetchNextPage();
        },
        function done(err) {
          if (err) {
            console.error(err);
            return;
          }
        }
      );
  };
  const [load, setLoad] = useState(true);

  const magicedenlrops = async () => {
    setLoad(true);
    try {
      setdropsList([]);
      // `https://api-mainnet.magiceden.io/launchpad_collections?edge_cache=true`

      const ressss = await axios.get(
        `https://api-mainnet.magiceden.io/launchpad_collections?edge_cache=true`
      );
      console.log(ressss);

      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/drop/magiceden`
      );

      const json = await res?.data
        ?.filter((f) => f?.evm?.chainId === 137)
        .map((item) => {
          return {
            id: item?._id,
            tittle: item.symbol,
            description: item.description,
            name: item.name,
            mintDate: item?.launchDate,
            siteHref: item?.websiteLink,
            twitterHref: item?.twitterLink,
            discordHref: item?.discordLink,
            imageSrc: item.image,
            contractAddress: item?.evm?.contractAddress,
          };
        });

      // setdropsList((prevState) => [...prevState, ...json]);
      setdropsList(json);
      setLoad(false);
    } catch (err) {
      console.log(err);
      setLoad(false);
    } finally {
      setLoad(false);
    }
  };
  function datesAreEqualWithoutTime(date1, date2) {
    const sameYear = date1.getFullYear() === date2.getFullYear();
    const sameMonth = date1.getMonth() === date2.getMonth();
    const sameDay = date1.getDate() === date2.getDate();

    return sameYear && sameMonth && sameDay;
  }

  function formatDate(dateString) {
    const dateObj = new Date(dateString);
    const options = { day: "numeric", month: "short", year: "numeric" };
    return dateObj.toLocaleDateString("en-US", options);
  }

  const sortedArray = dropsList?.sort(
    (a, b) => new Date(a.mintDate) - new Date(b.mintDate)
  );
  const groupedData = [];
  const todaysMint = [];
  const reversedArray = groupedData.reverse();
  sortedArray.forEach((item) => {
    const dayjsFun = dayjs();
    const date = formatDate(item.mintDate);
    const enddate = formatDate(item.mintEnd);
    const givenDate = new Date(date);
    const endDates = new Date(enddate);
    const today = new Date();
    const tenDayGap = dayjsFun.subtract(10, "day");
    const convetedEndDates = new Date(tenDayGap.$d);
    // if (convetedEndDates > today) {
    if (itemIdx === 1) {
      if (datesAreEqualWithoutTime(today, givenDate)) {
        todaysMint.push(item);
      } else if (givenDate >= convetedEndDates && givenDate <= today) {
        // if (!groupedData[date]) {
        //   groupedData[date] = [];
        // }

        if (!groupedData[date]) {
          groupedData[date] = [];
        }
        groupedData[date].push(item);
      }
    } else if (itemIdx === 2) {
      if (givenDate > today) {
        if (!groupedData[date]) {
          groupedData[date] = [];
        }
        groupedData[date].push(item);
      }
    }


  });

  const sortedGroupedData = Object.keys(groupedData)
    .sort((a, b) => new Date(b) - new Date(a))
    .reduce((acc, key) => ({ ...acc, [key]: groupedData[key] }), {});
    const [first, setfirst] = useState(1)
  useEffect(() => {
    if(first<= 3){
      setfirst(first+1);
    }
    if (state) {
      setItemIdx(state?.tabIndex === 1 ? 1 : 2);
    }
    // AirtableDropsFetch();
    magicedenlrops();
  }, [state,first]);
  return (
    <>
      {load ? (
        <div className="loader">
          <NewLoader />
        </div>
      ) : (
        <div className="" style={{ width: "100%" }}>
          <div className="marqee" style={{ width: "100%" }}>
            <DropsPageWrapper>
              <DropPageCtr2>
                <div className="inner">
                  <Carousel
                    cols={1}
                    rows={1}
                    showDots={true}
                    autoplay={5000}
                    loop={true}
                    dotColorActive={"#14EEEC"}
                    dotColorInactive={"#2D2F31"}
                    // hideArrow={true}
                  >
                    <Carousel.Item>
                      <CarouselCard card={bannerData[0]} />
                    </Carousel.Item>
                    <Carousel.Item>
                      <CarouselCard3 card={bannerData[2]} />
                    </Carousel.Item>
                  </Carousel>
                </div>
              </DropPageCtr2>

              <DropPageCtr>
                <DropPageNav setItemIdx={setItemIdx} currentItemIdx={itemIdx} />

                <a
                  href="https://docs.google.com/forms/d/e/1FAIpQLSf0vbhgn_0BFMTK1Vpr5sR-OHzhUbQztfIKyx95lOAJFshuxA/viewform"
                  target="_blank"
                  className="mobilButton"
                >
                  <SecondaryButton borderRadius={"0px"}>
                    Submit your Project
                  </SecondaryButton>
                </a>

                {itemIdx === 2 ? (
                  Object.entries(groupedData).map(([date, items]) => (
                    <DropSection key={date}>
                      <h2>{date}</h2>
                      <div className="div">
                        {items.map((data, id) => (
                          <DropsCard
                            key={id}
                            cardData={data}
                            ongoingMint={false}
                          />
                        ))}
                      </div>
                    </DropSection>
                  ))
                ) : (
                  <>
                    {Object.keys(todaysMint).length === 0 ? null : (
                      <DropSection>
                        <h2>Minting Today</h2>

                        <div className="div">
                          {todaysMint?.map((data, id) => (
                            <DropsCard
                              key={id}
                              cardData={data}
                              ongoingMint={true}
                            />
                          ))}
                        </div>
                      </DropSection>
                    )}
                    <h2> Ongoing Mints</h2>

                    {Object.entries(sortedGroupedData).map(([date, items]) => (
                      <DropSection key={date}>
                        <h2>{date}</h2>
                        <div className="div">
                          {items.reverse().map((data, id) => (
                            <DropsCard
                              key={id}
                              cardData={data}
                              ongoingMint={true}
                            />
                          ))}
                        </div>
                      </DropSection>
                    ))}
                  </>
                )}
              </DropPageCtr>
            </DropsPageWrapper>
          </div>
        </div>
      )}
    </>
  );
};

const DropSection = styled.div`
  margin-bottom: 50px;
  width: 100%;
  h2 {
    font-size: 24px;
    font-weight: 500;
    line-height: 130%;
    margin-bottom: 30px;
  }
  .div {
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    /* grid-template-rows: 1fr 1fr; */
    gap: 24px 24px;
  }

  @media only screen and (max-width: 700px) {
    margin-bottom: 30px;

    h2 {
      font-size: 24px;
      font-weight: 500;
      line-height: 130%;
      margin-bottom: 10px;
    }

    .div {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr;
      gap: 10px 10px;
    }
  }
`;
const DropsPageWrapper = styled.div`
  width: 100%;
  .marqee {
    width: 100%;
  }
  width: 100%;
  button.rec-dot {
    border: 0.5px solid #615971;
    filter: drop-shadow(0px 4px 9px rgba(0, 0, 0, 0.4));
  }

  button.rec-dot:hover,
  button.rec-dot:active,
  button.rec-dot:focus {
    /* box-shadow: 0 0 1px 3px rgba(235,16,16,0.5); */
  }

  width: 100%;

  .mobilButton {
    display: none;
    width: max-content;
    margin-left: auto;
    margin-bottom: 30px;
    text-decoration: none;
    ${({ theme }) => theme.mediaWidth.upToMedium`
       display: block;
    margin-left: 0px;
    // margin-top: 25px;
    }
  `}
  }

  @media only screen and (max-width: 700px) {
    .mobilButton {
      display: block;
      margin-left: 0px;
      /* margin-top: 50px; */
    }
  }
`;

const DropPageCtr = styled(PageWrapper)`
  h2 {
    font-size: 24px;
    font-weight: 500;
    line-height: 130%;
    margin-bottom: 30px;
  }
  ${(props) => props?.theme?.flexColumnNoWrap}
  .dluTbn {
    border: 1px solid #2d2f31;
  }

  .bBfHpH {
    background: #616161 !important;
  }
  .dZkckO {
    background: #616161 !important;
  }
  .Carousel__Rail-sc-hyhecw-2 {
    transition: all 0.7s ease-out;
  }

  @media screen and (max-width: 767px) {
    padding: 0 20px;
    margin-bottom: 20px;
    .cSNndH {
      /* display: flex; */
    }

    .fpKkgP {
      padding: 0px;
      grid-template-columns: repeat(3, 100%);
      border: 1px solid #2d2f31;
    }

    .gZzsDe {
      /* display: flex !important; */
      bottom: -20px;
    }
  }
`;
const DropPageCtr2 = styled(PageWrapper)`
  .inner {
    padding: 30px 15px;
    background: var(
      --gradient,
      linear-gradient(
        180deg,
        rgba(20, 238, 236, 0.7) 0%,
        rgba(45, 47, 49, 0) 100%
      )
    );
  }

  h2 {
    font-size: 24px;
    font-weight: 500;
    line-height: 130%;
    margin-bottom: 30px;
  }
  ${(props) => props?.theme?.flexColumnNoWrap}
  .dluTbn {
    border: 1px solid #2d2f31;
  }

  .bBfHpH {
    background: #616161 !important;
  }
  .dZkckO {
    background: #616161 !important;
  }
  .Carousel__Rail-sc-hyhecw-2 {
    transition: all 0.7s ease-out;
  }
  .gZzsDe {
    /* display: flex !important; */
    bottom: -20px;
  }

  @media screen and (max-width: 767px) {
    padding: 0 20px;
    margin-bottom: 20px;
    .cSNndH {
      /* display: flex; */
    }
    .inner {
      padding: 10px 10px;
      background: var(
        --gradient,
        linear-gradient(
          180deg,
          rgba(20, 238, 236, 0.7) 0%,
          rgba(45, 47, 49, 0) 100%
        )
      );
    }
    .gYgxKc {
      grid-template-columns: repeat(2, 100%) !important;
    }
    .dluTbn {
      background-color: black;
    }

    .fpKkgP {
      padding: 0px;
      grid-template-columns: repeat(3, 100%);
      border: 1px solid #2d2f31;
    }

    .gZzsDe {
      /* display: flex !important; */
      bottom: -20px;
    }
  }
`;

export default DropsPage;
