import { SecondaryButton } from "components/Button";
import assets from "assets";
import styled from "styled-components";

const CarouselCard3 = ({ card }) => {
  console.log(card);
  return (
    <CarouselCardWrapper3>
    <div className="wrar">
      <div className="leftSide">
        <div className="header">{card?.name}</div>
        <div className="dis">{card?.description}</div>
        <a className="btn" href={card.buttonlink} target="_blank">
          <SecondaryButton borderRadius={"0px"}>
            {card?.buttontext}
           
          </SecondaryButton>
        </a>
      </div>

      <div className="rightSide">
        <div className="circle">
          <img  className="pc" src={assets.images.circle} alt="" />
          <img className="mobile" src={assets.images.fullcircle} alt="" />

        </div>

        <div className="img">
          <img className="" src={card?.img} alt="" />
        </div>
      </div>
      </div>
    </CarouselCardWrapper3>
  );
};
const CarouselCardWrapper3 = styled.div`
background-color: #000;
 
  .wrar{
    padding: 60px 40px;
  display: flex;
  /* width: 100%; */
  height: 300px;
  background-image: url(${assets.images.bgline2});


  }

  overflow: hidden;
.mobile{
  display: none;
}
.pc{
  display: block;
}
  .leftSide {
    // flex: 1;
    max-width: 330px;

    .btn {
      margin: 0 auto;
      text-decoration: none;
      button {
        /* width: max-content; */
        margin: 0 auto;
        margin-top: 30px;

        /* background: #1b1b21; */
        /* border: 1px solid #363636; */
        /* border-radius: 4px; */
      }
    }
    /* height: 100%; */
    position: relative;

    // flex: 1;
    
    .header {
      font-style: normal;
      font-weight: 700;
      font-size: 32px;
      line-height: 39px;
      gap: 20px;
      margin-bottom: 18px;
    }
    .dis {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
    }

    img {
      height: 100%;
      width: 100%;
    }
  }
  position: relative;

  .rightSide {
    width: 100%;
    display: flex;
    justify-content: end;
    .circle {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      img{
        
      }
      
    }
    /* flex: 3; */
  }
  .img {
    position: relative;
    z-index: 10;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      max-width: 400px;
      height: fit-content;
    }
  }
  .carouselContent {
    display: flex;
    justify-content: center;

    .header {
      font-weight: 700;
      font-size: 16px;
      line-height: 20px;
      gap: 20px;
      display: flex;
      /* justify-content: center; */
      /* align-items: center; */
      /* margin-bottom: 30px; */
      img {
        width: 64px;
        height: 64px;
        border-radius: 50%;
      }
    }
    .dis {
      font-weight: 400;
      font-size: 12px;
      line-height: 15px;
      /* margin-bottom: 38px; */
    }
    .btn {
      margin: 0 auto;
      text-decoration: none;
      button {
        width: max-content;
        margin: 0 auto;
        background: rgba(101, 106, 144, 0.1);
        border: 1px solid #363636;
        border-radius: 12px;
      }
    }
  }

  @media only screen and (max-width: 700px) {
    .mobile{
      display: block;

    }
    .pc{
      display: none;

    }
    
    .wrar{
      flex-direction: column;
    width: 100%;
    height: 330px;  
    border: none;
    position: relative;

    }
    
    .leftSide {
      position: static;
      flex: 2;
      text-align: center;

      .header {
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
      line-height: 26px;
      gap: 20px;
      margin-bottom: 18px;
    }
    .dis {
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 13px;
    }
     
    }

    .img {
    position: relative;
    z-index: 10;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      margin-top: 30px;
      max-width: 140px;
      height: fit-content;
    }
  }
  .rightSide {
    width: 100%;
    display: flex;
    justify-content: end;
    .circle {
      position: absolute;
      top: auto;
      bottom: -150px;
      left: 0;
      display: flex;
      justify-content: center;
      width: 100%;
    
      img{
        width: 300px;
      }
    }
    /* flex: 3; */
  }
    .carouselContent {
      text-align: center;
      margin-top: 60px;
      margin-bottom: 40px;
      .header {
        font-size: 24px;
        line-height: 31px;
        gap: 20px;

        margin-bottom: 30px;
        img {
          width: 44px;
          height: 44px;
          border-radius: 50%;
        }

      }
      .dis {
        font-size: 12px;
        font-weight: 400;
        line-height: 14px;
        margin-bottom: 38px;
        max-width: 85%;
        margin: 0 auto;
      }
      .btn {
        margin: 0 auto;

        max-width: max-content;
        button {
          margin-top: 30px;
        }
      }
    }
  }
`;
export default CarouselCard3;
