import React from "react";
import styled from "styled-components";

const PageNav = ({ setItemIdx, currentItemIdx }) => {
  return (
    <PageNavCtr>
      <button onClick={() => setItemIdx(1)}>
        <span>Overall Stats</span>
        <p className={currentItemIdx === 1 ? "_activeTab _tab" : "_tab"}></p>
      </button>
      <button onClick={() => setItemIdx(2)}>
        <span>Trading Data</span>
        <p className={currentItemIdx === 2 ? "_activeTab _tab" : "_tab"}></p>
      </button>
      <button onClick={() => setItemIdx(3)}>
        <span>Wallet Data</span>
        <p className={currentItemIdx === 3 ? "_activeTab _tab" : "_tab"}></p>
      </button>
    </PageNavCtr>
  );
};

const PageNavCtr = styled.div`
  border-bottom: 1px solid ${(props) => props?.theme?.disabled};
  display: flex;
  gap: 1rem;
  ._tab {
    width: 100%;
    padding: 0.1rem;
    position: absolute;
    bottom:-15px;
    left: 0;
 
  }
  ._activeTab {
    background: #14EEEC;
  }
  button {
    background: none;
    border: none;
    outline: none;
    display: block;
    padding: .75rem;
    color: ${(props) => props.theme.body};
    font-family: var(--ff-title);
    position: relative;
  }
`;
export default PageNav;
