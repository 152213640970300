import React,{useEffect} from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

const CollectionSimpleCard = ({ card }) => {
  const navigate = useNavigate();
  

  

  return (
    <CardWrapper onClick={() => navigate(card?.link)}>
      <div className="imageSectionn">
        <img src={card?.image} alt="" />
      </div>
      <div className="content">
        <div className="topContent">
          <img src={card?.image} alt="" />

          <div className="nftDetaails">
            <p className="name">{card?.title}</p>
            {/* <p className="dis">24th volume</p> */}
          </div>
        </div>
      </div>
    </CardWrapper>
  );
};
const CardWrapper = styled.div`
  cursor: pointer;
  width: 100%;
  ${({ theme }) => theme.mediaWidth.upToMedium`
  height: 255px;
  min-width: 200px;
  max-width: 200px;



`}

  height: 350px;
  min-width: 280px;
  max-width: 280px;

  background: #082328;

  border: 1px solid #2d2f31;
  box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1),
    0px 1px 2px -1px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  .imageSectionn {
    ${({ theme }) => theme.mediaWidth.upToMedium`
    width: 100%;
    height: 200px;
  
  `}
    width: 100%;
    height: 280px;
    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }
  .content {
    padding: 25px;
    padding-top: 20px;
    ${({ theme }) => theme.mediaWidth.upToMedium`

    padding: 14px;
    padding-top:9px;
`}
  }
  .linkblock {
    text-decoration: none;

    font-weight: 400;
    font-size: 13px;
    line-height: 20px;
    color: #8247e5;
    cursor: pointer;
    ${({ theme }) => theme.mediaWidth.upToMedium`
    font-size: 8px;
    margin-top:5px;

    `}
  }
  .topContent {
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 15px;
    margin-bottom: 8px;
    ${({ theme }) => theme.mediaWidth.upToMedium`
    margin-bottom:0;
    gap: 8px;


    `}
    img {
      border-radius: 50%;
      height: 32px;
      width: 32px;
      ${({ theme }) => theme.mediaWidth.upToMedium`
      width:22px;
      height:22px;




`}
    }
    .nftDetaails {
      p {
        margin-block-start: 0;
        margin-block-end: 0;
      }
      .name {
        font-weight: 600;
        font-size: 14px;
        line-height: 15px;
        margin-bottom: 5px;
        ${({ theme }) => theme.mediaWidth.upToMedium`
        font-size: 10px;
        line-height: 11px;


  
  `}
      }
      .dis {
        font-weight: 400;
        font-size: 10px;
        line-height: 11px;
        ${({ theme }) => theme.mediaWidth.upToMedium`
        font-size: 8px;
  
  `}
      }
    }
  }
`;

export default CollectionSimpleCard;
