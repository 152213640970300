import assets from "assets";
import { AutoColumn } from "components/Column";
import { HorizontalDivider } from "components/Divider/inde";
import Row, { RowFixed } from "components/Row";
import useMatchBreakpoints from "hooks/useMatchBreakpoints";
import { useOnClickOutside } from "hooks/useOnClickOutside";
import useTheme from "hooks/useTheme";
import { useRef, useState } from "react";
import { TEXT } from "theme/texts";
import {
  Container,
  ContentWrapper,
  FlyOut,
  LogoWrapper,
  MenuWrapper,
  StyledDropdownIcon,
  StyledLabel,
  StyledSelectedMainOption,
  VerticalDivider,
  Wrapper,
} from "./styled";

type dropdownSubOptions = {
  id: number;
  name: string;
  value: string;
  checked: boolean;
};

export type checkboxDropdownOptions = {
  id: number;
  name: string;
  icon: string;
  selected: boolean;
  subOptions: dropdownSubOptions[];
};

//dropdownName, dropdownIcon, selectedOption, options,
export default function BasicCheckboxDropdown({
  data,
  setMainOption,
  setOptions,
}: {
  data: checkboxDropdownOptions[];
  setMainOption: (mainId: number) => void;
  setOptions: (mainId: number, subId: number, checked: boolean) => void;
}) {
  const theme = useTheme();
  const { isMobile } = useMatchBreakpoints();
  const [showMenu, setShowMenu] = useState(false);
  const node = useRef<HTMLDivElement>(null);
  useOnClickOutside(node, () => setShowMenu(false));

  const selectedItem: checkboxDropdownOptions =
    data.find((element) => element.selected) ?? data[0];

  return (
    <Container
      ref={node}
      style={{
        position: "relative",
        zIndex: "auto",
      }}
    >
      <Wrapper>
        <RowFixed>
          <TEXT.SmallBody
            pl={10}
            fontSize={isMobile ? "11px" : "14px"}
            style={{ whiteSpace: "nowrap", minWidth: "100px" }}
          >
            {selectedItem?.name}
          </TEXT.SmallBody>
          <VerticalDivider />
          <StyledDropdownIcon
            src={assets.icons.filledDownArrowIcon}
            onClick={() => setShowMenu(!showMenu)}
          />
        </RowFixed>
      </Wrapper>
      {showMenu && (
        <FlyOut>
          <AutoColumn>
            {/* <RowFixed>
              <LogoWrapper src={dropdownTitleIcon ?? assets.icons.ecosystemIcon} />
              <TEXT.SmallBody
                color={theme.header}
                fontWeight={600}
                fontSize={12}
                pl={2}
              >
                {dropdownTitle}
              </TEXT.SmallBody>
            </RowFixed> */}

            {data.map((mainMenu, index) => {
              return (
                <MenuWrapper id={mainMenu.id.toString()}>
                  <StyledSelectedMainOption
                    selected={mainMenu.selected}
                    onClick={() => {
                      setMainOption(mainMenu.id);
                    }}
                  >
                    <RowFixed>
                      <LogoWrapper src={mainMenu.icon} />
                      <TEXT.SmallBody
                        color={theme.header}
                        fontWeight={600}
                        fontSize={12}
                        pl={2}
                      >
                        {mainMenu.name}
                      </TEXT.SmallBody>
                    </RowFixed>
                  </StyledSelectedMainOption>
                  {mainMenu.subOptions.length !== 0 ? (
                    <ContentWrapper key={mainMenu.id}>
                      {mainMenu.subOptions.map((element: any) => {
                        return (
                          <Row
                            key={element.id}
                            onClick={() => {
                              // setShowMenu(false)
                            }}
                          >
                            <StyledLabel>
                              <input
                                style={{ accentColor: "", marginRight: "8px" }} // set accentColor to change checked checkbox color
                                type="checkbox"
                                checked={element.checked && mainMenu.selected}
                                disabled={!mainMenu.selected}
                                onChange={() =>
                                  setOptions(
                                    mainMenu.id,
                                    element.id,
                                    !element.checked
                                  )
                                }
                              />
                              <TEXT.SubBody color={theme.white}>
                                {element.name}
                              </TEXT.SubBody>
                            </StyledLabel>
                          </Row>
                        );
                      })}
                    </ContentWrapper>
                  ) : (
                    <></>
                  )}
                  {index === data.length - 1 ? (
                    <></>
                  ) : (
                    <HorizontalDivider topSpace="8px" bottomSpace="8px" />
                  )}
                </MenuWrapper>
              );
            })}
          </AutoColumn>
        </FlyOut>
      )}
    </Container>
  );
}
