import React, { useEffect, useState } from "react";
import { ChevronDown } from "react-feather";
import styled from "styled-components";
import { TEXT } from "theme/texts";

// Create an interface that describes your object
type DD_dataObj = {
  val: number;
  label: string;
  action?: () => any;
};
interface Props {
  data: DD_dataObj[];
  activeTab: number;
}

export const DropDownCard: React.FC<Props> = ({ data, activeTab }) => {
  const [showDDList, setShowDDList] = useState<boolean>(false);
  const _showDDList = () => setShowDDList((prev) => !showDDList);
  return (
    <DropdownCtr>
      <div className="_ddSelector" onClick={_showDDList}>
        <div className="_ddLabel">
          <TEXT.SmallBody style={{color:"rgba(20, 238, 236, 1)"}}>
            {data.filter((_, idx) => idx === activeTab)[0]?.label}
          </TEXT.SmallBody>
        </div>

        <div className="_spacer"></div>
        <div className="_ddIcon">
          <ChevronDown size={"1rem"} />
        </div>
      </div>
      {showDDList && (
        <div className="_ddList">
          {data?.map((ddObj, idx) => (
            <div
              className="_ddItem"
              key={idx}
              onClick={
                ddObj?.action
                  ? () => {
                      setShowDDList(false);
                      ddObj?.action?.();
                    }
                  : () => {}
              }
            >
              <p>{ddObj.label}</p>
            </div>
          ))}
        </div>
      )}
    </DropdownCtr>
  );
};

const DropdownCtr = styled.div`
  cursor: pointer;
  position: relative;
  ._ddSelector {
    display: flex;
    border: 1px solid ${(props) => props.theme.btnSecondary};
    color: ${(props) => props.theme.btnSecondary};
    min-width: 50px;
    border-radius: .2rem;
    ._ddLabel {
      padding: 0.35rem 0.75rem;
      
    }
    ._spacer {
      padding: 0.5px;
      background: ${(props) => props.theme.disabledVar2};
    }
    ._ddIcon {
      display: grid;
      place-items: center;
      padding: 0.25rem;
    }
  }
  ._ddList {
    min-width: 150px;
    
    border: 1px solid ${(props) => props.theme.disabledVar2};
    border-radius: 0.5rem;
    background: ${(props) => props.theme.bgModal};
    position: absolute;
    margin-top: 0.5rem;
    z-index: 100;
    overflow: hidden;
    ._ddItem {
      border-radius: 0.25rem;
      padding: 0.5px 0.5rem;
      &:hover {
        background: ${(props) => props.theme.disabledVar3};
      }
    }
  }
`;
