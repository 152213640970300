import assets from "assets";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";

const BrandLogo = ({ hideNavOnLoc,footer }) => {
  const navigate = useNavigate();
  return (
    <BrandLogoCtr
      onClick={() => {
        navigate("/");
        hideNavOnLoc();
      }}
    >
    {
      footer ?
      <div className="fLofo">
   
      <BrandLogoImgNew src={ assets.logos.layerELogo } alt="brand-logo" />
      </div>



      :
      <BrandLogoImg src={  assets.logos.layerELogo} alt="brand-logo" />


    }
    
    </BrandLogoCtr>
  );
};
const BrandLogoCtr = styled.div`
  display: grid;
  place-items: center;
  padding: 0.1rem;
  /* padding: 50px 30px; */

  width: clamp(150px, 10vw, 200px);
  cursor: pointer;

  .fLofo{
    display: flex;
    gap: 30px;
    justify-content: start;
    align-items: center;
  }
  .line{
    min-width: 1px;
    height: 80px;
    background-color: #fff;
  }
`;
const BrandLogoImg = styled.img`
  max-width: 75%;
  /* max-height: 80%; */
  object-fit: contain;
`;
const BrandLogoImgF = styled.img`
  max-width: 50%;
  /* max-height: 80%; */
  object-fit: contain;
`;
const BrandLogoImgNew = styled.img`
  max-width: 100%;
  /* max-height: 80%; */
  object-fit: contain;
`;
export default BrandLogo;
