import assets from "assets";
import React from "react";
import styled from "styled-components";

const CarouselCardHome3 = () => {
  return (
    <CarouselCardWrapper3>
      <div className="cer">
      <div className="dd">
      
      </div>
      </div>
      <div className="leftSide pc">
        <div className="imgBoxLeft">
          <img src={assets.images.gropcard} className="pc" alt="" />
        </div>
      </div>
      <div className="center">
        <div className="text pc">Verify your role on Discord </div>
        <div className="span pc">
          <span>Grab a PurpleDegen Role</span>
        </div>
        <div className="tt mobil">Verify your role on Discord <br></br>on Discord & Grab a <br/> PurpleDegen Role </div>

      </div>
      <div className="rightSide ">
        <div className="imgBoxright">
        <div className="fl">
          <div className="bigCIrcle">
        <div className="fl">

            <div className="smallCircle">
              <a href="https://discord.com/invite/ZCngbtb8ry" target="_blank"  className="jiscordbtn">
                <img src={assets.images.discordi} alt="" />
                <div className="dtittle">Join Now</div>
              </a>
            </div>
            </div>
          </div>
          </div>
        </div>
      </div>
    </CarouselCardWrapper3>
  );
};
const CarouselCardWrapper3 = styled.div`
overflow: hidden;
.mobil{
  display: none;
}

  .imgBoxright {
  border-radius: 50%;
  margin-right: 26px;

  .fl{
    box-sizing: border-box;
    padding: 2px;
    border-radius: 100%;
    background: linear-gradient(209.57deg, rgba(144, 150, 230, 0.69) 18.11%, rgba(16, 22, 101, 0) 47.68%, rgba(126, 132, 211, 0.7) 81.9%); 

  }

    .bigCIrcle {
      display: flex;
      justify-content: center;
      align-items: center;
      /* border: 2px solid; */

  /* border-image-source: linear-gradient(209.57deg, rgba(144, 150, 230, 0.69) 18.11%, rgba(16, 22, 101, 0) 47.68%, rgba(126, 132, 211, 0.7) 81.9%); */


  

  border-radius: 50%;

  

      background: linear-gradient(
          180deg,
          rgba(127, 100, 236, 0.228) 0%,
          rgba(48, 28, 130, 0.201) 100%
        ),
        #08091d;
      width: 274px;
      height: 274px;
    }
    .smallCircle {
      display: flex;
      justify-content: center;
      align-items: center;
      background: linear-gradient(
          180deg,
          rgba(100, 109, 236, 0.152) 0%,
          rgba(28, 34, 130, 0.134) 100%
        ),
        #08091d;
      width: 206px;
      height: 206px;
      /* border: 2px solid; */
      border-radius: 50%;
    }
    .jiscordbtn {
    
      text-decoration: none;
      flex-direction: column;

      display: flex;
      justify-content: center;
      align-items: center;
      img {
      }

      .dtittle {
        font-weight: 600;
        font-size: 28px;
        line-height: 30px;
        background: linear-gradient(
            174.92deg,
            #9e77ed 1.97%,
            rgba(133, 140, 255, 0.95) 102.23%
          ),
          #070707;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
      }
    }
  }
  .rightSide {
    position: relative;
      z-index: 20;
  }
  .mobil {
    display: none;
  }
  .pc {
    display: block;
  }
  position: relative;
  display: flex;
  width: 100%;
  height: 225px;
  /* background: #0e0e0e; */

  /* stroke */

  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
  /* border-radius: 16px; */
  background-image: url(${assets.images.bgup2});
  background-repeat: no-repeat;
  background-size: 100% 100%;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);

  display: flex;
  justify-content: space-between;
  align-items: center;
  /* border: 1px solid #2d2f31; */
  .cer {
    width: 100%;
    position: absolute;
    left: auto;
    right: auto;
    top: -450px;
    display: flex;
    justify-content: center;
  }
  .leftSide {
    .imgBoxLeft {
      img {
      }
    }
  }

  .center {
    color: #000000;
    text-align: center;
    position: relative;
    z-index: 10;
    position: absolute;
    width: 100%;
    .smallText {
      font-weight: 400;
      font-size: 15px;
      line-height: 140%;
      margin-bottom: 12px;
    }
    .text {
      font-weight: 700;
      font-size: 32px;
      line-height: 120%;
      /* or 38px */

      text-align: center;

      background: linear-gradient(180deg, #ffffff 60.11%, #615971 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }
    .span {
      font-weight: 700;
      font-size: 32px;
      line-height: 120%;
      color: #fff !important;
    }
    .mediumText {
      font-weight: 300;
      font-size: 26px;
      line-height: 120%;
    }
    .btnBox {
      display: flex;
      gap: 30px;
      margin-top: 20px;
      a {
        text-decoration: none;
      }
      .btn {
        min-width: 170px;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 8px;

        margin: 0 auto;
        color: #fff;
        width: max-content;
        background: #1b1b21;
        border: 0.5px solid #363636;
        border-radius: 8px;
        padding: 12px 16px;
        svg {
          width: 20px;
          height: 15px;
        }
      }
    }
  }
  @media only screen and (max-width: 1300px) {
    .leftSide {
      /* position: absolute; */
      .imgBoxLeft {
        width: 350px;

        img {
          margin-left: -70px;
        }
      }
    }
    .center {
      .text {
        font-weight: 700;
        font-size: 28px;
        line-height: 120%;
      }
      .span {
        font-weight: 700;
        font-size: 28px;
        line-height: 120%;
        color: #fff !important;
      }
      .btnBox {
        display: flex;
        gap: 20px;
        margin-top: 20px;
        a {
          text-decoration: none;
        }
        .btn {
          /* min-width: 170px; */

          display: flex;
          justify-content: center;
          align-items: center;
          gap: 8px;

          margin: 0 auto;
          color: #fff;
          width: max-content;
          background: #1b1b21;
          border: 0.5px solid #363636;
          border-radius: 8px;
          padding: 12px 12px;
          font-size: 14px;

          svg {
            width: 20px;
            height: 15px;
          }
        }
      }
    }
  }

  @media only screen and (max-width: 700px) {
    .mobil{
      display: block;
    }
    .dd{
      background: radial-gradient(51.48% 51.48% at 50% 50%, #5D3AC2 0%, rgba(57, 46, 122, 0) 100%) /* warning: gradient uses a rotation that is not supported by CSS and may not behave as expected */;
mix-blend-mode: color;
width: 655px;
height: 544px;
    }
    .rightSide {
        position: static;
       
        
      }
    .imgBoxright {
      position: absolute;
     left: 0;
     display: flex;
     justify-content: center;
      width: 100%;
      bottom: -40%;


    .bigCIrcle {

      

    
     
      width: 233px;
      height: 233px;
      /* margin-right: 0; */
    }
    .smallCircle {
     
      width: 195px;
      height: 195px;
      /* border: 2px solid; */
      border-radius: 50%;
      align-items: start;
    }
    .jiscordbtn {
      margin-top: 47px;

      flex-direction: row;

      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 40px;
        margin-right: 8px;
      }

      .dtittle {
        font-weight: 600;
font-size: 20px;
line-height: 30px;
      
      }
    }
  }
    overflow: hidden;
    .leftSide {
      /* position: absolute; */
      .imgBoxLeft {
        width: auto;

        img {
          margin-left: 0;
        }
      }
    }
    .mobil {
      display: block;
    }
    .pc {
      display: none;
    }
    position: relative;
    display: flex;
    flex-direction: column;
    /* width: 100%; */
    height: 270px;
    padding: 20px;
    /* background: #0e0e0e; */

    /* stroke */

    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
    /* border-radius: 16px; */
    background: #000000;
    background: linear-gradient(
        6.78deg,
        rgba(82, 87, 163, 0.1) -43.06%,
        rgba(130, 71, 229, 0.063) 38.36%,
        rgba(0, 0, 0, 0) 130.63%
      ),
      #0e0e0e;

    display: flex;
    justify-content: space-between;
    align-items: center;
    /* border: 1px solid #2d2f31; */
    .cer {
      width: 100%;
      position: absolute;
      left: 0;
      right: auto;
      top: 0;
      /* bottom: -100px; */
      display: flex;
      justify-content: center;
      opacity: 0.4;
      img {
        width: 400px;
        height: 400px;
      }
    }
    .leftSide {
      position: relative;
      z-index: 10;
      .imgBoxLeft {
        img {
          width: 155px;
          margin-left: 0px;
        }
      }
    }
    .center {
      /* margin-top: -50px; */
      color: #000000;
      text-align: center;
      position: relative;
      z-index: 10;

      .text {
        font-weight: 700;
        font-size: 22px;
        line-height: 120%;
        /* or 38px */
        text-align: center;
        background: linear-gradient(
            174.92deg,
            #9e77ed 1.97%,
            rgba(133, 140, 255, 0.95) 102.23%
          ),
          #070707;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
      }
      .tt{
        color:#fff;
        font-weight: 700;
        font-size: 22px;
        line-height: 120%;
      }
      .mediumText {
        font-weight: 300;
        font-size: 26px;
        line-height: 120%;
      }
      .btnBox {
        margin-top: 12px;
        gap: 12px;
        a {
          text-decoration: none;
        }
        .btn {
          min-width: auto;
          font-size: 12px;
          margin: 0 auto;
          color: #fff;
          width: max-content;
          background: #1b1b21;
          border: 0.5px solid #363636;
          border-radius: 8px;
          padding: 9px 9px;
          svg {
            width: 20px;
            height: 15px;
          }
        }
      }
      
    }
  }
`;
export default CarouselCardHome3;
