import { createReducer } from "@reduxjs/toolkit";
import { currentTimestamp } from "../../constant";
import {
  updateCurrency,
  updateUserDarkMode,
  updateUserWallet,
  updateUserToken,
  updateUserHoldNFTStatus
} from "./actions";
import { Currencies, CurrencyList, TypeCurrency } from "constant/currency";
import { dropdownOptions } from "components/DropDown/BasicDropdown";
import { useAccount } from "wagmi";

export interface UserStore {
  userDarkMode: boolean | null; // the user's selected dark mode or light mode
  // matchesDarkMode: boolean // whether the dark mode media query matches
  currency: TypeCurrency;
  timestamp: number;
  wallet: `0x${string}` | null | undefined;
  holdsNFT: boolean;
  JWT?: string | null | undefined;
}

export const initialState: UserStore = {
  userDarkMode: true,
  // matchesDarkMode: false,
  currency: CurrencyList[0],
  timestamp: currentTimestamp(),
  wallet: null,
  holdsNFT: false, //change this initial state to dynamic value while integrating token gating
  JWT: null,
};

export default createReducer(initialState, (builder) =>
  builder
    .addCase(updateUserDarkMode, (state, action) => {
      state.userDarkMode = action.payload.userDarkMode;
      state.timestamp = currentTimestamp();
    })
    .addCase(updateCurrency, (state, action) => {
      state.currency = action.payload.currency;
      state.timestamp = currentTimestamp();
    })
    .addCase(updateUserWallet, (state, action) => {
      state.wallet = action.payload.wallet;
    })
    .addCase(updateUserToken, (state, action) => {
      state.JWT = action.payload.JWT;
    })
    .addCase(updateUserHoldNFTStatus, (state, action) => {
      state.holdsNFT = action.payload.holdsNFT;
    })
);
