import assets from "assets";

export const dropsList = [


    {
      webScraperOrder: "1684921789-1",
      webScraperStartUrl: "https://nftcalendar.io/b/polygon/",
      tittle: "Original Polaroids",
      tittleHref: "https://nftcalendar.io/event/original-polaroids-b/",
      name: "Original Polaroids",
      mintDate: "June 02, 2023 ",
      site: "Website",
      siteHref: "https://nftcalendar.io/out/original-polaroids-b/",
      twitter: "Twitter",
      twitterHref: "https://twitter.com/weeposters",
      discrod: "Marketplace URL",
      discrodHref: "https://opensea.io/collection/originalpolaroids",
      blockchain: "OpenSea",
      description: "Introducing a captivating and unique collection of original polaroids! This extraordinary collection showcases the artistry of analog photography, capturing timeless moments frozen in time.",
      imageSrc: assets.drop.Original
    },
    {
      webScraperOrder: "1684921792-2",
      webScraperStartUrl: "https://nftcalendar.io/b/polygon/",
      tittle: "We Are Friends",
      tittleHref: "https://nftcalendar.io/event/we-are-friends-chi/",
      name: "We Are Friends",
      mintDate: "June 01, 2023 ",
      site: "Website",
      siteHref: "https://nftcalendar.io/out/we-are-friends-chi/",
      twitter: "Twitter",
      twitterHref: "https://twitter.com/wearefriendscca",
      discrod: "Discord",
      discrodHref: "https://discord.gg/mjZf2dnYfK",
      blockchain: "OpenSea",
      description: "We are Friends CCA is founded by a cancer survivor and the little images sybolize the little precious moments in life during the period of fighting cancer. We are raising awareness towards childhood cancer and fundraise pediatric research through our NFT sales. The research We Are Friends CCA funds is delivering safer, more effective pediatric research.",
      imageSrc: assets.drop.Friends
    },
    {
      webScraperOrder: "1684921795-3",
      webScraperStartUrl: "https://nftcalendar.io/b/polygon/",
      tittle: "Portal Fantasy",
      tittleHref: "https://nftcalendar.io/event/portal-fantasy/",
      name: "Portal Fantasy",
      mintDate: "May 31, 2023 ",
      site: "Website",
      siteHref: "https://portalfantasy.io/",
      twitter: "Twitter",
      twitterHref: "https://twitter.com/PortalFantasyio",
      discrod: "Discord",
      discrodHref: "https://discord.com/invite/portalfantasy",
      blockchain: "OpenSea",
      description: "Portal Fantasy is a highly-anticipated, free-to-play, pixel adventure RPG. Play as a Hero or an Architect. Explore worlds and battle alongside your Porbles as a Hero. As an Architect, craft your own realms for other players.",
      imageSrc: assets.drop.Portal
    },
    {
      webScraperOrder: "1684921799-4",
      webScraperStartUrl: "https://nftcalendar.io/b/polygon/",
      tittle: "CodeBLUE",
      tittleHref: "https://nftcalendar.io/event/codeblue-the-medica/",
      name: "CodeBLUE",
      mintDate: "May 31, 2023 ",
      site: "Website",
      siteHref: "https://nftcalendar.io/out/codeblue-the-medica/",
      twitter: "Twitter",
      twitterHref: "https://twitter.com/CodeBlueNFT",
      discrod: "Marketplace URL",
      discrodHref: "https://opensea.io/collection/codebluenft",
      blockchain: "OpenSea",
      description: "Step into the world of hospital life with Code BLUE, the satirical NFT collection that captures unexpected journeys to hospital admissions, from the hilarious to the heartbreaking. With each unique NFT, you'll not only own a piece of digital art but also gain access to the story behind the admission. Our medical reports can be both humorous and heart-wrenching, just like the everyday experiences in hospitals around the world.",
      imageSrc: assets.drop.CodeBLUE
    },
    {
      webScraperOrder: "1684921803-5",
      webScraperStartUrl: "https://nftcalendar.io/b/polygon/",
      tittle: "Bada Club",
      tittleHref: "https://nftcalendar.io/event/bada-club-nft/",
      name: "Bada Club",
      mintDate: "May 31, 2023 ",
      site: "Website",
      siteHref: "https://nftcalendar.io/out/bada-club-nft/",
      twitter: "Twitter",
      twitterHref: "https://twitter.com/BadaClubNFT",
      discrod: "Marketplace URL",
      discrodHref: "https://opensea.io/collection/bada-club-nft",
      blockchain: "OpenSea",
      description: "Bada Club is a 3D NFT collection.",
      imageSrc: assets.drop.Bada
    },
    {
      webScraperOrder: "1684921807-6",
      webScraperStartUrl: "https://nftcalendar.io/b/polygon/",
      tittle: "Duke",
      tittleHref: "https://nftcalendar.io/event/duke-punitive-expe/",
      name: "Duke",
      mintDate: "May 30, 2023 ",
      site: "Website",
      siteHref: "https://nftcalendar.io/out/duke-punitive-expe/",
      twitter: "Twitter",
      twitterHref: "https://twitter.com/StickmenWorldWS",
      discrod: "Marketplace URL",
      discrodHref: "https://opensea.io/Stickmen_World_by_sloth_of_wallstreet",
      blockchain: "OpenSea",
      description: "Buy your NFT, Reedem your Mystery Phrase and Get your Personalized Prizes for Free. Visit the Site for more information: stickmenworld.com",
      imageSrc: assets.drop.Duke
    },
    {
      webScraperOrder: "1684921810-7",
      webScraperStartUrl: "https://nftcalendar.io/b/polygon/",
      tittle: "Obtgons",
      tittleHref: "https://nftcalendar.io/event/obtgons-minting-date/",
      name: "Obtgons",
      mintDate: "May 30, 2023 ",
      site: "Website",
      siteHref: "https://nftcalendar.io/out/obtgons-minting-date/",
      twitter: "Twitter",
      twitterHref: "https://twitter.com/Obtgon",
      discrod: "Discord",
      discrodHref: "https://discord.gg/EKGFrZGm",
      blockchain: "OpenSea",
      description: "The wait is over! Obtgons minting begins on May 30th, 2023 at 12:00 PM UTC",
      imageSrc: assets.drop.Obtgons
    },
    {
      webScraperOrder: "1684921814-8",
      webScraperStartUrl: "https://nftcalendar.io/b/polygon/",
      tittle: "LazyCatBlissVille",
      tittleHref: "https://nftcalendar.io/event/lazycatblissville/",
      name: "LazyCatBlissVille",
      mintDate: "May 29, 2023 ",
      site: "Twitter",
      siteHref: "https://twitter.com/LazyCatBV",
      twitter: "Discord",
      twitterHref: "https://discord.gg/GrUVkEEsr3",
      discrod: "Marketplace URL",
      discrodHref: "https://opensea.io/collection/lazy-cat-blissville/drop",
      blockchain: "OpenSea",
      description: "Once upon a time, in the enchanting village of Blissville, a group of passionate individuals set out on a remarkable journey to revolutionize the world of sports and digital art. Their vision extended beyond creating a captivating collection of 10,000 unique Lazy Cat BlissVille NFTs; they aimed to build a thriving community that would shape the future of sports and uncover hidden athletic talents across the globe.",
      imageSrc: assets.drop.LazyCatBlissVille
    },
    {
      webScraperOrder: "1684921817-9",
      webScraperStartUrl: "https://nftcalendar.io/b/polygon/",
      tittle: "Tentacle Brother's",
      tittleHref: "https://nftcalendar.io/event/tentacle-brothers/",
      name: "Tentacle Brother's",
      mintDate: "May 29, 2023 ",
      site: "Website",
      siteHref: "https://nftcalendar.io/out/tentacle-brothers/",
      twitter: "Twitter",
      twitterHref: "https://twitter.com/Tentacle_Lodge",
      discrod: "Discord",
      discrodHref: "https://discord.gg/VtBDWHtHg4",
      blockchain: "OpenSea",
      description: "Get ready to embark on an epic journey unlike any other!",
      imageSrc: assets.drop.Tentacle
    },
    {
      webScraperOrder: "1684921820-10",
      webScraperStartUrl: "https://nftcalendar.io/b/polygon/",
      tittle: "XVerified",
      tittleHref: "https://nftcalendar.io/event/xverified/",
      name: "XVerified",
      mintDate: "May 28, 2023 ",
      site: "Website",
      siteHref: "https://nftcalendar.io/out/xverified/",
      twitter: "Twitter",
      twitterHref: "https://twitter.com/KongLabs",
      discrod: "",
      discrodHref: "",
      blockchain: "OpenSea",
      description: "Welcome to XVerified NFTs, the exclusive collection that aims to raise awareness about the recent rebranding of Twitter to X Corp and the removal of blue checks from high-profile accounts unless they pay a fee. Our project features 10,000 unique pieces, each with a distinctive design that captures the essence of this pivotal moment in social media history.",
      imageSrc: assets.drop.XVerified
    },
    {
      webScraperOrder: "1684921823-11",
      webScraperStartUrl: "https://nftcalendar.io/b/polygon/",
      tittle: "Queens Ape Guard Club",
      tittleHref: "https://nftcalendar.io/event/queens-ape-guard-clu/",
      name: "Queens Ape Guard Club",
      mintDate: "May 28, 2023",
      site: "Discord",
      siteHref: "https://discord.com/invite/NZuNTcguW6",
      twitter: "Source",
      twitterHref: "https://medium.com/@queensapeguardclub/we-are-not-bored-we-are-on-the-board-5a1f0d86edaa",
      discrod: "Marketplace URL",
      discrodHref: "https://opensea.io/collection/queens-ape-guard-club",
      blockchain: "OpenSea",
      description: "The Queens Ape Guard Club is a card game collection of 2000 one-of-a-kind NFTs on the Polygon blockchain. Each NFT card showcases an ape wearing a crown, inspired by the UK Queens Guard.",
      imageSrc: assets.drop.Queens
    },
    {
      webScraperOrder: "1684921827-12",
      webScraperStartUrl: "https://nftcalendar.io/b/polygon/",
      tittle: "Good Time Pals Mint",
      tittleHref: "https://nftcalendar.io/event/good-time-pals-mint/",
      name: "Good Time Pals Mint",
      mintDate: "May 26, 2023 ",
      site: "Website",
      siteHref: "https://nftcalendar.io/out/good-time-pals-mint/",
      twitter: "Twitter",
      twitterHref: "https://www.twitter.com/goodtimepals",
      discrod: "Discord",
      discrodHref: "https://discord.gg/EjAVVnbxc6",
      blockchain: "OpenSea",
      description: "The Good Time Pals NFT collection, is a vibrant and unique collection of 7500 pieces, with over 190 traits. The collection is created on the Polygon blockchain. The collection features a colorful cast of characters, each with their own distinct personalities and quirks, that are sure to bring a smile to your face. From the mischievous prankster to the daring adventurer, there's a Good Time Pal for everyone! The project has an exciting roadmap that includes the creation of a DAO, the development of a dapp, and NFT airdrops, as well as gated member experiences.",
      imageSrc: assets.drop.Good
    },
    {
      webScraperOrder: "1684921831-13",
      webScraperStartUrl: "https://nftcalendar.io/b/polygon/",
      tittle: "Digisport Challenge",
      tittleHref: "https://nftcalendar.io/event/digisport-collectors/",
      name: "Digisport Challenge",
      mintDate: "May 26, 2023",
      site: "Website",
      siteHref: "https://nftcalendar.io/out/digisport-collectors/",
      twitter: "Twitter",
      twitterHref: "https://twitter.com/digisportxyz",
      discrod: "Discord",
      discrodHref: "https://discord.gg/lympo",
      blockchain: "OpenSea",
      description: "Collectors challenge with Digisport x Rose Namajunas Prepare for the Ultimate Collector’s Journey with UFC Champion Rose Namajunas, digital sports ecosystem Lympo, and digital entertainment leader Animoca Brands. Forge Your Champion Fighter and Score Exclusive Fan Experiences!",
      imageSrc: assets.drop.Digisport
    },
    {
      webScraperOrder: "1684921834-14",
      webScraperStartUrl: "https://nftcalendar.io/b/polygon/",
      tittle: "Lost in The AI Metaverse",
      tittleHref: "https://nftcalendar.io/event/lost-in-the-ai-metav/",
      name: "Lost in The AI Metaverse",
      mintDate: "May 25, 2023 ",
      site: "Website",
      siteHref: "https://nftcalendar.io/out/lost-in-the-ai-metav/",
      twitter: "Twitter",
      twitterHref: "https://twitter.com/MyCreativeOwls",
      discrod: "Discord",
      discrodHref: "https://discord.com/invite/WFMbFMTqt2",
      blockchain: "OpenSea",
      description: "Step into the world of artificial intelligence and get ready to be lost in a mesmerizing digital realm. Our 'Lost in The AI Metaverse' NFT collection is a tribute to the limitless possibilities of the future, where technology meets imagination. Each NFT is a unique masterpiece that captures the essence of the AI universe, bringing to life characters and environments that will leave you in awe. Don't miss your chance to own a piece of the future - join us on this journey to the heart of the AI metaverse.",
      imageSrc: assets.drop.Lost
    },
    {
      webScraperOrder: "1684921837-15",
      webScraperStartUrl: "https://nftcalendar.io/b/polygon/",
      tittle: "ColonyX by Affi Network",
      tittleHref: "https://nftcalendar.io/event/colonyx-by-affi-netw/",
      name: "ColonyX by Affi Network",
      mintDate: "May 25, 2023",
      site: "Website",
      siteHref: "https://nftcalendar.io/out/colonyx-by-affi-netw/",
      twitter: "Twitter",
      twitterHref: "https://twitter.com/Affi_Network",
      discrod: "Discord",
      discrodHref: "https://discord.gg/ZmKqDedyQC",
      blockchain: "Polygon",
      description: "ColonyX is a collection of 3,333 dynamic NFTs with exclusive access to the first ever Web3 Marketing Academy for content creators!",
      imageSrc: assets.drop.ColonyX
    },
    {
      webScraperOrder: "1684921840-16",
      webScraperStartUrl: "https://nftcalendar.io/b/polygon/",
      tittle: "Monkey Frogs",
      tittleHref: "https://nftcalendar.io/event/monkey-frogs/",
      name: "Monkey Frogs",
      mintDate: "May 25, 2023",
      site: "Website",
      siteHref: "https://nftcalendar.io/out/monkey-frogs/",
      twitter: "Twitter",
      twitterHref: "https://twitter.com/monkeyfrogsNFT",
      discrod: "Discord",
      discrodHref: "https://discord.com/invite/Wp8ewYQPPq",
      blockchain: "OpenSea",
      description: "The Story starts with 4567 MonkeyFrogs of the finest quality, born after months of sketches and drawings made by talented artists. Some look weird. Some are just damn cool!",
      imageSrc: assets.drop.Monkey
    },
    {
      webScraperOrder: "1684921844-17",
      webScraperStartUrl: "https://nftcalendar.io/b/polygon/",
      tittle: "The Poker Pro",
      tittleHref: "https://nftcalendar.io/event/the-poker-pro/",
      name: "The Poker Pro",
      mintDate: "May 24, 2023 ",
      site: "Website",
      siteHref: "https://nftcalendar.io/out/the-poker-pro/",
      twitter: "Twitter",
      twitterHref: "https://twitter.com/The_Poker_pro",
      discrod: "Discord",
      discrodHref: "https://discord.gg/thepokerpro",
      blockchain: "Magic Eden",
      description: "We don't want to tire you with a lengthy description, as you can read our white paper at the following link: https://the-poker-pro.gitbook.io/the-poker-pro/ \nWe already have a working poker platform, and you can try it at this link: https://thepoker.pro/",
      imageSrc: assets.drop.Poker
    },
    {
      webScraperOrder: "1684921848-18",
      webScraperStartUrl: "https://nftcalendar.io/b/polygon/",
      tittle: "Bigwig",
      tittleHref: "https://nftcalendar.io/event/bigwig/",
      name: "Bigwig",
      mintDate: "May 24, 2023",
      site: "Website",
      siteHref: "https://nftcalendar.io/out/bigwig/",
      twitter: "Twitter",
      twitterHref: "https://twitter.com/bigwigzone/status/1659996365198352384?s=20",
      discrod: "Marketplace URL",
      discrodHref: "https://opensea.io/assets/matic/0x2953399124f0cbb46d2cbacd8a89cf0599974963/71490948530839330553596874052143703746977599950431339123064277144060087500801/",
      blockchain: "OpenSea",
      description: "The first collection of NFT that is formed by bigwigs or those who have positive or negative influences in the world. Welcome to the World of bigwigs ",
      imageSrc: assets.drop.Bigwig
    },
    {
      webScraperOrder: "1684921851-19",
      webScraperStartUrl: "https://nftcalendar.io/b/polygon/",
      tittle: "Bitcoin Future Coin",
      tittleHref: "https://nftcalendar.io/event/bitcoin-future-coin/",
      name: "Bitcoin Future Coin",
      mintDate: "May 24, 2023 ",
      site: "Website",
      siteHref: "https://nftcalendar.io/out/bitcoin-future-coin/",
      twitter: "Twitter",
      twitterHref: "https://twitter.com/MN_Verse",
      discrod: "Discord",
      discrodHref: "https://discord.com/channels/verse5023#0410",
      blockchain: "OpenSea",
      description: "Introducing Bitcoin Future Coin Gifs - an innovative animation project showcasing the potential of Bitcoin through captivating GIFs. Our meticulously crafted GIFs capture the essence of Bitcoin's design, inspiring curiosity and discussions about its futuristic impact. Join us on this exciting journey into the limitless possibilities of Bitcoin through the art of animated GIFs. Embrace the future with  Bitcoin Future GIFs  and discover the groundbreaking world of this digital currency.",
      imageSrc: assets.drop.Bitcoin
    },
    {
      webScraperOrder: "1684921854-20",
      webScraperStartUrl: "https://nftcalendar.io/b/polygon/",
      tittle: "The Hell Gangsters",
      tittleHref: "https://nftcalendar.io/event/the-hell-gangsters/",
      name: "The Hell Gangsters",
      mintDate: "May 24, 2023 ",
      site: "Twitter",
      siteHref: "https://twitter.com/NFT_Lamaso",
      twitter: "Discord",
      twitterHref: "https://discord.gg/LAMASO.NFT",
      discrod: "Marketplace URL",
      discrodHref: "https://rarible.com/NFT_Lamaso",
      blockchain: "Rarible",
      description: "The hell gangsters set is one of the most special sets in the NFT world, I've put in lot of effort into making it a special set, so that there is no like it. Be the first to buy this collection.",
      imageSrc: assets.drop.Gangsters
    },
   

    {
      webScraperOrder: "1682751465-22",
      webScraperStartUrl: "https://nftcalendar.io/b/polygon/",
      tittle: "Dumbiez",
      tittleHref: "https://nftcalendar.io/event/dumbiez-mint/",
      name: "Dumbiez",
      mintDate: "May 15, 2023 ",
      site: "Twitter",
      siteHref: "https://twitter.com/Dumbiez_NC",
      twitter: "Discord",
      twitterHref: "https://discord.gg/XK9meDkv",
      discrod: "Marketplace URL",
      discrodHref: "https://opensea.io/DUMBIE-Z",
      blockchain: "OpenSea",
      description: "We are heavily focused on driving value to our community, through onboarding, education and building on our deep and rich art-based lore to drive value and give immersive experiences to our holders. We've onboarded one of the most talented artists into the project and we're bringing you the next blue chip PFP art on POLYGON",
      imageSrc: assets.drop.Dumbiez
    },
  
   
];
