import { useQuery } from '@apollo/client'
import { collectionMintedToday  } from 'apollo/client'
import gql from 'graphql-tag'
import { useMemo } from 'react'
import { notEmpty } from 'utils'

export const QUERY_COLLECTION_MINTED_TODAY = gql`
query records {
    records {
        collections_minted_today
    }
  }
`

interface CollectionMintedTodayRes {
    records: { collections_minted_today: number }[] 
}

/**
 * Fetch unique owners
 */

export function useCollectionMintedToday(): {
    loading: boolean
    error: boolean,
    records: {  collections_minted_today: number }[] | undefined
} {
    const { loading, error, data } = useQuery<CollectionMintedTodayRes>(QUERY_COLLECTION_MINTED_TODAY, {
        client: collectionMintedToday,
        fetchPolicy: 'cache-first',
    })
    const formattedData = useMemo(() => {
        if (data) {
            return data.records
                .map((p) => {
                    return {
                        collections_minted_today: p.collections_minted_today,
                    }
                })
                .filter(notEmpty)
        } else {
            return undefined
        }
    }, [data])
    return {
        loading: loading,
        error: Boolean(error),
        records: formattedData,
    }
}
