import { useEffect, useRef, useState, useMemo } from "react";
import CollectionCard from "./components/CollectionCard";
import { useTotalNFTCollections } from "data/nft/totalNFTCollections";
import { GenericChartSeries, TimeWindow } from "types";
import dayjs from "dayjs";
import { formatAmount } from "utils/numbers";
import { useNFTCollections } from "data/nft/totalNFTCollection";

const TotalNftCollection = () => {
  const [timeWindow, setTimeWindow] = useState("3M");
  const { data: totalNFTCollections } = useNFTCollections();

  const reverseTotalNFTCollections = [...(totalNFTCollections ?? [])].reverse();

  const filteredTotalCount = useMemo(() => {
    let countValue = 0;
    let percentValue = 0;
    if (reverseTotalNFTCollections.length === 0) {
      return { countValue, percentValue };
    }
    switch (timeWindow) {
      case TimeWindow.threeMonth: {
        const beforeThreeMonthData = reverseTotalNFTCollections.find(
          (element) =>
            dayjs(element.dateTime).isBefore(
              dayjs().subtract(3, "month"),
              "month"
            )
        );
        const beforeSixMonthData = reverseTotalNFTCollections.find((element) =>
          dayjs(element.dateTime).isBefore(
            dayjs().subtract(6, "month"),
            "month"
          )
        );
        const lastThreeMonthCount =
          reverseTotalNFTCollections[1].cumulative_collections -
          (beforeThreeMonthData?.cumulative_collections ?? 0);
        const lastSecondThreeMonthCount =
          (beforeThreeMonthData?.cumulative_collections ?? 0) -
          (beforeSixMonthData?.cumulative_collections ?? 0);
        countValue =
          reverseTotalNFTCollections[0].cumulative_collections -
          (beforeThreeMonthData?.cumulative_collections ?? 0);
        percentValue = reverseTotalNFTCollections[0].pct_change_90_days;
        break;
      }
      case TimeWindow.sixMonth: {
        const beforeSixMonthData = reverseTotalNFTCollections.find((element) =>
          dayjs(element.dateTime).isBefore(
            dayjs().subtract(6, "month"),
            "month"
          )
        );
        const beforeTwelveMonthData = reverseTotalNFTCollections.find(
          (element) =>
            dayjs(element.dateTime).isBefore(
              dayjs().subtract(12, "month"),
              "month"
            )
        );
        const lastSixMonthCount =
          reverseTotalNFTCollections[1].cumulative_collections -
          (beforeSixMonthData?.cumulative_collections ?? 0);
        const lastSecondSixMonthCount =
          (beforeSixMonthData?.cumulative_collections ?? 0) -
          (beforeTwelveMonthData?.cumulative_collections ?? 0);
        countValue =
          reverseTotalNFTCollections[0]?.cumulative_collections_last_180_days ??
          0;
        percentValue =
          ((lastSixMonthCount - lastSecondSixMonthCount) * 100) /
          (lastSecondSixMonthCount ?? 1);

        // percentValue =
        //   ((reverseTotalNFTCollections[1].count_collections -
        //     (beforeSixMonthData?.count_collections ?? 0)) *
        //     100) /
        //   (beforeSixMonthData?.count_collections ?? 1);
        break;
      }
      case TimeWindow.oneYear: {
        const beforeOneYearData = reverseTotalNFTCollections.find((element) =>
          dayjs(element.dateTime).isBefore(dayjs().subtract(1, "year"), "month")
        );
        const beforeTwoYearData = reverseTotalNFTCollections.find((element) =>
          dayjs(element.dateTime).isBefore(dayjs().subtract(2, "year"), "month")
        );
        const lastOneYearCount =
          reverseTotalNFTCollections[1].cumulative_collections -
          (beforeOneYearData?.cumulative_collections ?? 0);
        const lastSecondYearCount =
          (beforeOneYearData?.cumulative_collections ?? 0) -
          (beforeTwoYearData?.cumulative_collections ?? 0);
        countValue =
          reverseTotalNFTCollections[0].cumulative_collections -
          (beforeOneYearData?.cumulative_collections ?? 0);
        percentValue =
          ((lastOneYearCount - lastSecondYearCount) * 100) /
          (lastSecondYearCount ?? 1);
        break;
      }
      case TimeWindow.all:
        countValue = reverseTotalNFTCollections[0].cumulative_collections;
        percentValue =
          ((reverseTotalNFTCollections[1].cumulative_collections -
            reverseTotalNFTCollections[2].cumulative_collections) *
            100) /
          reverseTotalNFTCollections[2].cumulative_collections;
        break;
      default:
        countValue = reverseTotalNFTCollections[0].cumulative_collections;
        percentValue =
          ((reverseTotalNFTCollections[1].cumulative_collections -
            reverseTotalNFTCollections[2].cumulative_collections) *
            100) /
          reverseTotalNFTCollections[2].cumulative_collections;
        break;
    }
    return { countValue, percentValue };
  }, [timeWindow, reverseTotalNFTCollections]);

  const volumeData = {
    title: "Total NFT Collections",
    endpoint: `${process.env.REACT_APP_API_URL}/topCollectionByCategory`,
    toolTip: "Top NFT collections in Polygon ecosystem",
    price_change: filteredTotalCount.percentValue,
    value: formatAmount(filteredTotalCount.countValue),
    showTimePeriod: true,
    showLogo: false,
  };

  return (
    <>
      <CollectionCard card={volumeData} time={setTimeWindow} />
    </>
  );
};

export default TotalNftCollection;
