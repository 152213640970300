import { PrimaryButton } from "components/Button";
import Search from "components/Search";
import React from "react";
import styled from "styled-components";
import { TEXT } from "theme/texts";
const active = true;

const DropNav = () => {
  return (
    <DropsNavWrapper>
      <div className="lefts">
        <AppNavCtr>
          <StyledNavLink to="/market-overview">
            <TEXT.Body paddingX={16} lineHeight={5}>
              Minting Today
            </TEXT.Body>
            <div className="_bottom" />
          </StyledNavLink>
          <StyledNavLink to="/market-overview">
            <TEXT.Body paddingX={16} lineHeight={5}>
              OnGoing
            </TEXT.Body>
            <div className="_bottom" />
          </StyledNavLink>
          <StyledNavLink to="/market-overview">
            <TEXT.Body paddingX={16} lineHeight={5}>
              Upcoming
            </TEXT.Body>
            <div className="_bottom" />
          </StyledNavLink>
        </AppNavCtr>
      </div>
      <div className="rightnav">
        <PrimaryButton>Submit your Project</PrimaryButton>
      </div>
    </DropsNavWrapper>
  );
};
const DropsNavWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #555;
  margin-bottom: 50px;
  width: 100%;
  .rightnav {
      display: flex;
    }

  @media only screen and (max-width: 700px) {
    .rightnav {
      display: none;
    }
  }
`;
const AppNavCtr = styled.div`
  display: flex;
  gap: 1rem;
  /* margin-left: 126px; */
`;

const StyledNavLink = styled.div`
  color: ${({ active, theme }) => (active ? theme.body : theme.disabledVar2)};
  text-decoration: none;
  align-items: center;
  &.active {
    color: ${({ theme }) => theme.body};
    ._bottom {
      width: auto;
      height: 4px;
      background: linear-gradient(
        90.09deg,
        #813e5b -52.35%,
        #984490 13.43%,
        #8a4bf3 137.97%
      );
    }
  }
`;


export default DropNav;
