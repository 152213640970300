import assets from "assets";
import { SecondaryButton } from "components/Button";
import { useNavigate } from "react-router-dom";

import styled from "styled-components";

const CarouselCard2 = ({ card }) => {
  const navigate = useNavigate()
 
  return (
    <CarouselCardWrapper2>
      <img className="tl" src={assets.images.tl} alt="" />
      <img className="br" src={assets.images.br} alt="" />

      <div className="leftSide">
        <div className="header">{card?.name}</div>
        <div className="dis pc">{card?.description}</div>
      </div>

      <div className="rightSides">
        <div className="carouselContents">
          {card?.cards.map((data) => (
            <div className="innerCard">
              <div className="wrapper">
                <img className="pc" src={data.Image} alt="" />
                <img className="mobile" src={data.imgM} alt="" />

                <div className="header">{data?.title}</div>

                <a className="btn" href={data?.buttonlink}   target="_blank">
                  <SecondaryButton borderRadius={"0px"}>
                  <div className="pc">
                  {data?.buttontext}

                  </div>
                    <svg
                      width="13"
                      height="13"
                      viewBox="0 0 13 13"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10.625 5.1875V2.375H7.8125"
                        stroke="white"
                        stroke-width="1.6875"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M7.25 5.75L10.625 2.375"
                        stroke="white"
                        stroke-width="1.6875"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                      <path
                        d="M9.125 7.25V10.25C9.125 10.3495 9.08549 10.4448 9.01517 10.5152C8.94484 10.5855 8.84946 10.625 8.75 10.625H2.75C2.65054 10.625 2.55516 10.5855 2.48484 10.5152C2.41451 10.4448 2.375 10.3495 2.375 10.25V4.25C2.375 4.15054 2.41451 4.05516 2.48484 3.98484C2.55516 3.91451 2.65054 3.875 2.75 3.875H5.75"
                        stroke="white"
                        stroke-width="1.6875"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      />
                    </svg>
                  </SecondaryButton>
                </a>
              </div>
            </div>
          ))}
        </div>
      </div>
    </CarouselCardWrapper2>
  );
};
const CarouselCardWrapper2 = styled.div`
  .pc {
    display: block;
  }
  .mobile {
    display: none;
  }
  padding: 30px 40px;
  position: relative;

  display: flex;
  /* justify-content: center; */
  align-items: center;
  /* width: 100%; */
  gap: 70px;
  height: 300px;
  /* background-image: url(${assets.images.bgline2}); */
  /* background: #1E1E1E; */

  overflow: hidden;

  .leftSide {
    position: relative;
    z-index: 2;
    max-width: 220px;

    .header {
      font-style: normal;
      font-weight: 700;
      font-size: 32px;
      line-height: 39px;
      gap: 20px;
      margin-bottom: 18px;
    }
    .dis {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      max-width: 260px;
    }

    img {
      height: 100%;
      width: 100%;
    }
  }
  .tl {
    top: 0;
    left: 0;
    position: absolute;
  }
  .br {
    bottom: 0;
    right: 0;
    position: absolute;
  }
  .rightSides {
    position: relative;
    z-index: 2;
    display: flex;
    justify-content: center;
    width: 100%;

    .carouselContents {
      margin: 0 auto;
      display: grid;

      grid-template-columns: 1fr 1fr 1fr;
      /* justify-content: center; */
      gap: 20px;

      .innerCard {
        background: #0e0e0e;
        border: 1px solid #2d2f31;
        border-radius: 8px;
        max-width: 220px;
      }
      .wrapper {
        padding: 16px;
        display: flex;
        flex-direction: column;
        gap: 10px;
      }

      .header {
        font-weight: 700;
        font-size: 14px;
        line-height: 28px;

        display: flex;

        img {
          width: 64px;
          height: 64px;
          border-radius: 50%;
        }
      }
      .dis {
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        /* margin-bottom: 38px; */
      }
      .btn {
        margin: 0 0;
        text-decoration: none;
        button {
          /* width: max-content; */
          width: 100% !important;
          margin: 0 0;

          background: rgba(101, 106, 144, 0.1);
          border: 1px solid #363636;
          border-radius: 12px;
          font-size: 12px;
          line-height: 14px;
        }
      }
    }
  }

  @media only screen and (max-width: 700px) {
  height: 330px;
  padding: 0 40px;
  padding-top: 50px;
  justify-content: space-between;


    .pc {
      display: none;
    }
    .mobile {
      display: block;
    }
    flex-direction: column;
    .leftSide {
    position: relative;
    justify-content: space-between;
    align-items: center;
    z-index: 2;
    max-width: auto;

    .header {
      font-style: normal;
      font-weight: 700;
      font-size: 24px;
line-height: 26px;
      gap: 20px;
      margin-bottom: 18px;
    }
    .dis {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      max-width: 260px;
    }

    img {
      height: 100%;
      width: 100%;
    }
  }
    .carouselContents {
      margin: 0 auto;
      display: grid;

      grid-template-columns: 1fr 1fr 1fr;
      /* justify-content: center; */
      gap: 8px !important;

      .innerCard {
        background: #0e0e0e;
        border: 1px solid #2d2f31;
        border-radius: 8px;
        max-width: 105px;
        width: 105px;
        height: 145px;
        padding: 30px 0;
        position: relative;
      }
      .wrapper {
        padding: 16px;
        display: flex;
        flex-direction: column;
        gap: 10px;
        img {
          left: auto;
          right: auto;
          position: absolute;
          width: 75px;
          height: 75px;
          border-radius: 50%;
          top: -50px;
        }
      }

      .header {
        font-weight: 700;
        text-align: center;
        font-size: 12px !important;
        line-height: 16px !important;
        display: flex;
      }
      .dis {
        font-weight: 400;
        font-size: 12px;
        line-height: 15px;
        /* margin-bottom: 38px; */
      }
      .btn {
        margin: 0 auto;
        text-decoration: none;
        button {
          /* width: max-content; */
          /* width: 100% !important; */
          margin: 0 auto;

          background: rgba(101, 106, 144, 0.1);
          border: 1px solid #363636;
          border-radius: 12px;
          font-size: 12px;
          line-height: 14px;
        }
      }
    }
  }
`;
export default CarouselCard2;
