import axios from "axios";
import { useMemo, useState } from "react";



interface api {
    loading: boolean;
    error: boolean;
    data: string | object | any;
}

const getTopMarketData = async () => {
    try {
        const { data } = await axios.get(
            process.env.REACT_APP_API_URL + "/topMarketplaceCrosschain?chain=polygon&duration=1d"
        )

        const topMarketPlace = data?.data?.data?.[0]?.contract_name;
        const totalSales = data?.data?.data?.reduce((acc: any, curr: any) => {
            return acc + curr?.sales;
        }, 0)
        return {
            topMarketPlace,
            totalSales
        }
    }
    catch (error) {
        return error;
    }
}

export function useTopMarketData() {
    const [api, setAPIData] = useState<api>({
        loading: true,
        error: false,
        data: "",
    });



    useMemo(async () => {
        const data = await getTopMarketData();
        setAPIData({
            loading: false,
            error: false,
            data: data
        })
    }, [])
    return api;
}
