import assets from "assets";
import React from "react";
import styled from "styled-components";

const CarouselCardHome4 = () => {
  return (
    <CarouselCardWrapper3>
      <div className="cer"></div>
      <div className="leftSide">
        <div className="imgBoxLeft">
          <img src={assets.images.epassl} className="pc" alt="" />
          <img src={assets.images.epassm} className="mobil" alt="" />
        </div>
      </div>
      <div className="center">
        <div className="text">
          Dropping a Bridge Pass exclusively for Migrators{" "}
        </div>

        <div className="btnBox">
          <a href="https://twitter.com/LayerEhq/status/1641551674442653698?s=20" target={"_blank"}>
            <div className="btn">View More</div>
          </a>
        </div>
      </div>
      <div className="rightSide pc">
        <div className="imgBoxright">
          <img src={assets.images.epassr} alt="" />
        </div>
      </div>
    </CarouselCardWrapper3>
  );
};
const CarouselCardWrapper3 = styled.div`
  .mobil {
    display: none;
  }
  .pc {
    display: block;
  }
  position: relative;
  display: flex;
  width: 100%;
  height: 225px;
  /* background: #0e0e0e; */

  /* stroke */

  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
  /* border-radius: 16px; */
  /* background: #000000;
   */
  background: linear-gradient(180deg, #fffefb 0%, #edd8af 120.22%);
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* border: 1px solid #2d2f31; */
  .cer {
    width: 100%;
    position: absolute;
    left: auto;
    right: auto;
    top: -400px;
    display: flex;
    justify-content: center;
  }
  .leftSide {
    .imgBoxLeft {
      img {
      }
    }
  }
  .center {
    color: #000000;
    text-align: center;
    position: relative;
    z-index: 10;
    .smallText {
      font-weight: 400;
      font-size: 15px;
      line-height: 140%;
      margin-bottom: 12px;
    }
    .text {
      font-weight: 700;
      font-size: 26px;
      line-height: 120%;
      max-width: 500px;
      /* or 38px */

      text-align: center;

      background: linear-gradient(
          174.92deg,
          #9e77ed 1.97%,
          rgba(133, 140, 255, 0.95) 102.23%
        ),
        #070707;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }
    .mediumText {
      font-weight: 300;
      font-size: 26px;
      line-height: 120%;
    }
    .btnBox {
      margin-top: 20px;
      a {
        text-decoration: none;
      }
      .btn {
        margin: 0 auto;
        color: #fff;
        width: max-content;
        background: #1b1b21;
        border: 0.5px solid #363636;
        border-radius: 8px;
        padding: 12px 16px;
      }
    }
    .rightSide {
      .imgBoxright {
        img {
        }
      }
    }
  }

  @media only screen and (max-width: 700px) {
    .mobil {
      display: block;
    }
    .pc {
      display: none;
    }
    position: relative;
    display: flex;
    flex-direction: column;
    /* width: 100%; */
    height: 270px;
    padding: 20px;
    /* background: #0e0e0e; */

    /* stroke */

    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
    /* border-radius: 16px; */
    /* background: #000000; */

    display: flex;
    justify-content: space-between;
    align-items: center;
    /* border: 1px solid #2d2f31; */
    .cer {
      width: 100%;
      position: absolute;
      left: auto;
      right: auto;
      top: -40px;
      /* bottom: -100px; */
      display: flex;
      justify-content: center;
      img {
        width: 300px;
        height: 300px;
      }
    }
    .leftSide {
      position: absolute;
        right: 0;
        bottom: auto;
        top: auto;
      z-index: 10;
      .imgBoxLeft {
        img {
          width: 200px;
        }
      }
    }
    .center {
      /* margin-top: -50px; */
      color: #000000;
      text-align: center;
      position: relative;
      z-index: 10;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: 100%;

      .text {
        max-width: 170px;
        font-weight: 700;
        font-size: 21px;
        line-height: 120%;
        /* or 38px */
        text-align: left;
        background: linear-gradient(
            174.92deg,
            #9e77ed 1.97%,
            rgba(133, 140, 255, 0.95) 102.23%
          ),
          #070707;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        background-clip: text;
        text-fill-color: transparent;
      }
      .mediumText {
        font-weight: 300;
        font-size: 26px;
        line-height: 120%;
      }
      .btnBox {
        margin-top: 12px;
        a {
          text-decoration: none;
        }
        .btn {
          font-size: 12px;
          margin: 0 0;
          color: #fff;
          width: max-content;
          background: #1b1b21;
          border: 0.5px solid #363636;
          border-radius: 8px;
          padding: 12px 16px;
        }
      }
      .rightSide {
       
        .imgBoxright {
          display: none;

          img {
          }
        }
      }
    }
  }
`;
export default CarouselCardHome4;
