import { useQuery } from '@apollo/client'
import { NFTSOLD  } from 'apollo/client'
import gql from 'graphql-tag'
import { useMemo } from 'react'
import { notEmpty } from 'utils'

export const QUERY_NFT_SOLD = gql`
query records {
    records {
      nft_count
    }
  }
`

interface NFTSoldRes {
    records: { nft_count: number }[] 
}

/**
 * Fetch unique owners
 */

export function useNFTSold(): {
    loading: boolean
    error: boolean,
    records: { nft_count: number }[] | undefined
} {
    const { loading, error, data } = useQuery<NFTSoldRes>(QUERY_NFT_SOLD, {
        client: NFTSOLD,
        fetchPolicy: 'cache-first',
    })
    const formattedData = useMemo(() => {
        if (data) {
            return data.records
                .map((p) => {
                    return {
                        nft_count: p.nft_count,
                    }
                })
                .filter(notEmpty)
        } else {
            return undefined
        }
    }, [data])
    return {
        loading: loading,
        error: Boolean(error),
        records: formattedData,
    }
}
