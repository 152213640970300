import React, {
  useRef,
  useCallback,
  useState,
  useEffect,
  useMemo,
} from "react";
import styled from "styled-components";
import Row, { RowBetween, RowFixed } from "components/Row";
import { useRoutes } from "react-router-dom";
import Column, { AutoColumn } from "components/Column";
import { TEXT } from "theme/texts";
import { HideSmall } from "components/Hide";
import { formatDollarAmount } from "utils/numbers";
import { POOL_HIDE } from "constant";
import { feeTierPercent } from "utils";
import { GreyBadge } from "components/Card";
import assets from "assets";
import SearchDD from "./SearchDD";
import axios from "axios";
import SearchHistoryUI, { searchItem } from "./SearchHistory";
import { GenericImageWrapper } from "components/Logo";
import PopularNFTCollections from "./PopularNFTCollections";
import Spacer from "components/Spacer";
import useMatchBreakpoints from "hooks/useMatchBreakpoints";
import { useOnClickOutside } from "hooks/useOnClickOutside";
import dayjs from "dayjs";

const Container = styled.div`
  position: relative;
  z-index: 30;
  width: 100%;
`;

const Wrapper = styled(Column)<{ hide: boolean }>`
  /* background-color: ${({ theme }) => theme.black}; */
  background-color: rgba(23, 56, 55, 1);
  
  padding: 0.5rem 0.75rem;
  /* width: auto; */
  top: -21px;
  right: 0px;
  min-width: 300px;
  position: absolute;
  z-index: 9999;
  border: 1px solid rgba(151, 165, 165, 1);
  /* border: 1px solid ${({ theme }) => theme.border}; */
  border-radius: ${({ theme }) => theme.borderRadius.search};

  ${({ theme }) => theme.mediaWidth.upToMedium`
     right:-20px;
      top:40px;
      min-width: 300px;

  `};
  display: ${({ hide }) => (hide ? "none" : "block")};
`;

const StyledInput = styled.input`
  position: relative;
  display: flex;
  align-items: center;
  white-space: nowrap;
  background: none;
  border: none;
  width: 100%;
  font-size: 16px;
  outline: none;
  /* color: ${({ theme }) => theme.body}; */
  color: rgba(151, 165, 165, 1);


  ::placeholder {
    /* color: ${({ theme }) => theme.subBody}; */
    color: rgba(151, 165, 165, 1);
    font-size: 0.9rem;
    ::focus {
      padding: 10px;
    }
  }

  ${({ theme }) => theme.mediaWidth.upToMedium`
    font-size: 14px;
    ::placeholder {
    color: ${({ theme }) => theme.subBody};
    font-size: 12px;
    ::focus {
      padding: 10px;
    }
  }
  `};
`;

const Menu = styled.div<{ hide: boolean }>`
  z-index: 9999;
  /* width: 366px; */
  min-height: 400px;
  max-height: 400px;
  position: relative;
  overflow: hidden;
  overflow-y: scroll;
  /* background-color: ${({ theme }) => theme.black}; */
  display: ${({ hide }) => (hide ? "none" : "block")};
  //border-radius: 12px;
  //border: 1px solid ${({ theme }) => theme.border};
  margin: 20px 10px 10px 10px;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    margin: 1px;
    width: 100%;
    min-height: 260px;
    max-height: 400px;
  `};

  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge add Firefox */
  -ms-overflow-style: none;
  scrollbar-width: none; /* Firefox */
`;

const Break = styled.div`
  height: 4px;
  ${({ theme }) => theme.mediaWidth.upToMedium`
  height: 2px;
  `};
`;

const HoverText = styled.div<{ hide?: boolean | undefined }>`
  color: ${({ theme }) => theme.blue};
  display: ${({ hide = false }) => hide && "none"};
  :hover {
    cursor: pointer;
    opacity: 0.6;
  }
`;

const HoverRowLink = styled.div`
  :hover {
    cursor: pointer;
    opacity: 0.6;
  }
`;

const OptionButton = styled.div<{ enabled: boolean }>`
  width: fit-content;
  padding: 4px 8px;
  border-radius: 8px;
  display: flex;
  font-size: 12px;
  font-weight: 600;
  margin-right: 10px;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme, enabled }) =>
    enabled ? theme.bgMiddle : "transparent"};
  color: ${({ theme, enabled }) => (enabled ? theme.white : theme.body)};
  :hover {
    opacity: 0.6;
    cursor: pointer;
  }
`;
const StyledSearchIcon = styled.img`
  width: 1rem;
  height: 1rem;
`;

const SearchCollectionWrappper = styled.div`
  ${({ theme }) => theme.mediaWidth.upToMedium`
  margin-top:20px;
  `};
`;

const DefaultCollectionWrappper = styled.div`
  ${({ theme }) => theme.mediaWidth.upToMedium`
  margin-top:20px;
  `};
`;

const SearchBar = ({ ...rest }: React.HTMLAttributes<HTMLDivElement>) => {
  // const history = useRoutes()
  const { isTablet, isMobile } = useMatchBreakpoints();

  const ref = useRef<HTMLInputElement>(null);
  const menuRef = useRef<HTMLDivElement>(null);
  const textRef = useRef<HTMLDivElement>(null);

  const handleDown = useCallback(() => {
    if (ref != null && ref.current !== null) {
      ref.current.focus();
    }
  }, []);

  const [focused, setFocused] = useState<boolean>(false);
  const [showMenu, setShowMenu] = useState(false);
  const [value, setValue] = useState("");
  const [showWatchlist, setShowWatchlist] = useState<any[]>([]);
  const [recentSearchlist, setRecentSearchlist] = useState<
    { contractAddress: string; name: string; iconLink: string; items: number }[]
  >([]);
  const [popularCollectionlist, setPopularCollectionlist] = useState<any[]>([]);

  // PERSITST THE SEARCH HERE
  const { searchQuery, searchResults } = useMemo(() => {
    const defaultData: { searchQuery: string; searchResults: [] } = {
      searchQuery: "",
      searchResults: [],
    };
    try {
      if (window.sessionStorage.getItem("storeRecentSearchList") === null) {
        window.sessionStorage.setItem(
          "storeRecentSearchList",
          JSON.stringify({ searchQuery: "", searchResults: [] })
        );
      }
      return JSON.parse(
        window.sessionStorage.getItem("storeRecentSearchList") || "{}"
      );
    } catch (e) {
      return defaultData;
    }
  }, [showMenu]);

  useEffect(() => {
    fetchPopularCollectionsData();
    const results: searchItem[] =
      (searchResults &&
        searchResults?.map((item: any) => {
          return {
            contractAddress: item.contractAddress,
            name: item.name,
            iconLink: item.logo,
            items: item.items,
            onClick: () => {
              setValue("");
              setShowMenu(false);
            },
          };
        })) ??
      [];
    setRecentSearchlist(results.slice(0, 2));
  }, [searchResults]);

  console.debug(
    "searchResults",
    searchResults,
    window.sessionStorage.getItem("storeRecentSearchList")
  );

  const [searchQueryText, setSearchQueryText] = useState<string>(
    searchQuery ? searchQuery : ""
  );

  const fetchSearch = async (value: string) => {
    try {
      console.log("fetchSearch", value);
      const response = await axios.get(
        `${process.env.REACT_APP_API_URL}/search?search=${value}`
      );
      const { data } = response.data;
      setShowWatchlist(data);
      // STORE THE DATA HERE
      setSearchQueryText(value);
      if (data && data[0] && data[0] !== undefined && data[0] !== "")
        if (
          [...searchResults].find(
            (element) => element.contractAddress === data[0].contractAddress
          ) === undefined
        ) {
          window.sessionStorage.setItem(
            "storeRecentSearchList",
            JSON.stringify({
              searchQuery: searchQueryText,
              searchResults: [...searchResults, data[0]],
            })
          );
        }
    } catch (e) {
      console.debug(e);
    }
  };

  useEffect(() => {
    if (!value.length) {
      fetchSearch(value);
    }
    if (value !== "") {
      console.log("fetchSearch", value);
      fetchSearch(value);
      setFocused(true);
    } else {
      setFocused(false);
    }
  }, [value]);
  const node = useRef<HTMLDivElement>(null);
  useOnClickOutside(node, () => setShowMenu(false));

  const handleClick = (e: any) => {
    // if (
    //   !(menuRef.current && menuRef.current.contains(e.target)) &&
    //   !(ref.current && ref.current.contains(e.target))
    // ) {
    //   setShowMenu(false);
    // }
  };

  useEffect(() => {
    // document.addEventListener("click", handleClick);
    // return () => {
    //   document.removeEventListener("click", handleClick);
    // };
  });

  // filter on view
  const results =
    showWatchlist &&
    showWatchlist?.map((item: any) => {
      return {
        contractAddress: item.contractAddress,
        name: item.name,
        iconLink: item.logo,
        items: item.items,
        onClick: () => {
          setValue("");
          setShowMenu(false);
        },
      };
    });

  const onRemoveFromRecentSearchClick = (contractAddress: string) => {
    let data = [...recentSearchlist];
    setRecentSearchlist(
      data.filter((value) => value.contractAddress !== contractAddress)
    );
    const result =
      searchResults &&
      [...searchResults].filter(
        (element: { contractAddress: string }) =>
          element.contractAddress !== contractAddress
      );
    window.sessionStorage.setItem(
      "storeRecentSearchList",
      JSON.stringify({ searchQuery: searchQueryText, searchResults: result })
    );
  };

  // Fetch Popular Collection callbacks
  const fetchPopularCollectionsData = async () => {
    try {
      let res = await axios.get(
        process.env.REACT_APP_API_URL + `/topCollection?duration=${3}&page=${1}`
      );
      console.debug(res.data);
      if (
        res &&
        res.data &&
        res.data.data &&
        res.data.data !== undefined &&
        res.data.data !== ""
      ) {
        const formattedData = (res.data.data as Array<any>).map(
          (value: {
            contractAddress: string;
            name: string;
            logo: string;
            supply: number;
          }) => {
            return {
              contractAddress: value.contractAddress,
              name: value.name,
              iconLink: value.logo,
              items: value.supply,
              onClick: () => {
                setValue("");
                setShowMenu(false);
              },
            };
          }
        );
        setPopularCollectionlist(formattedData);
      }
    } catch (e) {
      console.debug("fetchPopularCollectionsData  ERROR:", e);
    }
  };
  //

  const SearchField = () => {
    return (
      <StyledInput
        type="text"
        value={value}
        onChange={(e) => {
          setValue(e.target.value);
        }}
        placeholder="Search a collection, token or protocol"
        ref={ref}
        onFocus={() => {
          setFocused(true);
          setShowMenu(true);
        }}
        onBlur={() => setFocused(false)}
      />
    );
  };

  return (
    <Container ref={node}>
      {(isTablet || isMobile) && (
        <StyledSearchIcon
          onClick={() => {
            setFocused(true);
            setShowMenu(true);
          }}
          src={assets.icons.searchIcon}
        />
      )}
      <Wrapper {...rest} hide={!showMenu && (isTablet || isMobile)}>
        {!(isTablet || isMobile) ? (
          <RowBetween gap="8px">
            {focused || showMenu ? (
              <GenericImageWrapper
                style={{ cursor: "pointer", zIndex: "100" }}
                size="14px"
                src={assets.icons.closeIcon}
                onClick={() => {
                  setFocused(false);
                  setShowMenu(false);
                }}
              />
            ) : (
              <StyledSearchIcon
                onClick={() => {
                  setValue("");
                  ref.current?.focus();
                }}
                src={assets.icons.searchIcon}
              />
            )}
            {SearchField()}
          </RowBetween>
        ) : (
          <></>
        )}

        <Menu hide={!showMenu} ref={menuRef}>
          {(isTablet || isMobile) && (
            <RowBetween>
              {SearchField()}
              {focused ? (
                <GenericImageWrapper
                  style={{ cursor: "pointer", zIndex: "100" }}
                  size="14px"
                  src={assets.icons.closeIcon}
                  onClick={() => {
                    setFocused(false);
                    setShowMenu(false);
                  }}
                />
              ) : (
                <StyledSearchIcon
                  onClick={() => {
                    setValue("");
                    ref.current?.focus();
                  }}
                  src={assets.icons.searchIcon}
                />
              )}
            </RowBetween>
          )}
          {value?.length > 0 ? (
            <SearchCollectionWrappper>
              <SearchDD
                list={results.filter((item) =>
                  item?.name
                    ?.split(" ")
                    ?.join(" ")
                    ?.toLowerCase()
                    ?.includes(value?.toLowerCase())
                )}
              />
            </SearchCollectionWrappper>
          ) : (
            <DefaultCollectionWrappper>
              <Row gap="8px">
                <GenericImageWrapper src={assets.icons.recentIcon} />
                <TEXT.SubBody>Recents</TEXT.SubBody>
              </Row>
              <Break />
              <SearchHistoryUI
                list={recentSearchlist}
                onRemoveClick={onRemoveFromRecentSearchClick}
              />
              <Break />
              <Break />
              <Row gap="8px">
                <GenericImageWrapper src={assets.icons.trendingUpIcon} />
                <TEXT.SubBody>Popular NFT collections</TEXT.SubBody>
              </Row>
              <Break />
              <PopularNFTCollections list={popularCollectionlist} />
            </DefaultCollectionWrappper>
          )}
        </Menu>
      </Wrapper>
    </Container>
  );
};

export default SearchBar;

const historyData = [
  {
    id: 1,
    name: "ARTIFACT DIGITAL",
    iconLink:
      "https://polygon.nftscan.com/static/ic-loading-error.7dc1dd36.png",
  },
  {
    id: 2,
    name: "ARTIFACT DIGITAL Simba",
    iconLink:
      "https://polygon.nftscan.com/static/ic-loading-error.7dc1dd36.png",
  },
];

const topCollectionsData = [
  {
    id: 1,
    name: "ARTIFACT DIGITAL",
    iconLink:
      "https://polygon.nftscan.com/static/ic-loading-error.7dc1dd36.png",
  },
  {
    id: 2,
    name: "ARTIFACT DIGITAL Simba",
    iconLink:
      "https://polygon.nftscan.com/static/ic-loading-error.7dc1dd36.png",
  },
  {
    id: 3,
    name: "ARTIFACT DIGITAL Baboon",
    iconLink:
      "https://polygon.nftscan.com/static/ic-loading-error.7dc1dd36.png",
  },
];
