import axios from "axios";
import ContentCardCtr from "components/ContentCard";
import DataTable from "../DataTable";
import { DropDownCard } from "components/DropDown";
import QuestionHelper from "components/QuestionHelper";
import Row from "components/Row";
import React, { useCallback, useEffect, useState, useRef } from "react";
import { AlertCircle, ArrowLeft, ArrowRight, Search } from "react-feather";
import styled from "styled-components";
import { TEXT } from "theme/texts";
import { useCurrencyUnit } from "store/user/hooks";
import SKLTCollectionsTable from "components/Skeletons/CollectionsPage/SKLTCollectionsTable";
import {
  GenericImageWrapper,
  SnapshotBrandLogoImageWrapper,
} from "components/Logo";
import { isMobile } from "react-device-detect";
import useTakeScreenshot from "hooks/useTakeSnapshot";
import assets from "assets";

const CollectionsTableNav = ({ results, page, setPage }) => {
  const firstPage = () => {
    if (page > 1) setPage(1);
  };
  const prevPage = () => {
    if (page > 1) setPage((prev) => (prev -= 1));
  };

  const nextPage = () => {
    if (page < results.totalPages) setPage((prev) => (prev += 1));
  };
  const lastPage = () => {
    if (page < results.totalPages) setPage(results.totalPages);
  };



  const pagesArray = Array(results.totalPages)
    .fill()
    .map((_, index) => index + 1);
  return results?.totalPages > 0 ? (
    <div className="_navs">
      <button className="_staticNavs" onClick={firstPage}>
        First
      </button>
      <nav className="_tableNav">
        <button onClick={prevPage} disabled={page === 0} className="_navBtn">
          <ArrowLeft size={"1rem"} />
        </button>
        {/* Removed isPreviousData from PageButton to keep button focus color instead */}

        <button className="_navPageBtn">
          Page {page} of {results.totalPages}
        </button>

        <button
          onClick={() => nextPage(results)}
          disabled={page === results.totalPages}
          className="_navBtn"
        >
          <ArrowRight size={"1rem"} />
        </button>
      </nav>
      <button className="_staticNavs" onClick={lastPage}>
        Last
      </button>
    </div>
  ) : null;
};

const AllChainCollectionsTable = () => {
  //constants
  const SORT_DIR = { ASEC: "asc", DESC: "desc" };
  const SORT_BY = {
    FLOOR: "floor_price",
    VOL1: "volume_1d",
    VOL7: "volume_7d",
    VOL30: "volume_30d",
    SALES1: "sales_1d",
    SALES7: "sales_7d",
    SALES30: "sales_30d",

    SUPPLY: "supply",
  };
  const { currency } = useCurrencyUnit();
  //states
  const [tableData, setTableData] = useState(null);
  const [page, setPage] = useState(1);
  const [sortBy, setSortBy] = useState("volume_1d");
  const [sortDir, setSortDir] = useState("desc");
  const [query, setQuery] = useState(""); //page query endpoint
  const [chain, setChain] = useState("crosschain");
  const [currentPageData, setCurrentPageData] = useState([]);

  const [currentTime, setCurrentTime] = useState({ active: 0, duration: "1d" });
  const [supplyLimit, setSupplyLimit] = useState(0);
  const [category, setCategory] = useState({
    id: 0,
    name: "All",
  });

  const refCard = useRef(null);
  const snapshotDownloadIconId = `snapshot-${2}-download-icon`;
  const snapshotBrandLogoId = `snapshot-${2}-brand-logo`;

  const downloadScreenshot = useTakeScreenshot({
    ref: refCard,
    elementIdsTohide: [snapshotDownloadIconId],
    elementIdsToShow: [snapshotBrandLogoId],
  });


  //callbacks
  const fetchData = useCallback(async () => {
   
      let res = await axios.get(
        `${process.env.REACT_APP_API_URL}/topCollectionCrosschain?chain=${chain}&page=${page}&sortBy=${sortBy}&duration=${currentTime.duration}&sortDirection=${sortDir}`
      );
      setTableData(res?.data);

   

   
  }, [currentTime, page, chain, sortBy, sortDir]);
  const sortItem = ({ sort_by }) => {
    setSortBy(sort_by);
    setSortDir((prev) =>
      prev === SORT_DIR.ASEC ? SORT_DIR.DESC : SORT_DIR.ASEC
    );
  };
  const columns = [
    {
      id: 1,
      heading: "",
      value: "no",
    },

    {
      id: 2,
      heading: "Name",
      value: "contract_name",
      link: "contractAddress",
      logo: "logo_url",
      chain: "chain",

    },
    {
      id: 3,
      heading: "Volume",
      value:
        currentTime?.duration === "1d"
          ? "volume_1d"
          : currentTime?.duration === "7d"
          ? "volume_7d"
          : currentTime?.duration === "30d"
          ? "volume_30d"
          : "volume_total",
      action: () => {
        sortItem({
          sort_by:
            currentTime?.duration === "1d"
              ? SORT_BY.VOL1
              : currentTime?.duration === "7d"
              ? SORT_BY.VOL7
              : currentTime?.duration === "30d"
              ? SORT_BY.VOL30
              : SORT_BY.VOL30,
        });
      },
      sort_dir: sortDir,
      sort_item:
        currentTime?.duration === "1d"
          ? SORT_BY.VOL1
          : currentTime?.duration === "7d"
          ? SORT_BY.VOL7
          : currentTime?.duration === "30d"
          ? SORT_BY.VOL30
          : SORT_BY.VOL30,
    },
    {
      id: 4,
      heading: "Sales",
      value:
        currentTime?.duration === "1d"
          ? "sales_1d"
          : currentTime?.duration === "7d"
          ? "sales_7d"
          : currentTime?.duration === "30d"
          ? "sales_30d"
          : "sales_total",
      action: () => {
        sortItem({
          sort_by:
            currentTime?.duration === "1d"
              ? SORT_BY.SALES1
              : currentTime?.duration === "7d"
              ? SORT_BY.SALES7
              : currentTime?.duration === "30d"
              ? SORT_BY.SALES30
              : SORT_BY.SALES30,
        });
      },
      sort_dir: sortDir,
      sort_item:
        currentTime?.duration === "1d"
          ? SORT_BY.SALES1
          : currentTime?.duration === "7d"
          ? SORT_BY.SALES7
          : currentTime?.duration === "30d"
          ? SORT_BY.SALES30
          : SORT_BY.SALES30,
    },
    {
      id: 5,
      heading: "Floor",
      value:
        currency?.symbol === "USD"
          ? "floor_price"
          : currency?.symbol === "ETH"
          ? "floor_price"
          : "floor_price",
      action: () => {
        sortItem({ sort_by: SORT_BY.FLOOR });
      },
      sort_dir: sortDir,
      sort_item: SORT_BY.FLOOR,
    },
    // {
    //   id: 6,
    //   heading: "Floor Change",
    //   value: "floor_change",
    // },
    {
      id: 7,
      heading: "Supply",
      value: "items_total",
      // action: () => {
      //   sortItem({ sort_by: SORT_BY.SUPPLY });
      // },
    },
    {
      id: 8,
      heading: "Avg Price",

      value:
        currentTime?.duration === "1d"
          ? "average_price_1d"
          : currentTime?.duration === "7d"
          ? "average_price_7d"
          : currentTime?.duration === "30d"
          ? "average_price_30d"
          : "average_price_30d",
    },
    {
      id: 9,
      heading: "Volume Change",
      value:
        currentTime?.duration === "1d"
          ? "volume_change_1d"
          : currentTime?.duration === "7d"
          ? "volume_change_7d"
          : currentTime?.duration === "30d"
          ? "volume_change_30d"
          : "volume_change_30d",
    },
    {
      id: 10,
      heading: "Sales Change",
      value:
        currentTime?.duration === "1d"
          ? "sales_change_1d"
          : currentTime?.duration === "7d"
          ? "sales_change_7d"
          : currentTime?.duration === "30d"
          ? "sales_change_30d"
          : "sales_change_30d",
    },
    // {
    //   id: 11,
    //   heading: "Wash Trades",
    //   value:
    //     currency?.symbol === "USD"
    //       ? "wash_trades_in_usd"
    //       : currency?.symbol === "ETH"
    //       ? "wash_trades_in_eth"
    //       : "wash_trades_in_matic",
    // },
  ];

  

  useEffect(() => {
    
    fetchData();
  }, [fetchData]);


  return (
    <CollectionsTableCtr ref={refCard}>
      <ContentCardCtr hideHover={false}>
        <div className="_tableHeader">
          <TEXT.MediumHeader>
            <span>Top Collections</span>
            <QuestionHelper text="Top NFT collections across  ecosystem" />
          </TEXT.MediumHeader>
          <div className="_rightHeaderCtr">
            {/* <div className="_searchResultsBar">
              <input
                type="search"
                name="search_item"
                id=""
                onChange={(e) => {
                  setQuery(e.target.value);
                  setPage(1);
                }}
                placeholder="Search by collection"
              />
              <Search color="grey" />
            </div>
              */}
            {/* <div className="_timelines">
             <input type="checkbox" id="reddit" name="reddit" value={reddit} onChange={(e) => {
                setReddit(e.target.checked);
              }}/>
              <label for="reddit">  Reddit Avatars</label>

              </div> */}

            <div className="_timelines">
              <DropDownCard
                data={[
                  {
                    val: 0,
                    label: "CrossChain ",
                    action: () => {
                      setSupplyLimit(0);
                      setChain("crosschain");
                      setPage(1)
                    },
                  },

                  {
                    val: 1,
                    label: "Ethereum",
                    action: () => {
                      setSupplyLimit(1);
                      setChain("rest");
                      setPage(1)

                    },
                  },
                  {
                    val: 2,
                    label: "Polygon",
                    action: () => {
                      setSupplyLimit(2);
                      setChain("polygon");
                      setPage(1)

                    },
                  },
                  {
                    val: 3,
                    label: "BNB Chain",
                    action: () => {
                      setSupplyLimit(3);
                      setChain("bnb");
                      setPage(1)

                    },
                  },
                  {
                    val: 4,
                    label: "Arbitrum One",
                    action: () => {
                      setSupplyLimit(4);
                      setChain("arbitrum");
                      setPage(1)

                    },
                  },
                  {
                    val: 5,
                    label: "Optimism",
                    action: () => {
                      setSupplyLimit(5);
                      setChain("optimism");
                      setPage(1)

                    },
                  },
                  {
                    val: 6,
                    label: "Avalanche",
                    action: () => {
                      setSupplyLimit(6);
                      setChain("avax");
                      setPage(1)

                    },
                  },
                  // {
                  //   val: 7,
                  //   label: "Cronos",
                  //   action: () => {
                  //     setSupplyLimit(7);
                  //     setChain("cronos");
                  //   },
                  // },
                  // {
                  //   val: 8,
                  //   label: "PlatON",
                  //   action: () => {
                  //     setSupplyLimit(8);
                  //     setChain("platon");
                  //   },
                  // },
                  // {
                  //   val: 9,
                  //   label: "Moonbeam",
                  //   action: () => {
                  //     setSupplyLimit(9);
                  //     setChain("moonbeam");
                  //   },
                  // },
                  {
                    val: 7,
                    label: "Fantom",
                    action: () => {
                      setSupplyLimit(7);
                      setChain("fantom");
                      setPage(1)

                    },
                  },
                  // {
                  //   val: 11,
                  //   label: "Gnosis",
                  //   action: () => {
                  //     setSupplyLimit(11);
                  //     setChain("gnosis");
                  //   },
                  // },
                ]}
                activeTab={supplyLimit}
              />
            </div>

            <div className="_timelines">
              <DropDownCard
                data={[
                  {
                    val: 0,
                    label: "1 day",
                    action: () => {
                      setCurrentTime({ active: 0, duration: "1d" });
                    },
                  },
                  {
                    val: 1,
                    label: "7 day",
                    action: () => {
                      setCurrentTime({ active: 1, duration: "7d" });
                    },
                  },
                  {
                    val: 2,
                    label: "30 Day",
                    action: () => {
                      setCurrentTime({ active: 2, duration: "30d" });
                    },
                  },
                  // {
                  //   val: 3,
                  //   label: "All",
                  //   action: () => {
                  //     setCurrentTime({ active: 3, duration: "all" });
                  //   },
                  // },
                ]}
                activeTab={currentTime.active}
              />
            </div>

            <GenericImageWrapper
              id={snapshotDownloadIconId}
              className="_cardDownloadIcon"
              onClick={() => downloadScreenshot()}
              src={assets.icons.roundedDownloadIcon}
              size="2rem"
            />
            <SnapshotBrandLogoImageWrapper
              id={snapshotBrandLogoId}
              src={assets.logos.surfaceboardXYZLogo}
              size="6rem"
              style={{ marginRight: isMobile ? "8px" : "10px" }}
            />
          </div>
        </div>
        {tableData?.data?.length > 0 ? (
          <DataTable
            column={columns}
            data={tableData?.data}
            sortItemLabel={sortBy}
            chain={chain}
          />
        ) : (
          <SKLTCollectionsTable />
        )}
      </ContentCardCtr>
      {tableData?.data?.length > 0 ? (
        <CollectionsTableNav
          results={tableData}
          page={page}
          setPage={setPage}
          setChain
          chain
        />
      ) : null}
    </CollectionsTableCtr>
  );
};

const CollectionsTableCtr = styled.div`
  gap: 0;
  margin: 1rem 0;

  ._navs {
    ${(props) => props?.theme?.flexRowNoWrap}
    gap:.25rem;
    width: fit-content;
    margin: 1rem;
    margin-left: auto;
    button {
      background: none;
      outline: none;
      border: none;
      color: ${(props) => props?.theme?.body};
      font-family: var(--ff-subtitle);
      display: grid;
      place-items: center;
      padding: 0.5rem;
      font-size: var(--fs-s) !important;
    }

    ._staticNavs {
      width: 70px;
      border: 1px solid ${(props) => props?.theme?.btnSecondary};
      border-radius: 2rem;
    }

    ._tableNav {
      ${(props) => props?.theme?.flexRowNoWrap}
      gap:.25rem;
      align-items: center;
      justify-content: center;
      width: fit-content;
      border: 1px solid ${(props) => props?.theme?.btnSecondary};
      border-radius: 2rem;
      ._navPageBtn {
        min-width: 100px;
      }
      ._navBtn {
        &:nth-of-type(1) {
          border-right: 1px solid ${(props) => props?.theme?.disabled};
        }
        &:nth-of-type(3) {
          border-left: 1px solid ${(props) => props?.theme?.disabled};
        }
      }
    }

    //@media quries : mobile screens
    ${({ theme }) => theme.mediaWidth.upToLarge`
      margin:1rem auto;
      `}
  }

  ._tableHeader {
    padding: 1.25rem 1rem;
    ${(props) => props?.theme?.flexRowNoWrap}
    justify-content: space-between;
    align-items: center;
    ._rightHeaderCtr {
      display: flex;
      align-items: center;
      gap: 1rem;
    }
    ._tableTitle {
      font-family: var(--ff-title);
      font-size: var(--fs-l);
      display: flex;
      gap: 0.5rem;
    }
    ._searchResultsBar {
      ${(props) => props?.theme?.flexRowNoWrap}
      align-items: center;
      gap: 0.5rem;
      border: 1px solid ${(props) => props?.theme?.disabled};
      width: 200px;
      padding: 0 0.5rem;
      border-radius: 0.25rem;

      input {
        background: none;
        color: ${(props) => props?.theme?.body};
        border: none;
        outline: none;
        padding: 0.5rem;
        font-family: var(--ff-subtitle);
      }
    }

    //@media quries : mobile screens
    ${({ theme }) => theme.mediaWidth.upToExtraSmall`
        flex-direction:column;
        gap:1rem;
        align-items:flex-start;
        padding:.75rem 1rem;
        ._rightHeaderCtr{
            // flex-direction:column;
            justify-content: flex-start;
        // width:fit-content;
        align-items:flex-start;
        // margin-right:auto;
        }
      `}
  }
`;

export default AllChainCollectionsTable;
