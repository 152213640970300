import { SmallOptionButton } from "components/Button";
import BarChart from "components/Chart/BarChart";
import LineChart from "components/Chart/LineChart";
import { AutoColumn } from "components/Column";
import YearMonthPickerComponent from "components/DateTime/YearMonthPickerComponent";
import { HorizontalDivider } from "components/Divider/inde";
import GraphTableTopHeader from "components/HeaderComponent/GraphTableTopHeader";
import { RowBetween } from "components/Row";
import { TabularNumsText } from "components/shared";
import { Currencies, TypeCurrency } from "constant/currency";
import { useNFTMarketCap } from "data/nft/marketCap";
import dayjs from "dayjs";
import useMatchBreakpoints from "hooks/useMatchBreakpoints";
import useTakeScreenshot from "hooks/useTakeSnapshot";
import useTheme from "hooks/useTheme";
import React, { useEffect, useMemo, useState } from "react";
import { useCurrencyUnit } from "store/user/hooks";
import { TEXT } from "theme/texts";
import { GenericChartSeries, TimeWindow } from "types";
import getTimewindowFilteredData from "utils/chart";
import { formatAmount, formatDollarAmount } from "utils/numbers";
import {
  appRoutes,
  getScrollToElementPath,
  marketOverviewPageElementIDs,
} from "utils/path";
import {
  ChartTopRightSectionWrapper,
  ChartWrapper,
  ListButtonWrapper,
} from "./styled";
import { GenericImageWrapper } from "components/Logo";

const twitterShareScrollPath = getScrollToElementPath(
  appRoutes.marketOverview,
  marketOverviewPageElementIDs.nftMarketCap
);
const snapshotDownloadIconId = "snapshot-nft-marketcap-download-icon";
const snapshotBrandLogoId = "snapshot-nft-marketcap-brand-logo";

const TopRightComponent = ({
  timeWindow,
  setTimeWindow,
  isMobileView,
  onCalendarChange,
  selectedCustomTime,
}: {
  timeWindow: TimeWindow;
  setTimeWindow: React.Dispatch<React.SetStateAction<TimeWindow>>;
  isMobileView: boolean;
  selectedCustomTime: { year: number; month: number } | undefined;
  onCalendarChange: (selectedYear: number, selectedMonth: number) => void;
}) => {
  return (
    <ChartTopRightSectionWrapper key="top-right">
      <ListButtonWrapper>
        {[
          TimeWindow.threeMonth,
          TimeWindow.sixMonth,
          TimeWindow.oneYear,
          TimeWindow.all,
          TimeWindow.custom,
        ].map((option, index) => {
          return (
            <SmallOptionButton
              key={index}
              active={timeWindow === option}
              onClick={() => setTimeWindow(option)}
            >
              {option === "custom" ? (
                <YearMonthPickerComponent
                  year={
                    selectedCustomTime
                      ? selectedCustomTime.year
                      : dayjs().year()
                  }
                  month={
                    selectedCustomTime
                      ? selectedCustomTime.month
                      : dayjs().month()
                  }
                  onChange={onCalendarChange}
                  isMobileView={isMobileView}
                />
              ) : (
                option
              )}
            </SmallOptionButton>
          );
        })}
      </ListButtonWrapper>
    </ChartTopRightSectionWrapper>
  );
};

const TopLeftComponent = ({
  isMobileView,
  filteredTotalCount,
  selectedCurrency,
}: {
  isMobileView: boolean;
  filteredTotalCount: number;
  selectedCurrency: TypeCurrency;
}) => {
  return (
    <RowBetween key="top-left" width={isMobileView ? "100%" : "auto"}>
      <AutoColumn gap="10px">
        <TEXT.LargeHeader fontSize={"clamp(1.75rem,3vw,2.5rem)"}>
          <TabularNumsText>
            <GenericImageWrapper
              src={selectedCurrency.logo}
              size={isMobileView ? "26px" : "28px"}
            />
            {`
          ${formatAmount(filteredTotalCount).toUpperCase()}`}
          </TabularNumsText>
        </TEXT.LargeHeader>
      </AutoColumn>
    </RowBetween>
  );
};

export type graphProps = {
  refNFTMarketGraph: React.RefObject<HTMLElement>;
};
type typeChartData = {
  dateTime: string;
  category?: string;
  marketCapUSD: number;
  marketCapInMatic: number;
  marketCapInWETH: number;
};

function NFTMarketCapGraph({ refNFTMarketGraph }: graphProps) {
  const theme = useTheme();
  const [volumeHover, setVolumeHover] = useState<number | undefined>();
  const [liquidityHover, setLiquidityHover] = useState<number | undefined>();
  const [leftLabel, setLeftLabel] = useState<string | undefined>();
  const [rightLabel, setRightLabel] = useState<string | undefined>();
  const [timeWindow, setTimeWindow] = useState(TimeWindow.all);
  const [showBarGraph, setShowBarGraph] = useState(true);
  const { isMobile } = useMatchBreakpoints();

  const { data: nftMarketCapData } = useNFTMarketCap();
  const reverseNFTMarketCapData = useMemo(() => {
    return nftMarketCapData || [];
  }, [nftMarketCapData]);
  const { currency } = useCurrencyUnit();

  useEffect(() => {
    setLiquidityHover(undefined);
    setVolumeHover(undefined);
  }, []);

  const downloadScreenshot = useTakeScreenshot({
    ref: refNFTMarketGraph,
    elementIdsTohide: [snapshotDownloadIconId],
    elementIdsToShow: [snapshotBrandLogoId],
  });
  const [selectedCustomTime, setSelectedCustomTime] = useState<
    { year: number; month: number } | undefined
  >(undefined);

  const onCalendarChange = (year: number, month: number) => {
    setSelectedCustomTime({ year: year, month: month });
  };

  const getDataByTime = (data: typeChartData[]): typeChartData[] => {
    const filteredD: {
      [x: string]: typeChartData;
    } = {};
    data.map((value) => {
      if (filteredD[value.dateTime] === undefined) {
        filteredD[value.dateTime] = {
          dateTime: value.dateTime,
          marketCapUSD: value.marketCapUSD,
          marketCapInMatic: value.marketCapInMatic,
          marketCapInWETH: value.marketCapInWETH,
        };
      } else {
        filteredD[value.dateTime] = {
          dateTime: value.dateTime,
          marketCapUSD:
            filteredD[value.dateTime].marketCapUSD + value.marketCapUSD,
          marketCapInMatic:
            filteredD[value.dateTime].marketCapInMatic + value.marketCapInMatic,
          marketCapInWETH:
            filteredD[value.dateTime].marketCapInWETH + value.marketCapInWETH,
        };
      }
    });
    return Object.keys(filteredD).map((key) => {
      return {
        dateTime: key,
        marketCapUSD: filteredD[key].marketCapUSD,
        marketCapInMatic: filteredD[key].marketCapInMatic,
        marketCapInWETH: filteredD[key].marketCapInWETH,
      };
    });
  };

  /**
   * @notice
   * @dev 'formattedVolumeData' function format data according to graph plot format
   * @dev 'getTransformedVolumeData' function filter chart data according to selected timewindow
   * @dev 'formattedChartSeries' change according to requirement
   * @param timewindow is selected option out of provided time options to user for filtering chart data
   * @param time will be for x-axis
   * @param value... must be same as @param datakey of config variable 'formattedChartSeries'
   */
  const formattedChartData = useMemo(() => {
    if (
      nftMarketCapData &&
      reverseNFTMarketCapData &&
      reverseNFTMarketCapData?.length > 0
    ) {
      const totalData = getDataByTime(
        getTimewindowFilteredData(
          reverseNFTMarketCapData,
          timeWindow,
          selectedCustomTime
        ) as typeChartData[]
      ).map((record: any) => {
        return {
          time: record.dateTime,
          valueNFTVolume:
            currency.id === Currencies.USD
              ? record.marketCapUSD
              : currency.id === Currencies.MATIC
              ? record.marketCapInMatic
              : record.marketCapInWETH,
        };
      });
      return totalData;
    } else {
      return [];
    }
  }, [
    nftMarketCapData,
    reverseNFTMarketCapData,
    timeWindow,
    selectedCustomTime,
    currency.id,
  ]);

  /**
   * @notice
   * @dev 'formattedChartSeries'  must change according to requirement
   * Add according requirement (This will plot line graph with provided color and data)
   */
  const formattedChartSeries: GenericChartSeries[] = [
    { dataKey: "valueNFTVolume", name: "NFT Volume", color: theme.btnSecondary },
  ];

  const filteredTotalCount: number = useMemo(() => {
    if (
      nftMarketCapData &&
      reverseNFTMarketCapData &&
      reverseNFTMarketCapData.length > 0
    ) {
      let previousToCurrentTimeTotal = 0;
      formattedChartData?.forEach((singleData) => {
        previousToCurrentTimeTotal =
          previousToCurrentTimeTotal + (singleData.valueNFTVolume ?? 0);
      });
      return previousToCurrentTimeTotal;
    } else {
      return 0;
    }
  }, [formattedChartData, nftMarketCapData, reverseNFTMarketCapData]);

  return (
    <>
      <GraphTableTopHeader
        title={"NFT Market Cap"}
        tooltip="Worth of all NFTs in Matic across all marketplaces"
        showBarGraph={showBarGraph}
        setShowBarGraph={setShowBarGraph}
        downloadScreenshot={downloadScreenshot}
        isMobileView={isMobile}
        snapshotBrandLogoId={snapshotBrandLogoId}
        snapshotDownloadIconId={snapshotDownloadIconId}
        tweetText={`https://twitter.com/intent/tweet?text=💜 Polygon NFT insights: Total Market cap%0A📆 for: ${timeWindow}%0A🎯 ${formatDollarAmount(
          filteredTotalCount
        )} %0A%0A🔗Check out the detailed chart on MNFST Tools by @MNFSTLabs here: ${twitterShareScrollPath} `}
      />
      <HorizontalDivider />
      {showBarGraph ? (
        <ChartWrapper id="nftmarketcapchart">
          <BarChart
            data={formattedChartData}
            series={formattedChartSeries}
            yAxisLabel="NFT Market Cap"
            setValue={setVolumeHover}
            setLabel={setRightLabel}
            value={volumeHover}
            label={rightLabel}
            activeWindow={timeWindow}
            topRight={
              <TopRightComponent
                timeWindow={timeWindow}
                setTimeWindow={setTimeWindow}
                isMobileView={isMobile}
                selectedCustomTime={selectedCustomTime}
                onCalendarChange={onCalendarChange}
              />
            }
            topLeft={
              <TopLeftComponent
                isMobileView={isMobile}
                filteredTotalCount={filteredTotalCount}
                selectedCurrency={currency}
              />
            }
          />
        </ChartWrapper>
      ) : (
        <ChartWrapper id="nftmarketcapchart">
          <LineChart
            data={formattedChartData}
            series={formattedChartSeries}
            yAxisLabel="NFT Market Cap"
            setValue={setVolumeHover}
            setLabel={setRightLabel}
            value={volumeHover}
            label={rightLabel}
            activeWindow={timeWindow}
            itemID="nft-market-cap-graph"
            topRight={
              <TopRightComponent
                timeWindow={timeWindow}
                setTimeWindow={setTimeWindow}
                isMobileView={isMobile}
                selectedCustomTime={selectedCustomTime}
                onCalendarChange={onCalendarChange}
              />
            }
            topLeft={
              <TopLeftComponent
                isMobileView={isMobile}
                filteredTotalCount={filteredTotalCount}
                selectedCurrency={currency}
              />
            }
          />
        </ChartWrapper>
      )}
    </>
  );
}

export default NFTMarketCapGraph;
