import ContentCardCtr from "components/ContentCard";
import { useState } from "react";
import styled from "styled-components";
import QuestionHelper from "components/QuestionHelper";
import GainLossPercentComponent from "../../../../components/Percent";
import assets from "assets";
import { useCurrencyUnit } from "store/user/hooks";
import SKLTCollectionCard from "components/Skeletons/CollectionsPage/SKLTCollectionCard";
import Column from "components/Column";
import { IconWrapper } from "theme/components";
import { CircledImageCtr } from "components/Logo";

const CollectionCard = ({ card, time }) => {
  const [timeframe, setTimeframe] = useState(0); //0-> 1hr, 1-> 1day, 2-> alltime
  const { currency } = useCurrencyUnit();
  return (
    <CollectionCardCtr>
      {card?.value !== "-" ? (
        <Column style={{ gap: "1rem" }}>
          <div className="_cardHeader">
            <div className="textT">
              {card.title} <QuestionHelper text={card.toolTip} />
            </div>

            {card?.showTimePeriod ? (
              <p className="_cardNav">
                <button
                  onClick={() => {
                    time("3M");
                    setTimeframe(0);
                  }}
                  className={timeframe === 0 ? "activeCardTab" : null}
                >
                  3M
                </button>
                <button
                  onClick={() => {
                    time("6M");
                    setTimeframe(1);
                  }}
                  className={timeframe === 1 ? "activeCardTab" : null}
                >
                  6M
                </button>
                <button
                  onClick={() => {
                    time("1Y");
                    setTimeframe(2);
                  }}
                  className={timeframe === 2 ? "activeCardTab" : null}
                >
                  1Y
                </button>
              </p>
            ) : null}
          </div>
          <EntityCardCtr>
            <div className="entityCred">
              {card?.showLogo ? (
                currency?.symbol === "USD" ? (
                  <img
                    src={assets.logos.usdLogo}
                    alt=""
                    className="usd"
                    style={{ height: "1.25rem" }}
                  />
                ) : currency?.symbol === "ETH" ? (
                  <img
                    src={assets.logos.ethLogo}
                    alt=""
                    className="eth"
                    style={{ width: "1.5rem" }}
                  />
                ) : (
                  <img
                    src={assets.logos.matic}
                    alt=""
                    className="matic"
                    style={{ width: "1.5rem" }}
                  />
                )
              ) : null}
              <p className="entityVol">
                {card.dollarSign ? "$ " : null}
                {card?.value}
              </p>
            </div>

            <GainLossPercentComponent
              value={card?.price_change}
              fontSize=".8rem"
            />
          </EntityCardCtr>
        </Column>
      ) : (
        <SKLTCollectionCard />
      )}
    </CollectionCardCtr>
  );
};

const CollectionCardCtr = styled(ContentCardCtr)`
  border: 1px solid ${({ theme }) => theme.btnSecondary};
  ${({ theme }) => theme.flexColumnNoWrap}
  background: ${({ theme }) => theme.disabledVar2};
  position: relative;
  overflow: hidden;
  min-height: 100%;
  
  &::before {
    content: "";
    position: absolute;
    left: 0px;
    top: 0px;
    right: 0px;
    bottom: 0px;
    background: ${({ theme }) => theme.activeRadialGradient};
    opacity: 0;
    -webkit-transition: all 500ms ease-out;
    -moz-transition: all 500ms ease-out;
    -o-transition: all 500ms ease-out;
    transition: all 500ms ease-out;
    z-index: 1;
    user-select: none;
    pointer-events: none;
  }
  &:hover::before {
    opacity: ${(props) => (props?.hideHover ? 0 : 0.4)};
  }
  padding: 1rem;

  ${({ theme }) => theme.mediaWidth.upToMedium`
  display:flex;
  flex-direction: column;
  align-items: start;
   justify-content: space-between;

  `}

  ._cardHeader {
    .textT {
      font-family: var(--ff-subtitle);
      font-size: var(--fs-r);
    }

    ${(props) => props.theme.flexColNoWrap}
    display:flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 0.5rem;
    ._cardTitle {
      display: flex;
      align-items: center;
      gap: 0.5rem;
      font-family: var(--ff-subtitle);
    }
    ._cardNav {
      border: 1px solid ${({ theme }) => theme.btnSecondary};
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 0.25rem;
      overflow: hidden;
      /* background: #2d2f31; */
      overflow: hidden;
      button {
        background: none;
        color: #fff !important;
        padding: 0.25rem 0.5rem;
        color: ${(props) => props.theme.body};
        border: none;
        outline: none;
        font-family: var(--ff-subtitle);
        font-size: 10px !important;
        :hover {
          color: #fff !important;
        }
      }
      .activeCardTab {
        background:  ${(props) => props.theme.btnSecondary} !important;
        border-radius: 0px;
        font-weight: 400 !important;
        border: none !important;
        color: black!important;
        :hover {
          color: black !important;
        }
      }
    }
  }
  ._cardResults {
    display: grid;
    gap: 1rem;

    overflow: hidden;
    overflow-y: auto;
  }

  //@media quries : mobile screens
`;
const EntityCardCtr = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  flex-direction: column;
  .entityCred {
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 0.5rem;
    .entityVol {
      font-family: var(--ff-subtitle);
      font-size: var(--fs-r);
      margin: 0;
      text-transform: capitalize;
    }
    .matic {
      width: 30px;
      ${({ theme }) => theme.mediaWidth.upToMedium`
      width:16px;

      `}
    }
    .usd {
      width: 18px;
      ${({ theme }) => theme.mediaWidth.upToMedium`
      width:10px;

      `}
    }
    .eth {
      width: 25px;
      ${({ theme }) => theme.mediaWidth.upToMedium`
      width:16px;

      `}
    }
  }
`;

export default CollectionCard;
