export const cardsData = [
  {
    title: "Top Collections by Volume",
    endpoint: `${process.env.REACT_APP_API_URL}/topCollectionByVolume`,
    query: "",
    toolTip: "Top collections by the volume of their transactions in Matic",
    options: ["1 day", "1 week"],
  },
  {
    title: "Top Trending Collections",
    endpoint: `${process.env.REACT_APP_API_URL}/topCollectionByTrend`,
    query: "",
    toolTip: "Top trending collections across Polygon by number of Sales",
    options: ["1 day", "1 week"],
  },
  {
    title: "Highest NFT sales",
    endpoint: `${process.env.REACT_APP_API_URL}/topCollectionBySales`,
    toolTip: "Top NFTs sold for the highest number of Matic",
    options: ["1 day", "1 week"],
  },
  // {
  //   title: "Top Trending Cateogries",
  //   endpoint: `${process.env.REACT_APP_API_URL}/topCollectionByCategory`,
  //   toolTip: "The Top NFT Categories in the Polygon Ecosystem",
  //   options: ["1 day", "1 week"],
  // },
];
