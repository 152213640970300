import axios from "axios";
import { useMemo, useState } from "react";

const getDailyWallet = async () => {
    try {
        const { data } = await axios.get(
            process.env.REACT_APP_API_URL + "/marketOverview/dailynewwallets"
        )
        return data;
    }
    catch (error) {
        return error;
    }
}

export function useDailyWallet() {
    const [api, setAPIData] = useState<any>({
        loading: true,
        error: false,
        data: undefined
    });



    useMemo(async () => {
        const data = await getDailyWallet();
        setAPIData({
            loading: false,
            error: false,
            data: data
        })
    }, [])
    return api;
}
