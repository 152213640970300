export const ENDPOINT_CLIENT = "";
export const ENDPOINT_BLOCK_CLIENT =
  "";
export const ENDPOINT_health_CLIENT = "";
export const ENDPOINT_MEDIAN_AVG_MATIC_PRICE = ""
export const ENDPOINT_MATIC_CURRENT_PRICE = ""

export const TOTAL_NFT_MINT = ""

export const TOTAL_DAILY_NFT_BUYER = ""
export const TOTAL_DAILY_CONTRACT_INTERACTIONS = ""
export const TOTAL_DAILY_NFT_SELLER = ""
export const TOTAL_UNIQUE_OWNERS = ""

export const TOTAL_NFT_COLLECTIONS = ""
export const TOTAL_NFT_WALLETS = ""
export const TOP_TRENDING_CATEGORIES = ""
export const TOTAL_NFT_VOLUME_AND_SALES = "";
export const NFT_SOLD = ""

export const COLLECTION_MINTED_TODAY = ""
export const TOP_MARKETPLACE = ""
export const NFT_MARKET_CAP = ""