import React, { useMemo, useRef, useState, useEffect, ReactNode } from "react";
import styled from "styled-components";
import {
  BlueCard,
  OutlineCard,
  OutlineCardVar2,
  RadialGradientCard,
  StyledCommonCard,
} from "components/Card";
import { AutoColumn } from "components/Column";
import Row, { AutoRow, ResponsiveRow, RowFixed, RowFlat } from "components/Row";
import { formatAmount, formatDollarAmount } from "utils/numbers";
import Percent from "components/Percent";
import { TEXT } from "theme/texts";
import { IconWrapper, StyledInternalLink } from "theme/components";
import Tooltip from "components/Tooltip";
import { HelpCircle } from "react-feather";
import QuestionHelper from "components/QuestionHelper";
import assets from "assets";
import { Card } from "rebass";
import { colors } from "theme/colors";
import { HideMedium, ShowMedium } from "components/Hide";
import useMatchBreakpoints from "hooks/useMatchBreakpoints";
import { useCurrencyUnit } from "store/user/hooks";
import { CircledImageCtr } from "components/Logo";

const CardWrapper = styled(StyledInternalLink)`
  min-width: 190px;
  margin-right: 16px;

  :hover {
    cursor: pointer;
    opacity: 0.6;
  }
`;
export const ScrollableRow = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  overflow-x: auto;
  white-space: nowrap;

  ::-webkit-scrollbar {
    display: none;
  }
`;

export const GradientText = styled(TEXT.LargeHeader)`
  background: ${({ theme }) => theme.linearGradient2};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
  text-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
`;

const StyledCard = styled(StyledCommonCard)`
  padding: 32px 18px;
  border: 1px solid #14EEEC;
background: rgba(10, 13, 19, 0.70);
box-shadow: 0px 0px 50px 0px rgba(20, 238, 236, 0.20) inset;

  ${({ theme }) => theme.mediaWidth.upToMedium`
     padding: 14px 18px;
  `}
`;

const ResponsiveGradientText = styled(GradientText)`
  margine-top: 6px;
  margin-bottom: 2px;
  ${({ theme }) => theme.mediaWidth.upToMedium`
     font-size: 24px!important;
     margine:0px;
  `}
`;

const ResponsiveColumn = styled.div<{}>`
  display: flex;
  flex-direction: column;
  padding-top: 6px;
  gap: 6px;
  ${({ theme }) => theme.mediaWidth.upToMedium`
      flex-direction: row;
      align-items:center;
  `}
`;
export type typeOverviewDataCard = {
  title: string;
  tooltip?: string;
  count: number;
  priceChange: number;
};
export const OverviewDataCards = ({
  title,
  tooltip,
  count,
  priceChange,
}: typeOverviewDataCard) => {
  const { isMobile } = useMatchBreakpoints();
  return (
    <StyledCard>
      <AutoColumn>
        <AutoColumn gap="3px">
          <Row>
            <Row gap="0px" justify="start" style={{ minHeight: "30px" }}>
              <HideMedium>
                <TEXT.Body>{title}</TEXT.Body>
              </HideMedium>
              <ShowMedium>
                <TEXT.Body fontSize={12}>{title}</TEXT.Body>
              </ShowMedium>
              {tooltip ? <QuestionHelper text={tooltip ?? ""} /> : null}
            </Row>
          </Row>
          <ResponsiveColumn>
            <TEXT.LargeHeader fontSize={isMobile ? 24 : 42}>
              {formatAmount(count, 2).toLocaleUpperCase()}
            </TEXT.LargeHeader>
            <Percent value={priceChange} fontSize="12px" />
          </ResponsiveColumn>
        </AutoColumn>
      </AutoColumn>
    </StyledCard>
  );
};

export type typeOverviewProfileCard = {
  title: string;
  count: number;
  priceChange?: number;
  chain?: string;
  text?: string;
  percentCount?: number;
  priceCount?: number;
  tooltip?: string;
  redirect?: string;
};

export const OverviewProfileCard = ({
  title,
  count,
  priceChange,
  text,
  redirect,
  chain,
  percentCount,
  priceCount,
  tooltip,
  ...rest
}: typeOverviewProfileCard) => {
  const { currency } = useCurrencyUnit();
  const { isMobile } = useMatchBreakpoints();
  return (
    <OutlineCardVar2 {...rest}>
      <Row>
        <TEXT.SmallHeader>{title}</TEXT.SmallHeader>
        {tooltip ? (
          <QuestionHelper text={tooltip ?? ""} iconSize="16px" />
        ) : null}
      </Row>
      <Row style={{ justifyContent: "space-between", marginTop: "1rem" }}>
        <Row width={"fit-content"} gap=".5rem">
          {priceCount ? (
            <CircledImageCtr size={isMobile ? "15px" : "24px"}>
              <img
                src={currency?.logo}
                alt={"currency-logo"}
                style={{ objectFit: "contain" }}
              />
            </CircledImageCtr>
          ) : null}
          {percentCount ? (
            <TEXT.MediumHeader>
              {formatAmount(percentCount).toUpperCase()}
              {"%"}
            </TEXT.MediumHeader>
          ) : priceCount ? (
            <TEXT.MediumHeader>
              {formatAmount(priceCount).toUpperCase()}
            </TEXT.MediumHeader>
          ) : text ? (
            <TEXT.SmallHeader
              style={{ cursor: "pointer" }}
              onClick={() => {
                if (redirect) {
                  window.open(redirect, "_blank");
                }
              }
              }
            >{text}</TEXT.SmallHeader>
          ) : (
            <TEXT.MediumHeader>
              {formatAmount(count).toUpperCase()}
            </TEXT.MediumHeader>
          )}
        </Row>
        {priceChange ? <Percent value={priceChange} /> : null}
      </Row>
    </OutlineCardVar2>
  );
};

export type typeOutlinedLabel = {
  isSuccess?: boolean;
  children: ReactNode;
};
export const OutlinedLabel = ({ isSuccess, children }: typeOutlinedLabel) => {
  return (
    <OutlineLabeCtr
      className="_wallet"
      border={`1px solid ${isSuccess ? "#1A740E" : colors().disabled}`}
      width={"fit-content"}
      backgroundColor={isSuccess ? "rgba(45, 144, 23, 0.2)" : null}
    >
      {children}
    </OutlineLabeCtr>
  );
};

const OutlineLabeCtr = styled(Row)`
  gap: 0.5rem;
  padding: 0.25rem 0.5rem;
  border-radius: 0.5rem;
  cursor: pointer;
  transition: all 0.2s;
  &:hover {
    transform: scale(1.05);
  }
`;
