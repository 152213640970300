import assets from "assets";
import React from "react";
import styled from "styled-components";

const CarouselCardOne = () => {
  return (
    <CarouselCardWrapperraa>
    
       <img src={assets.images.one} alt="" />
     
    </CarouselCardWrapperraa>
  );
};
const CarouselCardWrapperraa = styled.div`
   width: 100%;
  height: 100%;
  background-size: cover;
    background-position: center;

  background-repeat: no-repeat;

  /* border: 1px solid #2d2f31; */

  background-size: contain;
  img{
    width: 100%;
  height: 100%;

  }


  @media only screen and (max-width: 700px) {
    flex-direction: column;
    /* height: 270px; */
    background-size: cover;
    background-position: center;

   
  }
`;
export default CarouselCardOne;
