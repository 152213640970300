import React from "react";
import { colors } from "theme/colors";

export default function Spacer() {
  return (
    <div
      style={{
        background: `${colors().disabledVar2}`,
        padding: ".5px",
        margin: ".5rem 0",
      }}
    ></div>
  );
}
