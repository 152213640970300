import { PageWrapper } from "pages/styled";
import { useEffect, useRef, useState, useMemo, useCallback } from "react";
import styled, { keyframes } from "styled-components";
import CollectionCard from "./components/CollectionCard";
import CollectionSimpleCard from "./components/CollectionSimpleCards";
import CollectionsTable from "./components/CollectionsTable";
import { blogData, collectionAddresses, collectionURl, trending } from "./data";
import assets from "assets";
import BlogCard from "./components/BlogCard";
import TotalNftCollection from "./totalNftCollection";
import { useNavigate, useLocation } from "react-router-dom";
import Carousel from "better-react-carousel";
import CarouselCardHome from "./components/CarouselCard.jsx/index.jsx";
import { SecondaryButton } from "components/Button";
import { useMaticPrices } from "data/prices/meanAndCurrentPrice";
import TotalNftWallet from "./totalNftWallet";
import TotalNftMarketCap from "./totalNftMarktcap";
import axios from "axios";
import TotalNftVolume from "./totalNftVolume";
import { ResponsiveRowWrap, ResponsiveRowWrap2 } from "components/Row";
import NFTBuyPopup from "components/NFTBuyPopup";
import { useToGetUserInfo } from "store/user/hooks";
import { ethers } from "ethers";

import { updateUserHoldNFTStatus, updateUserWallet } from "store/user/actions";
import { useDispatch } from "react-redux";

import { ADDRESS, ABI } from "data/contract/data";
import NewLoader from "components/Loader/NewLoader";
import { useModal } from "connectkit";
import CarouselCardHome2 from "./components/CarouselCard.jsx/CarouselCard2";
import CarouselCardHome3 from "./components/CarouselCard.jsx/CarouselCard3";
import SignupBanner from "components/SignUpBanner";
import CarouselCardHome4 from "./components/CarouselCard.jsx/CarouselCard4";
import CarouselCardHome5 from "./components/CarouselCard.jsx/CarouselCard5";
import CarouselCardHome6 from "./components/CarouselCard.jsx/CarouselCard6";
import AOS from "aos";
import "aos/dist/aos.css";
import CarouselCardBeta from "./components/CarouselCard.jsx/beta";
import CarouselCardNews from "./components/CarouselCard.jsx/news";
import CarouselCardOne from "./components/CarouselCard.jsx/one";
import CarouselCardWellcome from "./components/CarouselCard.jsx/wellcom";
const LandingPage = () => {
  // const [scrollProgress, setScrollProgress] = useState(0);
  const [maticPrice, setMaticPrice] = useState({});
  AOS.init();

  const maticCurrentPrice = useMaticPrices();
  const { setOpen } = useModal();
  const [showPopup, setShowPopup] = useState(false);
  const { holdsNFT, wallet } = useToGetUserInfo();
  const target = useRef();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const fetchMaticPrice = async () => {
    try {
      const data = await axios.get(
        `${process.env.REACT_APP_API_URL}/maticPrice`
      );
      setMaticPrice(data.data);
    } catch (err) {
      console.log(err);
    }
  };

  const handleToggle = () => {
    setShowPopup(true);
  };

  const tokenCheck = async () => {
    try {
      setLoading(true);
      // const provider = new ethers.providers.JsonRpcProvider(
      //   "https://polygon-rpc.com"
      // );
      // const contract = new ethers.Contract(ADDRESS, ABI, provider);
      // const balance = await contract.balanceOfBatch(
      //   [wallet, wallet, wallet, wallet, wallet, wallet],
      //   [1, 2, 3, 4, 5, 7]
      // );
      // let balanceInNumber =
      //   ethers.BigNumber.from(balance[0]).toNumber() +
      //   ethers.BigNumber.from(balance[1]).toNumber() +
      //   ethers.BigNumber.from(balance[2]).toNumber() +
      //   ethers.BigNumber.from(balance[3]).toNumber() +
      //   ethers.BigNumber.from(balance[4]).toNumber() +
      //   ethers.BigNumber.from(balance[5]).toNumber();

      // if (balanceInNumber > 0) {
      setLoading(false);
      dispatch(updateUserHoldNFTStatus({ holdsNFT: true }));
      setShowPopup(false);
      const query = window.location.hash;
      const redirect = query.split("?redirect=")[1];
      if (redirect) {
        navigate(redirect);
      }
      // } else {
      //   setLoading(false);
      //   setShowPopup(true);
      // }
    } catch (error) {
      setLoading(false);
      console.log(error);
      setShowPopup(true);
    }
  };

  useEffect(() => {
    const queryCheck = window.location.hash;
    const redirectCheck = queryCheck.split("?app=")[1];
    if (redirectCheck) {
      localStorage.setItem("app", true);
    } else {
      if (wallet && !holdsNFT) {
        tokenCheck();
      }
      if (!wallet) {
        const query = window.location.hash;
        const redirect = query.split("?redirect=")[1];
        if (redirect) {
          // setOpen(true);
        }
      }
    }
  }, [wallet, holdsNFT]);

  const [collectionContent, setCollectionContent] = useState(null);
  const [timeframe, setTimeframe] = useState(0); //0-> 1hr, 1-> 1day, 2-> alltime
  // collectionAddresses
  const fetchCollectionContent = useCallback(async () => {
    const res = await axios.get(
      `${process.env.REACT_APP_API_URL}/topCollection?&category=PFP`
    );

    const result = await res?.data?.all
      ?.filter((d) => collectionAddresses.includes(d.contractAddress))
      .slice(0, 6);
    const filteredData = await result.filter((item) => {
      // Check if the address matches any in the checkingData
      const matchingData = collectionURl.find(
        (d) => d.address === item.contractAddress
      );

      // If there's a match and the imgUrl is false or not equal to the item's imgUrl
      // then replace the item's imgUrl
      if (matchingData && matchingData.imgUrl !== false) {
        item.logo = matchingData.imgUrl;
        return true; // keep this item in the filtered array
      }
      return !!matchingData; // keep this item in the filtered array if there's a match
    });
    let data = filteredData?.map(async (item) => {
      return {
        ...item,
        title: item?.name,
        image: item?.logo,
        link: `/collections/${item.contractAddress}`,
      };
    });
    data = await Promise.all(data);
    console.log(data, "dfdfdsfdddddddss");

    setCollectionContent(data);
  }, [timeframe]);
  const [rerentur, setrerentur] = useState(0);

  useEffect(() => {
    if (rerentur <= 2) {
      setrerentur(rerentur + 1);
    }
    fetchCollectionContent(timeframe);
    console.log(collectionContent, "dsfdsfrwdfds");
  }, [fetchCollectionContent, setrerentur, rerentur]);

  const cardsData = [
    {
      title: "Total NFT Sales",
      endpoint: `${process.env.REACT_APP_API_URL}/topCollectionByTrend`,
      toolTip: "Total NFT Sales on Polygon",
      price_change: 2.4,
      value: "908.56M ",
      showTimePeriod: true,
      showLogo: false,
    },
    {
      title: "Matic Price",
      endpoint: `${process.env.REACT_APP_API_URL}/topCollectionBySales`,
      toolTip: "Current Matic Price",
      price_change:
        maticCurrentPrice && maticCurrentPrice?.data?.change
          ? maticCurrentPrice?.data?.change
          : 0,
      value:
        maticCurrentPrice && maticCurrentPrice?.data?.current
          ? maticCurrentPrice?.data?.current
          : 0,
      showTimePeriod: false,
      showLogo: false,
      dollarSign: true,
    },

    {
      title: "Volume",
      endpoint: `${process.env.REACT_APP_API_URL}/topCollectionByCategory`,
      toolTip: "Total NFT Volume on Polygon",
      price_change: 2.4,
      value: "356k",
      showTimePeriod: true,
      showLogo: false,
    },
  ];

  // useEffect(() => {
  //   fetchMaticPrice();
  // }, []);
  return (
    <>
      {loading ? (
        <NewLoader />
      ) : (
        <CollectionsPageCtr>
          <div className="loger">
            <div className="logW">
              <svg
                width="100%"
                height="100%"
                viewBox="0 0 1423 975"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g opacity="0.2">
                  <g filter="url(#filter0_i_3851_8629)">
                    <circle
                      cx="712.5"
                      cy="135"
                      r="710"
                      fill="#14EEEC"
                      fill-opacity="0.04"
                      class="svg-elem-1"
                      data-aos="fade-down"
                      data-aos-delay="100"
                    ></circle>
                  </g>
                  <g filter="url(#filter1_i_3851_8629)">
                    <circle
                      cx="712.5"
                      cy="18.6269"
                      r="593.627"
                      fill="#14EEEC"
                      fill-opacity="0.04"
                      class="svg-elem-2"
                      data-aos="fade-down"
                      data-aos-delay="150"
                    ></circle>
                  </g>
                  <g filter="url(#filter2_i_3851_8629)">
                    <circle
                      cx="712.5"
                      cy="-85.3662"
                      r="489.634"
                      fill="#14EEEC"
                      fill-opacity="0.04"
                      class="svg-elem-3"
                      data-aos="fade-down"
                      data-aos-delay="200"
                    ></circle>
                  </g>
                </g>
                <defs>
                  <filter
                    id="filter0_i_3851_8629"
                    x="2.5"
                    y="-575"
                    width="1420"
                    height="1420"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                  >
                    <feFlood
                      flood-opacity="0"
                      result="BackgroundImageFix"
                    ></feFlood>
                    <feBlend
                      mode="normal"
                      in="SourceGraphic"
                      in2="BackgroundImageFix"
                      result="shape"
                    ></feBlend>
                    <feColorMatrix
                      in="SourceAlpha"
                      type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                      result="hardAlpha"
                    ></feColorMatrix>
                    <feOffset></feOffset>
                    <feGaussianBlur stdDeviation="100"></feGaussianBlur>
                    <feComposite
                      in2="hardAlpha"
                      operator="arithmetic"
                      k2="-1"
                      k3="1"
                    ></feComposite>
                    <feColorMatrix
                      type="matrix"
                      values="0 0 0 0 0.0784314 0 0 0 0 0.933333 0 0 0 0 0.92549 0 0 0 0.26 0"
                    ></feColorMatrix>
                    <feBlend
                      mode="normal"
                      in2="shape"
                      result="effect1_innerShadow_3851_8629"
                    ></feBlend>
                  </filter>
                  <filter
                    id="filter1_i_3851_8629"
                    x="118.873"
                    y="-575"
                    width="1187.25"
                    height="1187.25"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                  >
                    <feFlood
                      flood-opacity="0"
                      result="BackgroundImageFix"
                    ></feFlood>
                    <feBlend
                      mode="normal"
                      in="SourceGraphic"
                      in2="BackgroundImageFix"
                      result="shape"
                    ></feBlend>
                    <feColorMatrix
                      in="SourceAlpha"
                      type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                      result="hardAlpha"
                    ></feColorMatrix>
                    <feOffset></feOffset>
                    <feGaussianBlur stdDeviation="100"></feGaussianBlur>
                    <feComposite
                      in2="hardAlpha"
                      operator="arithmetic"
                      k2="-1"
                      k3="1"
                    ></feComposite>
                    <feColorMatrix
                      type="matrix"
                      values="0 0 0 0 0.0784314 0 0 0 0 0.933333 0 0 0 0 0.92549 0 0 0 0.26 0"
                    ></feColorMatrix>
                    <feBlend
                      mode="normal"
                      in2="shape"
                      result="effect1_innerShadow_3851_8629"
                    ></feBlend>
                  </filter>
                  <filter
                    id="filter2_i_3851_8629"
                    x="222.866"
                    y="-575"
                    width="979.268"
                    height="979.268"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                  >
                    <feFlood
                      flood-opacity="0"
                      result="BackgroundImageFix"
                    ></feFlood>
                    <feBlend
                      mode="normal"
                      in="SourceGraphic"
                      in2="BackgroundImageFix"
                      result="shape"
                    ></feBlend>
                    <feColorMatrix
                      in="SourceAlpha"
                      type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                      result="hardAlpha"
                    ></feColorMatrix>
                    <feOffset></feOffset>
                    <feGaussianBlur stdDeviation="100"></feGaussianBlur>
                    <feComposite
                      in2="hardAlpha"
                      operator="arithmetic"
                      k2="-1"
                      k3="1"
                    ></feComposite>
                    <feColorMatrix
                      type="matrix"
                      values="0 0 0 0 0.0784314 0 0 0 0 0.933333 0 0 0 0 0.92549 0 0 0 0.26 0"
                    ></feColorMatrix>
                    <feBlend
                      mode="normal"
                      in2="shape"
                      result="effect1_innerShadow_3851_8629"
                    ></feBlend>
                  </filter>
                  <linearGradient
                    id="paint0_linear_3851_8629"
                    x1="711.5"
                    y1="73"
                    x2="712"
                    y2="853"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stop-color="#121212" stop-opacity="0"></stop>
                    <stop offset="1" stop-color="#121212"></stop>
                  </linearGradient>
                </defs>
              </svg>
            </div>
          </div>
          <div className="box">
            {/* <Carousel
              cols={1}
              rows={1}
              showDots={true}
              autoplay={5000}
              loop={true}
              dotColorActive={"#14EEEC"}
              dotColorInactive={"#2ba5a3"}
              // hideArrow={true}
            >
              <Carousel.Item>
                <CarouselCardHome />
              </Carousel.Item>

              <Carousel.Item>
                <CarouselCardHome3 />
              </Carousel.Item>
              <Carousel.Item>
                <CarouselCardHome6 />
              </Carousel.Item>
            </Carousel>

      */}
            <div className="nftgetStartCard">
              <div className="rightSection">
                {/*
              <img src={assets.images.tool} alt="" />
              <img src={assets.images.frame} alt="" />
                
              */}
                <div className="framwrapper">
                  <img src={assets.images.frame} alt="" className="svg pc" />
                  <div className="frame">
                    <Carousel
                      cols={1}
                      rows={1}
                      showDots={true}
                      autoplay={5000}
                      loop={true}
                      dotColorActive={"#14EEEC"}
                      dotColorInactive={"##14EEEc"}
                      hideArrow={true}
                    >
                      <Carousel.Item>
                        <CarouselCardWellcome />
                      </Carousel.Item>
                      <Carousel.Item>
                        <CarouselCardBeta />
                      </Carousel.Item>
                      <Carousel.Item>
                        <CarouselCardNews />
                      </Carousel.Item>
                    </Carousel>
                  </div>
                </div>
              </div>
              <div className="leftSection">
                <h1>
                  Ready to get started? <br></br>Stay ahead of the game
                  with the latest data
                </h1>
                <p>
                  Explore the Polygon NFT space and find alpha for your next big
                  flip with our tools
                </p>
                <div className="buttonBox">
                  <SecondaryButton
                    borderRadius={"0px"}
                    onClick={() => navigate("/market-overview")}
                    className="bgButton"
                  >
                    Get Started →
                  </SecondaryButton>
                  {/* <button>Start exploring</button> */}
                </div>
              </div>
            </div>

            <div className="_cardsCtr">
              <div className="cardContentCardgBox ">
                <h3 className="smalls">✦ Polygon NFT Explorer ✦ </h3>
              </div>
              <ResponsiveRowWrap2 ref={target}>
                <TotalNftMarketCap />
                <CollectionCard card={cardsData[1]} />

                <TotalNftVolume dataType={"Sales"} />
                {/*
                 */}
                <TotalNftWallet />
                <TotalNftCollection />
                {/*
              
              */}
                <TotalNftVolume dataType={"All"} />
              </ResponsiveRowWrap2>

              {/* <div className="_cardsNav">{renderDots(cardsData?.length)}</div> */}
            </div>
          </div>

          <div className="_cardsCtr">
            <div className="cardContentCardgBoxf">
              <h3>What’s Trending?</h3>
            </div>

            <div className="_cards2" ref={target}>
              <MarqueeBody>
                <MarqueeGroup speed={20}>
                  {collectionContent?.map((card, idx) => (
                    <CollectionSimpleCard card={card} key={idx} />
                  ))}
                </MarqueeGroup>
                <MarqueeGroup speed={20}>
                  {collectionContent?.map((card, idx) => (
                    <CollectionSimpleCard card={card} key={idx} />
                  ))}
                </MarqueeGroup>
                <MarqueeGroup speed={20}>
                  {collectionContent?.map((card, idx) => (
                    <CollectionSimpleCard card={card} key={idx} />
                  ))}
                </MarqueeGroup>
              </MarqueeBody>
            </div>
            <a
              onClick={() => navigate("/collections")}
              className="linkblockq"
              // href={""}
              // target="_blank"
            >
              Checkout Collections
              <svg
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M20.25 9.375V3.75H14.625"
                  stroke-width="1.71429"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M13.5 10.5L20.25 3.75"
                  stroke-width="1.71429"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M17.25 13.5V19.5C17.25 19.6989 17.171 19.8897 17.0303 20.0303C16.8897 20.171 16.6989 20.25 16.5 20.25H4.5C4.30109 20.25 4.11032 20.171 3.96967 20.0303C3.82902 19.8897 3.75 19.6989 3.75 19.5V7.5C3.75 7.30109 3.82902 7.11032 3.96967 6.96967C4.11032 6.82902 4.30109 6.75 4.5 6.75H10.5"
                  stroke-width="1.71429"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </a>
          </div>

          <div className="_cardsCtr">
            <div className="cardContentCardgBoxf">
              <h3>Popular Collection </h3>
            </div>
            <CollectionsTable />
            <a
              onClick={() => navigate("/collections")}
              className="linkblockq"
              // href={""}
              // target="_blank"
            >
              Go to ranking
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M20.25 9.375V3.75H14.625"
                  stroke-width="1.71429"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M13.5 10.5L20.25 3.75"
                  stroke-width="1.71429"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M17.25 13.5V19.5C17.25 19.6989 17.171 19.8897 17.0303 20.0303C16.8897 20.171 16.6989 20.25 16.5 20.25H4.5C4.30109 20.25 4.11032 20.171 3.96967 20.0303C3.82902 19.8897 3.75 19.6989 3.75 19.5V7.5C3.75 7.30109 3.82902 7.11032 3.96967 6.96967C4.11032 6.82902 4.30109 6.75 4.5 6.75H10.5"
                  stroke-width="1.71429"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </a>
          </div>

          <div className="blogSection">
            {blogData?.map((card, idx) => (
              <BlogCard card={card} key={idx} />
            ))}
          </div>
          <NFTBuyPopup
            hidePopup={() => setShowPopup(false)}
            showPopup={showPopup}
          />
        </CollectionsPageCtr>
      )}
    </>
  );
};

const scrollX = keyframes`
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
`;
const MarqueeBody = styled.div`
  margin: 0 auto;
  display: flex;
  justify-content: flex-start;
  overflow: hidden;
  user-select: none;
  width: 100%;
  /* max-width: 1200px; */
  position: relative;
  mask-image: linear-gradient(
    to right,
    hsl(0 0% 0% / 0),
    hsl(0 0% 0% / 1) 10%,
    hsl(0 0% 0% / 1) 90%,
    hsl(0 0% 0% / 0)
  );
`;
const MarqueeGroup = styled.div`
  flex-shrink: 0;
  display: flex;
  align-items: center;
  white-space: nowrap;
  justify-content: space-around;
  /* min-width: 100%; */
  animation: ${scrollX} ${(props) => `${props?.speed}s linear infinite;`};
  gap: 1.5rem;
  padding: 0 1rem;
`;
const CollectionsPageCtr = styled(PageWrapper)`
  .logW {
    width: 1400px;
  }
  .loger {
    width: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    top: 0;
    left: 0;
    z-index: -1;
  }
  font-family: "Inter";
  position: relative;
  ${(props) => props?.theme?.flexColumnNoWrap}
  p, h2,h1 {
    margin-block-start: auto;
    margin-block-end: auto;
  }
  button.rec-dot {
    border: 0.5px solid #615971;
    background: #616161;
  }
  .box {
    padding: 16px 16px;
    margin-top: 15px;
    /* padding-top: 26px; */
    /* background: linear-gradient(180deg, #0e0e0e 0%, rgba(45, 47, 49, 0) 100%); */
    /* border: 1px solid #2d2f31;
    background: var(
      --gradient,
      linear-gradient(
        180deg,
        rgba(20, 238, 236, 0.7) 0%,
        rgba(45, 47, 49, 0) 100%
      )
    );

    border: 1px solid #2d2f31; */
    ${({ theme }) => theme.mediaWidth.upToMedium`
          padding: 10px 10px;
    padding-top: 10px;
   `}

    .dluTbn {
      /* border: 1px solid #2d2f31; */
      margin: 0;
    }
    .hAHFsi {
    }
    .hAHFsi,
    .ekDRsi {
      border-radius: 0 !important;
      width: 15px !important;
      height: 15px !important;
      background: #14eeec;
    }
    .ekDRsi {
      border: 4px solid #14eeec !important;
      background: #2ba5a3 !important;
    }
    .Carousel__Dots-sc-hyhecw-4 {
      bottom: 8px !important;
      width: auto !important;
      height: 30px !important;
      right: 30px !important;
      justify-content: center;
      align-items: center;
    }
    .bBfHpH {
      background: #616161 !important;
    }
    .dZkckO {
      background: #616161 !important;
    }
    .Carousel__Rail-sc-hyhecw-2 {
      transition: all 0.7s ease-out;
    }
  }
  button.rec-dot:hover,
  button.rec-dot:active,
  button.rec-dot:focus {
  }
  /* background-color: #000000; */
  gap: 2rem;
  padding-top: 1rem;
  .linkblockq {
    margin: 0 auto;
    display: flax;
    gap: 10px;
    cursor: pointer;
    text-decoration: none;
    transition: all ease-in 0.3s;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    margin-top: 24px;
    color: rgba(20, 238, 236, 1);
    svg {
      stroke: rgba(20, 238, 236, 1);
      width: 22px;
      height: 22px;
      ${({ theme }) => theme.mediaWidth.upToMedium`
           width:13px;
            height:13px;
     `}
    }
    ${({ theme }) => theme.mediaWidth.upToMedium`
            margin-top: 8px;
    font-size: 12px;
     `}
    :hover {
      color: #0aacaa;
      svg {
        stroke: #0aacaa;
      }
    }
  }
  .collectionHeader {
    padding: 70px 0px;
    .collectionHeadTest {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      max-width: 890px;
      margin: 0 auto;
      text-align: center;
      h2 {
        ${({ theme }) => theme.mediaWidth.upToMedium`
        font-size: 28px;
     `}
        font-weight: 500;
        font-size: 54px;
        line-height: 130%;
        margin-bottom: 25px;
      }
      p {
        ${({ theme }) => theme.mediaWidth.upToMedium`
        font-size: 12px;
     `}
        font-weight: 400;
        font-size: 16px;
        line-height: 130%;
        max-width: 600px;
        color: rgba(255, 255, 255, 0.6);
      }
    }
  }
  .headerCardsBox {
    h4 {
      font-weight: 600;
      font-size: 42px;
      line-height: 130%;
      margin-bottom: 70px;
      ${({ theme }) => theme.mediaWidth.upToMedium`
      font-size: 24px;
      margin-bottom: 40px;
   `}
    }
    margin-top: 70px;
    align-items: center;
    display: flex;
    justify-content: center;
    flex-direction: column;
    .cardFlexBox {
      display: flex;
      gap: 80px;
      ${({ theme }) => theme.mediaWidth.upToMedium`
      flex-direction: column;
      gap: 40px;
   `}
    }
  }
  ._cardsCtr {
    /* margin-bottom: 75px; */
    display: grid;
    gap: 1rem;
    &::-webkit-scrollbar {
      display: none;
    }
    -ms-overflow-style: none;
    scrollbar-width: none; /* Firefox */
    ._cardsNav {
      display: none;
    }
    ._cards2 {
      width: 100%;
      display: flex;
      gap: 1rem;
      overflow-x: auto;
      position: relative;
      ::-webkit-scrollbar {
        display: none;
      }
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
      ::before {
        position: absolute;
        width: 100%;
        height: 100%;
        background: linear-gradient(
          90deg,
          #000000 0%,
          rgba(0, 0, 0, 0) 14.69%,
          rgba(1, 1, 1, 0) 85.49%,
          #010101 100%
        );
        z-index: 10;
      }
      ${({ theme }) => theme.mediaWidth.upToMedium`
      grid-template-columns: 1fr 1fr ;
      grid-template-rows: 1fr 1fr ;
   `}
    }
    ._cardsa {
      display: grid;
      width: 100%;
      /* flex-wrap: wrap; */
      grid-template-columns: 1fr 1fr 1fr;
      grid-template-rows: 1fr 1fr;
      gap: 15px 15px;
      ${({ theme }) => theme.mediaWidth.upToMedium`
      grid-template-columns: 1fr 1fr ;
      grid-template-rows: 1fr 1fr 1fr;
      gap: 9px 9px;
   `}
    }
    ._cards {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(290px, 1fr));
      gap: 1rem;
    }
    //@media quries : mobile screens
    ${({ theme }) => theme.mediaWidth.upToMedium`
    ._cards {
      display:flex;
      overflow-x:auto;
    }
     ._cardsNav{
      display: flex;
      width:100%;
      align-items:center;
      gap:.5rem;
      justify-content:center;
    }
    `}
  }
  .cardContentCardgBoxf {
    text-align: center;
    margin-bottom: 36px;
    margin-top: 46px;
    ${({ theme }) => theme.mediaWidth.upToMedium`
    margin-bottom: 18px;
 `}
    h3 {
      ${({ theme }) => theme.mediaWidth.upToMedium`
      font-size: 24px;
      margin-bottom: 0;
   `}
      font-weight: 600;
      font-size: 28px;
      line-height: 110%;
      margin-block-start: 0;
      margin-block-end: 0;
      margin-bottom: 10px;
    }
  }
  .cardContentCardgBox {
    text-align: center;
    /* margin-bottom: 36px; */
    h3 {
      ${({ theme }) => theme.mediaWidth.upToMedium`
      font-size: 24px;
      margin-bottom: 0;
   `}
      font-weight: 600;
      font-size: 28px;
      line-height: 110%;
      margin-block-start: 0;
      margin-block-end: 0;
      margin-bottom: 0px;
    }
    .smalls {
      margin: 17px 0;
      margin-bottom: 5px;
      font-family: "contentFont";
      font-weight: 500;
      font-size: 18px;
      line-height: 120%;
      ${({ theme }) => theme.mediaWidth.upToMedium`
   font-size: 16px;
   margin: 16px 0;
 `}
    }
    /* p {
      font-weight: 400;
      font-size: 20px;
      line-height: 28px;
      color: #6b7280;
    } */
  }
  .nftgetStartCard {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 120px;

    ${({ theme }) => theme.mediaWidth.upToMedium`
    flex-direction: column;
    gap: 0px;
    margin-bottom:60px;


 `}

    .framwrapper {
      position: relative;
      max-width: 800px;
      max-height: 400px;
      display: flex;
      justify-content: center;
      align-items: center;

      padding: 20px 20px;

      .svg {
        position: absolute;
        min-width: 100%;
        height: 100%;
        top: 0;
        z-index: 109;
        user-select: none;
        pointer-events: none;
      }
    }

    .frame {
      max-width: 100%;
      width: 100%;
      /* max-height: 600px; */
      /* background-color: RED; */
      /* border: 1px solid ${({ theme }) => theme.btnSecondary}; */
      clip-path: polygon(
        0 0,
        calc(100% - 30px) 0,
        100% 5%,
        100% 100%,
        3% 100%,
        0 calc(100% - 20px)
      );
      overflow: hidden;

      .card {
        width: 100%;
        height: 100%;
        background: BLUE;
      }
    }

    @media only screen and (max-width: 760px) {
      .framwrapper{
        padding:0;
      }
      .pc {
        display: none;
      }

      .framwrapper {
        width: 100%;
        max-height: auto;
        border: 1px solid #14eeec;
        margin-bottom: 60px;
      }
      .frame {
        max-width: 100%;
        width: 100%;
        max-height: auto;
      }
    }

    .rightSection {
      display: flex;
      justify-content: center;
      background-image: url(${assets.images.starbg});
      /* background-color:red ; */
      width: 100%;
      background-size: contain;
      min-width: 566px;
      img {
        max-width: 700px;
      }
      ${({ theme }) => theme.mediaWidth.upToMedium`
  order: 1;
  min-width: 266px;
      img {
        max-width: 100%;
        // margin-bottom:30px;
      }
 `}
    }
    width: 100%;
    gap: 40px;
    text-align: center;
    .leftSection {
      ${({ theme }) => theme.mediaWidth.upToMedium`
  order: 2;
  
  
 `}
      h1 {
        font-family: "Intern500";
        font-weight: 500;
        font-size: 48px;
        line-height: 130%;
        ${({ theme }) => theme.mediaWidth.upToMedium`
        font-size: 24px;
 `}
      }
      p {
        color: #737373;
        font-weight: 400;
        font-size: 24px;
        line-height: 130%;
        /* max-width: 540px; */
        margin-top: 26px;
        ${({ theme }) => theme.mediaWidth.upToMedium`
        font-size: 14px;
        max-width: 330px;
        margin: 0 auto;
        margin-top: 16px;
 `}
      }
      .buttonBox {
        margin-top: 36px;
        button {
          padding: 9px 35px;
          font-weight: 500;
          font-size: 15px;
          line-height: 24px;
          margin-right: 10px;
          width: max-content;
        }
        .bgButton {
          margin: 0 auto;
        }
      }
    }
  }
  .blogSection {
    margin-top: 80px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    gap: 26px 50px;
    ${({ theme }) => theme.mediaWidth.upToMedium`
    grid-template-columns:  1fr;
    grid-template-rows: 1fr 1fr;
    margin-top: 60px;

`}
  }
  @media only screen and (max-width: 760px) {
    .fpKkgP,
    .bEjuCR {
      grid-template-columns: repeat(4, 100%) !important;
      padding-left: 0px !important;
    }
    .dluTbn {
      background-color: black;
    }
    .collectionHeadTest {
      h2 {
        font-weight: 500;
        font-size: 28px;
      }
    }
    ._cardsa {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: 1fr 1fr 1fr;
      gap: 24px 24px;
    }
    .gZzsDe {
      display: none !important;
    }
  }
`;

export default LandingPage;
