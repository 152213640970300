import { useState, useMemo } from "react";
import CollectionCard from "./components/CollectionCard";
import { useNFTMarketCap } from "data/nft/marketCap";
import getTimewindowFilteredData from "utils/chart";
import { formatAmount } from "utils/numbers";
import { useCurrencyUnit } from "store/user/hooks";
import { Currencies } from "constant/currency";

const TotalNftMarketCap = () => {
  const [timeWindow, setTimeWindow] = useState("3M");
  const { data: nftMarketCapData } = useNFTMarketCap();
  const reverseNFTMarketCapData = useMemo(() => {
    return nftMarketCapData || [];
  }, [nftMarketCapData]);

  const { currency } = useCurrencyUnit();

  const getDataByTime = (data) => {
    const filteredD = {};
    const total = {};

    data.map((value) => {
      if (filteredD[value.dateTime] === undefined) {
        filteredD[value.dateTime] = {
          dateTime: value.dateTime,
          marketCapUSD: value.marketCapUSD,
          marketCapInMatic: value.marketCapInMatic,
          marketCapInWETH: value.marketCapInWETH,
        };
      } else {
        filteredD[value.dateTime] = {
          dateTime: value.dateTime,
          marketCapUSD:
            filteredD[value.dateTime].marketCapUSD + value.marketCapUSD,
          marketCapInMatic:
            filteredD[value.dateTime].marketCapInMatic + value.marketCapInMatic,
          marketCapInWETH:
            filteredD[value.dateTime].marketCapInWETH + value.marketCapInWETH,
        };
      }
    });
    return Object.keys(filteredD).map((key) => {
      return {
        dateTime: key,
        marketCapUSD: filteredD[key].marketCapUSD,
        marketCapInMatic: filteredD[key].marketCapInMatic,
        marketCapInWETH: filteredD[key].marketCapInWETH,
      };
    });
  };

  const formattedChartData = useMemo(() => {
    if (
      nftMarketCapData &&
      reverseNFTMarketCapData &&
      reverseNFTMarketCapData.length !== 0
    ) {
      const alltimeMarketcapv = getTimewindowFilteredData(
        reverseNFTMarketCapData,
        "All"
      );

      const alltimeMarketcap = alltimeMarketcapv.map((record) => {
        return {
          time: record.dateTime,
          valueNFTVolume:
            currency.id === Currencies.USD
              ? record.marketCapUSD
              : currency.id === Currencies.MATIC
              ? record.marketCapInMatic
              : record.marketCapInWETH,
        };
      });
      const totalData = getDataByTime(
        getTimewindowFilteredData(reverseNFTMarketCapData, timeWindow)
      ).map((record) => {
        return {
          time: record.dateTime,
          valueNFTVolume:
            currency.id === Currencies.USD
              ? record.marketCapUSD
              : currency.id === Currencies.MATIC
              ? record.marketCapInMatic
              : record.marketCapInWETH,
        };
      });
      let updateTimeWindow;
      if (timeWindow === "3M") {
        updateTimeWindow = "6M";
      } else if (timeWindow === "6M") {
        updateTimeWindow = "1Y";
      } else if (timeWindow === "1Y") {
        updateTimeWindow = "2Y";
      }
      const secondtotalData = getDataByTime(
        getTimewindowFilteredData(reverseNFTMarketCapData, updateTimeWindow)
      ).map((record) => {
        return {
          time: record.dateTime,
          valueNFTVolume:
            currency.id === Currencies.USD
              ? record.marketCapUSD
              : currency.id === Currencies.MATIC
              ? record.marketCapInMatic
              : record.marketCapInWETH,
        };
      });

      return { totalData, alltimeMarketcap, secondtotalData };
    } else {
      return [];
    }
  }, [currency.id, nftMarketCapData, reverseNFTMarketCapData, timeWindow]);
  const filteredTotalCount = useMemo(() => {
    if (
      nftMarketCapData &&
      reverseNFTMarketCapData &&
      reverseNFTMarketCapData.length !== 0
    ) {
      let previousToCurrentTimeTotal = 0;
      let TotalToCurrentTimeTotal = 0;
      let TotalToSecontCurrentTimeTotal = 0;

      formattedChartData.alltimeMarketcap.forEach((singleData) => {
        TotalToCurrentTimeTotal =
          TotalToCurrentTimeTotal + (singleData.valueNFTVolume ?? 0);
      });
      formattedChartData.totalData.forEach((singleData) => {
        previousToCurrentTimeTotal =
          previousToCurrentTimeTotal + (singleData.valueNFTVolume ?? 0);
      });
      formattedChartData.secondtotalData.forEach((singleData) => {
        TotalToSecontCurrentTimeTotal =
          TotalToSecontCurrentTimeTotal + (singleData.valueNFTVolume ?? 0);
      });
      const l = TotalToSecontCurrentTimeTotal - previousToCurrentTimeTotal;
      // beforeOneYearCount > 0
      // ? ((beforeOneYearCount-OneYearDataCount) /
      // OneYearDataCount) *
      //   100
      // : 0;
      const percentValue = (l / TotalToSecontCurrentTimeTotal) * 100;
      return { previousToCurrentTimeTotal, percentValue };
    } else {
      return 0;
    }
  }, [
    formattedChartData.alltimeMarketcap,
    formattedChartData.secondtotalData,
    formattedChartData.totalData,
    nftMarketCapData,
    reverseNFTMarketCapData,
  ]);

  const volumeData = {
    title: "Market Cap",
    endpoint: `${process.env.REACT_APP_API_URL}/topCollectionByVolume`,
    toolTip: "Total Market Cap of Polygon NFTs",
    price_change: filteredTotalCount.percentValue,
    value: formatAmount(filteredTotalCount?.previousToCurrentTimeTotal) || 0,
    showTimePeriod: true,
    showLogo: true,
  };

  return (
    <>
      <CollectionCard card={volumeData} time={setTimeWindow} />
    </>
  );
};

export default TotalNftMarketCap;
