import { ApolloClient, InMemoryCache } from "@apollo/client";
import {
    TOTAL_DAILY_NFT_BUYER,
    ENDPOINT_BLOCK_CLIENT,
    ENDPOINT_CLIENT,
    ENDPOINT_health_CLIENT,
    ENDPOINT_MATIC_CURRENT_PRICE,
    ENDPOINT_MEDIAN_AVG_MATIC_PRICE,
    TOTAL_DAILY_CONTRACT_INTERACTIONS,
    TOTAL_DAILY_NFT_SELLER,
    TOTAL_NFT_COLLECTIONS,
    TOTAL_NFT_MINT,
    TOTAL_NFT_WALLETS,
    TOP_TRENDING_CATEGORIES,
    TOTAL_UNIQUE_OWNERS,
    TOTAL_NFT_VOLUME_AND_SALES,
    NFT_SOLD,
    COLLECTION_MINTED_TODAY,
    TOP_MARKETPLACE,
    NFT_MARKET_CAP,
} from "./endpoints";

export const client = new ApolloClient({
    uri: ENDPOINT_CLIENT,
    cache: new InMemoryCache({
        typePolicies: {
            Token: {
                // Singleton types that have no identifying field can use an empty
                // array for their keyFields.
                keyFields: false,
            },
            Pool: {
                // Singleton types that have no identifying field can use an empty
                // array for their keyFields.
                keyFields: false,
            },
        },
    }),
    queryDeduplication: true,
    defaultOptions: {
        watchQuery: {
            fetchPolicy: "no-cache",
        },
        query: {
            fetchPolicy: "no-cache",
            errorPolicy: "all",
        },
    },
});

export const blockClient = new ApolloClient({
    uri: ENDPOINT_BLOCK_CLIENT,
    cache: new InMemoryCache(),
    queryDeduplication: true,
    defaultOptions: {
        watchQuery: {
            fetchPolicy: "cache-first",
        },
        query: {
            fetchPolicy: "cache-first",
            errorPolicy: "all",
        },
    },
});

export const healthClient = new ApolloClient({
    uri: ENDPOINT_health_CLIENT,
    cache: new InMemoryCache(),
});

export const medianAvgMaticPriceClient = new ApolloClient({
    uri: ENDPOINT_MEDIAN_AVG_MATIC_PRICE,
    headers: {
        "x-api-key": "d37da4b0-1150-4942-9fb9-e7eb87fdf159",
    },
    cache: new InMemoryCache(),
});

export const maticCurrentPriceClient = new ApolloClient({
    uri: ENDPOINT_MATIC_CURRENT_PRICE,
    headers: {
        "x-api-key": "d37da4b0-1150-4942-9fb9-e7eb87fdf159",
    },
    cache: new InMemoryCache(),
});

export const totalNFTMintClient = new ApolloClient({
    uri: TOTAL_NFT_MINT,
    headers: {
        "x-api-key": "d37da4b0-1150-4942-9fb9-e7eb87fdf159",
    },
    cache: new InMemoryCache(),
});
export const totalNFTBuyerClient = new ApolloClient({
    uri: TOTAL_DAILY_NFT_BUYER,
    headers: {
        "x-api-key": "d37da4b0-1150-4942-9fb9-e7eb87fdf159",
    },
    cache: new InMemoryCache(),
});

export const totalDailyContractInteractionClient = new ApolloClient({
    uri: TOTAL_DAILY_CONTRACT_INTERACTIONS,
    headers: {
        "x-api-key": "d37da4b0-1150-4942-9fb9-e7eb87fdf159",
    },
    cache: new InMemoryCache(),
});

export const totalDailyNFTSellerClient = new ApolloClient({
    uri: TOTAL_DAILY_NFT_SELLER,
    headers: {
        "x-api-key": "d37da4b0-1150-4942-9fb9-e7eb87fdf159",
    },
    cache: new InMemoryCache(),
});

export const totalUniqueOwners = new ApolloClient({
    uri: TOTAL_UNIQUE_OWNERS,
    headers: {
        "x-api-key": "d37da4b0-1150-4942-9fb9-e7eb87fdf159",
    },
    cache: new InMemoryCache(),
});


export const totalNFTCollectionsClient = new ApolloClient({
    uri: TOTAL_NFT_COLLECTIONS,
    headers: {
        "x-api-key": "d37da4b0-1150-4942-9fb9-e7eb87fdf159",
    },
    cache: new InMemoryCache(),
});

export const totalNFTWalletsClient = new ApolloClient({
    uri: TOTAL_NFT_WALLETS,
    headers: {
        "x-api-key": "d37da4b0-1150-4942-9fb9-e7eb87fdf159",
    },
    cache: new InMemoryCache(),
});

export const topTrendingCategoriesClient = new ApolloClient({
    uri: TOP_TRENDING_CATEGORIES,
    headers: {
        "x-api-key": "d37da4b0-1150-4942-9fb9-e7eb87fdf159",
    },
    cache: new InMemoryCache(),
});


export const totalNFTVolumeAndSales = new ApolloClient({
    uri: TOTAL_NFT_VOLUME_AND_SALES,
    headers: {
        "x-api-key": "d37da4b0-1150-4942-9fb9-e7eb87fdf159",
    },
    cache: new InMemoryCache(),
});
export const NFTSOLD = new ApolloClient({
    uri: NFT_SOLD,
    headers: {
        "x-api-key": "d37da4b0-1150-4942-9fb9-e7eb87fdf159",
    },
    cache: new InMemoryCache(),
});

export const collectionMintedToday = new ApolloClient({
    uri: COLLECTION_MINTED_TODAY,
    headers: {
        "x-api-key": "d37da4b0-1150-4942-9fb9-e7eb87fdf159",
    },
    cache: new InMemoryCache(),
});

export const topMarketplace = new ApolloClient({
    uri: TOP_MARKETPLACE,
    headers: {
        "x-api-key": "d37da4b0-1150-4942-9fb9-e7eb87fdf159",
    },
    cache: new InMemoryCache(),
});

export const nftMarketCap = new ApolloClient({
    uri: NFT_MARKET_CAP,
    headers: {
        "x-api-key": "d37da4b0-1150-4942-9fb9-e7eb87fdf159",
    },
    cache: new InMemoryCache(),
});