import React from "react";
import ReactDOM from "react-dom/client";
import App from "./pages/App";
import reportWebVitals from "./reportWebVitals";
import ReactGA from "react-ga";
import { isMobile } from "react-device-detect";
import { ApolloProvider } from "@apollo/client/react";
import { client } from "./apollo/client";
import store from "./store";
import { Provider } from "react-redux";
import ThemeProvider, { FixedGlobalStyle, ThemedGlobalStyle } from "./theme";
import { HashRouter } from "react-router-dom";
import ProtocolUpdater from "./store/protocol/updater";
import UserUpdater from "./store/user/updater";
import ScrollToTop from "utils/ScrollToTop";
import { WagmiConfig, createClient } from "wagmi";
import { ConnectKitProvider, getDefaultClient } from "connectkit";
import {
  mainnet,
  polygonMumbai,
  polygon,
  goerli,
  arbitrum,
  optimism,
  bsc,
  avalanche,
} from "wagmi/chains";
const GOOGLE_ANALYTICS_ID: string | undefined =
  process.env.REACT_APP_GOOGLE_ANALYTICS_ID;
if (GOOGLE_ANALYTICS_ID && typeof GOOGLE_ANALYTICS_ID === "string") {
  ReactGA.initialize(GOOGLE_ANALYTICS_ID);
  ReactGA.set({
    customBrowserType: !isMobile
      ? "desktop"
      : "web3" in window || "ethereum" in window
      ? "mobileWeb3"
      : "mobileRegular",
  });
} else {
  ReactGA.initialize("test", {
    testMode: true,
    debug: true,
    gaOptions: {},
  });
}

window.addEventListener("error", (error) => {
  ReactGA.exception({
    description: `${error.message} @ ${error.filename}:${error.lineno}:${error.colno}`,
    fatal: true,
  });
});

const alchemyId = process.env.ALCHEMY_ID;
const chains = [
  polygon,
  mainnet,
  polygonMumbai,
  optimism,
  arbitrum,
  bsc,
  avalanche,
  goerli,
];
const clientWagmi = createClient(
  getDefaultClient({
    appName: "Surfaceboard",
    alchemyId,
    walletConnectOptions: {
      version: "2",
      projectId: "828203d46d0e4b5f290a0bc2946a2ee4",
    },
  })
);

const Updater = () => {
  return (
    <>
      <ProtocolUpdater />
      <UserUpdater />
    </>
  );
};

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode>
    <WagmiConfig client={clientWagmi}>
      <ConnectKitProvider
        options={{
          enforceSupportedChains: false,
          initialChainId: 0,
          walletConnectCTA: "both",
        }}
      >
        <FixedGlobalStyle />
        <ApolloProvider client={client}>
          <Provider store={store}>
            <Updater />
            <ThemeProvider>
              <ThemedGlobalStyle />
              <HashRouter>
                <ScrollToTop>
                  <App />
                </ScrollToTop>
              </HashRouter>
            </ThemeProvider>
          </Provider>
        </ApolloProvider>
      </ConnectKitProvider>
    </WagmiConfig>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
