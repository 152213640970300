import axios from "axios";
import { useMemo, useState } from "react";



interface api {
    loading: boolean;
    error: boolean;
    data: any;
}

const getPrices = async () => {
    try {
        const { data } = await axios.get(
            process.env.REACT_APP_API_URL + "/topCollection?duration=2&page=1&search=&sortBy=sales&sortDirection=desc&category=All&supply=0"
        )
        return data;
    }
    catch (error) {
        return error;
    }
}

export function useTopCollectionBySales() {
    const [api, setAPIData] = useState<api>({
        loading: true,
        error: false,
        data: "",
    });



    useMemo(async () => {
        const data = await getPrices();
        setAPIData({
            loading: false,
            error: false,
            data: data
        })
    }, [])
    return api;
}
