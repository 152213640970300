import assets from "assets";
import React from "react";
import styled from "styled-components";

const CarouselCardHome = () => {
  return (
    <CarouselCardWrapper>
      <div className="rightSide">
        <div className="carouselContent">
          <div className="text">
            Your 360<sup>&#176;</sup> view of the Polygon NFT Ecosystem
          </div>
        </div>
      </div>
    </CarouselCardWrapper>
  );
};
const CarouselCardWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 225px;
  /* background: #0e0e0e; */
  background: linear-gradient(180deg, #020202 0%, #1c1d2b 100%);
  background-color: black;
  /* stroke */

  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
  /* border-radius: 16px; */
  background-image: url(${assets.images.bgline1});
  background-size: cover;
  background-repeat: no-repeat;
  display: flex;
  justify-content: center;
  align-items: center;
  /* border: 1px solid #2d2f31; */

  .rightSide {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .carouselContent {
    text-align: center;
    max-width: 1100px;

    z-index: 10;
    padding: 0 20px;

    .text {
      font-weight: 600;
      font-size: 48px;
      line-height: 130%;
      background: linear-gradient(180deg, #ffffff 60.11%, #615971 100%);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      background-clip: text;
      text-fill-color: transparent;
    }
  }

  @media only screen and (max-width: 700px) {
    flex-direction: column;
    height: 270px;
    background-size: cover;
    background-position: center;

    .carouselContent {
      .text {
        font-weight: 600;
        font-size: 24px;
        line-height: 130%;
      }
    }
  }
`;
export default CarouselCardHome;
