import assets from "assets";
import axios from "axios";
import React, {useRef} from "react";
import styled from "styled-components";

const CarouselCardBeta = () => {

 
  return (
    <CarouselCardWrappersr>
      <div className="cont">
        <div className="heade">
          <img src={assets.logos.layerELogo} alt="" />
          IS LIVE
        </div>
        <div className="sub">
          <div className="line">
            In-Depth <span>NFT INSIGHT</span>
          </div>
          <div className="line">
            TREND <span>DISCOVERY</span>
          </div>
          <div className="line">
            LIVE <span>CHARTING</span>
          </div>
          <div className="line">
            & <span> MORE COMING SOON </span>
          </div>
        </div>

        <div className="btn" onClick={()=>    window.open('https://docs.google.com/forms/d/e/1FAIpQLSevo17OkdCnfCx-npIvkG4o-4spHttGuF4dDY6DUTkNLbzYcA/viewform', '_blank')}>
          <div className="btntes">
            <span>SUBMIT</span>
            FEEDBACK
          </div>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="dd"
            viewBox="0 0 9 15"
            fill="none"
          >
            <path d="M1 0.5L8 7.5L1 14.5" stroke="#14EEEC" />
          </svg>
        </div>
      </div>
    </CarouselCardWrappersr>
  );
};
const CarouselCardWrappersr = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  font-family: contentFont;

  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  background: url(${assets.images.bgf});
  background-repeat: no-repeat;

  /* border: 1px solid #2d2f31; */
  background-position: center center;
  background-size: cover;
  padding: 30px 0;
  img {
    width: 100%;
  }
  .cont {
  }
  .heade {
    font-size: 48px;
    font-family: titleFont;

    font-weight: 700;
    color: #14eeec;
    line-height: 130%; /* 72.8px */
    text-transform: uppercase;
    font-style: normal;
    img {
      margin-right: 20px;
      width: 160px;
    }
  }
  .sub {
    font-size: 28px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%;
    text-transform: uppercase;
  }
  span {
    color: #14eeec;
  }
  .line {
  }
  .btn {
    display: flex;
    gap: 20px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-top: 25px;
    font-size: 24px;
    .dd{
      width: 9px;
      height: 15px;
    }

  }
  .btntes {
    border-bottom: 1px solid #14eeec;
    border-top: 1px solid #14eeec;
    padding: 5px;
  }
  @media only screen and (max-width: 700px) {
    flex-direction: column;
    height: 270px;
    background-size: cover;
    background-position: center;
    .btn {
    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-top: 25px;
    font-size: 14px;

    .dd{
      width: 5px;
      height: 10px;
    }

  }
    .heade {
    font-size: 24px;
    font-family: titleFont;

    font-weight: 700;
    color: #14eeec;
    line-height: 130%; /* 72.8px */
    text-transform: uppercase;
    font-style: normal;
    img {
      margin-right: 20px;
      width: 80px;
    }
    margin-bottom: 20px;
  }
  .sub {
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%;
    text-transform: uppercase;
  }

    .carouselContent {
      .text {
        font-weight: 600;
        font-size: 24px;
        line-height: 130%;
      }
    }
  }
`;
export default CarouselCardBeta;
