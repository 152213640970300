import axios from "axios";
import { useMemo, useState } from "react";

const getTotalNFTCollections = async () => {
    try {
        const { data } = await axios.get(
            process.env.REACT_APP_API_URL + "/marketOverview/totalNFTCollections?type=full"
        )
        return data;
    }
    catch (error) {
        return error;
    }
}

export function useNFTCollections() {
    const [api, setAPIData] = useState<any>({
        loading: true,
        error: false,
        data: undefined
    });



    useMemo(async () => {
        const data = await getTotalNFTCollections();
        setAPIData({
            loading: false,
            error: false,
            data:
                data?.length > 0 &&
                data?.map((item: any) => {
                    return {
                        ...item,
                        dateTime: new Date(item.date_time).getTime(),
                    }
                }
                ) || []
        })
    }, [])
    return api;
}
