import axios from "axios";
import ContentCardCtr from "components/ContentCard";
import DataTable from "../MarketDataTable";
import { DropDownCard } from "components/DropDown";
import QuestionHelper from "components/QuestionHelper";
import Row from "components/Row";
import React, { useCallback, useEffect, useState, useRef } from "react";
import { AlertCircle, ArrowLeft, ArrowRight, Search } from "react-feather";
import styled from "styled-components";
import { TEXT } from "theme/texts";
import { useCurrencyUnit } from "store/user/hooks";
import SKLTCollectionsTable from "../SKLTCollectionsTable";
import MarketDataTable from "../MarketDataTable";
import {
  GenericImageWrapper,
  SnapshotBrandLogoImageWrapper,
} from "components/Logo";
import { isMobile } from "react-device-detect";
import assets from "assets";
import useTakeScreenshot from "hooks/useTakeSnapshot";

const TopMarket = () => {
  //constants
  const SORT_DIR = { ASEC: "asc", DESC: "desc" };
  const SORT_BY = { VOL: "volume", SALES: "sales", WALLET: "wallet" };
  const { currency } = useCurrencyUnit();
  //states
  const [tableData, setTableData] = useState(null);
  const [sortBy, setSortBy] = useState("volume");
  const [sortDir, setSortDir] = useState("desc");
  const [query, setQuery] = useState(""); //page query endpoint
  const [chain, setChain] = useState("crosschain"); //crosschain

  const [currentTime, setCurrentTime] = useState({ active: 0, duration: "1d" });
  const [supplyLimit, setSupplyLimit] = useState(0);
  const [category, setCategory] = useState({
    id: 0,
    name: "All",
  });
  const refCard = useRef(null);
  const snapshotDownloadIconId = `snapshot-${0}-download-icon`;
  const snapshotBrandLogoId = `snapshot-${0}-brand-logo`;

  const downloadScreenshot = useTakeScreenshot({
    ref: refCard,
    elementIdsTohide: [snapshotDownloadIconId],
    elementIdsToShow: [snapshotBrandLogoId],
  });

  //callbacks
  const fetchData = useCallback(async () => {
   
    if (chain === "crosschain") {
      let sortedData;
      let resultArray = await axios.get(
        `${process.env.REACT_APP_API_URL}/topMarketplaceCrosschain?chain=${chain}&duration=${currentTime.duration}`
      );
      const mergedData = {};
      const feteData = await resultArray.data.data;
      console.log(`merged data`, resultArray);
      feteData.forEach((element) => {
        const { contract_name, volume, wallets, sales, logo_url } = element;
        if (mergedData[contract_name]) {
          mergedData[contract_name].volume += volume;
          mergedData[contract_name].wallet += wallets;
          mergedData[contract_name].sales += sales;
          mergedData[contract_name].logo_url = logo_url;
        } else {
          mergedData[contract_name] = {
            contract_name,
            volume,
            sales,
            wallets,
            logo_url,
          };
        }
      });
      const crosschainData = Object.values(mergedData);
      // if (sortBy === "volume" && sortDir === "asc") {
      //   sortedData = crosschainData.sort((a, b) => a.volume + b.volume);
      // }
      // if (sortBy === "volume" && sortDir === "desc") {
      //   sortedData = crosschainData.sort((a, b) => a.volume - b.volume);
      // }
      // if (sortBy === "sales" && sortDir === "asc") {
      //   sortedData = crosschainData.sort((a, b) => a.sales + b.sales);
      // }
      // if (sortBy === "sales" && sortDir === "desc") {
      //   sortedData = crosschainData.sort((a, b) => a.sales - b.sales);
      // }
      // if (sortBy === "wallet" && sortDir === "asc") {
      //   sortedData = crosschainData.sort((a, b) => a.wallet + b.wallet);
      // }
      // if (sortBy === "wallet" && sortDir === "desc") {
      //   sortedData = crosschainData.sort((a, b) => a.wallet - b.wallet);
      // } else {
      //   sortedData = crosschainData;
      // }
      if (sortBy) {
        sortedData = crosschainData
          // ?.filter((item) => item.name)
          ?.sort((a, b) => {
            const sortValueA = a[sortBy];
            const sortValueB = b[sortBy];
            return sortDir === "desc"
              ? sortValueB - sortValueA
              : sortValueA - sortValueB;
          });
      }
console.log(crosschainData)
      setTableData(sortedData);
    } else {
      let res = await axios.get(
        `${process.env.REACT_APP_API_URL}/topMarketplaceCrosschain?chain=${chain}&duration=${currentTime.duration}`
      );
      let sortedData;

      const crosschainData = Object.values(res.data.data.data);
      if (sortBy === "volume" && sortDir === "asc") {
        sortedData = crosschainData.sort((a, b) => a.volume + b.volume);
      }
      if (sortBy === "volume" && sortDir === "desc") {
        sortedData = crosschainData.sort((a, b) => a.volume - b.volume);
      }
      if (sortBy === "sales" && sortDir === "asc") {
        sortedData = crosschainData.sort((a, b) => a.sales + b.sales);
      }
      if (sortBy === "sales" && sortDir === "desc") {
        sortedData = crosschainData.sort((a, b) => a.sales - b.sales);
      }
      if (sortBy === "wallet" && sortDir === "asc") {
        sortedData = crosschainData.sort((a, b) => a.wallet + b.wallet);
      }
      if (sortBy === "wallet" && sortDir === "desc") {
        sortedData = crosschainData.sort((a, b) => a.wallet - b.wallet);
      } else {
        sortedData = crosschainData;
      }

      setTableData(sortedData);
    }

    // setTableData(res.data);
  }, [currentTime, category, chain, query, sortBy, sortDir, supplyLimit]);
  const sortItem = ({ sort_by }) => {
    setSortBy(sort_by);
    setSortDir((prev) =>
      prev === SORT_DIR.ASEC ? SORT_DIR.DESC : SORT_DIR.ASEC
    );
  };
  const columns = [
    {
      id: 1,
      heading: "",
      value: "no",
    },

    {
      id: 2,
      heading: "Platforms",
      value: "contract_name",
      link: "contractAddress",
      logo: "logo_url",
    },
    {
      id: 3,
      heading: "Volume",
      value:
        currency?.symbol === "USD"
          ? "volume"
          : currency?.symbol === "ETH"
          ? "volume"
          : "volume",
      action: () => {
        sortItem({ sort_by: SORT_BY.VOL });
      },
      sort_dir: sortDir,
      sort_item: SORT_BY.VOL,
    },

    {
      id: 4,
      heading: "Sales",
      value: "sales",
      action: () => {
        sortItem({ sort_by: SORT_BY.SALES });
      },
      sort_dir: sortDir,
      sort_item: SORT_BY.SALES,
    },

    {
      id: 5,
      heading: "Wallets",
      value: "wallets",
      action: () => {
        sortItem({ sort_by: SORT_BY.WALLET });
      },
      sort_dir: sortDir,
      sort_item: SORT_BY.WALLET,
    },
  ];
 
const [first, setfirst] = useState(1)
  useEffect(() => {
    if(first < 3) {
      setfirst(first+1);
    }
    fetchData();
  }, [fetchData,first,setfirst,setTableData]);

  return (
    <MarketCollectionsTableCtr ref={refCard}>
      <ContentCardCtr hideHover={false}>
        <div className="_tableHeader">
          <TEXT.MediumHeader>
            <span>Top Marketplaces </span>
            <QuestionHelper text="Top Marketplaces across ecosystem" />
          </TEXT.MediumHeader>
          <div className="_rightHeaderCtr">
            <div className="_timelines">
              <DropDownCard
                data={[
                  {
                    val: 0,
                    label: "CrossChain ",
                    action: () => {
                      setSupplyLimit(0);
                      setChain("crosschain");
                    },
                  },

                  {
                    val: 1,
                    label: "Ethereum",
                    action: () => {
                      setSupplyLimit(1);
                      setChain("rest");
                    },
                  },
                  {
                    val: 1,
                    label: "Polygon",
                    action: () => {
                      setSupplyLimit(2);
                      setChain("polygon");
                    },
                  },
                  {
                    val: 2,
                    label: "BNB Chain",
                    action: () => {
                      setSupplyLimit(3);
                      setChain("bnb");
                    },
                  },
                  {
                    val: 3,
                    label: "Arbitrum One",
                    action: () => {
                      setSupplyLimit(4);
                      setChain("arbitrum");
                    },
                  },
                  {
                    val: 4,
                    label: "Optimism",
                    action: () => {
                      setSupplyLimit(5);
                      setChain("optimism");
                    },
                  },
                  {
                    val: 5,
                    label: "Avalanche",
                    action: () => {
                      setSupplyLimit(6);
                      setChain("avax");
                    },
                  },
                  // {
                  //   val: 6,
                  //   label: "Cronos",
                  //   action: () => {
                  //     setSupplyLimit(6);
                  //     setChain("cronos");
                  //   },
                  // },
                  // {
                  //   val: 7,
                  //   label: "PlatON",
                  //   action: () => {
                  //     setSupplyLimit(7);
                  //     setChain("platon");
                  //   },
                  // },
                  // {
                  //   val: 8,
                  //   label: "Moonbeam",
                  //   action: () => {
                  //     setSupplyLimit(8);
                  //     setChain("moonbeam");
                  //   },
                  // },
                  {
                    val: 6,
                    label: "Fantom",
                    action: () => {
                      setSupplyLimit(7);
                      setChain("fantom");
                    },
                  },
                  // {
                  //   val: 100000,
                  //   label: "Gnosis",
                  //   action: () => {
                  //     setSupplyLimit(10);
                  //     setChain("gnosis");
                  //   },
                  // },
                ]}
                activeTab={supplyLimit}
              />
            </div>

            <div className="_timelines">
              <DropDownCard
                data={[
                  {
                    val: 0,
                    label: "1 day",
                    action: () => {
                      setCurrentTime({ active: 0, duration: "1d" });
                    },
                  },
                  {
                    val: 1,
                    label: "7 day",
                    action: () => {
                      setCurrentTime({ active: 1, duration: "7d" });
                    },
                  },
                  {
                    val: 2,
                    label: "30 Day",
                    action: () => {
                      setCurrentTime({ active: 2, duration: "30d" });
                    },
                  },
                  {
                    val: 3,
                    label: "All",
                    action: () => {
                      setCurrentTime({ active: 3, duration: "all" });
                    },
                  },
                ]}
                activeTab={currentTime.active}
              />
            </div>
            <GenericImageWrapper
              id={snapshotDownloadIconId}
              className="_cardDownloadIcon"
              onClick={() => downloadScreenshot()}
              src={assets.icons.roundedDownloadIcon}
              size="2rem"
            />
            <SnapshotBrandLogoImageWrapper
              id={snapshotBrandLogoId}
              src={assets.logos.surfaceboardXYZLogo}
              size="6rem"
              style={{ marginRight: isMobile ? "8px" : "10px" }}
            />
            <SnapshotBrandLogoImageWrapper
              id={snapshotBrandLogoId}
              src={assets.logos.surfaceboardXYZLogo}
              size="6rem"
              style={{ marginRight: isMobile ? "8px" : "10px" }}
            />
          </div>
        </div>
        {tableData?.length > 0 ? (
          <MarketDataTable
            column={columns}
            data={tableData}
            sortItemLabel={sortBy}
          />
        ) : (
          <SKLTCollectionsTable />
        )}
      </ContentCardCtr>
    </MarketCollectionsTableCtr>
  );
};

const MarketCollectionsTableCtr = styled.div`
  gap: 0;

  ._navs {
    ${(props) => props?.theme?.flexRowNoWrap}
    gap:.25rem;
    width: fit-content;
    margin: 1rem;
    margin-left: auto;
    button {
      background: none;
      outline: none;
      border: none;
      color: ${(props) => props?.theme?.body};
      font-family: var(--ff-subtitle);
      display: grid;
      place-items: center;
      padding: 0.5rem;
      font-size: var(--fs-s) !important;
    }

    ._staticNavs {
      width: 70px;
      border: 1px solid ${(props) => props?.theme?.disabledVar3};
      border-radius: 2rem;
    }

    ._tableNav {
      ${(props) => props?.theme?.flexRowNoWrap}
      gap:.25rem;
      align-items: center;
      justify-content: center;
      width: fit-content;
      border: 1px solid ${(props) => props?.theme?.disabledVar3};
      border-radius: 2rem;
      ._navPageBtn {
        min-width: 100px;
      }
      ._navBtn {
        &:nth-of-type(1) {
          border-right: 1px solid ${(props) => props?.theme?.disabled};
        }
        &:nth-of-type(3) {
          border-left: 1px solid ${(props) => props?.theme?.disabled};
        }
      }
    }

    //@media quries : mobile screens
    ${({ theme }) => theme.mediaWidth.upToLarge`
      margin:1rem auto;
      `}
  }

  ._tableHeader {
    padding: 1.25rem 1rem;
    ${(props) => props?.theme?.flexRowNoWrap}
    justify-content: space-between;
    align-items: center;
    ._rightHeaderCtr {
      display: flex;
      align-items: center;
      gap: 1rem;
    }
    ._tableTitle {
      font-family: var(--ff-title);
      font-size: var(--fs-l);
      display: flex;
      gap: 0.5rem;
    }
    ._searchResultsBar {
      ${(props) => props?.theme?.flexRowNoWrap}
      align-items: center;
      gap: 0.5rem;
      border: 1px solid ${(props) => props?.theme?.disabled};
      width: 200px;
      padding: 0 0.5rem;
      border-radius: 0.25rem;

      input {
        background: none;
        color: ${(props) => props?.theme?.body};
        border: none;
        outline: none;
        padding: 0.5rem;
        font-family: var(--ff-subtitle);
      }
    }

    //@media quries : mobile screens
    ${({ theme }) => theme.mediaWidth.upToExtraSmall`
        flex-direction:column;
        gap:1rem;
        align-items:flex-start;
        padding:.75rem 1rem;
        ._rightHeaderCtr{
            // flex-direction:column;
        width:fit-content;
        align-items:flex-start;
        margin-right:auto;
        }
      `}
  }
`;

export default TopMarket;
