import { PageWrapper } from "pages/styled";
import { useEffect, useRef, useState } from "react";
import { CheckCircle, Circle } from "react-feather";
import styled from "styled-components";
import CollectionCard from "./components/CollectionCard";
import CollectionsTable from "./components/RedditGen3CollectionTable";
import { cardsData } from "./data";
import { HideMedium, ShowMedium } from "components/Hide";
import assets from "assets";
import { TEXT } from "theme/texts";
import Marquee from "components/Marquee";
import { useNFTSold } from "data/nft/nftSold";

import { useCollectionMintedToday } from "data/nft/collectionMintedToday";

import axios from "axios";
import NewLoader from "components/Loader/NewLoader";
import { useTopCollectionBySales } from "data/nft/TopCollectionBySales";
import { useAverageFloorPrices } from "data/nft/AveragePriceTopCollection";
import { formatAmount } from "utils/numbers";

const Dot = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background: ${(props) =>
    props?.active ? props?.theme?.disabledVar3 : "none"};
  border: 1px solid ${(props) => props?.theme?.disabled};
`;
const RedditGen3CollectionsPage = () => {
  const [scrollProgress, setScrollProgress] = useState(0);
  const [gasPrice, setGasPrice] = useState(0);

  const nftSold = useNFTSold();
  const collectionMintedToday = useCollectionMintedToday();

  const topCollectionBySales = useTopCollectionBySales();
  const AvgFloorPriceTopCollection = useAverageFloorPrices();
  const avgPrice =
    AvgFloorPriceTopCollection?.data?.data &&
    AvgFloorPriceTopCollection?.data?.data?.reduce((acc, curr) => {
      return acc + curr.avg_price_in_usd;
    }, 0) / AvgFloorPriceTopCollection?.data?.data?.length;
  const target = useRef();
  const scrollListener = () => {
    if (!target.current) {
      return;
    }

    const element = target.current;
    const windowScroll = element.scrollLeft; // Distance of the scrollbar from the leftmost point
    const totalWidth = element.scrollWidth - element.clientWidth; // Total width the scrollbar can traverse
    if (windowScroll === 0) {
      return setScrollProgress(0);
    }

    if (windowScroll > totalWidth) {
      return setScrollProgress(100);
    }

    setScrollProgress((windowScroll / totalWidth) * 100);
  };
  const fetchGasPrice = async () => {
    try {
      const data = await axios.get(`https://gasstation-mainnet.matic.network/`);
      setGasPrice(data?.data?.standard);
    } catch (err) {
      console.log(err);
    }
  };

  const renderDots = () => {
    let count = cardsData?.length;
    const selectedDotValue = (scrollProgress * count) / 100;
    return [...Array(count).keys()].map((index) => (
      <Dot
        key={index}
        active={selectedDotValue >= index && selectedDotValue <= index + 1}
      />
    ));
  };
  const subheader_data = [
    {
      label: "Current Gas Price",
      value: (gasPrice || 0) + " Gwei",
    },
    {
      label: "Collections Minted Today",
      value: collectionMintedToday?.records?.[0]?.collections_minted_today || 0,
    },
    {
      label: "NFTs on Sale",
      value: nftSold?.records?.[0]?.nft_count || 0,
    },
    {
      label: "Collection with highest sales(24h)",
      value:
        (topCollectionBySales?.data?.data &&
          topCollectionBySales?.data?.data[0]?.name) ||
        "TBU",
    },
    {
      label: "Avg floor price",
      value: formatAmount(avgPrice) + " USD" || "TBU",
    },
  ];
  //   useEffect(() => {
  //     target.current.addEventListener("touchmove", scrollListener);
  //     target.current.addEventListener("scroll", scrollListener);
  //     return () =>
  //       target.current &&
  //       target.current.removeEventListener("touchmove", scrollListener) &&
  //       target.current.removeEventListener("scroll", scrollListener);
  //   });

  useEffect(() => {
    fetchGasPrice();
    console.log("cards lenght", cardsData?.length);
  }, [gasPrice, cardsData?.length]);

  return cardsData?.length > 0 ? (
    <>
      <Marquee data={subheader_data} />
      <CollectionsPageCtr>
        {/* <div className="_cardsCtr">
          <div className="_cards" ref={target}>
            {cardsData?.map((card, idx) => (
              <CollectionCard card={card} key={idx} index={idx}/>
            ))}
          </div>
          <div className="_cardsNav">{renderDots(cardsData?.length)}</div>
        </div> */}
        <CollectionsTable />
      </CollectionsPageCtr>
    </>
  ) : (
    <NewLoader />
  );
};

const CollectionsPageCtr = styled(PageWrapper)`
  ${(props) => props?.theme?.flexColumnNoWrap}
  gap:2rem;
  padding-top: 2rem;
  ._cardsCtr {
    display: grid;
    gap: 1rem;
    /* Hide scrollbar for Chrome, Safari and Opera */
    &::-webkit-scrollbar {
      display: none;
    }
    /* Hide scrollbar for IE, Edge add Firefox */
    -ms-overflow-style: none;
    scrollbar-width: none; /* Firefox */

    ._cardsNav {
      display: none;
    }
    ._cards {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(290px, 1fr));

      gap: 1rem;
    }
    //@media quries : mobile screens
    ${({ theme }) => theme.mediaWidth.upToMedium`
    ._cards {
      display:flex;
      overflow-x:auto;
    }
     ._cardsNav{
      display: flex;
      width:100%;
      align-items:center;
      gap:.5rem;
      justify-content:center;
    }
    `}
  }

  .cardContentCardgBox {
    text-align: center;
    h3 {
      font-weight: 600;
      font-size: 42px;
      line-height: 110%;
      margin-block-start: 0;
      margin-block-end: 0;
    }
    p {
      font-weight: 400;
      font-size: 20px;
      line-height: 28px;
      color: #6b7280;
    }
  }
`;

const Wrapper = styled.div`
  width: 100%;
`;

const ResponsiveSubHeader = styled.div`
  width: 100%;
  min-width: 1000px;
  height: 45px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 14px 126px;
  border-bottom: 0.5px solid ${({ theme }) => theme.border2};
  border-top: 0.5px solid ${({ theme }) => theme.border2};
  justify-content: space-around;
  ${({ theme }) => theme.mediaWidth.upToMedium`
      padding: 14px 16px;
  `};
`;

const LogoWrapper = styled.div`
  padding-top: 2px;
  padding-right: 100px;
  ${({ theme }) => theme.mediaWidth.upToMedium`
     padding-right: 6px;
  `}
`;

export default RedditGen3CollectionsPage;
