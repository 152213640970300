import Column from "components/Column";
import ContentCardCtr from "components/ContentCard";
import QuestionHelper from "components/QuestionHelper";
import Row from "components/Row";
import styled from "styled-components";
import { TEXT } from "theme/texts";
import OwnersTable from "./OwnersTable";
import { formatAmount } from "utils/numbers";



const FinancialProfiles = ({ data }) => {
 

  const owners = data?.map((owner, index) => {
    return {
      id: index + 1,
      wallet: owner?.walletAddress,
      collectionOwned: owner?.collectionsOwnedCount,
      nftsOwned: owner?.nftsOwnedCount,
      profits: owner?.nftsTotalNetProfit || 0,
      avgPurchasePrice: owner?.nftsPurchasesAvgPrice || 0,
      balance: owner?.balance  || 0 ,
    };
  });


  const columns = [
    {
      heading: "",
      value: "id",
    },
    {
      heading: "Wallet",
      value: "wallet",
    },
    {
      heading: "Collection Owned",
      value: "collectionOwned",
    },
    {
      heading: "NFTs Owned",
      value: "nftsOwned",
    },
    {
      heading: "Profits",
      value: "profits",
    },
    {
        heading: "Avg Purchase Price",
        value: "avgPurchasePrice",
    },{
        heading: "Balance",
        value: "balance"
    }
  ];

  return (
    <TopOwnersCtr>
      <ContentCardCtr
        className="_ownersList"
        style={{
          height: "100%",
          padding: "0rem",
          borderRadius: 0,
          gap: 0,
        }}
      >
        <Row
          alignItems={"center"}
          alignContent={"center"}
          justifyContent={"center"}
          className="_cardTitle"
        >
          <TEXT.MediumHeader>Financial Profiles</TEXT.MediumHeader>
          <QuestionHelper
            text={"Financial Profiles of the top 20 owners of this collection."}
            iconSize="24px"
          />
        </Row>
        <OwnersTable column={columns} data={owners} />
      </ContentCardCtr>
    </TopOwnersCtr>
  );
};

const TopOwnersCtr = styled(Column)`
  width: 100%;
  max-height: 550px;
  ._cardTitle {
    padding: 1rem;
  }
  ._ownersList {
    border-radius: 0.5rem;
  }
`;
export default FinancialProfiles;
