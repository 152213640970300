import Column from "components/Column";
import { Wrapper } from "components/DropDown/styled";
import Marquee from "components/Marquee";
import React, { createRef, useEffect, useState } from "react";

import { ResponsiveRow } from "components/Row";
import { ResponsiveRowReverseByDesktop } from "pages/MarketOverview/styled";
import { PageWrapper } from "pages/styled";
import styled from "styled-components";
import TopChains from "./components/TopChains";
import axios from "axios";
import AllChainCollectionsTable from "./components/CollectionsTable";
import TopMarket from "./components/TopMarket";
import TopWallets from "./components/TopWallets";
import TopChain from "./components/TopChain";
import TopSellersWallet from "./components/TopWalletsSeller";

const LeaderBoard = () => {
  const [topMarket, settopMarket] = useState(null);
  const [topChainMarque, settopChainMarque] = useState(null);
  const [topSale, setTopSales] = useState(null);
  const [topColloction, setTopColloction] = useState(null);

  const chains = [
    "polygon",
    "rest",
    "bnb",
    "arbitrum",
    "optimism",
    "avax",
    "cronos",
    "platon",
    "moonbeam",
    "fantom",
    "gnosis",
  ];

  const TopMarketplaces = async () => {
    let res = await axios.get(
      `${process.env.REACT_APP_API_URL}/topMarketplaceCrosschain?chain=crosschain&duration=1d`
    );

    const resultArray = res.data.data;
    const mergedData = {};
    resultArray.forEach((element) => {
      const { contract_name, volume, wallets, sales, logo_url } = element;
      if (mergedData[contract_name]) {
        mergedData[contract_name].volume += volume;
        mergedData[contract_name].wallet += wallets;
        mergedData[contract_name].sales += sales;
        mergedData[contract_name].logo_url = logo_url;
      } else {
        mergedData[contract_name] = {
          contract_name,
          volume,
          sales,
          wallets,
          logo_url,
        };
      }
    });

    const crosschainData = Object.values(mergedData);

  
      let sortedData = crosschainData
        ?.filter((item) => item.volume > 0)
        ?.sort((a, b) => {
          const sortValueA = a["volume"];
          const sortValueB = b["volume"];
          return sortValueB - sortValueA
            // : sortValueA - sortValueB;
        });
    

    settopMarket(sortedData[0]);
    const sale1 = sortedData[0]?.contract_name;
    const sale2 = sortedData[1]?.contract_name;
    // const sale3 = sortedData[2]?.contract_name;
    const concatenatedString = [sale1, sale2].join(" | ");
    setTopSales(concatenatedString);
  };
  const capitalizeFirstLetter = (str) => {
    if (typeof str !== "string" || str.length === 0) {
      return str;
    }
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const TopChainMAr = async () => {
    let res = await axios.get(
      `${process.env.REACT_APP_API_URL}/topChain?chain=crosschain&duration=day`
    );

    const apiData = res?.data?.data;

    const chain1 = capitalizeFirstLetter(apiData[0].blockchainId);
    const chain2 = capitalizeFirstLetter(apiData[1].blockchainId);
    const chain3 = capitalizeFirstLetter(apiData[2].blockchainId);
    const concatenatedString = [chain1, chain2,chain3].join(" | ");

    settopChainMarque(concatenatedString);
  };

  const TopColloction = async () => {
    let res = await axios.get(
      `${process.env.REACT_APP_API_URL}/topCollectionCrosschain?chain=crosschain&page=1&sortBy=volume_1d&duration=1d&sortDirection=desc`
    );

    const apiData = res?.data?.data;
    const chain1 = capitalizeFirstLetter(apiData[0].contract_name);
    const chain2 = capitalizeFirstLetter(apiData[1].contract_name);
    const chain3 = capitalizeFirstLetter(apiData[2].contract_name);
    const concatenatedString = [chain1, chain2,chain3].join("  |  ");

    setTopColloction(concatenatedString);
  };

  useEffect(() => {
    TopMarketplaces();
    TopChainMAr();
    TopColloction();
  }, []);
  const subheader_data = [
    // {
    //   label: "Top NFT Sales Crosschain",
    //   value: topSale || "TBU",
    // },
    {
      label: "Top Marketplace",
      value: topMarket?.contract_name || "TBU",
    },
    {
      label: "Top Collections",
      value: topColloction || "TBU",
    },
    {
      label: "Top Chains",
      value: topChainMarque || "TBU",
    },
  ];

  return (
    <>
      <Marquee data={subheader_data} />
      <LeaderBoardCtr>
        <RowResponsiveRank className="">
          <ColumnN className="">
            <TopMarket />
          </ColumnN>
          <ColumnN className="">
            <TopChain />
          </ColumnN>
        </RowResponsiveRank>

        <Column
          className="_graph"
          // ref={refSalesGraph}
        >
          <AllChainCollectionsTable />
        </Column>

        <RowResponsiveRank className="">
          <ColumnN style={{ maxHeight: "560px"}} >
            <TopWallets />
          </ColumnN>
          <ColumnN style={{ maxHeight: "560px" }} >
            <TopSellersWallet />
          </ColumnN>
        </RowResponsiveRank>
      </LeaderBoardCtr>
    </>
  );
};

const LeaderBoardCtr = styled(PageWrapper)`
  padding-top: 1rem;
  /* ._graph {
    border: 1px solid ${(props) => props.theme.disabled};
  } */
  ._profileNameLabel {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 300px;
  }
  ._volAndFloorGraphsCtr,
  ._salesAndOwnersGraphsCtr {
    overflow: hidden;
    //@media quries : mobile screens
    ${({ theme }) => theme.mediaWidth.upToLarge`
      flex-direction:column;
    `}
  }
`;
const ColumnN = styled.div`
  overflow: hidden;
  width: 100%;
`;

const RowResponsiveRank = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  margin: 1rem 0;
  ${({ theme }) => theme.mediaWidth.upToLarge`
        grid-template-columns: 1fr;

    `}
`;

export default LeaderBoard;
