import assets from "assets";
import { SmallOptionButton } from "components/Button";
import LineAreaChart from "components/Chart/LineAreaChart";
import { AutoColumn } from "components/Column";
import { HorizontalDivider } from "components/Divider/inde";
import GraphTableTopHeader from "components/HeaderComponent/GraphTableTopHeader";
import { GenericImageWrapper } from "components/Logo";
import Row from "components/Row";
import { TabularNumsText } from "components/shared";
import useMatchBreakpoints from "hooks/useMatchBreakpoints";
import useTakeScreenshot from "hooks/useTakeSnapshot";
import useTheme from "hooks/useTheme";
import React, { useEffect, useMemo, useState } from "react";
import { useCurrencyUnit } from "store/user/hooks";
import { TEXT } from "theme/texts";
import {
  ChartDayData,
  GenericChartSeries,
  TimeWindow,
  TradeActionWindow,
} from "types";
import { unixToDate } from "utils/date";
import { formatAmount } from "utils/numbers";
import { getScrollToElementPath } from "utils/path";
import {
  ChartTopRightSectionWrapper,
  ChartWrapper,
  ListButtonWrapper,
} from "./styled";

const twitterShareScrollPath = getScrollToElementPath(null, null);
const snapshotDownloadIconId = "snapshot-floor-price-download-icon";
const snapshotBrandLogoId = "snapshot-floor-price-brand-logo";

const ButtonList = ({
  timeWindow,
  setTimeWindow,
  tradeActionWindow,
  setTradeActionWindow,
}: {
  timeWindow: TimeWindow;
  setTimeWindow: React.Dispatch<React.SetStateAction<TimeWindow>>;
  tradeActionWindow: TradeActionWindow;
  setTradeActionWindow: React.Dispatch<React.SetStateAction<TradeActionWindow>>;
}) => {
  return (
    <ChartTopRightSectionWrapper>
      <ListButtonWrapper>
        {[TimeWindow.threeMonth, TimeWindow.sixMonth, TimeWindow.oneYear].map(
          (option) => {
            return (
              <SmallOptionButton
                active={timeWindow === option}
                onClick={() => setTimeWindow(option)}
              >
                {option === "custom" ? (
                  <GenericImageWrapper
                    src={assets.icons.calendarIcon}
                    size="18px"
                  />
                ) : (
                  option
                )}
              </SmallOptionButton>
            );
          }
        )}
      </ListButtonWrapper>
    </ChartTopRightSectionWrapper>
  );
};

export type graphProps = {
  chartData: any;
  refGraph: React.RefObject<HTMLElement>;
  disable: boolean;
};

function FloorPriceGraph({ chartData, refGraph, disable }: graphProps) {
  const theme = useTheme();
  const isBarGraph = false;
  const { isMobile } = useMatchBreakpoints();
  const [volumeHover, setVolumeHover] = useState<number | undefined>();
  const [liquidityHover, setLiquidityHover] = useState<number | undefined>();
  const [leftLabel, setLeftLabel] = useState<string | undefined>();
  const [rightLabel, setRightLabel] = useState<string | undefined>();
  const [timeWindow, setTimeWindow] = useState(TimeWindow.oneYear);
  const [avgFloorPrice, setAvgFloorPrice] = useState(0);
  const [allData, setAllData] = useState<any[]>([]);
  const { currency } = useCurrencyUnit();
  const [tradeActionWindow, setTradeActionWindow] = useState(
    TradeActionWindow.volume
  );
  const downloadScreenshot = useTakeScreenshot({
    ref: refGraph,
    elementIdsTohide: [snapshotDownloadIconId],
    elementIdsToShow: [snapshotBrandLogoId],
    fileName: "floor-price",
    location: window.location.href,
  });;


  useEffect(() => {
    setLiquidityHover(undefined);
    setVolumeHover(undefined);
  }, []);



  useEffect(() => {
    let data = chartData?.map((day: any) => {
      return {
        ...day,
        min: currency?.symbol === "ETH" ? day?.min_in_eth : currency?.symbol === "USD" ? day?.min_in_usd : day?.min,
        avg: currency?.symbol === "ETH" ? day?.avg_in_eth : currency?.symbol === "USD" ? day?.avg_in_usd : day?.avg,
        max: currency?.symbol === "ETH" ? day?.max_in_eth : currency?.symbol === "USD" ? day?.max_in_usd : day?.max,
      }
    })
    setAllData(data);
  }, [chartData, currency?.symbol]);



  const FilterDate = (data: ChartDayData[], timeWindow: TimeWindow) => {
    let filteredData: any[] = [];
    switch (timeWindow) {
      case TimeWindow.threeMonth:
        filteredData = data.filter(
          (day) => day.date > new Date().getTime() / 1000 - 7776000
        );
        break;
      case TimeWindow.sixMonth:
        filteredData = data.filter(
          (day) => day.date > new Date().getTime() / 1000 - 15552000
        );
        break;
      case TimeWindow.oneYear:
        filteredData = data.filter(
          (day) => day.date > new Date().getTime() / 1000 - 31104000
        );
        break;
      default:
        filteredData = data;
        break;
    }
    const min =  [...filteredData]?.reverse()?.find((day) => day.avg !== 0);
    setAvgFloorPrice(min?.min);
    return filteredData;
  };

  const formattedChartData = useMemo(() => {
    if (allData) {
      return FilterDate(allData, timeWindow).map((day: any) => {
        return {
          time: disable ? new Date(day.date * 1000)?.toLocaleString("en-US", {
            hour12: true,
          })  : unixToDate(day.date),
          valueOpensea: Number(day.min),
          valueMagicEden: Number(day.avg),
          valueFractal: Number(day.max),
        };
      });
    } else {
      return [];
    }
  }, [allData, timeWindow]);
  /**
   * @notice
   * @dev 'formattedChartSeries'  must change according to requirement
   * Add according requirement (This will plot line graph with provided color and data)
   */
  const formattedChartSeries: GenericChartSeries[] = [
    { dataKey: "valueOpensea", name: "Floor", color: "rgba(20, 238, 236, 1)" },

    // { dataKey: "valueFractal", name: "Avg", color: "#1FD57B" },
  ];

  return (
    <>
      <GraphTableTopHeader
        title={"Floor Prices"}
        tooltip="The minimum Matic required to purchase a NFT from this collection"
        downloadScreenshot={downloadScreenshot}
        isMobileView={isMobile}
        snapshotBrandLogoId={snapshotBrandLogoId}
        snapshotDownloadIconId={snapshotDownloadIconId}
        tweetText={`https://twitter.com/intent/tweet?text=💜 Polygon NFT insights: Floor Price%0A📆 for: ${timeWindow}%0A🎯 ${formatAmount(
          avgFloorPrice,2
        )} ${currency?.name?.toLocaleUpperCase()} %0A%0A🔗Check out the detailed chart on MNFST Tools by @MNFSTLabs here: ${twitterShareScrollPath} `} />
      <HorizontalDivider />
      <ChartWrapper>
        <LineAreaChart
          data={formattedChartData}
          // Change this according to dataset
          series={formattedChartSeries}
          setValue={setVolumeHover}
          setLabel={setRightLabel}
          value={volumeHover}
          label={rightLabel}
          activeWindow={timeWindow}
          topRight={
            disable ? null : 
            <ButtonList
              timeWindow={timeWindow}
              setTimeWindow={setTimeWindow}
              tradeActionWindow={tradeActionWindow}
              setTradeActionWindow={setTradeActionWindow}
            />
          }
          topLeft={
            <AutoColumn gap="4px">
              <Row gap="8px">
                <TEXT.LargeHeader>
                  <TabularNumsText>
                    {`${formatAmount(avgFloorPrice, 2).toLocaleUpperCase()}`}
                  </TabularNumsText>
                </TEXT.LargeHeader>
                <GenericImageWrapper
                  src={currency?.logo}
                  size="24px"
                />
              </Row>

              {/* <TEXT.SubBody fontSize="16px">
                <TabularNumsText>
                  {formatDollarAmount(avgFloorPrice * 1.25)}
                </TabularNumsText>
              </TEXT.SubBody> */}

              <TEXT.SmallHeader fontSize="12px" height="14px">
                {rightLabel ? (
                  <TabularNumsText>{rightLabel} (UTC)</TabularNumsText>
                ) : null}
              </TEXT.SmallHeader>
            </AutoColumn>
          }
        />
      </ChartWrapper>
    </>
  );
}

export default FloorPriceGraph;
