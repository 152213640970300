import { useState, useMemo } from "react";
import CollectionCard from "./components/CollectionCard";
import { useNFTWallets } from "data/nft/nftWallets";

import { TimeWindow } from "types";
import dayjs from "dayjs";
import { formatAmount } from "utils/numbers";

const TotalNftWallet = () => {
  const [timeWindow, setTimeWindow] = useState("3M");
  const { data: nftWallets } = useNFTWallets();
  let newNFTWallets =
    (nftWallets?.length > 0 &&
      nftWallets?.map((item) => {
        return {
          ...item,
          dateTime: new Date(item.day).getTime(),
        };
      })) ||
    [];

  const totalNFTWallets = [...(newNFTWallets ?? [])].reverse();

  const filteredTotalCount = useMemo(() => {
    if (totalNFTWallets && totalNFTWallets && totalNFTWallets?.length > 0) {
      let totalCount = totalNFTWallets[0].cum_new_address;
      let percentValue = 0;

      switch (timeWindow) {
        case TimeWindow.threeMonth: {
          const beforeThreeMonthData = totalNFTWallets.find((element) =>
            dayjs(element.dateTime).isBefore(
              dayjs().subtract(3, "month"),
              "month"
            )
          );

          const beforeThreeMonthCount =
            beforeThreeMonthData?.cum_new_address ?? 0;

          const threeMonthData = totalNFTWallets.find((element) =>
            dayjs(element.dateTime).isBefore(
              dayjs().subtract(6, "month"),
              "month"
            )
          );

          const threeMonthCount = threeMonthData?.cum_new_address ?? 0;
          totalCount -= beforeThreeMonthCount;
          percentValue =
            beforeThreeMonthCount > 0
              ? ((beforeThreeMonthCount - threeMonthCount) / threeMonthCount) *
                100
              : 0;
          break;
        }
        case TimeWindow.sixMonth: {
          const beforeSixMonthData = totalNFTWallets.find((element) =>
            dayjs(element.dateTime).isBefore(
              dayjs().subtract(6, "month"),
              "month"
            )
          );
          const sixMonthData = totalNFTWallets.find((element) =>
            dayjs(element.dateTime).isBefore(
              dayjs().subtract(12, "month"),
              "month"
            )
          );

          const beforeSixMonthCount = beforeSixMonthData?.cum_new_address ?? 0;

          const sixMonthCount = sixMonthData?.cum_new_address ?? 0;

          totalCount -= beforeSixMonthCount;
          percentValue =
            beforeSixMonthCount > 0
              ? ((beforeSixMonthCount - sixMonthCount) / sixMonthCount) * 100
              : 0;
          break;
        }
        case TimeWindow.oneYear: {
          const beforeOneYearData = totalNFTWallets.find((element) =>
            dayjs(element.dateTime).isBefore(
              dayjs().subtract(1, "year"),
              "month"
            )
          );
          const OneYearData = totalNFTWallets.find((element) =>
            dayjs(element.dateTime).isBefore(
              dayjs().subtract(2, "year"),
              "month"
            )
          );
          const OneYearDataCount = OneYearData?.cum_new_address ?? 0;

          const beforeOneYearCount = beforeOneYearData?.cum_new_address ?? 0;

          totalCount -= beforeOneYearCount;
          percentValue =
            beforeOneYearCount > 0
              ? ((beforeOneYearCount - OneYearDataCount) / OneYearDataCount) *
                100
              : 0;
          break;
        }
        case TimeWindow.all:
          break;
        default:
          break;
      }

      return {
        totalCount,
        percentValue,
      };
    } else {
      return {
        totalCount: 0,
        percentValue: 0,
      };
    }
  }, [totalNFTWallets, timeWindow]);

  const volumeData = {
    title: "Total NFT Wallets",
    endpoint: `${process.env.REACT_APP_API_URL}/topCollectionByCategory`,
    toolTip: "Total NFT Wallets on Polygon",
    price_change: filteredTotalCount?.percentValue,
    value: formatAmount(filteredTotalCount?.totalCount),
    showTimePeriod: true,
    showLogo: false,
  };

  return (
    <>
      <CollectionCard card={volumeData} time={setTimeWindow} />
    </>
  );
};

export default TotalNftWallet;
