import React, { useEffect } from "react";
import { useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { formatAmount } from "utils/numbers";
import GainLossPercentComponent from "components/Percent";
import { ChevronDown, ChevronUp } from "react-feather";
import { CircledImageCtr } from "components/Logo";
import assets from "assets";
import { useCurrencyUnit } from "store/user/hooks";
import chalk from "chalk";

const TableHeadItem = ({ item, selectedItem }) => {
  return (
    <th
      className="_heading"
      onClick={item?.action ? item?.action : () => {}}
      style={item?.action ? { cursor: "pointer" } : {}}
    >
      <span>
        {item.heading}
        {item?.action ? (
          item?.sort_dir === "asc" && item?.sort_item === selectedItem ? (
            <ChevronUp size={"1rem"} />
          ) : (
            <ChevronDown size={"1rem"} />
          )
        ) : null}
      </span>
    </th>
  );
};

const TableDataItem = ({ item, column, chain }) => {
  const navigate = useNavigate();
  const currency = useCurrencyUnit();
  const [chainLogo, setchainLogo] = useState(null);

  const chainslogo = [
    "https://s2.coinmarketcap.com/static/img/coins/64x64/3890.png",
    "https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png",
    "https://s2.coinmarketcap.com/static/img/coins/64x64/1839.png",
    "https://www.ankr.com/rpc/static/media/optimism.53b7f574.svg",
    "https://s2.coinmarketcap.com/static/img/coins/64x64/11840.png",
    "https://s2.coinmarketcap.com/static/img/coins/64x64/5805.png",
    "https://s2.coinmarketcap.com/static/img/coins/64x64/3635.png",
    "https://s2.coinmarketcap.com/static/img/coins/64x64/9720.png",
    "https://s2.coinmarketcap.com/static/img/coins/64x64/6836.png",
    "https://s2.coinmarketcap.com/static/img/coins/64x64/3513.png",
    "https://s2.coinmarketcap.com/static/img/coins/64x64/1659.png",
  ];

  const chainlogo = () => {
    if (chain === "polygon") {
      console.log(chainslogo[0]);
      // return chainslogo[0]
      setchainLogo(chainslogo[0]);
    } else if (chain === "rest") {
      setchainLogo(chainslogo[1]);
    } else if (chain === "bnb") {
      setchainLogo(chainslogo[2]);
    } else if (chain === "arbitrum") {
      setchainLogo(chainslogo[3]);
    } else if (chain === "optimism") {
      setchainLogo(chainslogo[4]);
    } else if (chain === "avax") {
      setchainLogo(chainslogo[5]);
    } else if (chain === "cronos") {
      setchainLogo(chainslogo[6]);
    } else if (chain === "platon") {
      setchainLogo(chainslogo[7]);
    } else if (chain === "moonbeam") {
      setchainLogo(chainslogo[8]);
    } else if (chain === "fantom") {
      setchainLogo(chainslogo[9]);
    } else if (chain === "gnosis") {
      setchainLogo(chainslogo[10]);
    } else {
    }
  };
  const chainlogof = (/* The above code is not valid JavaScript code. It appears to be a comment in a
  syntax called "JavaScript React". The comment is using the " */
  chainId) => {
    if (chainId === "polygon") {

      return chainslogo[0]
     
    } else if (chainId === "rest") {
      return chainslogo[1];
     
    } else if (chainId === "bnb") {
      return chainslogo[2];
     
    } else if (chainId === "arbitrum") {
      return chainslogo[3];
     
    } else if (chainId === "optimism") {
      return chainslogo[4];
    } else if (chainId === "avax") {
      return chainslogo[5]
    } else if (chainId === "cronos") {
      return chainslogo[6]
    } else if (chainId === "platon") {
      return chainslogo[7]
    } else if (chainId === "moonbeam") {
      return chainslogo[8] 
    } else if (chainId === "fantom") {
      return chainslogo[9]
    } else if (chainId === "gnosis") {
      return chainslogo[10]
    } else {
    }
  };
  useEffect(() => {
    chainlogo();
  }, [chain, item]);

  return (
    <tr className="_tableData" key={item.id}>
      {column.map((colItem, idx) => {
        if (colItem.value === "no") {
          return (
            <td key={idx} className="_dataCell">
              <span>{item[`${colItem.value}`]}</span>
            </td>
          );
        } else if (
          colItem.value === "floor_change" ||
          colItem.value === "sales_change_1d" ||
          colItem.value === "sales_change_7d" ||
          colItem.value === "sales_change_30d" ||
          colItem.value === "volume_change_1d" ||
          colItem.value === "volume_change_7d" ||
          colItem.value === "volume_change_30d"
        ) {
          return (
            <td key={idx} className={"_dataCell"}>
              <GainLossPercentComponent
                value={parseFloat(item[`${colItem.value}`])}
                formatFig={true}
                fontSize={".8rem"}
              />
            </td>
          );
        } else
          return (
            <td
              key={idx}
              className="_dataCell"
              onClick={() =>
                colItem.value === "name" &&
                (item[`${colItem.link}`]?.includes("redditGen3")
                  ? navigate(`/reddit/gen3/collections`)
                  : item[`${colItem.link}`]?.includes("reddit")
                  ? navigate(`/reddit/collections`)
                  : navigate(`/collections/${item[`${colItem.link}`]}`))
              }
            >
              {colItem.value === "contract_name" ? (
                <div className="profileName">
                  <CircledImageCtr size="36px" radius=".25rem">
                    <img
                      src={
                        item[`${colItem.logo}`] ||
                        assets.icons.defaultCollectionIcon
                      }
                      alt={item[`${colItem.value}`]}
                    />
                  </CircledImageCtr>
                  <span>{item[`${colItem.value}`]}</span>
                  {chain === "crosschain"  && colItem.chain  ?   (
                    <CircledImageCtr size="18px">
                      <img
                        src={chainlogof(item[`${colItem?.chain}`])}
                        alt="currency"
                        style={{ objectFit: "contain" }}
                      />
                    </CircledImageCtr>
                  ):null}

                  
                </div>
              ) : colItem.value === "sales_1d" ||
                colItem.value === "sales_7d" ||
                colItem.value === "sales_30d" ||
                colItem.value === "items_total" ? (
                <span>
                  {formatAmount(
                    parseFloat(item[`${colItem.value}`])
                  ).toUpperCase()}
                </span>
              ) : (
                <span className="currencyLogo">
                  {chain === "crosschain" ? null : (
                    null
                  )}

                  {item[`${colItem.value}`] === undefined ||
                  item[`${colItem.value}`] === null
                    ? 0
                    : colItem.value === "sales"
                    ? formatAmount(
                        parseFloat(item[`${colItem.value}`]),
                        0
                      ).toUpperCase()
                    : formatAmount(
                        parseFloat(item[`${colItem.value}`])
                      ).toUpperCase()}
                </span>
              )}
            </td>
          );
      })}
    </tr>
  );
};
const DataTable = ({ data, column, sortItemLabel, chain }) => {
 
  
  return (
    <DataTableCtr>
      <table className="_table">
        <thead>
          <tr className="_headings">
            {column.map((item, idx) => (
              <TableHeadItem
                item={item}
                key={idx}
                selectedItem={sortItemLabel}
              />
            ))}
          </tr>
        </thead>
        <tbody className="_tableBody">
          {data.map((item, idx) => {
            return (
              <TableDataItem
                item={item}
                column={column}
                key={idx}
                chain={chain}
                id={idx}
              />
            );
          })}
        </tbody>
      </table>
    </DataTableCtr>
  );
};
const DataTableCtr = styled.div`
  min-height: 535px;
  overflow: hidden;
  overflow-x: auto;
  position: relative;
  /* width */
  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }
  /* Track */
  ::-webkit-scrollbar-track {
    width: 10px;
    height: 10px;
  }
  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.disabledVar3};
  }
  table {
    border-collapse: collapse !important;
    border-spacing: 0px !important;
  }
  ._table {
    width: 100%;
    font-family: var(--ff-subtitle);
  }
  ._headings {
    // border-top: 1px solid ${(props) => props?.theme?.disabledVar3};
    // border-bottom: 1px solid ${(props) => props?.theme?.disabledVar3};
    border-bottom: 0.5px solid #14eeec;
    background: #1c2631;
    font-family: var(--ff-title);
    display: flex;
  }
  ._heading {
    flex: 1;
    min-width: 125px;
    white-space: nowrap;
    padding: 1rem 0.5rem;
    display: flex;
    align-items: center;

    &:nth-of-type(2) {
      min-width: 250px;
      justify-content: initial;
    }
    &:nth-of-type(1) {
      min-width: initial;
      flex: 0;
      min-width: 50px;
    }
    //@media quries : mobile screens
    ${({ theme }) => theme.mediaWidth.upToLarge`
        justify-content:center;
        &:nth-of-type(1), &:nth-of-type(2) {
          position:sticky;
          left:0;
          top:0;
          z-index:10;
            background: #1C2631;
         border-bottom: 0.3px solid ${(props) => props?.theme?.btnSecondary};

    }
    &:nth-of-type(2){
     min-width: 200px;
        //  border-right: 1px solid ${(props) => props?.theme?.disabledVar3};
    }
      `}
  }
  ._tableBody {
    ${(props) => props?.theme?.flexColumnNoWrap};
    margin: 0;
  }
  table {
    border-collapse: collapse !important;
    border-spacing: 0px !important;
    padding: 0;
    margin: 0;
  }

  ._tableData {
    display: flex;
    cursor: pointer;
    min-height: 50px;

    &:nth-last-of-type(1) {
      td {
        padding-bottom: 2rem;
      }
    }
    ._dataCell {
      flex: 1;
      min-width: 125px;
      display: flex;
      align-items: center;
      padding: 0.5rem;
      &:nth-of-type(1) {
        flex: 0;
        min-width: 50px;
      }
      &:nth-of-type(2) {
        min-width: 250px;
        justify-content: initial;
      }
      .currencyLogo {
        width: 100%;
        display: flex;
        align-items: center;
        gap: 0.15rem;
      }
      .profileName {
        width: 100%;
        display: flex;
        align-items: center;
        gap: 0.5rem;
        .profileImg {
          width: 1.5rem;
          height: 1.5rem;
          border-radius: 50%;
        }
        span {
          max-width: 200px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          display: initial !important;
        }
      }
      //@media quries : mobile screens
      ${({ theme }) => theme.mediaWidth.upToLarge`
         span {
        text-align: center;
        display: flex;
        justify-content: center;
      }
      justify-content:center;
        &:nth-of-type(1), &:nth-of-type(2) {
          position:sticky;
          left:0;
          top:0;
              z-index:10;
          // background:${(props) => props?.theme?.bgModal};
          background: #1C2631;

    }
    &:nth-of-type(2){
     min-width: 200px;
              // border-right: 1px solid ${(props) =>
                props?.theme?.disabledVar3};
    }
      `}
    }
  }
`;
export default DataTable;
