import axios from "axios";
import ContentCardCtr, { ContentCardCtrB } from "components/ContentCard";
import DataTable from "../DataTable";
import { useCallback, useEffect, useState } from "react";
import { AlertCircle, ArrowLeft, ArrowRight, Search } from "react-feather";
import styled from "styled-components";
import { useCurrencyUnit } from "store/user/hooks";
import SKLTCollectionsTable from "components/Skeletons/CollectionsPage/SKLTCollectionsTable";
import { collectionAddresses, collectionURl } from "pages/LandingPage/data";

const CollectionsTable = () => {
  //constants
  const SORT_DIR = { ASEC: "asec", DESC: "desc" };
  const SORT_BY = { FLOOR: "floor", VOL: "volume", SALES: "sales" };
  const { currency } = useCurrencyUnit();
  //states
  const [tableData, setTableData] = useState(null);
  const [page, setPage] = useState(1);
  const [sortBy, setSortBy] = useState(null);
  const [sortDir, setSortDir] = useState(null);
  const [query, setQuery] = useState(""); //page query endpoint
  const [currentTime, setCurrentTime] = useState(2);
  const [category, setCategory] = useState({
    id: 0,
    name: "All",
  });

  const [AvailableCategories, setAvailableCategories] = useState([]);
  








  //callbacks
  const fetchData = useCallback(async () => {
    let res = await axios.get(
      process.env.REACT_APP_API_URL +
        `/topCollection?&category=PFP`
    );

    // d.contractAddress !== "0x0afba5d5ebe6beff2a9d9378c35b82e86931e79c"
    const dataf = await res?.data?.all?.filter(d => collectionAddresses.includes(d.contractAddress) );
    const filteredData = dataf.filter(item => {
      // Check if the address matches any in the checkingData
      const matchingData = collectionURl.find(d => d.address === item.contractAddress);

      // If there's a match and the imgUrl is false or not equal to the item's imgUrl
      // then replace the item's imgUrl
      if (matchingData && (matchingData.imgUrl !== false )) {
        item.logo = matchingData.imgUrl;
        return true;  // keep this item in the filtered array
      }
      return !!matchingData;  // keep this item in the filtered array if there's a match
    });
 
    
// console.log("============vgfgfg=xx=======", filteredData)

    setTableData(await filteredData);
    setAvailableCategories(
      res.data?.category?.map((item, index) => {
        return {
          id: index,
          name: item,
        };
      })
    );
    if (category?.id > 0 && !res.data?.category?.includes(category?.name)) {
      setCategory({
        id: 0,
        name: "All",
      });
    }
  }, [currentTime, category, page, query, sortBy, sortDir]);
  const sortItem = ({ sort_by }) => {
    setSortBy(sort_by);
    setSortDir((prev) =>
      prev === SORT_DIR.ASEC ? SORT_DIR.DESC : SORT_DIR.ASEC
    );
  };

  const columns = [
    {
      id: 1,
      heading: "",
      value: "no",
    },

    {
      id: 2,
      heading: "Name",
      value: "name",
      link: "contractAddress",
      logo: "logo",
    },
    {
      id: 3,
      heading: "Volume",
      value:
        currency?.symbol === "USD"
          ? "volume_in_usd"
          : currency?.symbol === "ETH"
          ? "volume_in_eth"
          : "volume_in_matic",
    },
    {
      id: 4,
      heading: "Sales",
      value: "sales",
    },
    {
      id: 5,
      heading: "Floor",
      value:
        currency?.symbol === "USD"
          ? "floor_in_usd"
          : currency?.symbol === "ETH"
          ? "floor_in_eth"
          : "floor_in_matic",
    },
    {
      id: 6,
      heading: "Floor Change",
      value: "floor_change",
    },
    {
      id: 7,
      heading: "Supply",
      value: "supply",
    },
    {
      id: 8,
      heading: "Avg Price",
      value:
        currency?.symbol === "USD"
          ? "avg_price_in_usd"
          : currency?.symbol === "ETH"
          ? "avg_price_in_eth"
          : "avg_price_in_matic",
    },
    {
      id: 9,
      heading: "Volume Change",
      value: "volume_change",
    },
    {
      id: 10,
      heading: "Sales Change",
      value: "sales_change",
    },
    // {
    //   id: 11,
    //   heading: "Wash Trades",
    //   value:
    //     currency?.symbol === "USD"
    //       ? "wash_trades_in_usd"
    //       : currency?.symbol === "ETH"
    //       ? "wash_trades_in_eth"
    //       : "wash_trades_in_matic",
    // },
  ];

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <CollectionsTableCtr>
      <ContentCardCtrB hideHover={false}>
        {tableData?.length > 0 ? (
          <DataTable
            column={columns}
            data={tableData?.slice(0, 100)}
            sortItemLabel={sortBy}
          />
        ) : (
          <SKLTCollectionsTable />
        )}
      </ContentCardCtrB>
    </CollectionsTableCtr>
  );
};

const CollectionsTableCtr = styled.div`
  gap: 0;
  overflow-x: auto;
  ._navs {
    ${(props) => props?.theme?.flexRowNoWrap}
    gap:.25rem;
    width: fit-content;
    margin: 1rem;
    margin-left: auto;
    button {
      background: none;
      outline: none;
      border: none;
      color: ${(props) => props?.theme?.body};
      font-family: var(--ff-subtitle);
      display: grid;
      place-items: center;
      padding: 0.5rem;
      font-size: var(--fs-s) !important;
    }

    ._staticNavs {
      width: 70px;
      border: 1px solid ${(props) => props?.theme?.disabledVar3};
      border-radius: 2rem;
    }

    ._tableNav {
      ${(props) => props?.theme?.flexRowNoWrap}
      gap:.25rem;
      align-items: center;
      justify-content: center;
      width: fit-content;
      border: 1px solid ${(props) => props?.theme?.disabledVar3};
      border-radius: 2rem;
      ._navPageBtn {
        min-width: 100px;
      }
      ._navBtn {
        &:nth-of-type(1) {
          border-right: 1px solid ${(props) => props?.theme?.disabled};
        }
        &:nth-of-type(3) {
          border-left: 1px solid ${(props) => props?.theme?.disabled};
        }
      }
    }

    //@media quries : mobile screens
    ${({ theme }) => theme.mediaWidth.upToLarge`
      margin:1rem auto;
      `}
  }

  ._tableHeader {
    padding: 1.25rem 1rem;
    ${(props) => props?.theme?.flexRowNoWrap}
    justify-content: space-between;
    align-items: center;
    ._rightHeaderCtr {
      display: flex;
      align-items: center;
      gap: 1rem;
    }
    ._tableTitle {
      font-family: var(--ff-title);
      font-size: var(--fs-l);
      display: flex;
      gap: 0.5rem;
    }
    ._searchResultsBar {
      ${(props) => props?.theme?.flexRowNoWrap}
      align-items: center;
      gap: 0.5rem;
      border: 1px solid ${(props) => props?.theme?.disabled};
      width: 200px;
      padding: 0 0.5rem;
      border-radius: 0.25rem;

      input {
        background: none;
        color: ${(props) => props?.theme?.body};
        border: none;
        outline: none;
        padding: 0.5rem;
        font-family: var(--ff-subtitle);
      }
    }

    //@media quries : mobile screens
    ${({ theme }) => theme.mediaWidth.upToExtraSmall`
        flex-direction:column;
        gap:1rem;
        align-items:flex-start;
        padding:.75rem 1rem;
        ._rightHeaderCtr{
            flex-direction:column;
        width:fit-content;
        align-items:flex-start;
        margin-right:auto;
        }
      `}
  }
`;

export default CollectionsTable;
