// import contentFont from "./fonts/Roobert-Regular.ttf";
// import subtitleFont from "./fonts/Roobert-SemiBold.ttf";
// import titleFont from "./fonts/Roobert-Bold.ttf";
// import GeneralSans from "./fonts/GeneralSans/GeneralSans-Regular.ttf";
// import Intern500 from "./fonts/Inter/Inter-SemiBold.ttf";

import contentFont from "./fonts/Saira-VariableFont.ttf";
import subtitleFont from "./fonts/Saira-VariableFont.ttf";
import titleFont from"./fonts/Saira-VariableFont.ttf";
import GeneralSans from "./fonts/Saira-VariableFont.ttf";
import Intern500 from "./fonts/Saira-VariableFont.ttf";


//icons
import bargraphIcon from "./icons/icon-bargraph.png";
import heartIcon from "./icons/icon-heart.png";
import calendarIcon from "./icons/icon-calendar.svg";
import downloadIcon from "./icons/icon-download.svg";
import ecosystemIcon from "./icons/icon-ecosystem.svg";
import filledDownArrowIcon from "./icons/icon-filled-down-arrow.svg";
import infoIcon from "./icons/icon-info.png";
import linegraphIcon from "./icons/icon-linegraph.svg";
import marketplacesIcon from "./icons/icon-marketplaces.svg";
import navMenuIcon from "./icons/icon-nav-menu.svg";
import recentIcon from "./icons/icon-recent.svg";
import searchIcon from "./icons/icon-search.svg";
import trendingUpIcon from "./icons/icon-trending-up.svg";
import verifyIcon from "./icons/icon-verify.svg";
import closeIcon from "./icons/icon-close.png";
import roundedDownloadIcon from "./icons/icon-roundedDownloadButton.svg";
import ticketsIcon from "./icons/icon-tickets.svg";
import musicIcon from "./icons/icon-music.svg";
import robotIcon from "./icons/icon-robot.svg";
import gridIcon from "./icons/icon-grid.svg";
import defiIcon from "./icons/icon-defi.svg";
import gameIcon from "./icons/icon-game.svg";
import utilIcon from "./icons/icon-util.svg";
import likeIcon from "./icons/icon-like.svg";
import entertainmentIcon from "./icons/icon-entertainment.svg";
import miscxIcon from "./icons/icon-miscx.svg";
import userIcon from "./icons/icon-user.svg";
import artIcon from "./icons/icon-art.svg";
import dailyPriceIcon from "./icons/icon-daily-price.svg";
import defaultCollectionIcon from "./icons/icon-defaultCollection.svg";
import moneyBagIcon from "./icons/icon-money-bag.svg";
import rocketIcon from "./icons/icon-rocket.svg";
import sandClockIcon from "./icons/icon-sand-clock.svg";
import thinkingEmojiIcon from "./icons/icon-thinking-emoji.svg";

//logos
import discordWhiteLogo from "./logos/logo-discord-white.svg";
import discordLogo from "./logos/logo-discord.svg";
import ethscanLogo from "./logos/logo-ethScan.svg";
import mediumWhiteLogo from "./logos/logo-medium-white.svg";
import maticLogo from "./logos/maticn.svg";
import maticBlack from "./logos/maticBlack.svg";
import usd from "./logos/usd.svg";
import usdBlack from "./logos/usdBlack.svg";
import eth  from "./logos/eth.svg";
import ethBlack from "./logos/ethBlack.svg";




// import mediumLogo from "./logos/logo-medium.svg";
import polygonLogoWithName from "./logos/logo-polygon-with-name.png";
import polygonLogoIcon from "./logos/logo-polygon.png";
import polygonLogoCircle from "./logos/logo-polyon-circled.svg";
import twitterLogo from "./logos/logo-twitter.svg";
import twitterWhiteLogo from "./logos/logo-twitter-white.svg";
import openseaLogo from "./logos/logo-opensea.svg";
import openseaLogoCircled from "./logos/logo-opensea-circle.svg";
import onelanetNFTLogo from "./logos/logo-oneplanetNFT.png";
import raribleLogo from "./logos/logo-rarible.png";
import magicEdenLogo from "./logos/logo-magic-eden.svg";
import nftradeLogo from "./logos/logo-nfttrade.png";
import layerELogo from "./logos/logo-layerE.png";
import layerELogoF from "./logos/logo-layerEF.png";

import matic from "./logos/matic.svg";
import ethLogo from "./logos/logo-eth.svg";
import ethLogoIcon from "./logos/logo-eth-icon.svg";
import usdLogo from "./logos/logo-usd.svg";
import surfaceboardLogo from "./logos/logo-surfaceboard.svg";
import surfaceboardLogoCircled from "./logos/logo-surfaceboard-circled.svg";
import surfaceboardXYZLogo from "./logos/logo-surfaceboard-xyz.png";
import instagramLogo from "./logos/logo-instagram.svg";
import linkedinLogo from "./logos/logo-linkedin.svg";
import solanaLogo from "./logos/logo-solana.svg";
import yootsLogo from "./logos/logo-yoots.svg";
import zettaLogo from "./logos/logo-zettablock.svg";

//images
import bgSignupBanner from "./imges/bgSignupBanner.svg";
import genesis_pass from "./imges/genesis_pass.png";
import Beta from "./imges/BETA.png";
import  frame from "./imges/frame.png";
import  one from "./imges/one.png";
import  bgf from "./imges/bgf.png";



import news from "./imges/NEWS.png";

// import bg from "./imges/bg.svg";
import bgmain from "./imges/bgmain.png";
import nft1 from "./imges/nft1.png";
import nft2 from "./imges/nft2.jpg";
import top1 from "./imges/top1.webp";
import top2 from "./imges/top2.png";
import top3 from "./imges/top3.png";
import top4 from "./imges/top4.gif";
import top5 from "./imges/top5.gif";
import blog1 from "./imges/blog1.svg";
import blog2 from "./imges/blog2.svg";
import bgline1 from "./imges/bgline.png";
import bgline2 from "./imges/bglinewithstar.png";
import starbg from "./imges/starbg.png";
import bgLogin from "./imges/bgLogin.svg";
import bgYoots from "./imges/bgYoots.svg";
import bgNFTPopup from "./imges/bgNftPopup.svg";

import circle from "./imges/circle.png";
import yoots3 from "./imges/yoots3.png";
import yootsCard from "./imges/yoots3Card.png";
import yoots from "./imges/yoots.png";
import nfts1 from "./imges/nfts1.png";
import nfts2 from "./imges/nfts2.png";
import nfts3 from "./imges/nfts3.png";
import sb from "./imges/sb.png";
import br from "./imges/br.png";
import tl from "./imges/tl.png";
import fullcircle from "./imges/fullcircle.png";
import passleft from "./imges/passleft.png";
import passright from "./imges/passright.png";
import passmobil from "./imges/passmobil.png";
import yootsmobile from "./imges/yootsmobile.png";

import bubbuls from "./imges/bubbuls.png";
import unft1 from "./imges/unft1.png";
import unft2 from "./imges/unft2.png";
import unft3 from "./imges/unft3.png";
import unftm1 from "./imges/unftm1.png";
import unftm2 from "./imges/unftm2.png";
import unftm3 from "./imges/unftm3.png";

import rpass from "./imges/rpass.png";
import rpassm from "./imges/rpassm.png";
import epassl from "./imges/epassl.png";
import epassm from "./imges/epassm.png";
import epassr from "./imges/epassr.png";
import rolly from "./imges/rolly.png";
import rollym from "./imges/rollym.png";
import formImg from "./imges/fromImg.png";
import tmint from "./imges/tmint.png";
import tmintlog from "./imges/tmintlog.png";

import bgup from "./imges/bgup.png";
import bgup2 from "./imges/bgup2.png";
import discordi from "./imges/discordi.png";
import twitteri from "./imges/twitteri.png";
import gropcard from "./imges/gropcard.png";
import bgsing from "./imges/bgsing.png";
import bgsingm from "./imges/bgsingm.png";
import signp from "./imges/signp.png";
import tool from "./imges/LOGO TOOLS.png";
import loaderImg from "./imges/loader.gif";
import go from "./imges/go.png";

import wellL from "./imges/wellL.png";
import wellR from "./imges/wellR.png";






// drops

import Tentacle from "./drops/Tentacle.webp";
import Dumbiez from "./drops/Dumbiez.webp";
import Bada from "./drops/Bada.webp";
import Bigwig from "./drops/Bigwig.jpg";
import Bitcoin from "./drops/Bitcoin.webp";
import CodeBLUE from "./drops/CodeBLUE.webp";
import ColonyX from "./drops/ColonyX.webp";
import Digisport from "./drops/Digisport.webp";
import Duke from "./drops/Duke.webp";
import Friends from "./drops/Friends.jpg";
import Gangsters from "./drops/Gangsters.webp";
import Good from "./drops/Good.webp";
import LazyCatBlissVille from "./drops/LazyCatBlissVille.webp";
import Lost from "./drops/Lost.webp";
import Monkey  from "./drops/Monkey.webp";
import Obtgons from "./drops/Obtgons.webp";
import Poker from "./drops/Poker.webp";
import Portal from "./drops/Portal.webp";
import Queens from "./drops/Queens.webp";
import XVerified from "./drops/XVerified.webp";
import Original from "./drops/original.webp";




const drop = {
  Bada,
  Bigwig,
  Bitcoin,
  CodeBLUE,
  ColonyX,
  Digisport,
  Duke,
  XVerified,
  Queens,Portal,
  Poker,
  Obtgons,
  Monkey,Lost,LazyCatBlissVille,Good,Gangsters,Friends,
  Original,


  Dumbiez,
  Tentacle,
};

const fonts = {
  contentFont,
  subtitleFont,
  titleFont,
  GeneralSans,
  Intern500,
};
const icons = {
  heartIcon,
  bargraphIcon,
  calendarIcon,
  downloadIcon,
  ecosystemIcon,
  filledDownArrowIcon,
  infoIcon,
  linegraphIcon,
  marketplacesIcon,
  navMenuIcon,
  recentIcon,
  searchIcon,
  trendingUpIcon,
  verifyIcon,
  closeIcon,
  roundedDownloadIcon,
  musicIcon,
  robotIcon,
  ticketsIcon,
  userIcon,
  gridIcon,
  defiIcon,
  gameIcon,
  entertainmentIcon,
  miscxIcon,
  utilIcon,
  likeIcon,
  artIcon,
  dailyPriceIcon,
  defaultCollectionIcon,
  moneyBagIcon,
  rocketIcon,
  sandClockIcon,
  thinkingEmojiIcon,
};
const logos = {
  zettaLogo,
  discordWhiteLogo,
  discordLogo,
  ethscanLogo,
  mediumWhiteLogo,
  polygonLogoWithName,
  polygonLogoIcon,
  polygonLogoCircle,
  twitterLogo,
  twitterWhiteLogo,
  openseaLogo,
  onelanetNFTLogo,
  raribleLogo,
  magicEdenLogo,
  nftradeLogo,
  layerELogo,
  layerELogoF,
  matic,
  ethLogo,
  ethLogoIcon,
  usdLogo,
  surfaceboardLogo,
  instagramLogo,
  linkedinLogo,
  surfaceboardLogoCircled,
  surfaceboardXYZLogo,
  solanaLogo,
  yootsLogo,
  openseaLogoCircled,
  maticLogo,
  maticBlack, usd,usdBlack,
  eth, ethBlack
};
const images = {
  frame,
  bgf,
  loaderImg,
  bgsing,
  bgsingm,
  signp,
  bgup,
  bgup2,
  discordi,
  twitteri,gropcard,
  bgSignupBanner,
  genesis_pass,
  top5,
  wellL,
  wellR,
  // bg,
  tmint,
  tmintlog,
  bgmain,
  nft1,
  one,
  nft2,
  top1,
  top2,
  top3,
  top4,
  go,
  blog1,
  blog2,
  bgline1,
  bgline2,
  starbg,
  bgLogin,
  bgYoots,
  bgNFTPopup,
  yoots,
  yoots3,
  yootsCard,
  circle,
  nfts1,
  nfts2,
  nfts3,
  sb,
  tool,
  br,
  tl,
  fullcircle,
  yootsmobile,
  passleft,
  passright,
  passmobil,
  rollym,
  rolly,
  formImg,
  bubbuls,
  unft1,
  unft2,
  unft3,
  unftm1,
  unftm2,
  unftm3,
  rpass,
  rpassm,
  epassl,
  epassm,
  epassr,
  Beta,
  news
};
const assets = {
  fonts,
  icons,
  logos,
  images,
  drop,
};
export default assets;
