import React, { useEffect } from "react";
import { useState } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { formatAmount } from "utils/numbers";
import GainLossPercentComponent from "components/Percent";
import { ChevronDown, ChevronUp } from "react-feather";
import { CircledImageCtr } from "components/Logo";
import assets from "assets";
import { useCurrencyUnit } from "store/user/hooks";

const TableHeadItem = ({ item, selectedItem }) => {
  return (
    <th
      className="_heading"
      onClick={item?.action ? item?.action : () => {}}
      style={item?.action ? { cursor: "pointer" } : {}}
    >
      <span>
        {item.heading}
        {item?.action ? (
          item?.sort_dir === "asc" && item?.sort_item === selectedItem ? (
            <ChevronUp size={"1rem"} />
          ) : (
            <ChevronDown size={"1rem"} />
          )
        ) : null}
      </span>
    </th>
  );
};

const TableDataItem = ({ item, column, chain }) => {
  const navigate = useNavigate();
  const currency = useCurrencyUnit();

  const [chainLogo, setchainLogo] = useState(null);

  const chainslogo = [
    "https://s2.coinmarketcap.com/static/img/coins/64x64/3890.png",
    "https://s2.coinmarketcap.com/static/img/coins/64x64/1027.png",
    "https://s2.coinmarketcap.com/static/img/coins/64x64/1839.png",
    "https://s2.coinmarketcap.com/static/img/coins/64x64/11841.png",
    "https://www.ankr.com/rpc/static/media/optimism.53b7f574.svg",
    "https://s2.coinmarketcap.com/static/img/coins/64x64/5805.png",
    "https://s2.coinmarketcap.com/static/img/coins/64x64/3635.png",
    "https://s2.coinmarketcap.com/static/img/coins/64x64/9720.png",
    "https://s2.coinmarketcap.com/static/img/coins/64x64/6836.png",
    "https://s2.coinmarketcap.com/static/img/coins/64x64/3513.png",
    "https://s2.coinmarketcap.com/static/img/coins/64x64/1659.png",
  ];

  const chainlogo = () => {
    if (chain === "polygon") {

      // return chainslogo[0]
      setchainLogo(chainslogo[0]);
    } else if (chain === "rest") {
      setchainLogo(chainslogo[1]);
    } else if (chain === "bnb") {
      setchainLogo(chainslogo[2]);
    } else if (chain === "arbitrum") {
      setchainLogo(chainslogo[3]);
    } else if (chain === "optimism") {
      setchainLogo(chainslogo[4]);
    } else if (chain === "avax") {
      setchainLogo(chainslogo[5]);
    } else if (chain === "cronos") {
      setchainLogo(chainslogo[6]);
    } else if (chain === "platon") {
      setchainLogo(chainslogo[7]);
    } else if (chain === "moonbeam") {
      setchainLogo(chainslogo[8]);
    } else if (chain === "fantom") {
      setchainLogo(chainslogo[9]);
    } else if (chain === "gnosis") {
      setchainLogo(chainslogo[10]);
    } else {
    }
  };
  useEffect(() => {
    chainlogo();
  }, [chain]);
  return (
    <tr className="_tableData" key={item.id}>
      {column.map((colItem, idx) => {
        if (colItem.value === "no") {
          return (
            <td key={idx} className="_dataCell">
              <span></span>
            </td>
          );
        } else if (
          colItem.value === "realized_gains_volume" ||
          colItem.value === "salesVolumeChangePercentage"
        ) {
          return (
            <td key={idx} className={"_dataCell"}>
              <GainLossPercentComponent
                value={parseFloat(item[`${colItem.value}`])}
                formatFig={true}
                fontSize={".8rem"}
              />
            </td>
          );
        } else
          return (
            <td
              key={idx}
              className="_dataCell"
              onClick={() =>
                colItem.value === "name" &&
                (item[`${colItem.link}`]?.includes("redditGen3")
                  ? navigate(`/reddit/gen3/collections`)
                  : item[`${colItem.link}`]?.includes("reddit")
                  ? navigate(`/reddit/collections`)
                  : navigate(`/collections/${item[`${colItem.link}`]}`))
              }
            >
              {colItem.value === "contract_name" ||
              colItem.value === "account_address" ||
              colItem.value === "blockchainId" ? (
                <div className="profileName ">
                  {colItem.value === "account_address" ? null : (
                    <CircledImageCtr size="36px" radius=".25rem">
                      <img
                        src={
                          item[`${colItem.logo}`] ||
                          assets.icons.defaultCollectionIcon
                        }
                        alt={item[`${colItem.value}`]}
                      />
                    </CircledImageCtr>
                  )}

                  <span className="capitalize">{item[`${colItem.value}`]}</span>
                </div>
              ) : colItem.value === "floor" ? (
                <span>
                  {formatAmount(
                    parseFloat(item[`${colItem.value}`])
                  ).toUpperCase()}
                </span>
              ) : (
                <span className="currencyLogo">
                  {chain && colItem.value !== "trades_total" ? (
                    <CircledImageCtr size="15px">
                      <img
                        src={chainLogo}
                        alt="currency"
                        style={{ objectFit: "contain" }}
                      />
                    </CircledImageCtr>
                  ) : null}

                  {item[`${colItem.value}`] === undefined ||
                  item[`${colItem.value}`] === null
                    ? 0
                    : colItem.value === "sales"
                    ? formatAmount(
                        parseFloat(item[`${colItem.value}`]),
                        0
                      ).toUpperCase()
                    : formatAmount(
                        parseFloat(item[`${colItem.value}`])
                      ).toUpperCase()}
                </span>
              )}
            </td>
          );
      })}
    </tr>
  );
};
const MarketDataTable = ({ data, column, sortItemLabel, chain }) => {
  return (
    <MarketDataTableCtr>
      <table className="_table">
        <thead>
          <tr className="_headings">
            {column.map((item, idx) => (
              <TableHeadItem
                item={item}
                key={idx}
                selectedItem={sortItemLabel}
              />
            ))}
          </tr>
        </thead>
        <tbody className="_tableBody">
          {data.map((item, idx) => {
            return (
              <TableDataItem
                item={item}
                column={column}
                key={idx}
                id={idx}
                chain={chain}
              />
            );
          })}
        </tbody>
      </table>
    </MarketDataTableCtr>
  );
};
const MarketDataTableCtr = styled.div`
  overflow: auto;
  position: relative;
  max-height: 460px;
  min-height: 460px;

  .capitalize::first-letter {
    text-transform: uppercase;
  }
  table {
    border-collapse: collapse !important;
    border-spacing: 0px !important;
  }
  ._table {
    width: 100%;
    font-family: var(--ff-subtitle);
  }
  thead {
    position: sticky;
    top: 0;
    z-index: 11;
    background: linear-gradient(
        0deg,
        rgba(66, 66, 66, 0.2),
        rgba(66, 66, 66, 0.2)
      ),
      black;
  }
  ._headings {
    background: #1C2631;
         border-bottom: 0.3px solid ${(props) => props?.theme?.btnSecondary};
    font-family: var(--ff-title);
    display: flex;
  }
  ._heading {
    flex: 1;
    min-width: 145px;
    white-space: nowrap;
    padding: 1rem 0.5rem;
    display: flex;
    align-items: center;

    &:nth-of-type(2) {
      min-width: 170px;
      justify-content: initial;
    }
    &:nth-of-type(1) {
      min-width: initial;
      flex: 0;
      min-width: 30px;
    }
    //@media quries : mobile screens
    ${({ theme }) => theme.mediaWidth.upToLarge`
        justify-content:center;
        &:nth-of-type(1), &:nth-of-type(2) {
          position:sticky;
          left:0;
          top:0;
          z-index:10;
          background: #1C2631;
         border-bottom: 0.3px solid ${(props) => props?.theme?.btnSecondary};
    }
    &:nth-of-type(2){
     min-width: 200px;
         border-right: 1px solid ${(props) => props?.theme?.disabledVar3};
    }
      `}
  }
  ._tableBody {
    ${(props) => props?.theme?.flexColumnNoWrap};
    margin: 0;
    max-height: 415px;
    overflow: auto;
    ${({ theme }) => theme.mediaWidth.upToLarge`
    overflow: unset;

    `}
    ::-webkit-scrollbar {
      width: 10px;
      height: 10px;
      display: none;
    }

    ::-webkit-scrollbar-track {
      width: 10px;
      height: 10px;
    }

    ::-webkit-scrollbar-thumb {
      background: ${(props) => props.theme.disabledVar3};
    }
  }
  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    display: none;
  }

  ::-webkit-scrollbar-track {
    width: 10px;
    height: 10px;
    display: none;
  }

  ::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.disabledVar3};
  }
  table {
    border-collapse: collapse !important;
    border-spacing: 0px !important;
    padding: 0;
    margin: 0;
  }
  // thead{
  //   position: sticky;
  //   top: 0;
  //   z-index: 2;
  // }
  ._tableData {
    display: flex;
    cursor: pointer;
    min-height: 50px;

    &:nth-last-of-type(1) {
      td {
        padding-bottom: 2rem;
      }
    }
    ._dataCell {
      flex: 1;
      min-width: 125px;
      display: flex;
      align-items: center;
      padding: 0.5rem;
      padding-top: 1rem;

      &:nth-of-type(1) {
        flex: 0;
        min-width: 30px;
      }
      &:nth-of-type(2) {
        min-width: 170px;
        justify-content: initial;
      }
      .currencyLogo {
        width: 100%;
        display: flex;
        align-items: center;
        gap: 0.15rem;
      }
      .profileName {
        width: 100%;
        display: flex;
        align-items: center;
        gap: 0.5rem;
        .profileImg {
          width: 1.5rem;
          height: 1.5rem;
          border-radius: 50%;
        }
        span {
          max-width: 200px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          display: initial !important;
        }
      }
      //@media quries : mobile screens
      ${({ theme }) => theme.mediaWidth.upToLarge`
         span {
        text-align: center;
        display: flex;
        justify-content: center;
      }
      justify-content:center;
        &:nth-of-type(1), &:nth-of-type(2) {
          position:sticky;
          left:0;
          top:0;
              z-index:10;
              background: #1C2631;


    }
    &:nth-of-type(2){
     min-width: 200px;
              border-right: 1px solid ${(props) => props?.theme?.disabledVar3};
    }
      `}
    }
  }
`;
export default MarketDataTable;
