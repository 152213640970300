import assets from "assets";
import { PrimaryButton } from "components/Button";
import Column from "components/Column";
import { CircledImageCtr } from "components/Logo";
import Row from "components/Row";
import React from "react";
import styled from "styled-components";
import { TEXT } from "theme/texts";

const CarouselCard4 = () => {
  return (
    <CarouselCard4Wrapper>
      <BGWrapper>
        <img src={assets.drop.bgFourthBanner} alt="" />
      </BGWrapper>
      <LeftCtr>
        <CircledImageCtr size="100px">
          <img src={assets.images.tmintlog} style={{borderRadius: "50%"}} alt="" />
        </CircledImageCtr>
        <TEXT.LargeHeader>PolygonBoyz is now minting!</TEXT.LargeHeader>
        <PrimaryButton style={{ background: "#1B1B21", width: "100px", textDecoration:'none' }} onClick={()=> window.open("https://magiceden.io/drops/polygonboyz", "_blank")} >
          Mint Now
        </PrimaryButton>
       
      </LeftCtr>
      <RightCtr>
        <CircledImageCtr size="250px">
          <img src={assets.images.tmint} alt="" />
        </CircledImageCtr>
      </RightCtr>
    </CarouselCard4Wrapper>
  );
};
const BGWrapper = styled.div`
a{
  text-decoration: none;
}
  width: 100%;
  height: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  transform: translateY(25%);
  z-index: -1;
  img {
    width: 100%;
    min-height: 100%;
    object-fit: cover;
  }
`;
const CarouselCard4Wrapper = styled(Row)`
  justify-content: space-around;
  min-height: 100%;
  position: relative;
  z-index: 1;
`;
const LeftCtr = styled(Column)`
  width: fit-content;
  justify-content: center;
  gap: 2rem;
  padding: 1rem;
  ${({ theme }) => theme.mediaWidth.upToMedium`
    text-align:center;
    align-items:center;
    `}
`;
const RightCtr = styled(Column)`
  width: fit-content;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  display: grid;
  ${({ theme }) => theme.mediaWidth.upToMedium`
   display:none;
  `}
`;

export default CarouselCard4;
