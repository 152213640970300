import { SecondaryButton } from "components/Button";
import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

const DropsCard = ({ cardData, ongoingMint }) => {
  const navigate = useNavigate();
  function truncateText(text) {
    const truncated = text.split(" ").slice(0, 25).join(" ");
    return text.length > 25 ? `${truncated}...` : truncated;
  }

  return (
    <DropsCardWrapper>
      <div className="wrapperDrop">
        <div className="cradImageBox">
          <div className="imgContainer">
            <img src={cardData?.imageSrc}></img>
          </div>
        </div>
        <div className="cradcondentBox">
          <div>
            <div className="cardTitle">{cardData?.name}</div>
            <div className="cardDiscription">
              {truncateText(cardData?.description)}
            </div>
          </div>
          <div>
            <div className="socialBox">
              {cardData?.siteHref ? (
                <a href={cardData?.siteHref} target={"_blank"}>
                  <svg
                    className="svg1"
                    viewBox="0 0 20 20"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M14.36 12C14.44 11.34 14.5 10.68 14.5 10C14.5 9.32 14.44 8.66 14.36 8H17.74C17.9 8.64 18 9.31 18 10C18 10.69 17.9 11.36 17.74 12M12.59 17.56C13.19 16.45 13.65 15.25 13.97 14H16.92C15.9512 15.6683 14.4141 16.932 12.59 17.56ZM12.34 12H7.66C7.56 11.34 7.5 10.68 7.5 10C7.5 9.32 7.56 8.65 7.66 8H12.34C12.43 8.65 12.5 9.32 12.5 10C12.5 10.68 12.43 11.34 12.34 12ZM10 17.96C9.17 16.76 8.5 15.43 8.09 14H11.91C11.5 15.43 10.83 16.76 10 17.96ZM6 6H3.08C4.03886 4.32721 5.5748 3.06149 7.4 2.44C6.8 3.55 6.35 4.75 6 6ZM3.08 14H6C6.35 15.25 6.8 16.45 7.4 17.56C5.57862 16.9317 4.04485 15.6677 3.08 14ZM2.26 12C2.1 11.36 2 10.69 2 10C2 9.31 2.1 8.64 2.26 8H5.64C5.56 8.66 5.5 9.32 5.5 10C5.5 10.68 5.56 11.34 5.64 12M10 2.03C10.83 3.23 11.5 4.57 11.91 6H8.09C8.5 4.57 9.17 3.23 10 2.03ZM16.92 6H13.97C13.657 4.76146 13.1936 3.5659 12.59 2.44C14.43 3.07 15.96 4.34 16.92 6ZM10 0C4.47 0 0 4.5 0 10C0 12.6522 1.05357 15.1957 2.92893 17.0711C3.85752 17.9997 4.95991 18.7362 6.17317 19.2388C7.38642 19.7413 8.68678 20 10 20C12.6522 20 15.1957 18.9464 17.0711 17.0711C18.9464 15.1957 20 12.6522 20 10C20 8.68678 19.7413 7.38642 19.2388 6.17317C18.7362 4.95991 17.9997 3.85752 17.0711 2.92893C16.1425 2.00035 15.0401 1.26375 13.8268 0.761205C12.6136 0.258658 11.3132 0 10 0Z" />
                  </svg>
                </a>
              ) : null}
              {cardData?.twitterHref ? (
                <a href={cardData?.twitterHref} target={"_blank"}>
                  <svg
                    className="svg"
                    viewBox="0 0 22 17"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M21.46 2C20.69 2.35 19.86 2.58 19 2.69C19.88 2.16 20.56 1.32 20.88 0.31C20.05 0.81 19.13 1.16 18.16 1.36C17.37 0.5 16.26 0 15 0C12.65 0 10.73 1.92 10.73 4.29C10.73 4.63 10.77 4.96 10.84 5.27C7.28004 5.09 4.11004 3.38 2.00004 0.79C1.63004 1.42 1.42004 2.16 1.42004 2.94C1.42004 4.43 2.17004 5.75 3.33004 6.5C2.62004 6.5 1.96004 6.3 1.38004 6V6.03C1.38004 8.11 2.86004 9.85 4.82004 10.24C4.19077 10.4122 3.53013 10.4362 2.89004 10.31C3.16165 11.1625 3.69358 11.9084 4.41106 12.4429C5.12854 12.9775 5.99549 13.2737 6.89004 13.29C5.37367 14.4904 3.49404 15.1393 1.56004 15.13C1.22004 15.13 0.880039 15.11 0.540039 15.07C2.44004 16.29 4.70004 17 7.12004 17C15 17 19.33 10.46 19.33 4.79C19.33 4.6 19.33 4.42 19.32 4.23C20.16 3.63 20.88 2.87 21.46 2Z" />
                  </svg>
                </a>
              ) : null}
              {cardData?.discordHref ? (
                <a href={cardData?.discordHref} target={"_blank"}>
                  <svg
                    className="svg"
                    viewBox="0 0 22 17"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M18.2701 1.33005C16.9401 0.710046 15.5001 0.260046 14.0001 4.59982e-05C13.987 -0.000374605 13.9739 0.00209348 13.9618 0.00727676C13.9497 0.01246 13.9389 0.0202326 13.9301 0.0300462C13.7501 0.360046 13.5401 0.790046 13.4001 1.12005C11.8091 0.880046 10.1911 0.880046 8.60012 1.12005C8.46012 0.780046 8.25012 0.360046 8.06012 0.0300462C8.05012 0.0100462 8.02012 4.59982e-05 7.99012 4.59982e-05C6.49012 0.260046 5.06012 0.710046 3.72012 1.33005C3.71012 1.33005 3.70012 1.34005 3.69012 1.35005C0.970117 5.42005 0.220117 9.38004 0.590117 13.3C0.590117 13.32 0.600117 13.34 0.620117 13.35C2.42012 14.67 4.15012 15.47 5.86012 16C5.89012 16.01 5.92012 16 5.93012 15.98C6.33012 15.43 6.69012 14.85 7.00012 14.24C7.02012 14.2 7.00012 14.16 6.96012 14.15C6.39012 13.93 5.85012 13.67 5.32012 13.37C5.28012 13.35 5.28012 13.29 5.31012 13.26C5.42012 13.18 5.53012 13.09 5.64012 13.01C5.66012 12.99 5.69012 12.99 5.71012 13C9.15012 14.57 12.8601 14.57 16.2601 13C16.2801 12.99 16.3101 12.99 16.3301 13.01C16.4401 13.1 16.5501 13.18 16.6601 13.27C16.7001 13.3 16.7001 13.36 16.6501 13.38C16.1301 13.69 15.5801 13.94 15.0101 14.16C14.9701 14.17 14.9601 14.22 14.9701 14.25C15.2901 14.86 15.6501 15.44 16.0401 15.99C16.0701 16 16.1001 16.01 16.1301 16C17.8501 15.47 19.5801 14.67 21.3801 13.35C21.4001 13.34 21.4101 13.32 21.4101 13.3C21.8501 8.77004 20.6801 4.84005 18.3101 1.35005C18.3001 1.34005 18.2901 1.33005 18.2701 1.33005ZM7.52012 10.91C6.49012 10.91 5.63012 9.96005 5.63012 8.79005C5.63012 7.62005 6.47012 6.67005 7.52012 6.67005C8.58012 6.67005 9.42012 7.63005 9.41012 8.79005C9.41012 9.96005 8.57012 10.91 7.52012 10.91ZM14.4901 10.91C13.4601 10.91 12.6001 9.96005 12.6001 8.79005C12.6001 7.62005 13.4401 6.67005 14.4901 6.67005C15.5501 6.67005 16.3901 7.63005 16.3801 8.79005C16.3801 9.96005 15.5501 10.91 14.4901 10.91Z" />
                  </svg>
                </a>
              ) : null}
            </div>
            <SecondaryButton
              borderRadius={"0px"}
              onClick={()=> window.open(`https://magiceden.io/launchpad/polygon/${cardData?.tittle}`, "_blank")}
              // styled={{}}
              // onClick={() =>
              //   ongoingMint
              //     ? navigate(
              //         `/drops/${cardData?.name}?ongoing-mint=true`
              //       )
              //     : navigate(
              //         `/drops/${cardData?.name}?ongoing-mint=false`
              //       )
              // }
            >
              More Details
              <svg
                width="13"
                height="13"
                viewBox="0 0 13 13"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.625 5.1875V2.375H7.8125"
                  // stroke="white"
                  stroke-width="1.6875"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M7.25 5.75L10.625 2.375"
                  // stroke="white"
                  stroke-width="1.6875"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M9.125 7.25V10.25C9.125 10.3495 9.08549 10.4448 9.01517 10.5152C8.94484 10.5855 8.84946 10.625 8.75 10.625H2.75C2.65054 10.625 2.55516 10.5855 2.48484 10.5152C2.41451 10.4448 2.375 10.3495 2.375 10.25V4.25C2.375 4.15054 2.41451 4.05516 2.48484 3.98484C2.55516 3.91451 2.65054 3.875 2.75 3.875H5.75"
                  // stroke="white"
                  stroke-width="1.6875"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </SecondaryButton>
          </div>
        </div>
      </div>
    </DropsCardWrapper>
  );
};
const DropsCardWrapper = styled.div`
  // width: inherit;
  .wrapperDrop {
    /* max-width: 430px; */
    padding: 24px;
    display: flex;
    gap: 24px;
   
    background: rgba(10, 30, 30, 0.50);
    border: 1px solid #2d2f31;
    min-height: 270px;
  }

  .cradImageBox {
    .imgContainer {
      border-radius: 8px;
      overflow: hidden;
      width: 100px;
      height: 100px;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }

  .cradcondentBox {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    height: inherit;
    width: 100%;
    .cardTitle {
      font-size: 24px;
      font-weight: 600;
      line-height: 29px;
    }
    .cardDiscription {
      font-size: 16px;
      font-weight: 400;
      line-height: 19px;
      margin-top: 16px;
      margin-bottom: 20px;
    }
  }
  .svg {
    width: 22px;
    height: 17px;
  }
  .svg1 {
    width: 20px;
    height: 20px;
  }
  .socialBox {
    display: flex;
    gap: 14px;
    margin-bottom: 18px;

    a {
      background: #2d2f31;
      border: 1px solid #474747;
      border-radius: 8px;
      padding: 6px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      transition: all 0.5s;
      svg {
        fill: #919191;
      }
      :hover {
        background: #919191;

        svg {
          fill: #2d2f34;
        }
      }
    }
  }

  @media only screen and (max-width: 700px) {
    .wrapperDrop {
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      padding: 15px 12px;
      gap: 14px;
      min-height: 170px;
      height: 100%;
    }
    .cradImageBox {
      .imgContainer {
        border-radius: 8px;
        overflow: hidden;
        width: 80px;
        height: 80px;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
    .svg {
      width: 15px;
      height: 12px;
    }
    .svg1 {
      width: 13px;
      height: 13px;
    }
    .cradcondentBox {
      display: flex;
      width: 100%;

      flex-direction: column;
      justify-content: center;
      align-items: center;
      height: max-content;

      .cardTitle {
        font-size: 12px;
        font-weight: 600;
        line-height: 14px;
        text-align: center;
      }

      .cardDiscription {
        font-size: 16px;
        font-weight: 400;
        line-height: 19px;
        margin-top: 16px;
        margin-bottom: 10px;
        text-align: center;
        display: none;
      }
      button {
        width: max-content;
        padding: 8px 20px;
        font-size: 10px;
      }
      .socialBox {
        margin-top: 20px;
        display: flex;
        justify-content: center;
      }
    }
  }
`;

export default DropsCard;
