import React from "react";
import styled from "styled-components";
import { Box } from "rebass/styled-components";
import { CircledImageCtr } from "components/Logo";

export const ListTileWrapper = styled.div`
  width: 100%;
`;

const ResponsiveWrapper = styled.div<{
  width?: string;
  margin?: string;
  padding?: string;
  background?: string;
  border?: string;
  borderRadius?: string;
}>`
  width: ${({ width }) => width ?? "98%"};
  display: flex;
  flex-direction: row;
  gap: 10px;
  border-radius: 16px;
  min-height: 40px;
  margin: 1rem 0rem;
  align-items: center;
  cursor: pointer;
  margin: ${({ margin }) => margin};
  padding: ${({ padding }) => padding};
  background: ${({ background }) => background};
  border: ${({ border }) => border};
  border-radius: ${({ borderRadius }) => borderRadius};
  overflow: hidden;
  justify-content: space-evenly;
  ${({ theme }) => theme.mediaWidth.upToMedium`
   margin: 0.4rem 0rem;
  `}

`;

type props = {
  children: React.ReactElement | React.ReactElement[];
  prefixChild?: React.ReactElement;
  prefixIcon?: string;
  suffixChild?: React.ReactElement;
  onClick?: any;
};
function ResponsiveListTile({
  prefixChild,
  prefixIcon,
  children,
  suffixChild,
  onClick,
}: props) {
  return (
    <ResponsiveWrapper onClick={onClick}>
      {prefixIcon ? (
        <CircledImageCtr size="20px" radius="100%">
          <img src={prefixIcon} alt="" />
        </CircledImageCtr>
      ) : (
        prefixChild
      )}
      {children}
      {suffixChild}
    </ResponsiveWrapper>
  );
}

export default ResponsiveListTile;
